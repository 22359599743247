import React from "react";
import ConfirmationDialog from "@/lib/components/ConfirmationDialog";
import { useTranslation } from "react-i18next";
import { RequestKeyWordsI18N } from "@/translations";

/**
 * Props for the UnsavedChangesDialog component.
 *
 * @property {boolean} open - Whether the dialog is open.
 * @property {() => void} onConfirm - Function to call when the confirm action is triggered.
 * @property {() => void} onCancel - Function to call when the cancel action is triggered.
 * @property {(key: string) => string} t - Function to translate keys to localized strings.
 */
export interface UnsavedChangesDialogProps {
    open: boolean;
    onConfirm: () => void;
    onCancel: () => void;
}

/**
 * UnsavedChangesDialog component.
 *
 * @param {UnsavedChangesDialogProps} props - The props for the component.
 * @returns {React.ReactElement} The rendered UnsavedChangesDialog component.
 */
export const UnsavedChangesDialog = ({ open, onConfirm, onCancel }: UnsavedChangesDialogProps): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <ConfirmationDialog
            open={open}
            onClose={onCancel}
            onConfirm={onConfirm}
            title={t(RequestKeyWordsI18N.discardTitle)}
            message={t(RequestKeyWordsI18N.unsavedChangesMessage)}
            confirmButtonText={t(RequestKeyWordsI18N.discardButton)}
            cancelButtonText={t(RequestKeyWordsI18N.stayButton)}
            maxWidth="sm"
            fullWidth
        />
    );
};
