import {RequestApi} from "@/api/RequestApi";
import {UserApi} from "@/api/UserApi";
import {AuthenticationApi} from "@/api/AuthenticationApi";
import {ApiConfig} from "@/api/http-client";
import {FefcoCodeApi} from "@/api/FefcoCodeApi";
import {AdminApi} from "@/api/AdminApi";
import {QuoteApi} from "@/api/QuoteApi";
import {CartonGradeApi} from "@/api/CartonGradeApi";
import {OrderDataDocumentApi} from "@/api/OrderDataDocumentApi";
import {CompanyApi} from "@/api/CompanyApi";
import {AddressApi} from "./api/AddressApi";
import {ChatApi} from "@/api/ChatApi";
import {FileApi} from "@/api/FileApi";

// const getCsrfToken = () => {
//     const csrfCookie = document.cookie
//         .split('; ')
//         .find(row => row.startsWith('XSRF-TOKEN='));
//
//     return csrfCookie ? csrfCookie.split('=')[1] : null;
// }

// const axiosInstance = axios.create();
//
// // Add Axios request interceptor to include CSRF token
// axiosInstance.interceptors.request.use(
//     (config: any):any => {
//         const csrfToken = getCsrfToken();
//         if (csrfToken) {
//             config.headers['X-XSRF-TOKEN'] = csrfToken;
//         }
//         return config;
//     },
//     (error: any) => Promise.reject(error)
// );

// export interface ApiError {
//     error: string;
//     options?: {
//         status?: number;
//     }
// }

// export const handleApiError = (error: any, message: string) => {
//     if (axios.isAxiosError(error)) {
//         const errorMsg = error.response?.data?.error?.message || "Unknown Error";
//         console.error(errorMsg, error);
//         const rs: ApiError = { error: message + " " + errorMsg, {status: error.response?.status} };
//         return rs;
//     }
//     console.error("Api call failed", error);
//     const rs: ApiError = { error: message + " UnknownError", {status: 500} };
//     return rs;
// }

const apiConfig: ApiConfig = {
    baseURL: "/",
    withXSRFToken: true
};

export const authenticationApi = new AuthenticationApi(apiConfig);

export const addressApi = new AddressApi(apiConfig);

export const userApi = new UserApi(apiConfig);

export const requestApi = new RequestApi(apiConfig);

export const quoteApi = new QuoteApi(apiConfig);

export const fefcoCodeApi = new FefcoCodeApi(apiConfig);

export const cartonGradeApi = new CartonGradeApi(apiConfig);

export const orderDataDocumentApi = new OrderDataDocumentApi(apiConfig);

export const companyApi = new CompanyApi(apiConfig);

export const adminApi = new AdminApi(apiConfig);

export const chatApi = new ChatApi(apiConfig);

export const fileApi = new FileApi(apiConfig);
