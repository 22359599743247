import * as React from "react";
import { forwardRef, useMemo } from "react";
import { TextField } from "@mui/material";

/**
 * Props for the GroupBox component.
 *
 * @property {string} title - The title of the group box.
 * @property {React.ReactNode} children - The child elements to be rendered inside the group box.
 * @property {number} [paddingTop] - The top padding of the group box.
 * @property {number} [paddingBottom] - The bottom padding of the group box.
 * @property {number} [paddingLeft] - The left padding of the group box.
 * @property {number} [paddingRight] - The right padding of the group box.
 */
export type GroupBoxProps = {
    title: string;
    children: React.ReactNode;
    paddingTop?: number;
    paddingBottom?: number;
    paddingLeft?: number;
    paddingRight?: number;
    disabled?: boolean;
    variant?: "outlined" | "filled" | "standard";
    name?: string;
};

/**
 * GroupBox component.
 *
 * @param {GroupBoxProps} props - The props for the component.
 * @returns {React.ReactElement} The rendered GroupBox component.
 */
export const GroupBox: React.FC<GroupBoxProps> = ({
    title,
    children,
    paddingTop = 12,
    paddingBottom = 12,
    paddingLeft = 12,
    paddingRight = 12,
    disabled,
    variant,
    name
}: GroupBoxProps): React.ReactElement => {
    const slotProps = useMemo(
        () => ({
            input: {
                inputComponent: GroupBoxContent,
                inputProps: {
                    children,
                    width: "100%"
                },
                style: {
                    paddingTop,
                    paddingBottom,
                    paddingLeft,
                    paddingRight
                }
            },
            inputLabel: {
                shrink: true
            }
        }),
        [children, paddingTop, paddingBottom, paddingLeft, paddingRight]
    );
    return (
        <TextField
            name={name}
            fullWidth
            variant={variant ?? "outlined"}
            size="small"
            label={title}
            tabIndex={-1}
            slotProps={slotProps}
            disabled={disabled}
        />
    );
};

type GroupBoxContentProps = {
    width?: string | number;
    height?: string | number;
    paddingTop?: string | number;
    paddingBottom?: string | number;
    paddingLeft?: string | number;
    paddingRight?: string | number;
    children?: React.ReactNode;
};

const GroupBoxContent = forwardRef(
    (
        { width, height, paddingTop, paddingBottom, paddingLeft, paddingRight, children }: GroupBoxContentProps,
        ref: any
    ) => (
        <div ref={ref} style={{ width, height, paddingTop, paddingBottom, paddingLeft, paddingRight }}>
            {children}
        </div>
    )
);
GroupBoxContent.displayName = "GroupBoxContent";
