import React, { ReactNode } from "react";
import { Tooltip } from "@mui/material";
import { TOOLTIP_DELAY_MS } from "@/constants";

const tableCellContentNoWrap = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block"
};

export const TooltipContent = ({ value }: { value: ReactNode }) => (
    <Tooltip title={value} enterDelay={TOOLTIP_DELAY_MS} enterNextDelay={TOOLTIP_DELAY_MS}>
        <span style={tableCellContentNoWrap}>{value}</span>
    </Tooltip>
);
