import * as React from "react";
import { Grid2 as Grid } from "@mui/material";
import { ChangeHandler } from "@/lib/modelstate";
import StringInputField from "@/lib/components/inputfields/string-input-field";
import { CardEx } from "@/lib/components/CardEx";
import { languageOptions, salutationOptions } from "@/datacaches";
import SelectInputField from "@/lib/components/inputfields/select-input-field";
import CheckboxInputField from "@/lib/components/inputfields/checkbox-input-field";
import { CompanySignUpDTO } from "@/api/data-contracts";
import InfoTooltipIcon from "@/lib/components/InfoTooltipIcon";
import { Constants } from "@/api/constants";
import { useTranslation } from "react-i18next";
import { LanguagesI18N, SalutationsI18N, UserFormI18N } from "@/translations";

export const UserSignUpForm = ({
    model,
    errors,
    onChange
}: {
    model?: CompanySignUpDTO;
    onChange: ChangeHandler;
    errors: any;
}) => {
    const { t } = useTranslation();

    const salutationSelectOptions = salutationOptions.map((option) => ({
        label: option && t(SalutationsI18N[option]),
        value: option
    }));

    const languageSelectOptions = languageOptions.map((option) => ({
        label: option && t(LanguagesI18N[option]),
        value: option
    }));

    return (
        <CardEx title={t(UserFormI18N.userFormTitle)}>
            <Grid container spacing={3} direction="column" width="100%">
                <Grid size={{ sm: 6, md: 4, lg: 3 }}>
                    <StringInputField
                        name="email"
                        value={model?.email}
                        label={t(UserFormI18N.emailLabel)}
                        error={errors?.email}
                        maxChars={Constants.EMAIL_LENGTH_MAX}
                        endAdornment={<InfoTooltipIcon tooltipText="Sie können die Einladungs-E-Mail nicht ändern." />}
                        disabled
                    />
                </Grid>
                <Grid container direction="row" spacing={2} width="100%">
                    <Grid size={{ sm: 6, md: 4, lg: 3 }}>
                        <StringInputField
                            name="password"
                            type="password"
                            label={t(UserFormI18N.passwordLabel)}
                            value={model?.password}
                            error={errors?.password}
                            maxChars={Constants.PASSWORD_LENGTH_MAX}
                            onChange={onChange}
                        />
                    </Grid>

                    <Grid size={{ sm: 6, md: 4, lg: 3 }}>
                        <StringInputField
                            name="confirmPassword"
                            type="password"
                            label={t(UserFormI18N.confirmPasswordLabel)}
                            value={model?.confirmPassword}
                            error={errors?.confirmPassword}
                            maxChars={Constants.PASSWORD_LENGTH_MAX}
                            onChange={onChange}
                        />
                    </Grid>
                </Grid>
                <Grid size={{ sm: 4, md: 2, lg: 1 }}>
                    <SelectInputField
                        name="salutation"
                        label={t(UserFormI18N.salutationLabel)}
                        value={model?.salutation}
                        error={errors?.salutation}
                        options={salutationSelectOptions}
                        displayEmpty={true}
                        onChange={onChange}
                    />
                </Grid>
                <Grid container direction="row" spacing={2} width="100%">
                    <Grid size={{ sm: 6, md: 4, lg: 3 }}>
                        <StringInputField
                            name="firstName"
                            label={t(UserFormI18N.firstNameLabel)}
                            value={model?.firstName}
                            error={errors?.firstName}
                            maxChars={Constants.FIRSTNAME_LENGTH_MAX}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid size={{ sm: 6, md: 4, lg: 3 }}>
                        <StringInputField
                            name="lastName"
                            label={t(UserFormI18N.lastNameLabel)}
                            value={model?.lastName}
                            error={errors?.lastName}
                            maxChars={Constants.LASTNAME_LENGTH_MAX}
                            onChange={onChange}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" spacing={2} width="100%">
                    <Grid size={{ sm: 6, md: 4, lg: 3 }}>
                        <StringInputField
                            name="phone"
                            label={t(UserFormI18N.phoneLabel)}
                            value={model?.phone}
                            error={errors?.phone}
                            maxChars={Constants.PHONE_NUMBER_LENGTH_MAX}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid size={{ sm: 6, md: 4, lg: 3 }}>
                        <StringInputField
                            name="fax"
                            label={t(UserFormI18N.faxLabel)}
                            value={model?.fax}
                            error={errors?.fax}
                            maxChars={Constants.PHONE_NUMBER_LENGTH_MAX}
                            onChange={onChange}
                        />
                    </Grid>
                </Grid>
                <Grid size={{ sm: 4, md: 3, lg: 2 }}>
                    <SelectInputField
                        name="language"
                        label={t(UserFormI18N.languageLabel)}
                        value={model?.language}
                        error={errors?.language}
                        options={languageSelectOptions}
                        displayEmpty={true}
                        onChange={onChange}
                    />
                </Grid>
                <Grid size={{ sm: 6, md: 4, lg: 3 }}>
                    <CheckboxInputField
                        name="ceo"
                        label={t(UserFormI18N.isCEOLabel)}
                        onChange={onChange}
                        value={model?.ceo}
                        error={errors?.ceo}
                    />
                </Grid>
            </Grid>
        </CardEx>
    );
};
