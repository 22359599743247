/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    ErrorResponseDTO,
    RequestDTO,
    RequestDetailsDTO,
    RequestOverviewRowDTO,
    RequestSearchParamsDTO,
    RequestValidationParamsDTO
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class RequestApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags request-api
     * @name CancelPreviouslyPublishedRequest
     * @summary Cancel a previously published request with a current status of 'CREATED'
     * @request POST:/api/requests/{id}/cancel-previously-published
     * @secure
     */
    cancelPreviouslyPublishedRequest = (id: number, params: RequestParams = {}) =>
        this.request<RequestDTO, (string[] | Record<string, string>) | void | RequestDTO>({
            path: `/api/requests/${id}/cancel-previously-published`,
            method: "POST",
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags request-api
     * @name CancelRequest
     * @summary Cancel an request by ID
     * @request POST:/api/requests/{id}/cancel
     * @secure
     */
    cancelRequest = (id: number, params: RequestParams = {}) =>
        this.request<RequestDTO, (string[] | Record<string, string>) | void | RequestDTO>({
            path: `/api/requests/${id}/cancel`,
            method: "POST",
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags request-api
     * @name CreateAndPublishRequest
     * @summary Create and publish a new request
     * @request PUT:/api/requests/create-and-publish
     * @secure
     */
    createAndPublishRequest = (data: RequestDTO, params: RequestParams = {}) =>
        this.request<RequestDTO, (string[] | Record<string, string>) | void>({
            path: `/api/requests/create-and-publish`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags request-api
     * @name CreateRequest
     * @summary Create a new request
     * @request PUT:/api/requests
     * @secure
     */
    createRequest = (data: RequestDTO, params: RequestParams = {}) =>
        this.request<RequestDTO, (string[] | Record<string, string>) | void>({
            path: `/api/requests`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags request-api
     * @name DeleteRequest
     * @summary Delete a request
     * @request DELETE:/api/requests/{id}
     * @secure
     */
    deleteRequest = (id: number, params: RequestParams = {}) =>
        this.request<void, (string[] | Record<string, string>) | void>({
            path: `/api/requests/${id}`,
            method: "DELETE",
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags request-api
     * @name GetRequest
     * @summary Get an request by ID
     * @request GET:/api/requests/{id}
     * @secure
     */
    getRequest = (id: number, params: RequestParams = {}) =>
        this.request<RequestDTO, (string[] | Record<string, string>) | void | RequestDTO>({
            path: `/api/requests/${id}`,
            method: "GET",
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags request-api
     * @name GetRequestCloned
     * @summary Get cloned request template by ID
     * @request GET:/api/requests/{id}/clone
     * @secure
     */
    getRequestCloned = (id: number, params: RequestParams = {}) =>
        this.request<RequestDTO, (string[] | Record<string, string>) | void | RequestDTO>({
            path: `/api/requests/${id}/clone`,
            method: "GET",
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags request-api
     * @name GetRequestDetails
     * @summary Get all details of an request by ID
     * @request GET:/api/requests/{id}/details
     * @secure
     */
    getRequestDetails = (id: number, params: RequestParams = {}) =>
        this.request<RequestDetailsDTO, (string[] | Record<string, string>) | void | RequestDetailsDTO>({
            path: `/api/requests/${id}/details`,
            method: "GET",
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags request-api
     * @name GetRequestTemplate
     * @summary Get a template for creating a new request
     * @request GET:/api/requests/template
     * @secure
     */
    getRequestTemplate = (params: RequestParams = {}) =>
        this.request<RequestDTO, (string[] | Record<string, string>) | void>({
            path: `/api/requests/template`,
            method: "GET",
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags request-api
     * @name PublishRequest
     * @summary Publish an request by ID
     * @request POST:/api/requests/{id}/publish
     * @secure
     */
    publishRequest = (id: number, params: RequestParams = {}) =>
        this.request<RequestDTO, (string[] | Record<string, string>) | void | RequestDTO>({
            path: `/api/requests/${id}/publish`,
            method: "POST",
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags request-api
     * @name SearchRequests
     * @summary Get a list of requests for overview purposes
     * @request POST:/api/requests
     * @secure
     */
    searchRequests = (data: RequestSearchParamsDTO, params: RequestParams = {}) =>
        this.request<RequestOverviewRowDTO[], (string[] | Record<string, string>) | void | RequestOverviewRowDTO[]>({
            path: `/api/requests`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags request-api
     * @name UnpublishRequest
     * @summary Withdraw a published request.
     * @request POST:/api/requests/{id}/unpublish
     * @secure
     */
    unpublishRequest = (id: number, params: RequestParams = {}) =>
        this.request<RequestDTO, (string[] | Record<string, string>) | void | RequestDTO>({
            path: `/api/requests/${id}/unpublish`,
            method: "POST",
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags request-api
     * @name UpdateAndPublishRequest
     * @summary Update an publish an existing request by ID
     * @request PUT:/api/requests/{id}/update-and-publish
     * @secure
     */
    updateAndPublishRequest = (id: number, data: RequestDTO, params: RequestParams = {}) =>
        this.request<RequestDTO, (string[] | Record<string, string>) | void | RequestDTO>({
            path: `/api/requests/${id}/update-and-publish`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags request-api
     * @name UpdateRequest
     * @summary Update an existing request by ID
     * @request PUT:/api/requests/{id}
     * @secure
     */
    updateRequest = (id: number, data: RequestDTO, params: RequestParams = {}) =>
        this.request<RequestDTO, (string[] | Record<string, string>) | void | RequestDTO>({
            path: `/api/requests/${id}`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags request-api
     * @name ValidateRequestDates
     * @request POST:/api/requests/validate-dates
     * @secure
     */
    validateRequestDates = (data: RequestValidationParamsDTO, params: RequestParams = {}) =>
        this.request<ErrorResponseDTO, (string[] | Record<string, string>) | void>({
            path: `/api/requests/validate-dates`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
}
