import * as React from "react";
import {Grid2 as Grid} from "@mui/material";
import {CompanySignUpDTO} from "@/api/data-contracts";
import {ChangeHandler} from "@/lib/modelstate";
import {CardEx} from "@/lib/components/CardEx";
import StringInputField from "@/lib/components/inputfields/string-input-field";
import {Constants} from "@/api/constants";
import {useTranslation} from "react-i18next";
import {CompanyFormI18N} from "@/translations";
import {GroupBox} from "@/lib/components/GroupBox";
import CheckboxInputField from "@/lib/components/inputfields/checkbox-input-field";

export const CompanyDetailsForm = ({
                                       model,
                                       errors,
                                       onChange
                                   }: {
    model?: CompanySignUpDTO;
    onChange: ChangeHandler;
    errors: any;
}) => {
    const {t} = useTranslation();
    return (
        <Grid container spacing={2} width="100%">
            <Grid size={{xs: 12}}>
                <CardEx title={t(CompanyFormI18N.moreDataCompanyTitle)}>
                    <Grid container spacing={3} direction="column" width="100%">
                        <Grid size={{sm: 12, md: 6, lg: 4}}>
                            <StringInputField
                                name="officeHours"
                                value={model?.officeHours}
                                label={t(CompanyFormI18N.officeHoursLabel)}
                                error={errors?.officeHours}
                                maxChars={Constants.OFFICE_HOURS_LENGTH_MAX}
                                onChange={onChange}
                                multiline={true}
                            />
                        </Grid>
                        <Grid size={{sm: 12, md: 6, lg: 4}}>
                            <GroupBox title={t(CompanyFormI18N.machineryParkLabel)}>
                                <Grid size={{xs: 12}}>
                                    <CheckboxInputField
                                        name="miniliner"
                                        label={"Miniliner"}
                                        onChange={onChange}
                                        value={model?.miniliner}
                                        error={errors?.miniliner}
                                        dense
                                    />
                                    <CheckboxInputField
                                        name="midliner"
                                        label={"Midliner"}
                                        onChange={onChange}
                                        value={model?.midliner}
                                        error={errors?.midliner}
                                        dense
                                    />
                                    <CheckboxInputField
                                        name="maxiliner"
                                        label={"Maxiliner"}
                                        onChange={onChange}
                                        value={model?.maxiliner}
                                        error={errors?.maxiliner}
                                        dense
                                    />
                                </Grid>
                                <Grid size={{xs: 12}}>
                                    <CheckboxInputField
                                        name="dieCutting"
                                        label={"Stanzen"}
                                        onChange={onChange}
                                        value={model?.dieCutting}
                                        error={errors?.dieCutting}
                                        dense
                                    />
                                    <CheckboxInputField
                                        name="folderGluerMachine"
                                        label={"Faltschachtelklebemaschine"}
                                        onChange={onChange}
                                        value={model?.folderGluerMachine}
                                        error={errors?.folderGluerMachine}
                                        dense
                                    />
                                    <CheckboxInputField
                                        name="packaging"
                                        label={"Konfektionierung"}
                                        onChange={onChange}
                                        value={model?.packaging}
                                        error={errors?.packaging}
                                        dense
                                    />
                                </Grid>
                                <Grid size={{xs: 12}}>
                                    <CheckboxInputField
                                        name="hazardousGoodsQualification"
                                        label={"Gefahrgut-Qualifikation"}
                                        onChange={onChange}
                                        value={model?.hazardousGoodsQualification}
                                        error={errors?.hazardousGoodsQualification}
                                        dense
                                    />
                                </Grid>
                                <Grid size={{xs: 12}}>
                                    <CheckboxInputField
                                        name="flexoPrinting"
                                        label={"Flexo-Druck"}
                                        onChange={onChange}
                                        value={model?.flexoPrinting}
                                        error={errors?.flexoPrinting}
                                        dense
                                    />
                                    <CheckboxInputField
                                        name="hdFlexoPrinting"
                                        label={"HD-Flexo-Druck"}
                                        onChange={onChange}
                                        value={model?.hdFlexoPrinting}
                                        error={errors?.hdFlexoPrinting}
                                        dense
                                    />
                                    <CheckboxInputField
                                        name="offsetPrinting"
                                        label={"Offset-Druck"}
                                        onChange={onChange}
                                        value={model?.offsetPrinting}
                                        error={errors?.offsetPrinting}
                                        dense
                                    />
                                    <CheckboxInputField
                                        name="digitalPrinting"
                                        label={"Digital-Druck"}
                                        onChange={onChange}
                                        value={model?.digitalPrinting}
                                        error={errors?.digitalPrinting}
                                        dense
                                    />
                                </Grid>
                            </GroupBox>
                        </Grid>
                    </Grid>
                </CardEx>
            </Grid>
            <Grid size={{xs: 12}}>
                <CardEx title={t(CompanyFormI18N.erpDataTitle)}>
                    <Grid container spacing={3} direction="column" width="100%">
                        <Grid size={{sm: 12, md: 6, lg: 4}}>
                            <StringInputField
                                name="erpManufacturer"
                                value={model?.erpManufacturer}
                                label={t(CompanyFormI18N.erpManufacturerLabel)}
                                error={errors?.erpManufacturer}
                                maxChars={Constants.ERP_MANUFACTURER_LENGTH_MAX}
                                onChange={onChange}
                                multiline={true}
                            />
                        </Grid>
                        <Grid size={{sm: 12, md: 6, lg: 4}}>
                            <StringInputField
                                name="erpSoftwareName"
                                value={model?.erpSoftwareName}
                                label={t(CompanyFormI18N.erpSoftwareNameLabel)}
                                error={errors?.erpSoftwareName}
                                maxChars={Constants.ERP_SOFTWARE_NAME_LENGTH_MAX}
                                onChange={onChange}
                                multiline={true}
                            />
                        </Grid>
                        <Grid size={{sm: 12, md: 6, lg: 4}}>
                            <StringInputField
                                name="erpSoftwareVersion"
                                value={model?.erpSoftwareVersion}
                                label={t(CompanyFormI18N.erpSoftwareVersionLabel)}
                                error={errors?.erpSoftwareVersion}
                                maxChars={Constants.ERP_SOFTWARE_VERSION_LENGTH_MAX}
                                onChange={onChange}
                                multiline={true}
                            />
                        </Grid>

                        <Grid size={{sm: 12, md: 6, lg: 4}}>
                            <StringInputField
                                name="additionalInformation"
                                value={model?.additionalInformation}
                                label={t(CompanyFormI18N.additionalInformationLabel)}
                                error={errors?.additionalInformation}
                                maxChars={Constants.ADDITIONAL_INFORMATION_LENGTH_MAX}
                                onChange={onChange}
                                multiline={true}
                            />
                        </Grid>
                    </Grid>
                </CardEx>
            </Grid>
        </Grid>
    );
};
