// src/components/Chat/MessageList.tsx
import React, { useRef } from "react";
import { Grid2, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ChatEntryDTO, CompanyDisplayDTO, UserDisplayDTO } from "@/api/data-contracts";
import { useAuth } from "@/app/AuthContext";
import { blue, green, red, yellow } from "@mui/material/colors";
import { DateFormatter } from "@/lib/components/DateFormate";
import dayjs from "dayjs";
import { ButtonEx } from "@/lib/components/buttons/ButtonEx";
import { useTranslation } from "react-i18next";
import { ChatI18N } from "@/translations";
import { Globe, MessageCircleQuestion, MessageCircleWarning } from "lucide-react";

const messageStyle = { backgroundColor: "rgba(0, 0, 0, 0.1)", borderRadius: 4, px: 1, py: 0.5 };

interface ChatMessageListProps {
    entries: ChatEntryDTO[] | undefined;
    answerQuestion: (entry: ChatEntryDTO) => void;
    readonly?: boolean;
}

const ChatMessageList: React.FC<ChatMessageListProps> = ({ entries, readonly, answerQuestion }) => {
    const messagesEndRef = useRef<HTMLDivElement | null>(null);
    const theme = useTheme();
    const { t } = useTranslation();
    const dateFormat = DateFormatter("date_hm");
    const { company, user } = useAuth();

    // Scroll to bottom when messages change
    // useEffect(() => {
    //     messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    // }, [entries]);

    if (entries == null || entries.length === 0) {
        return <Typography>{t(ChatI18N.ChatEmpty)}</Typography>;
    }

    const icon = (entry: ChatEntryDTO) =>
        entry.forEveryone ? (
            <Globe color={blue[600]} />
        ) : entry.answer ? (
            <MessageCircleWarning color={green[800]} />
        ) : (
            <MessageCircleQuestion color={red[600]} />
        );

    const bc = (entry: ChatEntryDTO) => {
        if (entry.forEveryone) {
            return green[theme.palette.mode === "dark" ? 800 : 100];
        }
        if (entry.answer == null) {
            return red[theme.palette.mode === "dark" ? 800 : 100];
        }
        return yellow[theme.palette.mode === "dark" ? 800 : 100];
    };

    const formatTime = (time: any | undefined) => time && dayjs(time).format(dateFormat);

    const formatUser = (u: UserDisplayDTO | undefined, c: CompanyDisplayDTO | undefined) =>
        u && (u.id === user?.id ? `${t(ChatI18N.Me)}, ` : `${c?.name}, ${u.firstName} ${u.lastName}, `);

    return (
        <Grid2 container direction="column" ref={messagesEndRef} spacing={2} width="100%">
            {entries.map((entry) => (
                <Grid2
                    container
                    key={entry.id}
                    direction="column"
                    spacing={1}
                    sx={{ backgroundColor: bc(entry), borderRadius: 4, width: "100%", px: 1, py: 1 }}
                >
                    <Grid2 container direction="row" width="100%" flexWrap="nowrap">
                        <Grid2 size={{ xs: 3 }}>{icon(entry)}</Grid2>
                        <Grid2 size={{ xs: 9 }} container justifyContent="flex-end">
                            <Grid2 sx={messageStyle}>
                                <Typography marginBottom={0}>{entry.question?.message}</Typography>
                                <Typography fontSize="80%" textAlign="right">
                                    {formatUser(entry.question?.author, entry.from)}
                                    {formatTime(entry.question?.timestamp)}
                                </Typography>
                            </Grid2>
                        </Grid2>
                    </Grid2>

                    <Grid2 container direction="row" width="100%" flexWrap="nowrap">
                        <Grid2 size={{ xs: 9 }} container justifyContent="flex-start">
                            {entry.answer ? (
                                <Grid2 sx={messageStyle}>
                                    <Typography marginBottom={0}>{entry.answer?.message}</Typography>
                                    <Typography fontSize="80%">
                                        {formatUser(entry.answer?.author, entry.to)}
                                        {formatTime(entry.answer?.timestamp)}
                                    </Typography>
                                </Grid2>
                            ) : !readonly && entry.from?.id !== company?.id ? (
                                <Grid2>
                                    <ButtonEx
                                        label={t(ChatI18N.AnswerButton)}
                                        onClick={() => {
                                            answerQuestion(entry);
                                        }}
                                    />
                                </Grid2>
                            ) : (
                                <Grid2>
                                    <Typography fontStyle="italic">{t(ChatI18N.NotAnswered)}</Typography>
                                </Grid2>
                            )}
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 4 }} />
                    </Grid2>
                </Grid2>
            ))}
        </Grid2>
    );
};
export default ChatMessageList;
