import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {withAuthWrapperComponent} from "@/app/WithAuthWrapperComponent";
import {companyApi} from "@/boxhub-api";
import {useNavigate, useParams} from "react-router-dom";
import {AppFrame} from "@/app/AppFrame";
import {useModelState} from "@/lib/modelstate";
import {Grid2 as Grid} from "@mui/material";
import {useSnackbarEx} from "@/lib/snackbarex";
import {
    AddressDTO,
    CompanyActions,
    CompanyDTO,
    CompanyStatisticsDTO,
    CompanyStatus,
    UserDisplayDTO
} from "@/api/data-contracts";
import {IconButtonEx} from "@/lib/components/buttons/IconButtonEx";
import {HighlightOff, Lock, LockOpen, PersonAdd, RestartAlt} from "@mui/icons-material";
import {CompanyStatusBadge} from "@/common/CompanyStatusBadge";
import {ButtonEx} from "@/lib/components/buttons/ButtonEx";
import {CardEx} from "@/lib/components/CardEx";
import StringInputField from "@/lib/components/inputfields/string-input-field";
import SelectInputField from "@/lib/components/inputfields/select-input-field";
import {Constants} from "@/api/constants";
import {useCompanyEditSchema} from "@/pages/company-page/schema";
import {legalFormOptions} from "@/datacaches";
import {AppPaths} from "@/app/AppPaths";
import {DataTable} from "@/lib/components/DataTable";
import {ColumnDef} from "@tanstack/react-table";
import {UserStatusBadge} from "@/common/UserStatusBadge";
import {useTranslation} from "react-i18next";
import {
    AdministrationI18N,
    AuthenticationPageI18N,
    BlockingI18N,
    CompanyFormI18N,
    CompanyStatusI18N,
    CountriesI18N,
    GeneralI18N,
    LegalFormI18N,
    ReactivationI18N,
    SalutationsI18N,
    TerminationI18N,
    TerminationInitiatorI18N,
    UserFormI18N
} from "@/translations";
import {useDialogController} from "@/lib/dialog-controller";
import {UserInvitationDialog} from "@/pages/company-page/UserInvitationDialog";
import {truncateString} from "@/lib/utils";
import CheckboxInputField from "@/lib/components/inputfields/checkbox-input-field";
import {GroupBox} from "@/lib/components/GroupBox";
import {AddressEditDialog} from "@/pages/company-page/AddressEditDialog";
import ConfirmationDialog from "@/lib/components/ConfirmationDialog";
import {TerminationCompanyDialog} from "@/pages/company-page/TerminationCompanyDialog";
import DateInputField from "@/lib/components/inputfields/date-input-field";
import {getFullName} from "@/model/user";
import {LockIcon, LockOpenIcon} from "lucide-react";
import {BlockingCompanyDialog} from "@/pages/company-page/BlockingCompanyDialog";
import UndoIcon from "@mui/icons-material/Undo";

const CompanyDetailsPage = () => {
    const {t} = useTranslation();
    const params = useParams();
    const navigate = useNavigate();

    const {showMessage} = useSnackbarEx();
    const [Anker, showDialog] = useDialogController();

    const [locked, setLocked] = useState(true);

    const [companyStatistics, setCompanyStatistics] = useState<CompanyStatisticsDTO | undefined>(undefined);

    const schema = useCompanyEditSchema();

    const businessStructureSelectOptions = legalFormOptions.map((option) => ({
        label: t(LegalFormI18N[option]),
        value: option
    }));

    const {model, errors, onChange, status, isDirty, dispatch, setErrors, save, load} = useModelState<
        CompanyDTO,
        number
    >({
        initialState: {} as CompanyDTO,
        schema,
        id: +params.id!,
        loader: companyApi.getCompany,
        updater: companyApi.saveCompany,
        validateDisabled: locked
    });

    const loadCompanyStatistics = useCallback(() => {
        if (model.id) {
            companyApi.getCompanyStatistics(model.id)
                .then(response => {
                    setCompanyStatistics(response.data);
                })
                .catch(error => {
                    showMessage({
                        summary: t(CompanyFormI18N.loadStatisticsFailedError),
                        error,
                        severity: "error"
                    });
                });
        }
    }, [model.id]);

    useEffect(() => {
        loadCompanyStatistics();
    }, [model.id, loadCompanyStatistics]);

    const employeesColumns: ColumnDef<UserDisplayDTO, any>[] = [
        {
            header: t(UserFormI18N.salutationLabel),
            accessorKey: "salutation",
            accessorFn: (row) => row.salutation && t(SalutationsI18N[row.salutation])
        },
        {
            header: t(UserFormI18N.firstNameLabel),
            accessorKey: "firstName"
        },
        {
            header: t(UserFormI18N.lastNameLabel),
            accessorKey: "lastName"
        },
        {
            header: t(UserFormI18N.emailLabel),
            accessorKey: "email"
        },
        {
            header: t(UserFormI18N.phoneLabel),
            accessorKey: "telephone"
        },
        {
            header: t(AdministrationI18N.isCEOShortLabel),
            accessorKey: "isCEO",
            accessorFn: (row) => t(row?.ceo ? GeneralI18N.yes : GeneralI18N.no)
        },
        {
            header: t(AdministrationI18N.userStatusLabel),
            accessorKey: "status",
            cell: (info) => <UserStatusBadge status={info.getValue()}/>
        }
    ];

    const companyDetailsColumns: ColumnDef<AddressDTO, any>[] = [
        {header: t(CompanyFormI18N.streetLabel), accessorFn: (row) => row.street},
        {header: t(CompanyFormI18N.postalCodeLabel), accessorFn: (row) => row.zip},
        {header: t(CompanyFormI18N.cityLabel), accessorFn: (row) => row.city},
        {
            header: t(CompanyFormI18N.countryLabel),
            accessorFn: (row) => (row.country ? t(CountriesI18N[row.country]) : "")
        },
        {
            header: t(CompanyFormI18N.isCompanyHeadquarterLabel),
            accessorFn: (row) => t(row.companyAddress ? GeneralI18N.yes : GeneralI18N.no)
        },
        {
            header: t(CompanyFormI18N.isTimeSlotBookingLabel),
            accessorFn: (row) => t(row.deliveryInstruction?.timeSlotBookingRequired ? GeneralI18N.yes : GeneralI18N.no)
        }
    ];

    const navigateToCompanyOverview = () => {
        navigate(`${AppPaths.COMPANY_OVERVIEW}?status=${CompanyStatus.CONFIRMED}`, {replace: true});
    };

    const doSaveCompany = () => {
        const result = save();
        if (result) {
            result.then(() => {
                setLocked(true);
            });
        }
    };

    const approveSignUpRequest = () => {
        if (model.id !== undefined) {
            if (isDirty) {
                dispatch({type: "--reset"});
            }
            companyApi
                .approveRegistration(model.id)
                .then(() => {
                    showMessage({
                        summary: t(AdministrationI18N.approvingRequestSuccessMessage),
                        severity: "success"
                    });
                    navigateToCompanyOverview();
                })
                .catch((error) => {
                    showMessage({
                        summary: t(AdministrationI18N.approvingRequestFailedError),
                        error,
                        severity: "error"
                    });
                });
        } else {
            showMessage({summary: t(AdministrationI18N.approvingRequestFailedError), severity: "error"});
        }
    };

    const rejectSignUpRequest = () => {
        if (model.id !== undefined) {
            if (isDirty) {
                dispatch({type: "--reset"});
            }
            companyApi
                .rejectRegistration(model.id)
                .then(() => {
                    showMessage({
                        summary: t(AdministrationI18N.rejectingRequestSuccessMessage),
                        severity: "success"
                    });
                    navigateToCompanyOverview();
                })
                .catch((error) => {
                    showMessage({
                        summary: t(AdministrationI18N.rejectingRequestFailedError),
                        error,
                        severity: "error"
                    });
                });
        } else {
            showMessage({summary: t(AdministrationI18N.rejectingRequestFailedError), severity: "error"});
        }
    };

    const pageTitle = (
        <>
            {`${t(AdministrationI18N.companyLabel)} '${truncateString(model?.name, 64)}' `}
            &nbsp;
            <CompanyStatusBadge status={model?.status}/>
        </>
    );

    const handleLockToggle = () => {
        if (locked) {
            setLocked(false);
        } else {
            if (isDirty) {
                showDialog((open, close) => (
                    <ConfirmationDialog
                        open={open}
                        onClose={close}
                        onConfirm={() => {
                            dispatch({type: "--reset"});
                            setLocked(true);
                            setErrors({});
                            close();
                        }}
                        title={t(CompanyFormI18N.lockFormDialogTitle)}
                        message={t(CompanyFormI18N.lockFormDialogMessage)}
                        confirmButtonText={t(GeneralI18N.yes)}
                        cancelButtonText={t(GeneralI18N.no)}
                    />
                ));
            } else {
                setLocked(true);
                setErrors({});
            }
        }
    };

    const handleInviteUser = () => {
        showDialog((open, close) => <UserInvitationDialog open={open} close={close} onSuccess={() => load()}/>);
    };

    const handleTerminationCompany = () => {
        showDialog((open, close) => <TerminationCompanyDialog open={open} close={close} company={model}
                                                              onSuccess={() => load()}/>);
    };

    const handleRevertTerminationCompany = () => {
        showDialog((open, close) => (
            <ConfirmationDialog
                open={open}
                onClose={close}
                onConfirm={
                    () => {
                        if (model.id !== undefined) {
                            companyApi.cancelTerminationCompany(model.id)
                                .then(() => {
                                    showMessage({
                                        summary: t(TerminationI18N.terminationRevertSuccessMessage),
                                        severity: "success"
                                    });
                                    close();
                                    load();
                                })
                                .catch((error) => {
                                    showMessage({
                                        summary: t(TerminationI18N.terminationRevertFailedError),
                                        error,
                                        severity: "error"
                                    });
                                })
                        } else {
                            showMessage({
                                summary: t(TerminationI18N.terminationRevertFailedError), severity: "error"
                            });
                        }
                    }
                }
                title={t(TerminationI18N.terminationRevertConfirmationDialogTitle, {companyName: model.name})}
                message={t(TerminationI18N.terminationRevertConfirmationDialogMessage, {companyName: model.name})}
                confirmButtonText={t(GeneralI18N.yes)}
                cancelButtonText={t(GeneralI18N.no)}
            />
        ));
    };

    const handleReactivationCompany = () => {
        showDialog((open, close) => (
            <ConfirmationDialog
                open={open}
                onClose={close}
                onConfirm={
                    () => {
                        if (model.id !== undefined) {
                            companyApi.reactivateCompany(model.id)
                                .then(() => {
                                    showMessage({
                                        summary: t(ReactivationI18N.reactivateCompanySuccessMessage),
                                        severity: "success"
                                    });
                                    close();
                                    load();
                                })
                                .catch((error) => {
                                    showMessage({
                                        summary: t(ReactivationI18N.reactivateCompanyFailedError),
                                        error,
                                        severity: "error"
                                    });
                                })
                        } else {
                            showMessage({
                                summary: t(ReactivationI18N.reactivateCompanyFailedError), severity: "error"
                            });
                        }
                    }
                }
                title={t(ReactivationI18N.reactivateCompanyConfirmDialogTitle, {companyName: model.name})}
                message={t(ReactivationI18N.reactivateCompanyConfirmDialogMessage, {companyName: model.name})}
                confirmButtonText={t(GeneralI18N.yes)}
                cancelButtonText={t(GeneralI18N.no)}
            />
        ));
    };

    const handleBlockingCompany = () => {
        showDialog((open, close) => <BlockingCompanyDialog open={open} close={close} company={model}
                                                           companyStatistics={companyStatistics}
                                                           onSuccess={() => load()}/>);
    };

    const handleUnblockingCompany = () => {
        showDialog((open, close) => (
            <ConfirmationDialog
                open={open}
                onClose={close}
                onConfirm={
                    () => {
                        if (model.id !== undefined) {
                            companyApi.unlockCompany(model.id)
                                .then(() => {
                                    showMessage({
                                        summary: t(BlockingI18N.blockingRevertSuccessMessage),
                                        severity: "success"
                                    });
                                    close();
                                    load();
                                })
                                .catch((error) => {
                                    showMessage({
                                        summary: t(BlockingI18N.blockingRevertFailedError),
                                        error,
                                        severity: "error"
                                    });
                                })
                        } else {
                            showMessage({
                                summary: t(BlockingI18N.blockingRevertFailedError), severity: "error"
                            });
                        }
                    }
                }
                title={t(BlockingI18N.blockingRevertConfirmationDialogTitle, {companyName: model.name})}
                message={t(BlockingI18N.blockingRevertConfirmationDialogMessage, {companyName: model.name})}
                confirmButtonText={t(GeneralI18N.yes)}
                cancelButtonText={t(GeneralI18N.no)}
            />
        ));
    };

    const buttons = (
        <Grid container spacing={2} alignItems="center">
            {model?.allowedActions?.includes(CompanyActions.ADD_USER) && (
                <Grid>
                    <ButtonEx
                        label={t(UserFormI18N.inviteEmployeeButton)}
                        color="secondary"
                        variant="contained"
                        StartIcon={PersonAdd}
                        onClick={locked ? handleInviteUser : undefined}
                        disabled={!locked}
                    />
                </Grid>
            )}
            {model?.allowedActions?.includes(CompanyActions.REJECT_COMPANY_REGISTRATION) && (
                <Grid>
                    <ButtonEx
                        label={t(AdministrationI18N.signUpRequestRejectButton)}
                        data-cy="reject-request-button"
                        onClick={rejectSignUpRequest}
                        variant="contained"
                        color="error"
                        disabled={status === "saving" || !locked}
                    />
                </Grid>
            )}
            {model?.allowedActions?.includes(CompanyActions.CONFIRM_COMPANY_REGISTRATION) && (
                <Grid>
                    <ButtonEx
                        label={t(AdministrationI18N.signUpRequestApproveButton)}
                        data-cy="approve-request-button"
                        onClick={approveSignUpRequest}
                        variant="contained"
                        color="primary"
                        disabled={status === "saving" || !locked}
                    />
                </Grid>
            )}
            {model?.allowedActions?.includes(CompanyActions.UNLOCK_COMPANY) && (
                <Grid>
                    <ButtonEx
                        label={t(BlockingI18N.unblockCompanyButton)}
                        color="secondary"
                        variant="contained"
                        StartIcon={LockOpenIcon}
                        onClick={locked ? handleUnblockingCompany : undefined}
                        disabled={status === "saving" || !locked}
                    />
                </Grid>
            )}
            {model?.allowedActions?.includes(CompanyActions.LOCK_COMPANY) && (
                <Grid>
                    <ButtonEx
                        label={t(BlockingI18N.blockCompanyButton)}
                        color="error"
                        variant="contained"
                        StartIcon={LockIcon}
                        onClick={locked ? handleBlockingCompany : undefined}
                        disabled={status === "saving" || !locked}
                    />
                </Grid>
            )}
            {model?.allowedActions?.includes(CompanyActions.CANCEL_COMPANY_TERMINATION) && (
                <Grid>
                    <ButtonEx
                        label={t(TerminationI18N.revertTerminationButton)}
                        color="secondary"
                        variant="contained"
                        StartIcon={UndoIcon}
                        onClick={locked ? handleRevertTerminationCompany : undefined}
                        disabled={status === "saving" || !locked}
                    />
                </Grid>
            )}
            {model?.allowedActions?.includes(CompanyActions.REACTIVATE_COMPANY) && (
                <Grid>
                    <ButtonEx
                        label={t(ReactivationI18N.reactivateCompanyButton)}
                        color="secondary"
                        variant="contained"
                        StartIcon={RestartAlt}
                        onClick={locked ? handleReactivationCompany : undefined}
                        disabled={status === "saving" || !locked}
                    />
                </Grid>
            )}
            {model?.allowedActions?.includes(CompanyActions.TERMINATE_COMPANY) && (
                <Grid>
                    <ButtonEx
                        label={t(TerminationI18N.terminateCompanyButton)}
                        color="error"
                        variant="contained"
                        StartIcon={HighlightOff}
                        onClick={locked ? handleTerminationCompany : undefined}
                        disabled={status === "saving" || !locked}
                    />
                </Grid>
            )}
            {!locked && (
                <Grid>
                    <ButtonEx
                        label={t(AdministrationI18N.saveChangesButton)}
                        data-cy="save-company-button"
                        onClick={doSaveCompany}
                        variant="contained"
                        color="primary"
                        disabled={status === "saving" || !isDirty}
                    />
                </Grid>
            )}
            <Grid>
                <IconButtonEx
                    Icon={locked ? Lock : LockOpen}
                    tooltip={
                        locked
                            ? t(AdministrationI18N.unlockForEditingDescription)
                            : t(AdministrationI18N.lockEditingDescription)
                    }
                    onClick={handleLockToggle}
                    disabled={status === "saving"}
                />
            </Grid>
        </Grid>
    );

    const companyForm = (
        <CardEx title={t(CompanyFormI18N.companyFormTitle)}>
            <Grid container direction="row" spacing={2}>
                <Grid size={{xs: 12}}>
                    <StringInputField
                        name="name"
                        disabled={locked}
                        value={model?.name}
                        label={t(CompanyFormI18N.companyNameLabel)}
                        error={errors?.name}
                        maxChars={Constants.COMPANY_NAME_LENGTH_MAX}
                        onChange={onChange}
                    />
                </Grid>
                <Grid size={{xs: 12}}>
                    <SelectInputField
                        name="legalForm"
                        disabled={locked}
                        label={t(CompanyFormI18N.legalFormLabel)}
                        value={model?.legalForm}
                        error={errors?.legalForm}
                        options={businessStructureSelectOptions}
                        displayEmpty={true}
                        onChange={onChange}
                    />
                </Grid>
                <Grid size={{xs: 12}}>
                    <StringInputField
                        name="website"
                        disabled={locked}
                        value={model?.website}
                        label={t(CompanyFormI18N.companyWebsiteLabel)}
                        error={errors?.website}
                        maxChars={Constants.WEBSITE_LENGTH_MAX}
                        onChange={onChange}
                        activatePrefix={true}
                        prefixValue="https://"
                    />
                </Grid>
                <Grid size={{xs: 6}}>
                    <StringInputField
                        name="commercialRegistrationNumber"
                        disabled={locked}
                        value={model?.commercialRegistrationNumber}
                        label={t(CompanyFormI18N.commercialRegisterNumberLabel)}
                        error={errors?.commercialRegistrationNumber}
                        maxChars={Constants.COMMERCIAL_REGISTER_NUMBER_LENGTH_MAX}
                        onChange={onChange}
                    />
                </Grid>
                <Grid size={{xs: 6}}>
                    <StringInputField
                        name="vatIdentificationNumber"
                        disabled={locked}
                        value={model?.vatIdentificationNumber}
                        label={t(CompanyFormI18N.vatIdLabel)}
                        error={errors?.vatIdentificationNumber}
                        maxChars={Constants.VAT_ID_LENGTH_MAX}
                        onChange={onChange}
                    />
                </Grid>
                <Grid size={{xs: 12}}>
                    <StringInputField
                        name="officeHours"
                        disabled={locked}
                        value={model?.officeHours}
                        label={t(CompanyFormI18N.officeHoursLabel)}
                        error={errors?.officeHours}
                        maxChars={Constants.OFFICE_HOURS_LENGTH_MAX}
                        onChange={onChange}
                        multiline={true}
                    />
                </Grid>
                <Grid size={{xs: 12}}>
                    <StringInputField
                        name="additionalInformation"
                        disabled={locked}
                        value={model?.additionalInformation}
                        label={t(CompanyFormI18N.additionalInformationLabel)}
                        error={errors?.additionalInformation}
                        maxChars={Constants.ADDITIONAL_INFORMATION_LENGTH_MAX}
                        onChange={onChange}
                        multiline={true}
                    />
                </Grid>
            </Grid>
        </CardEx>
    );

    const bankForm = (
        <CardEx title={t(CompanyFormI18N.bankConnectionTitle)}>
            <Grid container direction="row" spacing={2} width="100%">
                <Grid size={{xs: 6}}>
                    <StringInputField
                        name="bankName"
                        disabled={locked}
                        value={model?.bankName}
                        label={t(CompanyFormI18N.bankNameLabel)}
                        error={errors?.bankName}
                        maxChars={Constants.BANKNAME_LENGTH_MAX}
                        onChange={onChange}
                        multiline={true}
                    />
                </Grid>
                <Grid size={{xs: 6}}>
                    <StringInputField
                        name="iban"
                        disabled={locked}
                        value={model?.iban}
                        label={t(CompanyFormI18N.ibanLabel)}
                        error={errors?.iban}
                        maxChars={Constants.IBAN_LENGTH_MAX}
                        onChange={onChange}
                        multiline={true}
                    />
                </Grid>
                <Grid size={{xs: 6}}>
                    <StringInputField
                        name="bic"
                        disabled={locked}
                        value={model?.bic}
                        label={t(CompanyFormI18N.bicLabel)}
                        error={errors?.bic}
                        maxChars={Constants.BIC_LENGTH_MAX}
                        onChange={onChange}
                        multiline={true}
                    />
                </Grid>
                <Grid size={{xs: 12}}>
                    <StringInputField
                        name="paymentTerms"
                        disabled={locked}
                        value={model?.paymentTerms}
                        label={t(CompanyFormI18N.paymentTermsLabel)}
                        error={errors?.paymentTerms}
                        maxChars={Constants.PAYMENT_TERMS_LENGTH_MAX}
                        onChange={onChange}
                        multiline={true}
                    />
                </Grid>
            </Grid>
        </CardEx>
    );

    const erpSystemForm = (
        <CardEx title={t(CompanyFormI18N.erpSystemTitle)}>
            <Grid container spacing={2} direction="row" width="100%">
                <Grid size={{xs: 12}}>
                    <StringInputField
                        name="erpManufacturer"
                        disabled={locked}
                        value={model?.erpManufacturer}
                        label={t(CompanyFormI18N.erpManufacturerLabel)}
                        error={errors?.erpManufacturer}
                        maxChars={Constants.ERP_MANUFACTURER_LENGTH_MAX}
                        onChange={onChange}
                        multiline={true}
                    />
                </Grid>
                <Grid size={{xs: 6}}>
                    <StringInputField
                        name="erpSoftwareName"
                        disabled={locked}
                        value={model?.erpSoftwareName}
                        label={t(CompanyFormI18N.erpSoftwareNameLabel)}
                        error={errors?.erpSoftwareName}
                        maxChars={Constants.ERP_SOFTWARE_NAME_LENGTH_MAX}
                        onChange={onChange}
                        multiline={true}
                    />
                </Grid>
                <Grid size={{xs: 6}}>
                    <StringInputField
                        name="erpSoftwareVersion"
                        disabled={locked}
                        value={model?.erpSoftwareVersion}
                        label={t(CompanyFormI18N.erpSoftwareVersionLabel)}
                        error={errors?.erpSoftwareVersion}
                        maxChars={Constants.ERP_SOFTWARE_VERSION_LENGTH_MAX}
                        onChange={onChange}
                        multiline={true}
                    />
                </Grid>
            </Grid>
        </CardEx>
    );

    const machineryParkForm = (
        <CardEx title={t(CompanyFormI18N.machineryParkLabel)}>
            <GroupBox title={t(CompanyFormI18N.machineryParkLabel)} disabled={locked}>
                <Grid container spacing={0}>
                    <Grid size={{sm: 6, md: 5, lg: 4}}>
                        <CheckboxInputField
                            name="maschineryFleet.miniliner"
                            disabled={locked}
                            label={"Miniliner"}
                            onChange={onChange}
                            value={model?.maschineryFleet?.miniliner}
                            error={errors?.maschineryFleet?.miniliner}
                            dense
                        />
                    </Grid>
                    <Grid size={{sm: 6, md: 5, lg: 4}}>
                        <CheckboxInputField
                            name="maschineryFleet.midliner"
                            disabled={locked}
                            label={"Midliner"}
                            onChange={onChange}
                            value={model?.maschineryFleet?.midliner}
                            error={errors?.maschineryFleet?.midliner}
                            dense
                        />
                    </Grid>
                    <Grid size={{sm: 6, md: 5, lg: 4}}>
                        <CheckboxInputField
                            name="maschineryFleet.maxiliner"
                            disabled={locked}
                            label={"Maxiliner"}
                            onChange={onChange}
                            value={model?.maschineryFleet?.maxiliner}
                            error={errors?.maschineryFleet?.maxiliner}
                            dense
                        />
                    </Grid>
                    <Grid size={{sm: 6, md: 5, lg: 4}}>
                        <CheckboxInputField
                            name="maschineryFleet.dieCutting"
                            disabled={locked}
                            label={"Stanzen"}
                            onChange={onChange}
                            value={model?.maschineryFleet?.dieCutting}
                            error={errors?.maschineryFleet?.dieCutting}
                            dense
                        />
                    </Grid>
                    <Grid size={{sm: 6, md: 5, lg: 4}}>
                        <CheckboxInputField
                            name="maschineryFleet.folderGluerMachine"
                            disabled={locked}
                            label={"Faltschachtelklebemaschine"}
                            onChange={onChange}
                            value={model?.maschineryFleet?.folderGluerMachine}
                            error={errors?.maschineryFleet?.folderGluerMachine}
                            dense
                        />
                    </Grid>
                    <Grid size={{sm: 6, md: 5, lg: 4}}>
                        <CheckboxInputField
                            name="maschineryFleet.packaging"
                            disabled={locked}
                            label={"Konfektionierung"}
                            onChange={onChange}
                            value={model?.maschineryFleet?.packaging}
                            error={errors?.maschineryFleet?.packaging}
                            dense
                        />
                    </Grid>
                    <Grid size={{sm: 6, md: 5, lg: 4}}>
                        <CheckboxInputField
                            name="maschineryFleet.hazardousGoodsQualification"
                            disabled={locked}
                            label={"Gefahrgut-Qualifikation"}
                            onChange={onChange}
                            value={model?.maschineryFleet?.hazardousGoodsQualification}
                            error={errors?.maschineryFleet?.hazardousGoodsQualification}
                            dense
                        />
                    </Grid>
                    <Grid size={{sm: 6, md: 5, lg: 4}}>
                        <CheckboxInputField
                            name="maschineryFleet.flexoPrinting"
                            disabled={locked}
                            label={"Flexo-Druck"}
                            onChange={onChange}
                            value={model?.maschineryFleet?.flexoPrinting}
                            error={errors?.maschineryFleet?.flexoPrinting}
                            dense
                        />
                    </Grid>
                    <Grid size={{sm: 6, md: 5, lg: 4}}>
                        <CheckboxInputField
                            name="maschineryFleet.hdFlexoPrinting"
                            disabled={locked}
                            label={"HD-Flexo-Druck"}
                            onChange={onChange}
                            value={model?.maschineryFleet?.hdFlexoPrinting}
                            error={errors?.maschineryFleet?.hdFlexoPrinting}
                            dense
                        />
                    </Grid>
                    <Grid size={{sm: 6, md: 5, lg: 4}}>
                        <CheckboxInputField
                            name="maschineryFleet.offsetPrinting"
                            disabled={locked}
                            label={"Offset-Druck"}
                            onChange={onChange}
                            value={model?.maschineryFleet?.offsetPrinting}
                            error={errors?.maschineryFleet?.offsetPrinting}
                            dense
                        />
                    </Grid>
                    <Grid size={{sm: 6, md: 5, lg: 4}}>
                        <CheckboxInputField
                            name="maschineryFleet.digitalPrinting"
                            disabled={locked}
                            label={"Digital-Druck"}
                            onChange={onChange}
                            value={model?.maschineryFleet?.digitalPrinting}
                            error={errors?.maschineryFleet?.digitalPrinting}
                            dense
                        />
                    </Grid>
                </Grid>
            </GroupBox>
        </CardEx>
    );

    const addressTable = (
        <CardEx title={t(AuthenticationPageI18N.addressDataTitle)}>
            <DataTable
                columns={companyDetailsColumns}
                data={model.companyAddress ? [model.companyAddress] : []}
                noResultsValue={t(AdministrationI18N.noEmployeesFoundLabel)}
                striped
                onRowClick=
                    {locked
                        ? (row) => {
                            showDialog((open, close) => (
                                <AddressEditDialog open={open} close={close} onSuccess={() => load()} address={row}/>
                            ));
                        }
                        : undefined
                    }
            />
        </CardEx>
    );

    const userTable = (
        <CardEx title={t(AdministrationI18N.companyUsersOverviewTitle)}>
            <DataTable
                columns={employeesColumns}
                data={model.users ?? []}
                noResultsValue={t(AdministrationI18N.noEmployeesFoundLabel)}
                striped
                onRowClick={(row) => {
                    navigate(AppPaths.USER_fn(row.id!));
                }}
            />
        </CardEx>
    );

    const readOnlyTermination = (
        <CardEx title={t(TerminationI18N.terminationCardTitle)}>
            <Grid container spacing={2} width="100%">
                <Grid container spacing={2} width="100%">
                    <Grid container spacing={2} width="100%">
                        <Grid size={{xs: 6}}>
                            <StringInputField
                                value={model.status ? t(CompanyStatusI18N[model.status]) : ""}
                                label={t(TerminationI18N.terminationStatusLabel)}
                                disabled
                            />
                        </Grid>
                        <Grid size={{xs: 6}}>
                            <DateInputField
                                name="termination.requestExecDate"
                                disabled
                                value={model?.termination?.requestExecDate}
                                label={t(TerminationI18N.terminationDateLabel)}
                            />
                        </Grid>
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <StringInputField
                            value={model.termination?.requestReason}
                            label={t(TerminationI18N.terminationReasonLabel)}
                            multiline
                            disabled
                        />
                    </Grid>
                    <Grid container spacing={2} width="100%">
                        <Grid size={{xs: 6}}>
                            <StringInputField
                                value={model.termination?.requestSource ? t(TerminationInitiatorI18N[model.termination?.requestSource]) : ""}
                                label={t(TerminationI18N.terminationInitiatorLabel)}
                                disabled
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} width="100%">
                        <Grid size={{xs: 6}}>
                            <DateInputField
                                name="termination.requestAt"
                                disabled
                                value={model?.termination?.requestAt}
                                label={t(TerminationI18N.terminationRequestAtLabel)}
                            />
                        </Grid>
                        <Grid size={{xs: 6}}>
                            <StringInputField
                                value={getFullName(model.termination?.requestBy)}
                                label={t(TerminationI18N.terminationRequestByLabel)}
                                disabled
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </CardEx>
    )

    const readOnlyBlocking = (
        <CardEx title={t(BlockingI18N.blockCardTitle)}>
            <Grid container spacing={2} width="100%">
                <Grid container spacing={2} width="100%">
                    <Grid size={{xs: 6}}>
                        <StringInputField
                            value={model.lock ? t(BlockingI18N.lockedLabel) : ""}
                            label={t(BlockingI18N.blockStatusLabel)}
                            disabled
                        />
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <StringInputField
                            value={model.lock?.lockedReason}
                            label={t(BlockingI18N.blockReasonLabel)}
                            multiline
                            disabled
                        />
                    </Grid>
                    <Grid container spacing={2} width="100%">
                        <Grid size={{xs: 6}}>
                            <DateInputField
                                name="lock.lockedAt"
                                disabled
                                value={model?.lock?.lockedAt}
                                label={t(BlockingI18N.lockRequestAtLabel)}
                            />
                        </Grid>
                        <Grid size={{xs: 6}}>
                            <StringInputField
                                value={getFullName(model.lock?.lockedBy)}
                                label={t(BlockingI18N.lockRequestByLabel)}
                                disabled
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </CardEx>
    )

    return (
        <AppFrame modelState={status} title={pageTitle} buttons={buttons} isDirty={isDirty}>
            <Grid container direction="row" spacing={2} padding={2} width="100%">
                <Grid container direction="column" spacing={2} size={{md: 12, lg: 5}}>
                    <Grid size={{xs: 12}}>{companyForm}</Grid>
                    <Grid size={{xs: 12}}> {bankForm}</Grid>
                    <Grid size={{xs: 12}}>{erpSystemForm}</Grid>
                </Grid>
                <Grid container direction="column" spacing={2} size={{md: 12, lg: 7}}>
                    <Grid size={{xs: 12}}>{machineryParkForm}</Grid>
                    <Grid size={{xs: 12}}>{addressTable}</Grid>
                    <Grid size={{xs: 12}}>{userTable}</Grid>
                    {(model.status === CompanyStatus.TERMINATED
                            || model.status === CompanyStatus.TERMINATION_RECEIVED
                            || model.status === CompanyStatus.TERMINATION_RECEIVED_LOCKED) &&
                        <Grid size={{xs: 12}}>{readOnlyTermination}</Grid>}
                    {(model.status == CompanyStatus.CONFIRMED_LOCKED
                            || model.status === CompanyStatus.TERMINATION_RECEIVED_LOCKED) &&
                        <Grid size={{xs: 12}}>{readOnlyBlocking}</Grid>}
                </Grid>
            </Grid>
            <Anker/>
        </AppFrame>
    );
};

export default withAuthWrapperComponent(CompanyDetailsPage);