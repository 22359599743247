import { formatAddressToString } from "@/model/address";
import { AddressDTO } from "@/api/data-contracts";
import { LabelValue } from "@/lib/components/LabelValueList";

/**
 * Generates a field object for an address with optional translation and tooltip.
 *
 * @param {AddressDTO | undefined} address - The address object to be formatted. If undefined, a default "not available" message is used.
 * @param {string} label - The label for the field.
 * @param {string} defaultValue - The default value.
 * @param {string} [locale] - The locale to use for formatting the address.
 * @param {string} [tooltipKey] - The key for the tooltip text.
 * @returns {LabelValue} The field object with label, value, and tooltipKey properties.
 */
export const addressField = (
    address: AddressDTO | undefined | null,
    label: string,
    defaultValue: string = "",
    locale?: string,
    tooltipKey?: string
): LabelValue => ({
    label,
    value: address ? formatAddressToString(address, locale) || defaultValue : defaultValue,
    tooltipKey
});
