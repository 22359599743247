import UserAuthForm from "@/pages/login-page/UserAuthForm";
import LightningBalls from "@/pages/landing-page/LightningBalls";
import React, { Suspense, useEffect } from "react";
import { Box, CircularProgress, Container, Grid2 as Grid, Typography } from "@mui/material";
import PageLoadingSpinner from "@/lib/components/PageLoadingSpinner";
import { useAuth } from "@/app/AuthContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppPaths } from "@/app/AppPaths";
import { APP_NAME } from "@/constants";
import { useTranslation } from "react-i18next";
import { AuthenticationPageI18N } from "@/translations";

export const SignInPage = () => {
    const { t } = useTranslation();
    const { authenticated, loading } = useAuth();
    const [searchParams] = useSearchParams();
    const redirectUrl = searchParams.get("redirect") || AppPaths.EXTERNAL_REQUEST_OVERVIEW;
    const navigate = useNavigate();

    useEffect(() => {
        if (!loading && authenticated) {
            navigate(redirectUrl);
        }
    }, [authenticated, navigate, redirectUrl, loading]);

    if (loading) {
        return <PageLoadingSpinner />;
    }

    if (authenticated) {
        return null;
    }

    return (
        <Container
            disableGutters
            maxWidth={false}
            sx={{
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden"
            }}
        >
            <Grid container direction="column" width="100%" height="100%">
                <Grid
                    size={{ xs: 12, lg: 6 }}
                    sx={{
                        display: { xs: "none", lg: "flex" },
                        flexDirection: "column",
                        justifyContent: "center",
                        position: "relative",
                        bgcolor: "primary.main",
                        height: "100%"
                    }}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            inset: 0
                        }}
                    />
                    <Box
                        sx={{
                            position: "relative",
                            display: "flex",
                            alignItems: "center",
                            padding: 2
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            style={{ marginRight: 8, height: 36, width: 36 }}
                        >
                            <path d="M15 6v12a3 3 0 1 0 3-3H6a3 3 0 1 0 3 3V6a3 3 0 1 0-3 3h12a3 3 0 1 0-3-3" />
                        </svg>
                        <Typography fontSize={24} fontWeight="bold" color="#fff">
                            {APP_NAME}
                        </Typography>
                    </Box>
                    <LightningBalls />
                </Grid>

                <Grid
                    size={{ xs: 12, lg: 6 }}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: { xs: 8, md: 16, lg: 20 },
                        bgcolor: "background.default",
                        height: "100%"
                    }}
                >
                    <Box sx={{ textAlign: "center" }}>
                        <Typography
                            variant="h4"
                            fontWeight={600}
                            letterSpacing="-0.01562em"
                            color="text.primary"
                            gutterBottom
                        >
                            {t(AuthenticationPageI18N.loginMessage)}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" paddingBottom={2}>
                            {t(AuthenticationPageI18N.description)}
                        </Typography>
                    </Box>
                    <Suspense fallback={<CircularProgress />}>
                        <UserAuthForm />
                    </Suspense>
                </Grid>
            </Grid>
        </Container>
    );
};
