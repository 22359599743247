import * as yup from "yup";
import { CENTRIMETRE_UNIT, MILLIMETRE_UNIT } from "@/constants";
import {
    NumberOfPallet,
    OrderDataDTO,
    PalletType,
    PrintType,
    QuantityOptionDTO,
    RequestDTO
} from "@/api/data-contracts";
import { convertDate, formatCurrency, formatInteger } from "@/lib/utils";
import { useMemo } from "react";
import { getI18n, useTranslation } from "react-i18next";
import { numberOfPalletLabels, palletTypeOptions, printOptions } from "@/datacaches";
import { Constants } from "@/api/constants";
import { RequestKeyWordsI18N } from "@/translations";

export type RequestValidation = Pick<
    RequestDTO,
    | "customerReferenceNumber"
    | "deliveryDateFrom"
    | "deliveryDateTo"
    | "deliveryAddress"
    | "additionalInformation"
    | "validUntil"
    | "orderData"
> & {
    deliveryDateFrom: any;
    deliveryDateTo: any;
    additionalInformation: any;
    orderData?: Pick<
        OrderDataDTO,
        | "cartonGrade"
        | "fefcoCode"
        | "palletType"
        | "internalDimensionLength"
        | "internalDimensionWidth"
        | "internalDimensionHeight"
        | "externalPrint"
        | "externalNumberOfColors"
        | "internalPrint"
        | "internalNumberOfColors"
        | "siliconeTape"
        | "tearTape"
        | "palletLabelNeutral"
        | "stretching"
        | "glued"
        | "stitched"
        | "taped"
        | "counterPallets"
        | "palletOverhang"
        | "allowPalletOverhang"
        | "palletizingFixed"
        | "palletizingNotes"
        | "palletHeight"
        | "numberOfPalletLabel"
        | "clicheCosts"
        | "toolingCosts"
        | "quantityOptions"
        | "paymentTerms"
    > & {
        quantityOptions?: Pick<QuantityOptionDTO, "quantity" | "netPricePer1000">[];
    };
};

export const useRequestSchema = () => {
    const { t } = useTranslation();
    const locale = getI18n().language;
    return useMemo(() => {
        const schema: yup.ObjectSchema<any> = yup
            .object()
            .shape({
                customerReferenceNumber: yup
                    .string()
                    .required(t(RequestKeyWordsI18N.quotationNumberError))
                    .max(
                        Constants.CUSTOMER_REFERENCE_NUMBER_LENGTH_MAX,
                        t(RequestKeyWordsI18N.maxRequestNumberLengthError, {
                            maxInteger: formatInteger(Constants.CUSTOMER_REFERENCE_NUMBER_LENGTH_MAX, locale)
                        })
                    ),
                contactPerson: yup.object().required(t(RequestKeyWordsI18N.contactPersonError)),
                deliveryDateFrom: yup
                    .date()
                    .optional()
                    .nullable()
                    .transform(convertDate)
                    .typeError(t(RequestKeyWordsI18N.dateFormatError))
                    .required(t(RequestKeyWordsI18N.deliveryDateFromError)),
                deliveryDateTo: yup
                    .date()
                    .optional()
                    .nullable()
                    .transform(convertDate)
                    .typeError(t(RequestKeyWordsI18N.dateFormatError))
                    .required(t(RequestKeyWordsI18N.deliveryDateFromError)),
                deliveryAddress: yup
                    .object()
                    .nullable()
                    .optional()
                    .required(t(RequestKeyWordsI18N.deliveryAddressError)),
                additionalInformation: yup
                    .string()
                    .nullable()
                    .optional()
                    .max(
                        Constants.ADDITIONAL_INFORMATION_LENGTH_MAX,
                        t(RequestKeyWordsI18N.maxCharactersLengthError, {
                            maxInteger: formatInteger(Constants.ADDITIONAL_INFORMATION_LENGTH_MAX, locale)
                        })
                    ),
                validUntil: yup
                    .date()
                    .optional()
                    .nullable()
                    .transform(convertDate)
                    .typeError(t(RequestKeyWordsI18N.dateFormatError))
                    .required(t(RequestKeyWordsI18N.requestValidityRequiredError)),

                orderData: yup.object().shape({
                    paymentTerms: yup
                        .string()
                        .nullable()
                        .optional()
                        .max(
                            Constants.PAYMENT_TERMS_LENGTH_MAX,
                            t(RequestKeyWordsI18N.maxCharactersLengthError, {
                                maxInteger: formatInteger(Constants.PAYMENT_TERMS_LENGTH_MAX, locale)
                            })
                        ),
                    cartonGrade: yup
                        .string()
                        .nullable()
                        .optional()
                        .required(t(RequestKeyWordsI18N.varietyError))
                        .max(
                            Constants.CARTON_GRADE_LENGTH_MAX,
                            t(RequestKeyWordsI18N.maxVarietyError, {
                                maxInteger: formatInteger(Constants.CARTON_GRADE_LENGTH_MAX, locale)
                            })
                        ),
                    fefcoCode: yup
                        .string()
                        .nullable()
                        .optional()
                        .required(t(RequestKeyWordsI18N.fefcoCodeError))
                        .max(
                            Constants.FEFCO_CODE_LENGTH_MAX,
                            t(RequestKeyWordsI18N.maxFefcoCodeError, {
                                maxInteger: formatInteger(Constants.FEFCO_CODE_LENGTH_MAX, locale)
                            })
                        ),
                    palletType: yup
                        .mixed<PalletType>()
                        .optional()
                        .nullable()
                        .required(t(RequestKeyWordsI18N.palletTypeError))
                        .oneOf(palletTypeOptions),
                    internalDimensionLength: yup
                        .number()
                        .optional()
                        .nullable()
                        .typeError(t(RequestKeyWordsI18N.integerError))
                        .required(t(RequestKeyWordsI18N.requiredLengthError))
                        .min(
                            Constants.INTERNAL_DIMENSION_MIN,
                            t(RequestKeyWordsI18N.minLengthError, {
                                minInteger: formatInteger(Constants.INTERNAL_DIMENSION_MIN, locale),
                                unit: MILLIMETRE_UNIT
                            })
                        )
                        .max(
                            Constants.INTERNAL_DIMENSION_MAX,
                            t(RequestKeyWordsI18N.maxLengthError, {
                                maxInteger: formatInteger(Constants.INTERNAL_DIMENSION_MAX, locale),
                                unit: MILLIMETRE_UNIT
                            })
                        ),
                    internalDimensionWidth: yup
                        .number()
                        .nullable()
                        .optional()
                        .typeError(t(RequestKeyWordsI18N.integerError))
                        .required(t(RequestKeyWordsI18N.requiredWidthError))
                        .min(
                            Constants.INTERNAL_DIMENSION_MIN,
                            t(RequestKeyWordsI18N.minWidthError, {
                                minInteger: formatInteger(Constants.INTERNAL_DIMENSION_MIN, locale),
                                unit: MILLIMETRE_UNIT
                            })
                        )
                        .max(
                            Constants.INTERNAL_DIMENSION_MAX,
                            t(RequestKeyWordsI18N.maxWidthError, {
                                maxInteger: formatInteger(Constants.INTERNAL_DIMENSION_MAX, locale),
                                unit: MILLIMETRE_UNIT
                            })
                        ),
                    internalDimensionHeight: yup
                        .number()
                        .nullable()
                        .optional()
                        .typeError(t(RequestKeyWordsI18N.integerError))
                        .required(t(RequestKeyWordsI18N.requiredHeightError))
                        .min(
                            Constants.INTERNAL_DIMENSION_MIN,
                            t(RequestKeyWordsI18N.minHeightError, {
                                minInteger: formatInteger(Constants.INTERNAL_DIMENSION_MIN, locale),
                                unit: MILLIMETRE_UNIT
                            })
                        )
                        .max(
                            Constants.INTERNAL_DIMENSION_MAX,
                            t(RequestKeyWordsI18N.maxHeightError, {
                                maxInteger: formatInteger(Constants.INTERNAL_DIMENSION_MAX, locale),
                                unit: MILLIMETRE_UNIT
                            })
                        ),
                    externalPrint: yup
                        .mixed<PrintType>()
                        .oneOf(printOptions)
                        .nullable()
                        .optional()
                        .required(t(RequestKeyWordsI18N.requiredPrintError)),
                    externalNumberOfColors: yup
                        .number()
                        .nullable()
                        .optional()
                        .when(["externalPrint"], {
                            is: (externalPrint: PrintType) =>
                                externalPrint != null && externalPrint !== PrintType.NO_PRINT,
                            then: (schema) => schema.required(t(RequestKeyWordsI18N.requiredNumberOfColorsError))
                        })
                        .min(
                            Constants.NUMBER_OF_COLORS_MIN,
                            t(RequestKeyWordsI18N.minNumberOfColorsError, {
                                minInteger: formatInteger(Constants.NUMBER_OF_COLORS_MIN, locale)
                            })
                        )
                        .max(
                            Constants.NUMBER_OF_COLORS_MAX,
                            t(RequestKeyWordsI18N.maxNumberOfColorsError, {
                                maxInteger: formatInteger(Constants.NUMBER_OF_COLORS_MAX, locale)
                            })
                        ),
                    internalPrint: yup
                        .mixed<PrintType>()
                        .oneOf(printOptions)
                        .nullable()
                        .optional()
                        .required(t(RequestKeyWordsI18N.requiredPrintError)),
                    internalNumberOfColors: yup
                        .number()
                        .nullable()
                        .optional()
                        .when(["internalPrint"], {
                            is: (internalPrint: PrintType) =>
                                internalPrint != null && internalPrint !== PrintType.NO_PRINT,
                            then: (schema) => schema.required(t(RequestKeyWordsI18N.requiredNumberOfColorsError))
                        })
                        .min(
                            Constants.NUMBER_OF_COLORS_MIN,
                            t(RequestKeyWordsI18N.minNumberOfColorsError, {
                                minInteger: formatInteger(Constants.NUMBER_OF_COLORS_MIN, locale)
                            })
                        )
                        .max(
                            Constants.NUMBER_OF_COLORS_MAX,
                            t(RequestKeyWordsI18N.maxNumberOfColorsError, {
                                maxInteger: formatInteger(Constants.NUMBER_OF_COLORS_MAX, locale)
                            })
                        )
                        .integer(t(RequestKeyWordsI18N.integerError)),
                    palletLabelNeutral: yup.boolean().nullable().optional(),
                    stretching: yup.boolean().nullable().optional(),
                    glued: yup.boolean().nullable().optional(),
                    stitched: yup.boolean().nullable().optional(),
                    taped: yup.boolean().nullable().optional(),
                    siliconeTape: yup.boolean().nullable().optional(),
                    tearTape: yup.boolean().nullable().optional(),
                    counterPallets: yup.boolean().nullable().optional(),
                    palletExchange: yup.boolean().nullable().optional(),
                    allowPalletOverhang: yup.boolean().nullable().optional(),
                    palletizingFixed: yup.boolean().nullable().optional(),
                    palletOverhang: yup
                        .number()
                        .typeError(t(RequestKeyWordsI18N.integerError))
                        .nullable()
                        .optional()
                        .when(["allowPalletOverhang"], {
                            is: (allowPalletOverhang: boolean) => allowPalletOverhang,
                            then: (schema) => schema.required(t(RequestKeyWordsI18N.requiredPalletOverhangError))
                        })
                        .min(
                            Constants.PALLET_OVERHANG_MIN,
                            t(RequestKeyWordsI18N.minPalletOverhangError, {
                                minInteger: formatInteger(Constants.PALLET_OVERHANG_MIN, locale),
                                unit: CENTRIMETRE_UNIT
                            })
                        )
                        .max(
                            Constants.PALLET_OVERHANG_MAX,
                            t(RequestKeyWordsI18N.maxPalletOverhangError, {
                                maxInteger: formatInteger(Constants.PALLET_OVERHANG_MAX, locale),
                                unit: CENTRIMETRE_UNIT
                            })
                        )
                        .integer(t(RequestKeyWordsI18N.integerError)),
                    palletizingNotes: yup
                        .string()
                        .max(
                            Constants.PALLETIZING_NOTES_LENGTH_MAX,
                            t(RequestKeyWordsI18N.maxPalletizingNotesLengthError, {
                                maxInteger: formatInteger(Constants.PALLETIZING_NOTES_LENGTH_MAX, locale)
                            })
                        )
                        .nullable()
                        .optional(),
                    palletHeight: yup
                        .number()
                        .typeError(t(RequestKeyWordsI18N.integerError))
                        .nullable()
                        .optional()
                        .required(t(RequestKeyWordsI18N.requiredPalletHeightError))
                        .min(
                            Constants.PALLET_HEIGHT_MIN,
                            t(RequestKeyWordsI18N.minPalletHeightError, {
                                minInteger: formatInteger(Constants.PALLET_HEIGHT_MIN, locale),
                                unit: CENTRIMETRE_UNIT
                            })
                        )
                        .max(
                            Constants.PALLET_HEIGHT_MAX,
                            t(RequestKeyWordsI18N.maxPalletHeightError, {
                                maxInteger: formatInteger(Constants.PALLET_HEIGHT_MAX, locale),
                                unit: CENTRIMETRE_UNIT
                            })
                        )
                        .integer(t(RequestKeyWordsI18N.integerError)),
                    numberOfPalletLabel: yup
                        .mixed<NumberOfPallet>()
                        .oneOf(numberOfPalletLabels)
                        .nullable()
                        .optional()
                        .required(t(RequestKeyWordsI18N.numberOfPalletLabelError)),
                    quantityOptions: yup
                        .array(
                            yup
                                .object()
                                .defined()
                                .shape({
                                    quantity: yup
                                        .number()
                                        .typeError(t(RequestKeyWordsI18N.integerError))
                                        .nullable()
                                        .optional()
                                        .required(t(RequestKeyWordsI18N.requiredQuantityError))
                                        .min(
                                            Constants.QUANTITY_MIN,
                                            t(RequestKeyWordsI18N.minQuantityError, {
                                                minInteger: formatInteger(Constants.QUANTITY_MIN, locale)
                                            })
                                        )
                                        .max(
                                            Constants.QUANTITY_MAX,
                                            t(RequestKeyWordsI18N.maxQuantityError, {
                                                maxInteger: formatInteger(Constants.QUANTITY_MAX, locale)
                                            })
                                        ),
                                    netPricePer1000: yup
                                        .number()
                                        .nullable()
                                        .optional()
                                        .test(
                                            "min",
                                            t(RequestKeyWordsI18N.minNetPriceError, {
                                                minCurrency: formatCurrency(Constants.NET_PRICE_PER_1000_MIN, locale)
                                            }),
                                            (value) => {
                                                if (value === null || value === undefined) {
                                                    return true;
                                                }
                                                return value >= Constants.NET_PRICE_PER_1000_MIN;
                                            }
                                        )
                                        .test(
                                            "max",
                                            t(RequestKeyWordsI18N.maxNetPriceError, {
                                                maxCurrency: formatCurrency(Constants.NET_PRICE_PER_1000_MAX, locale)
                                            }),
                                            (value) => {
                                                if (value === null || value === undefined) {
                                                    return true;
                                                }
                                                return value <= Constants.NET_PRICE_PER_1000_MAX;
                                            }
                                        )
                                })
                        )
                        .defined()
                        .test("uniqueQuantitiesError", t(RequestKeyWordsI18N.uniqueQuantitiesError), function (value) {
                            if (!value) {
                                return true;
                            }
                            const quantities = value.map((item) => item.quantity).filter((q) => q != null);
                            const unique = new Set(quantities);
                            if (quantities.length !== unique.size) {
                                return this.createError({ path: "quantities" });
                            }
                            return true;
                        }),
                    toolingCosts: yup
                        .number()
                        .nullable()
                        .optional()
                        .test(
                            "min",
                            t(RequestKeyWordsI18N.minToolingCostsError, {
                                minCurrency: formatCurrency(Constants.TOOLING_COSTS_PRICE_MIN, locale)
                            }),
                            (value) => {
                                if (value === null || value === undefined) {
                                    return true;
                                }
                                return value >= Constants.TOOLING_COSTS_PRICE_MIN;
                            }
                        )
                        .test(
                            "max",
                            t(RequestKeyWordsI18N.maxToolingCostsError, {
                                maxCurrency: formatCurrency(Constants.TOOLING_COSTS_PRICE_MAX, locale)
                            }),
                            (value) => {
                                if (value === null || value === undefined) {
                                    return true;
                                }
                                return value <= Constants.TOOLING_COSTS_PRICE_MAX;
                            }
                        ),
                    clicheCosts: yup
                        .number()
                        .nullable()
                        .optional()
                        .test(
                            "min",
                            t(RequestKeyWordsI18N.minClicheCostsError, {
                                minCurrency: formatCurrency(Constants.CLICHE_COSTS_PRICE_MIN, locale)
                            }),
                            (value) => {
                                if (value === null || value === undefined) {
                                    return true;
                                }
                                return value >= Constants.CLICHE_COSTS_PRICE_MIN;
                            }
                        )
                        .test(
                            "max",
                            t(RequestKeyWordsI18N.maxClicheCostsError, {
                                maxCurrency: formatCurrency(Constants.CLICHE_COSTS_PRICE_MAX, locale)
                            }),
                            (value) => {
                                if (value === null || value === undefined) {
                                    return true;
                                }
                                return value <= Constants.CLICHE_COSTS_PRICE_MAX;
                            }
                        )
                })
            })
            .stripUnknown();

        const typed = schema as yup.ObjectSchema<RequestValidation>;
        return typed as yup.ObjectSchema<RequestDTO>;
    }, [locale, t]);
};
