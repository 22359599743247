import React, { useMemo } from "react";
import { createHashRouter, Navigate } from "react-router-dom";
import { useAuth } from "@/app/AuthContext";
import { RouteObject, RouterProvider } from "react-router";
import { LandingPage } from "@/pages/landing-page/LandingPage";
import { AppPaths } from "@/app/AppPaths";
import { SignInPage } from "@/pages/login-page/AuthentificationPage";
import { CompanySignUpPage } from "@/pages/company-sign-up-page/CompanySignUpPage";
import ExternalRequestOverviewPage from "@/pages/request-overview-page/ExternalRequestOverviewPage";
import InternalRequestOverviewPage from "@/pages/request-overview-page/InternalRequestOverviewPage";
import InternalRequestPage from "@/pages/internal-request-page/InternalRequestPage";
import ExternalRequestPage from "@/pages/external-request-page/ExternalRequestPage";
import { UserRole } from "@/api/data-contracts";
import CompanyOverviewPage from "@/pages/company-overview-page/CompanyOverviewPage";
import CompanyPage from "@/pages/company-page/CompanyDetailsPage";
import UserPage from "@/pages/user-page/UserPage";
import { UserSignUpPage } from "@/pages/user-signup-page/UserSignUpPage";
import { PasswordResetPage } from "@/pages/user-password-reset-page/PasswordResetPage";
import PasswordResetRequestPage from "@/pages/user-password-reset-request-page/PasswordResetRequestPage";
import CompanyUnverifiedPage from "@/pages/company-overview-page/CompanyUnverifiedPage";

export const AppRoutes = () => {
    const { authenticated, role } = useAuth();

    const router = useMemo(() => {
        const routes: RouteObject[] = [
            { path: AppPaths.LANDING_PAGE, element: <LandingPage /> },
            { path: AppPaths.SIGN_IN, element: <SignInPage /> },
            { path: AppPaths.COMPANY_SIGNUP, element: <CompanySignUpPage /> },
            { path: AppPaths.COMPANY, element: <CompanyPage /> },
            { path: AppPaths.USER_SIGNUP, element: <UserSignUpPage /> },
            { path: AppPaths.USER_PASSWORD_RESET, element: <PasswordResetPage /> },
            { path: AppPaths.USER_PASSWORD_RESET_REQUEST, element: <PasswordResetRequestPage /> },
            { path: AppPaths.USER, element: <UserPage /> }
        ];
        if (authenticated) {
            if (role === UserRole.CLIENT) {
                routes.push(
                    { path: AppPaths.EXTERNAL_REQUEST_OVERVIEW, element: <ExternalRequestOverviewPage /> },
                    { path: AppPaths.EXTERNAL_REQUEST, element: <ExternalRequestPage /> },
                    { path: AppPaths.INTERNAL_REQUEST_OVERVIEW, element: <InternalRequestOverviewPage /> },
                    { path: AppPaths.INTERNAL_REQUEST, element: <InternalRequestPage /> },
                    { path: "*", element: <Navigate to={AppPaths.EXTERNAL_REQUEST_OVERVIEW} /> }
                );
            } else if (role === UserRole.OPERATOR) {
                routes.push(
                    { path: AppPaths.COMPANY_OVERVIEW, element: <CompanyOverviewPage /> },
                    { path: AppPaths.COMPANY_UNVERIFIED, element: <CompanyUnverifiedPage /> },
                    { path: "*", element: <Navigate to={AppPaths.COMPANY_OVERVIEW} /> }
                );
            } else {
                routes.push({ path: "*", element: <Navigate to="/" /> });
            }
        } else {
            routes.push({ path: "*", element: <Navigate to="/" /> });
        }

        return createHashRouter(routes);
    }, [authenticated, role]);
    return <RouterProvider router={router} />;
};
