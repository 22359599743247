// TODO: Regex mit dem Backend synchronisieren!

export const EMAIL_REGEX =
    /^[a-zA-Z0-9_%+-](?:[a-zA-Z0-9_%+-]|\.(?=[a-zA-Z0-9_%+-]))*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-.\/:;<=>?@[\\\]^_`{|}~]).*$/;
export const PHONE_NUMBER_REGEX = /^(?=(?:\D*\d){6,})(?:\+|00)?[1-9]\d{0,2}[-.\s]?\(?\d+\)?(?:[-.\s]\(?\d+\)?)*$/;
export const ZIP_CODE_REGEX = /^(?![A-Z]{3,})[A-Z0-9](?:[A-Z0-9\s-]*[A-Z0-9])?$/;

export const URL_REGEX = /^(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,10}(:\d{1,5})?(\/\S*)?$/;
export const COMMERCIAL_REGISTRATION_NUMBER_REGEX = /^(?:$|[A-Z0-9](?:[A-Z0-9\/\-.\s]*[A-Z0-9])?)$/;
export const VAT_ID_REGEX = /^[A-Z]{2}(?![A-Z]{7,})[A-Z0-9]+$/;

export const IBAN_REGEX = /^[A-Z]{2}\d{2}[A-Z0-9]{11,30}$/;

export const BIC_REGEX = /^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$/;
