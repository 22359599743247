import * as React from "react";
import {Grid2 as Grid} from "@mui/material";
import {OrderDocumentDTO, OrderDocumentsDTO, OrderDocumentType} from "@/api/data-contracts";
import {ModelAction} from "@/lib/modelstate";
import {CardEx} from "@/lib/components/CardEx";
import {OrderDocuments} from "@/common/OrderDocuments";
import {useTranslation} from "react-i18next";

export type OrderDocumentsCardProps = {
    orderDocuments?: OrderDocumentsDTO;
    dispatch?: React.Dispatch<ModelAction>;
    readonly?: boolean;
};

export const OrderDocumentsCard = ({orderDocuments, dispatch, readonly}: OrderDocumentsCardProps) => {
    const {t} = useTranslation();

    const onFileAdded = (files: OrderDocumentDTO[]) => {
        dispatch && dispatch({type: "setDocuments", data: files, nochange: true});
    };

    const onFileRemoved = (file: OrderDocumentDTO) => {
        dispatch && dispatch({type: "remDocument", data: file, nochange: true});
    };

    return (
        <CardEx title="Dokumente">
            <Grid container spacing={2} direction="column">
                <Grid size={{sm: 12}}>
                    <OrderDocuments
                        title="Konstruktionszeichnungen"
                        orderDocuments={orderDocuments}
                        type={OrderDocumentType.BLUE_PRINT}
                        onFileAdded={onFileAdded}
                        onFileRemoved={onFileRemoved}
                        disabled={readonly}
                    />
                </Grid>
                <Grid size={{sm: 12}}>
                    <OrderDocuments
                        title="Druckbilder"
                        orderDocuments={orderDocuments}
                        type={OrderDocumentType.PRINT_IMAGE}
                        onFileAdded={onFileAdded}
                        onFileRemoved={onFileRemoved}
                        disabled={readonly}
                    />
                </Grid>
            </Grid>
        </CardEx>
    );
};
