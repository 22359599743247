import { LabelValue } from "@/lib/components/LabelValueList";

/**
 * Generates a field object for a boolean value with optional translation and tooltip.
 *
 * @param {boolean | undefined | null} booleanValue - The boolean value to be displayed. If undefined or null, a default "not available" message is used.
 * @param {string} trueValue - The string to display if the boolean value is true.
 * @param {string} falseValue - The string to display if the boolean value is false.
 * @param {string} label - The label for the field.
 * @param {string} [defaultValue=""] - The default value.
 * @param {string} [tooltipKey] - The key for the tooltip text.
 * @returns {LabelValue} The field object with label, value, and tooltipKey properties.
 */
export const booleanField = (
    booleanValue: boolean | undefined | null,
    trueValue: string,
    falseValue: string,
    label: string,
    defaultValue: string = "",
    tooltipKey?: string
): LabelValue => ({
    label,
    value: booleanValue !== undefined && booleanValue !== null ? (booleanValue ? trueValue : falseValue) : defaultValue,
    tooltipKey
});
