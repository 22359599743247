import { RequestStatus } from "@/api/data-contracts";
import { amber, blue, green, grey, indigo, purple, yellow } from "@mui/material/colors";
import { AppThemeContextProps } from "@/app/AppThemeContext";
import { LabelValue } from "@/lib/components/LabelValueList";
import { GeneralI18N, RequestStatusI18N } from "@/translations";

interface RequestStatusField {
    requestStatus?: RequestStatus | null;
    statusValue?: string | null;
    submittedQuoteCount?: number | null;
}

/**
 * Generates a field object for the status of a request with a label, value, tooltip, and color.
 *
 * @param {RequestStatusField} status - The status object containing the request status and status value.
 * @param {string} label - The label for the field.
 * @param {AppThemeContextProps} theme - The theme context properties.
 * @param {string} [defaultValue=""] - The default value to use if the status value is not available.
 * @param {string} [tooltipKey] - The key for the tooltip text.
 * @returns {LabelValue} The field object with label, value, tooltipKey, and color properties.
 */
export const requestStatusField = (
    status: RequestStatusField,
    label: string,
    theme: AppThemeContextProps,
    defaultValue?: RequestStatusField,
    tooltipKey?: string
): LabelValue => ({
    label,
    value:
        status?.requestStatus && status?.statusValue
            ? status.requestStatus === RequestStatus.TO_BE_CONFIRMED && !!status.submittedQuoteCount
                ? `${status.statusValue}: ${status.submittedQuoteCount}`
                : status.statusValue
            : (defaultValue?.statusValue ?? ""),
    tooltipKey,
    color: getRequestStatusColor(theme.mode, status.requestStatus ?? undefined)
});

/**
 * Returns the color associated with a given request status.
 *
 * @returns {string} The color corresponding to the given status code.
 * @param mode
 * @param status
 */
export const getRequestStatusColor = (mode: "dark" | "light", status?: RequestStatus): string => {
    const tone = mode === "light" ? 200 : 800;
    switch (status) {
        case RequestStatus.CREATED:
            return amber[tone];
        case RequestStatus.PUBLISHED:
            return blue[tone];
        case RequestStatus.TO_BE_CONFIRMED:
            return yellow[tone];
        case RequestStatus.CONFIRMED:
            return green[tone];
        case RequestStatus.DEACTIVATED:
            return indigo[tone];
        case RequestStatus.CANCELLED:
            return purple[tone];
        case RequestStatus.DRAFT:
            return amber[tone];
        default:
            return mode === "light" ? grey[200] : grey[800];
    }
};

export const getRequestStatusText = (t: (key: string) => string, status?: RequestStatus): string =>
    (status && t(RequestStatusI18N[status])) ?? t(GeneralI18N.notGiven);
