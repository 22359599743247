import {Dialog, DialogActions, DialogContent, DialogTitle, Grid2 as Grid, Grid2} from "@mui/material";
import StringInputField from "@/lib/components/inputfields/string-input-field";
import {useModelState} from "@/lib/modelstate";
import {ButtonEx} from "@/lib/components/buttons/ButtonEx";
import * as React from "react";
import {useMemo} from "react";
import * as yup from "yup";
import {useTranslation} from "react-i18next";
import {BlockingI18N, CompanyFormI18N, GeneralI18N, SalutationsI18N, UserFormI18N} from "@/translations";
import ConfirmationDialog from "@/lib/components/ConfirmationDialog";
import {AccessLockDTO, CompanyDTO, CompanyStatisticsDTO} from "@/api/data-contracts";
import {Constants} from "@/api/constants";
import {companyApi} from "@/boxhub-api";
import {useDialogController} from "@/lib/dialog-controller";
import NumberInputField from "@/lib/components/inputfields/number-input-field";

export type BlockingCompanyDialogProps = {
    open: boolean;
    close: () => void;
    onSuccess?: () => void;
    company?: CompanyDTO;
    companyStatistics?: CompanyStatisticsDTO;
};

type BlockingCompanyValidation = Pick<AccessLockDTO, "lockedReason">;

const useSchema = () => {
    const {t} = useTranslation();
    return useMemo(
        () => {
            const schema = yup.object().shape({
                lockedReason: yup
                    .string()
                    .optional()
                    .nullable()
                    .max(Constants.REASON_LENGTH_MAX, t(BlockingI18N.maxBlockingReasonError, {maxInteger: Constants.REASON_LENGTH_MAX})),
            });
            const typed = schema as yup.ObjectSchema<BlockingCompanyValidation>;
            return typed as yup.ObjectSchema<AccessLockDTO>;
        }, [t]);
};

export const BlockingCompanyDialog = ({
                                          open,
                                          close,
                                          onSuccess,
                                          company,
                                          companyStatistics
                                      }: BlockingCompanyDialogProps) => {
    const {t} = useTranslation();
    const [DialogNode, showDialog] = useDialogController();
    const schema = useSchema();
    const {model, errors, onChange, save, validate} = useModelState<AccessLockDTO, number>({
        initialState: {} as AccessLockDTO,
        schema,
        id: company?.id,
        updater: companyApi.lockCompany
    });

    const handleConfirmBlocking = () => {
        save()?.then(() => {
            close();
            onSuccess && onSuccess();
        });
    };


    const salutationOfCEO = useMemo(() => {
        const salutationOfCEO = company?.users?.find((user) => user.ceo)?.salutation;
        if (salutationOfCEO) {
            return t(SalutationsI18N[salutationOfCEO]);
        } else {
            return "";
        }
    }, [t, company]);

    const ceoFirstName = useMemo(() => {
        return company?.users?.find((user) => user.ceo)?.firstName ?? "";
    }, [t, company]);

    const ceoLastName = useMemo(() => {
        return company?.users?.find((user) => user.ceo)?.lastName ?? "";
    }, [t, company]);

    const sumOpenRequests = useMemo(() => {
        return (companyStatistics?.numberOfCreatedRequests ?? 0) +
            (companyStatistics?.numberOfPublishedRequests ?? 0) +
            (companyStatistics?.numberOfToBeConfirmedRequests ?? 0);
    }, [t, company]);

    return (
        <>
            <Dialog open={open} onClose={close} maxWidth="sm" fullWidth>
                <DialogTitle>{t(BlockingI18N.blockCompanyDialogTitle, {companyName: company?.name})}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} padding={2} width="100%">
                        <Grid width="100%">
                            <StringInputField
                                value={company?.name}
                                label={t(CompanyFormI18N.companyNameLabel)}
                                disabled
                            />
                        </Grid>
                        <Grid width="100%">
                            <StringInputField
                                label={t(CompanyFormI18N.streetLabel)}
                                value={company?.companyAddress?.street}
                                disabled
                            />
                        </Grid>
                        <Grid container spacing={2} width="100%">
                            <Grid size={{xs: 6}}>
                                <StringInputField
                                    label={t(CompanyFormI18N.postalCodeLabel)}
                                    value={company?.companyAddress?.zip}
                                    disabled
                                />
                            </Grid>
                            <Grid size={{xs: 6}}>
                                <StringInputField
                                    label={t(CompanyFormI18N.cityLabel)}
                                    value={company?.companyAddress?.city}
                                    disabled
                                />
                            </Grid>
                        </Grid>
                        <Grid width="100%">
                            <StringInputField
                                label={t(CompanyFormI18N.countryLabel)}
                                value={company?.companyAddress?.country}
                                disabled
                            />
                        </Grid>
                        <Grid container spacing={2} width="100%">
                            <Grid size={{xs: 2}}>
                                <StringInputField
                                    label={t(UserFormI18N.salutationLabel)}
                                    value={salutationOfCEO}
                                    disabled
                                />
                            </Grid>
                            <Grid size={{xs: 5}}>
                                <StringInputField
                                    label={t(UserFormI18N.firstNameLabel)}
                                    value={ceoFirstName}
                                    disabled
                                />
                            </Grid>
                            <Grid size={{xs: 5}}>
                                <StringInputField
                                    label={t(UserFormI18N.lastNameLabel)}
                                    value={ceoLastName}
                                    disabled
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} width="100%">
                            <Grid size={{xs: 6}}>
                                <NumberInputField
                                    name="sumOpenRequests"
                                    label={t(BlockingI18N.sumOpenRequestsLabel)}
                                    value={sumOpenRequests}
                                    disabled/>
                            </Grid>
                            <Grid size={{xs: 6}}>
                                <NumberInputField
                                    name="sumOpenRequests"
                                    label={t(BlockingI18N.sumSentQuotesLabel)}
                                    value={companyStatistics?.numberOfSubmittedQuotes ?? 0}
                                    disabled/>
                            </Grid>
                        </Grid>
                        <Grid width="100%">
                            <StringInputField
                                name="lockedReason"
                                label={t(BlockingI18N.blockReasonLabel)}
                                value={model?.lockedReason}
                                error={errors?.lockedReason}
                                onChange={onChange}
                                maxChars={Constants.REASON_LENGTH_MAX}
                                multiline
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid2 container direction="row" flexWrap="nowrap" spacing={2} padding={1}>
                        <Grid2>
                            <ButtonEx
                                label={t(GeneralI18N.cancelButton)}
                                color="secondary"
                                variant="contained"
                                onClick={close}
                            />
                        </Grid2>
                        <Grid2>
                            <ButtonEx
                                label={t(BlockingI18N.blockCompanyButton)}
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                    if (validate()) {
                                        showDialog((open, close) => (
                                            <ConfirmationDialog
                                                open={open}
                                                onClose={close}
                                                onConfirm={handleConfirmBlocking}
                                                title={t(BlockingI18N.blockCompanyConfirmDialogTitle, {companyName: company?.name})}
                                                message={t(BlockingI18N.blockCompanyConfirmDialogMessage, {companyName: company?.name})}
                                                confirmButtonText={t(GeneralI18N.yes)}
                                                cancelButtonText={t(GeneralI18N.no)}
                                            />
                                        ));
                                    }
                                }}
                            />
                        </Grid2>
                    </Grid2>
                </DialogActions>
            </Dialog>
            <DialogNode/>
        </>
    );
};
