/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    ChangePasswordDTO,
    CompanyInvitationDTO,
    CompanySignUpDTO,
    SessionDTO,
    SignInDTO,
    UserInvitationDTO,
    UserPasswordResetDTO,
    UserSignUpDTO
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class AuthenticationApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags authentication-api
     * @name BeginCompanySignUp
     * @summary Begin sign up on token
     * @request GET:/api/auth/company-sign-up/{invitation-token}
     * @secure
     */
    beginCompanySignUp = (invitationToken: string, params: RequestParams = {}) =>
        this.request<CompanySignUpDTO, (string[] | Record<string, string>) | void | CompanySignUpDTO>({
            path: `/api/auth/company-sign-up/${invitationToken}`,
            method: "GET",
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags authentication-api
     * @name BeginPasswordReset
     * @summary Begin password reset on token
     * @request GET:/api/auth/password-reset/{invitation-token}
     * @secure
     */
    beginPasswordReset = (invitationToken: string, params: RequestParams = {}) =>
        this.request<UserPasswordResetDTO, (string[] | Record<string, string>) | void | UserPasswordResetDTO>({
            path: `/api/auth/password-reset/${invitationToken}`,
            method: "GET",
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags authentication-api
     * @name BeginUserSignUp
     * @summary Begin sign up on token
     * @request GET:/api/auth/user-sign-up/{invitation-token}
     * @secure
     */
    beginUserSignUp = (invitationToken: string, params: RequestParams = {}) =>
        this.request<UserSignUpDTO, (string[] | Record<string, string>) | void | UserSignUpDTO>({
            path: `/api/auth/user-sign-up/${invitationToken}`,
            method: "GET",
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags authentication-api
     * @name ChangePassword
     * @summary Change password
     * @request POST:/api/auth/change-password
     * @secure
     */
    changePassword = (data: ChangePasswordDTO, params: RequestParams = {}) =>
        this.request<void, (string[] | Record<string, string>) | void>({
            path: `/api/auth/change-password`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags authentication-api
     * @name CheckAuth
     * @summary Check if user is logged in
     * @request GET:/api/auth/check
     * @secure
     */
    checkAuth = (params: RequestParams = {}) =>
        this.request<SessionDTO, (string[] | Record<string, string>) | void>({
            path: `/api/auth/check`,
            method: "GET",
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags authentication-api
     * @name GenerateCompanySignUpInvitation
     * @summary generate invitation token request
     * @request POST:/api/auth/company-sign-up-invitation
     * @secure
     */
    generateCompanySignUpInvitation = (data: CompanyInvitationDTO, params: RequestParams = {}) =>
        this.request<CompanyInvitationDTO, (string[] | Record<string, string>) | void | CompanyInvitationDTO>({
            path: `/api/auth/company-sign-up-invitation`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags authentication-api
     * @name GenerateUserPasswordReset
     * @summary generate password reset token request
     * @request POST:/api/auth/password-reset-request
     * @secure
     */
    generateUserPasswordReset = (
        query: {
            email: string;
        },
        params: RequestParams = {}
    ) =>
        this.request<void, (string[] | Record<string, string>) | void>({
            path: `/api/auth/password-reset-request`,
            method: "POST",
            query: query,
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags authentication-api
     * @name GenerateUserSignUpInvitation
     * @summary generate invitation token request
     * @request POST:/api/auth/user-sign-up-invitation
     * @secure
     */
    generateUserSignUpInvitation = (data: UserInvitationDTO, params: RequestParams = {}) =>
        this.request<void, (string[] | Record<string, string>) | void>({
            path: `/api/auth/user-sign-up-invitation`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags authentication-api
     * @name PerformCompanySignUp
     * @summary sign up company and user on token
     * @request POST:/api/auth/company-sign-up/{invitation-token}
     * @secure
     */
    performCompanySignUp = (invitationToken: string, data: CompanySignUpDTO, params: RequestParams = {}) =>
        this.request<void, (string[] | Record<string, string>) | void>({
            path: `/api/auth/company-sign-up/${invitationToken}`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags authentication-api
     * @name PerformPasswordReset
     * @summary Change password on token
     * @request POST:/api/auth/password-reset/{invitation-token}
     * @secure
     */
    performPasswordReset = (invitationToken: string, data: UserPasswordResetDTO, params: RequestParams = {}) =>
        this.request<void, (string[] | Record<string, string>) | void>({
            path: `/api/auth/password-reset/${invitationToken}`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags authentication-api
     * @name PerformUserSignUp
     * @summary sign up company and user on token
     * @request POST:/api/auth/user-sign-up/{invitation-token}
     * @secure
     */
    performUserSignUp = (invitationToken: string, data: UserSignUpDTO, params: RequestParams = {}) =>
        this.request<void, (string[] | Record<string, string>) | void>({
            path: `/api/auth/user-sign-up/${invitationToken}`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags authentication-api
     * @name SignIn
     * @summary This API signs in a user.
     * @request POST:/api/auth/sign-in
     * @secure
     */
    signIn = (data: SignInDTO, params: RequestParams = {}) =>
        this.request<SessionDTO, (string[] | Record<string, string>) | void>({
            path: `/api/auth/sign-in`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags authentication-api
     * @name SignOut
     * @summary This API signs out a user.
     * @request POST:/api/auth/sign-out
     * @secure
     */
    signOut = (params: RequestParams = {}) =>
        this.request<void, (string[] | Record<string, string>) | void>({
            path: `/api/auth/sign-out`,
            method: "POST",
            secure: true,
            ...params
        });
}
