import { Badge } from "@/lib/components/Badge";
import * as React from "react";
import { QuoteStatus } from "@/api/data-contracts";
import { useAppTheme } from "@/app/AppThemeContext";
import { GroupBox } from "@/lib/components/GroupBox";
import { getQuoteStatusColor, getQuoteStatusText } from "@/common/request-details/quote-status-field";
import { useTranslation } from "react-i18next";

export const QuoteStatusBadge = ({ status, fullWidth }: { status?: QuoteStatus; fullWidth?: boolean }) => {
    const theme = useAppTheme();
    const { t } = useTranslation();
    return (
        <Badge
            label={getQuoteStatusText(t, status)}
            backgroundColor={getQuoteStatusColor(theme.mode, status)}
            fullWidth={fullWidth}
        />
    );
};

export const QuoteStatusBadgeField = ({ status }: { status?: QuoteStatus }) => {
    return (
        <GroupBox title="Status" paddingTop={9} paddingBottom={9}>
            <QuoteStatusBadge status={status} fullWidth />
        </GroupBox>
    );
};
