import React, { DragEvent, useCallback } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { AuthProvider } from "@/app/AuthContext";
import { ThemeProvider } from "@/app/AppThemeContext";
import { I18nextProvider } from "react-i18next";
import i18n from "@/i18n";
import { LocaleProvider } from "@/app/LocaleProvider";
import { SnackbarProvider } from "notistack";
import { AppRoutes } from "@/app/AppRoutes";

export const App = () => {
    const handleDragOver = useCallback((e: DragEvent<HTMLSpanElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer) {
            e.dataTransfer.dropEffect = "none";
        }
    }, []);
    return (
        <I18nextProvider i18n={i18n}>
            <ThemeProvider>
                <CssBaseline />
                <AuthProvider>
                    <span onDragOver={handleDragOver}>
                        <LocaleProvider i18n={i18n}>
                            <SnackbarProvider maxSnack={6}>
                                <AppRoutes />
                            </SnackbarProvider>
                        </LocaleProvider>
                    </span>
                </AuthProvider>
            </ThemeProvider>
        </I18nextProvider>
    );
};
