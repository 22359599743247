import { Box, Button, ButtonProps, Icon, Tooltip } from "@mui/material";
import React, { CSSProperties, ReactNode, useEffect } from "react";
import { Link, LinkProps } from "react-router-dom";

/**
 * Props for the ButtonEx component.
 *
 * @property {boolean} [visible=true] - If true, the button is visible.
 * @property {ReactNode} [label] - The label to display on the button.
 * @property {ButtonProps["component"]} [component] - The component to use for the button.
 * @property {LinkProps["to"]} [to] - The link to navigate to when the button is clicked.
 * @property {ButtonProps["onClick"]} [onClick] - The function to call when the button is clicked.
 * @property {ButtonProps["color"]} [color] - The color of the button.
 * @property {boolean} [disabled] - If true, disables the button.
 * @property {ButtonProps["variant"]} [variant] - The variant of the button.
 * @property {CSSProperties} [style] - The style of the button.
 * @property {string} [className] - The class name of the button.
 * @property {ButtonProps["size"]} [size] - The size of the button.
 * @property {ButtonProps["fullWidth"]} [fullWidth] - If true, the button will take up the full width of its container.
 * @property {string | React.ReactNode} [startIcon] - The icon to display at the start of the button.
 * @property {React.ComponentType<any>} [StartIcon] - The component to use for the start icon.
 * @property {boolean} [autoFocus] - If true, the button will be focused automatically.
 * @property {string} [title] - The title of the button.
 * @property {string} [name] - The name of the button.
 * @property {string | React.ReactNode} [tooltip] - The tooltip to display when hovering over the button.
 * @property {string} [data-cy] - The data-cy attribute for the button.
 * @property {boolean} [shouldFocus] - If true, the button will be focused when rendered.
 * @property {ButtonProps["type"]} [type] - The type of the button.
 * @property {ButtonProps["sx"]} [sx] - The style props for the button.
 */
export type ButtonExProps = {
    visible?: boolean;
    label?: ReactNode;
    component?: ButtonProps["component"];
    to?: LinkProps["to"];
    onClick?: ButtonProps["onClick"];
    color?: ButtonProps["color"];
    disabled?: boolean;
    variant?: ButtonProps["variant"];
    style?: CSSProperties;
    className?: string;
    size?: ButtonProps["size"];
    fullWidth?: ButtonProps["fullWidth"];
    startIcon?: string | React.ReactNode;
    StartIcon?: React.ComponentType<any>;
    autoFocus?: boolean;
    title?: string;
    name?: string;
    tooltip?: string | React.ReactNode;
    "data-cy"?: string;
    shouldFocus?: boolean;
    type?: ButtonProps["type"];
    sx?: ButtonProps["sx"];
};

/**
 * ButtonEx component.
 *
 * @param visible
 * @param label
 * @param name
 * @param to
 * @param startIcon
 * @param tooltip
 * @param StartIcon
 * @param onClick
 * @param shouldFocus
 * @param dataCy
 * @param props - The props for the component.
 * @returns {React.ReactElement | null} The rendered ButtonEx component or null if not visible.
 */
export const ButtonEx = ({
    visible = true,
    label,
    name,
    to,
    startIcon,
    tooltip,
    StartIcon,
    onClick,
    shouldFocus,
    "data-cy": dataCy,
    sx,
    ...props
}: ButtonExProps): React.ReactElement | null => {
    const buttonRef = React.createRef<HTMLButtonElement>();
    useEffect(() => {
        if (shouldFocus && buttonRef.current) {
            buttonRef.current.focus();
        }
    }, [buttonRef, shouldFocus]);

    if (visible) {
        let button = (
            <span>
                <Button
                    ref={buttonRef}
                    startIcon={
                        (StartIcon && <StartIcon />) ||
                        (typeof startIcon == "string" ? <Icon>{startIcon}</Icon> : startIcon)
                    }
                    onClick={onClick}
                    data-name={name || label}
                    data-cy={dataCy}
                    sx={{ ...sx, whiteSpace: "nowrap", overflowY: "hidden" }}
                    {...props}
                >
                    {label}
                </Button>
            </span>
        );

        if (to && !props.disabled) {
            button = <Link to={to}>{button}</Link>;
        }

        return tooltip ? (
            <Tooltip title={tooltip} arrow>
                <Box>{button}</Box>
            </Tooltip>
        ) : (
            button
        );
    }
    return null;
};
