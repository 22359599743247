import * as React from "react";
import {Grid2 as Grid, Typography} from "@mui/material";
import {convertToThreeDigitString, ErrorsShape} from "@/lib/utils";
import {
    AddressDTO,
    CompanyDisplayDTO,
    DeliveryInstructionDTO,
    OrderDataDTO,
    UserDisplayDTO
} from "@/api/data-contracts";
import StringInputField from "@/lib/components/inputfields/string-input-field";
import {formatAddressToMultiline} from "@/model/address";
import {CardEx} from "@/lib/components/CardEx";
import i18n from "@/i18n";
import DateInputField from "@/lib/components/inputfields/date-input-field";
import AutocompleteInputField from "@/lib/components/inputfields/auto-complete-input-field";
import {useEmployees} from "@/datacaches";
import {Constants} from "@/api/constants";
import {useTranslation} from "react-i18next";
import {CompanyFormI18N, GeneralI18N, RequestKeyWordsI18N} from "@/translations";
import {OrderDataType} from "@/model/orderData";
import {GroupBox} from "@/lib/components/GroupBox";

const formatDeliveryInstructions = (
    deliveryInstruction: DeliveryInstructionDTO | undefined,
    t: (key: string) => string
) => {
    if (deliveryInstruction == null) {
        return [];
    }

    const formatDay = (day: string, msg: string | undefined) => {
        if (msg == null) {
            return null;
        }
        return [day, msg];
    };

    const result = [] as (string[] | null)[];
    result.push(formatDay(t(CompanyFormI18N.mondayShortLabel), deliveryInstruction.mondayInstruction));
    result.push(formatDay(t(CompanyFormI18N.tuesdayShortLabel), deliveryInstruction.tuesdayInstruction));
    result.push(formatDay(t(CompanyFormI18N.wednesdayShortLabel), deliveryInstruction.wednesdayInstruction));
    result.push(formatDay(t(CompanyFormI18N.thursdayShortLabel), deliveryInstruction.thursdayInstruction));
    result.push(formatDay(t(CompanyFormI18N.fridayShortLabel), deliveryInstruction.fridayInstruction));
    result.push(formatDay(t(CompanyFormI18N.saturdayShortLabel), deliveryInstruction.saturdayInstruction));
    result.push(formatDay(t(CompanyFormI18N.sundayShortLabel), deliveryInstruction.sundayInstruction));
    return result.filter(Boolean) as string[][];
};

interface RequestInfo {
    customerReferenceNumber?: string;
    deliveryDateFrom?: string;
    deliveryDateTo?: string;
    deliveryAddress?: AddressDTO;
    contactPerson?: UserDisplayDTO;
    additionalInformation?: string;
    validUntil?: string | Date;
    company?: CompanyDisplayDTO;
    orderData?: OrderDataDTO;
}

export const RequestCard = ({
                                model,
                                errors,
                                onChange,
                                readonly,
                                mode
                            }: {
    model?: RequestInfo;
    onChange?: any;
    errors?: ErrorsShape<RequestInfo>;
    readonly?: boolean;
    mode: OrderDataType;
}) => {
    const {t} = useTranslation();
    const [employees] = useEmployees();
    const locale = i18n.language;
    const readonlyEx = readonly || mode === "quote";
    return (
        <CardEx title={mode === "request" ? "Ihre Anfrage" : `Anfrage von ${model?.company?.name}`}>
            <Grid container spacing={2} direction="column">
                <Grid size={{sm: 12}} container direction="row" spacing={2}>
                    <Grid size={{sm: 6}}>
                        <StringInputField
                            name="customerReferenceNumber"
                            value={model?.customerReferenceNumber}
                            label={t(RequestKeyWordsI18N.customerRequestNumber)}
                            error={errors?.customerReferenceNumber}
                            maxChars={Constants.CUSTOMER_REFERENCE_NUMBER_LENGTH_MAX}
                            activatePrefix={true}
                            onChange={onChange}
                            prefixValue={
                                model?.company?.number != null
                                    ? `${convertToThreeDigitString(model?.company?.number)} -`
                                    : t(GeneralI18N.notGiven)
                            }
                            disabled={readonlyEx}
                        />
                    </Grid>
                    <Grid size={{sm: 6}}>
                        <DateInputField
                            name="validUntil"
                            label={t(RequestKeyWordsI18N.requestValidityLabel)}
                            value={model?.validUntil}
                            error={errors?.validUntil}
                            onChange={onChange}
                            disabled={readonlyEx}
                        />
                    </Grid>
                </Grid>
                <Grid size={{sm: 12}} container direction="row" spacing={2}>
                    <Grid size={{sm: 6}}>
                        <DateInputField
                            name="deliveryDateFrom"
                            label={t(RequestKeyWordsI18N.deliveryDateFrom)}
                            value={model?.deliveryDateFrom}
                            error={errors?.deliveryDateFrom}
                            onChange={onChange}
                            disabled={readonlyEx}
                        />
                    </Grid>
                    <Grid size={{sm: 6}}>
                        <DateInputField
                            name="deliveryDateTo"
                            label={t(RequestKeyWordsI18N.deliveryDateTo)}
                            value={model?.deliveryDateTo}
                            error={errors?.deliveryDateTo}
                            onChange={onChange}
                            disabled={readonlyEx}
                        />
                    </Grid>
                </Grid>

                <Grid size={{sm: 12}}>
                    <StringInputField
                        name="deliveryAddressStreet"
                        label={t(RequestKeyWordsI18N.deliveryAddress)}
                        value={formatAddressToMultiline(model?.deliveryAddress, locale)}
                        multiline
                        disabled
                    />
                </Grid>

                <Grid size={{sm: 12}}>
                    <GroupBox
                        title={t(CompanyFormI18N.deliveryInstructionsLabel)}
                        name="additionalInformation"
                        disabled
                    >
                        <Grid container direction="column">
                            {formatDeliveryInstructions(model?.deliveryAddress?.deliveryInstruction, t).map((row) => (
                                <Grid
                                    key={row[0]}
                                    container
                                    direction="row"
                                    spacing={2}
                                    alignItems="flex-start"
                                    wrap="nowrap"
                                >
                                    <Grid
                                        size={{xs: 2, sm: 1}}
                                        sx={{whiteSpace: 'nowrap', paddingTop: "2px"}}
                                    >
                                        <strong>{row[0]}</strong>
                                    </Grid>
                                    <Grid
                                        size={{xs: 10, sm: 11}}
                                        sx={{paddingTop: "2px"}}
                                    >
                                        <Typography
                                            variant="body2"
                                            sx={{m: 0, wordBreak: 'break-word'}}
                                        >
                                            {row[1]}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </GroupBox>
                </Grid>

                <Grid size={{sm: 12}}>
                    <StringInputField
                        name="additionalInformation"
                        label={t(RequestKeyWordsI18N.additionalInformation)}
                        value={model?.additionalInformation}
                        onChange={onChange}
                        error={errors?.additionalInformation}
                        multiline
                        maxChars={Constants.ADDITIONAL_INFORMATION_LENGTH_MAX}
                        disabled={readonlyEx}
                    />
                </Grid>

                {mode === "quote" && (
                    <>
                        <Grid size={{sm: 12}}>
                            <StringInputField
                                name="companyName"
                                label={t(RequestKeyWordsI18N.companyName)}
                                value={model?.company?.name}
                                multiline
                                disabled
                            />
                        </Grid>
                        <Grid size={{sm: 12}}>
                            <StringInputField
                                name="deliveryAddressStreet"
                                label={t(RequestKeyWordsI18N.companyAddress)}
                                value={formatAddressToMultiline(model?.company?.companyAddress, locale)}
                                multiline
                                disabled
                            />
                        </Grid>
                        <Grid size={{sm: 12}} container direction="row" spacing={2}>
                            <Grid size={{sm: 6}}>
                                <StringInputField
                                    name="contactPersonTelephone"
                                    label={t(RequestKeyWordsI18N.phoneNumber)}
                                    value={model?.contactPerson?.telephone}
                                    multiline
                                    disabled
                                />
                            </Grid>
                            <Grid size={{sm: 6}}>
                                <StringInputField
                                    name="contactPersonTelefax"
                                    label={t(RequestKeyWordsI18N.telefax)}
                                    value={model?.contactPerson?.fax}
                                    multiline
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    </>
                )}

                <Grid size={{sm: 12}}>
                    <AutocompleteInputField
                        name="contactPerson"
                        label={t(RequestKeyWordsI18N.contactPerson)}
                        onChange={onChange}
                        value={model?.contactPerson}
                        error={errors?.contactPerson}
                        options={employees}
                        getOptionLabel={(option) =>
                            (option && `${option.firstName} ${option.lastName} <${option.email}>`) ?? ""
                        }
                        disabled={readonlyEx}
                    />
                </Grid>
            </Grid>
        </CardEx>
    );
};
