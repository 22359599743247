import * as React from "react";
import { QuoteStatus, RequestFilter } from "@/api/data-contracts";
import { withAuthWrapperComponent } from "@/app/WithAuthWrapperComponent";
import { RequestOverviewPage } from "@/pages/request-overview-page/RequestOverviewPage";
import { AppPaths } from "@/app/AppPaths";
import { QuoteStatusI18N } from "@/translations";
import { useTranslation } from "react-i18next";
import { quoteStatusOptions } from "@/datacaches";

const initial = [QuoteStatus.VACANT, QuoteStatus.SUBMITTED, QuoteStatus.ACCEPTED];

const ExternalRequestOverviewPage = () => {
    const { t } = useTranslation();

    const options = quoteStatusOptions.map((option) => ({
        label: option && t(QuoteStatusI18N[option]),
        value: option
    }));

    return (
        <RequestOverviewPage<QuoteStatus>
            type={RequestFilter.EXTERNAL}
            options={options}
            initial={initial}
            target={AppPaths.EXTERNAL_REQUEST_fn}
            statusField="quoteStatuses"
            localStorageName="externalRequestOverviewPageStatus"
        />
    );
};

export default withAuthWrapperComponent(ExternalRequestOverviewPage);
