/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ErrorResponseDTO, QuoteDTO, QuoteValidationParamsDTO, RequestDTO, RequestDisplayDTO } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class QuoteApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags quote-api
     * @name ConfirmQuote
     * @summary Confirm a quote
     * @request POST:/api/quotes/{id}/confirm
     * @secure
     */
    confirmQuote = (
        id: number,
        query: {
            /** @format int32 */
            quantityOptionUsed: number;
        },
        params: RequestParams = {}
    ) =>
        this.request<RequestDTO, (string[] | Record<string, string>) | void | RequestDTO>({
            path: `/api/quotes/${id}/confirm`,
            method: "POST",
            query: query,
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags quote-api
     * @name DismissQuote
     * @summary Dismiss a quote
     * @request POST:/api/quotes/{id}/dismiss
     * @secure
     */
    dismissQuote = (id: number, params: RequestParams = {}) =>
        this.request<RequestDTO, (string[] | Record<string, string>) | void | RequestDTO>({
            path: `/api/quotes/${id}/dismiss`,
            method: "POST",
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags quote-api
     * @name GetOrCreateQuote
     * @summary Get or create a quote filled with the request data
     * @request GET:/api/quotes/get-or-create
     * @secure
     */
    getOrCreateQuote = (
        query: {
            /** @format int64 */
            requestId: number;
        },
        params: RequestParams = {}
    ) =>
        this.request<QuoteDTO, (string[] | Record<string, string>) | void | QuoteDTO>({
            path: `/api/quotes/get-or-create`,
            method: "GET",
            query: query,
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags quote-api
     * @name RejectRequest
     * @summary Reject a request
     * @request POST:/api/quotes/reject-request
     * @secure
     */
    rejectRequest = (
        query: {
            /**
             * The ID of the request to be rejected
             * @format int64
             */
            requestId: number;
        },
        params: RequestParams = {}
    ) =>
        this.request<RequestDisplayDTO, (string[] | Record<string, string>) | void | RequestDisplayDTO>({
            path: `/api/quotes/reject-request`,
            method: "POST",
            query: query,
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags quote-api
     * @name RevokeRejectedRequestAsOfferor
     * @summary Revoke the rejection of a request as an offerer
     * @request POST:/api/quotes/revoke-rejected-request/{requestId}
     * @secure
     */
    revokeRejectedRequestAsOfferor = (requestId: number, params: RequestParams = {}) =>
        this.request<RequestDTO, (string[] | Record<string, string>) | void | RequestDTO>({
            path: `/api/quotes/revoke-rejected-request/${requestId}`,
            method: "POST",
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags quote-api
     * @name SubmitQuote
     * @summary Submit quote
     * @request POST:/api/quotes/submit
     * @secure
     */
    submitQuote = (data: QuoteDTO, params: RequestParams = {}) =>
        this.request<QuoteDTO, (string[] | Record<string, string>) | void | QuoteDTO>({
            path: `/api/quotes/submit`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags quote-api
     * @name ValidateQuoteDates
     * @request POST:/api/quotes/validate-dates
     * @secure
     */
    validateQuoteDates = (data: QuoteValidationParamsDTO, params: RequestParams = {}) =>
        this.request<ErrorResponseDTO, (string[] | Record<string, string>) | void>({
            path: `/api/quotes/validate-dates`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags quote-api
     * @name WithdrawQuote
     * @summary Withdraw a quote
     * @request POST:/api/quotes/{id}/withdraw
     * @secure
     */
    withdrawQuote = (id: number, params: RequestParams = {}) =>
        this.request<QuoteDTO, (string[] | Record<string, string>) | void | QuoteDTO>({
            path: `/api/quotes/${id}/withdraw`,
            method: "POST",
            secure: true,
            ...params
        });
}
