import {Badge} from "@/lib/components/Badge";
import * as React from "react";
import {useAppTheme} from "@/app/AppThemeContext";
import {GroupBox} from "@/lib/components/GroupBox";
import {UserStatus} from "@/api/data-contracts";
import {useTranslation} from "react-i18next";
import {UserStatusI18N} from "@/translations";
import {deepOrange, green, lime, orange, yellow} from "@mui/material/colors";

function getUserStatusText(t: (key: string) => string, status: UserStatus) {
    return status && t(UserStatusI18N[status]);
}

function getUserStatusColor(mode: string, status: UserStatus) {
    const tone = mode === "light" ? 200 : 900;
    switch (status) {
        case UserStatus.INVITED:
            return yellow[tone];
        case UserStatus.UNCONFIRMED:
            return orange[tone];
        case UserStatus.CONFIRMED:
            return green[tone];
        case UserStatus.DEACTIVATED:
            return deepOrange[tone];
        case UserStatus.EMAIL_CONFIRMED:
            return lime[tone];
    }
}

export const UserStatusBadge = ({status, fullWidth}: { status?: UserStatus; fullWidth?: boolean }) => {
    const theme = useAppTheme();
    const {t} = useTranslation();
    return (
        status && (
            <Badge
                label={getUserStatusText(t, status)}
                backgroundColor={getUserStatusColor(theme.mode, status)}
                fullWidth={fullWidth}
            />
        )
    );
};

export const UserStatusBadgeField = ({status}: { status: UserStatus }) => (
    <GroupBox title="Status" paddingTop={9} paddingBottom={9}>
        <UserStatusBadge status={status} fullWidth/>
    </GroupBox>
);
