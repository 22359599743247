import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

/**
 * Props for the IconButtonEx component.
 *
 * @property {string} [className] - The class name of the button.
 * @property {React.CSSProperties} [style] - The style of the button.
 * @property {"small" | "large" | "medium"} [size="small"] - The size of the button.
 * @property {any} [component] - The component to use for the button.
 * @property {React.ReactNode} [icon] - The icon to display inside the button.
 * @property {React.ComponentType<any>} [Icon] - The component to use for the icon.
 * @property {string} [title] - The title of the button.
 * @property {(e: React.MouseEvent<HTMLButtonElement>) => void} [onClick] - The function to call when the button is clicked.
 * @property {boolean} [disabled] - If true, disables the button.
 * @property {number} [tabIndex] - The tab index of the button.
 * @property {string} [data-cy] - The data-cy attribute for the button.
 * @property {"90deg" | "180deg" | "270deg"} [rotate] - The rotation angle for the icon.
 */
export type IconButtonExProps = {
    className?: string;
    style?: React.CSSProperties;
    size?: "small" | "large" | "medium";
    component?: any;
    icon?: React.ReactNode;
    Icon?: React.ComponentType<any>;
    title?: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    tabIndex?: number;
    "data-cy"?: string;
    rotate?: "90deg" | "180deg" | "270deg";
    to?: string;
    tooltip?: string;
};

/**
 * IconButtonEx component.
 *
 * @param {IconButtonExProps} props - The props for the component.
 * @returns {React.ReactElement} The rendered IconButtonEx component.
 */
export const IconButtonEx = ({
    className,
    style,
    size = "small",
    component,
    icon,
    Icon,
    title,
    onClick,
    disabled,
    tabIndex,
    rotate,
    "data-cy": dataCy,
    to,
    tooltip
}: IconButtonExProps): React.ReactElement => {
    const button = (
        <IconButton
            className={className}
            style={style}
            size={size}
            onClick={onClick}
            disabled={disabled}
            title={title}
            component={component}
            tabIndex={tabIndex}
            data-cy={dataCy}
        >
            {(Icon && <Icon fontSize={size} style={{ rotate }} />) || icon}
        </IconButton>
    );

    const comp = to && !disabled ? <Link to={to}>{button}</Link> : button;
    if (tooltip) {
        return <Tooltip title={tooltip}>{comp}</Tooltip>;
    }
    return comp;
};
