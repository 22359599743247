import {Dialog, DialogActions, DialogContent, DialogTitle, Grid2} from "@mui/material";
import StringInputField from "@/lib/components/inputfields/string-input-field";
import {useModelState} from "@/lib/modelstate";
import {ButtonEx} from "@/lib/components/buttons/ButtonEx";
import {ChatAnswerDTO, ChatEntryDTO, ChatGroupDTO, ChatQuestionDTO} from "@/api/data-contracts";
import * as React from "react";
import {useEffect, useRef} from "react";
import {Constants} from "@/api/constants";
import {useTranslation} from "react-i18next";
import {ChatI18N, GeneralI18N} from "@/translations";
import {chatApi} from "@/boxhub-api";
import CheckboxInputField from "@/lib/components/inputfields/checkbox-input-field";
import {useSnackbarEx} from "@/lib/snackbarex";

export type ChatAnswerDialogProps = {
    open: boolean;
    close: () => void;
    chatGroup: ChatGroupDTO;
    entry: ChatEntryDTO;
    setChatGroup: (chatGroup: ChatGroupDTO) => void;
};

export const ChatAnswerDialog = ({open, close, chatGroup, entry, setChatGroup}: ChatAnswerDialogProps) => {
    const {t} = useTranslation();
    const focusRef = useRef<HTMLInputElement>(null);
    const {showMessage} = useSnackbarEx();

    const {model, errors, onChange, validate} = useModelState<ChatAnswerDTO>({
        initialState: {
            entryId: entry.id!
        } as ChatQuestionDTO
    });

    const save = () => {
        if (validate()) {
            chatApi
                .chatAnswer(chatGroup.id!, model)
                .then((r) => {
                    setChatGroup(r.data);
                    close();
                })
                .catch((error) => {
                    showMessage({
                        severity: "error",
                        summary: t(ChatI18N.chatAnswerError),
                        error
                    });
                    close();
                });
        }
    };

    useEffect(() => {
        setTimeout(() => {
            focusRef.current?.focus();
        }, 250);
    }, []);

    return (
        <Dialog open={open} onClose={close} maxWidth="sm" fullWidth>
            <DialogTitle>{t(ChatI18N.AnswerTitle)}</DialogTitle>
            <DialogContent>
                <Grid2 container spacing={3} padding={2} width="100%">
                    <Grid2 width="100%">
                        <StringInputField
                            value={entry.question?.message}
                            label={t(ChatI18N.QuestionLabel)}
                            disabled
                            multiline
                            minRows={3}
                        />
                    </Grid2>
                    <Grid2 width="100%">
                        <StringInputField
                            name="message"
                            value={model?.message}
                            label={t(ChatI18N.AnswerLabel)}
                            error={errors?.message}
                            maxChars={Constants.CHAT_MESSAGE_LENGTH_MAX}
                            onChange={onChange}
                            multiline
                            minRows={3}
                            textFieldRef={focusRef}
                        />
                    </Grid2>
                    <Grid2>
                        <CheckboxInputField
                            name="forEveryone"
                            value={model.forEveryone}
                            onChange={onChange}
                            label={t(ChatI18N.ForEveryone)}
                        />
                    </Grid2>
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Grid2 container direction="row" flexWrap="nowrap" spacing={2} padding={1}>
                    <Grid2>
                        <ButtonEx
                            label={t(GeneralI18N.cancelButton)}
                            color="secondary"
                            variant="contained"
                            onClick={close}
                        />
                    </Grid2>
                    <Grid2>
                        <ButtonEx
                            label={t(ChatI18N.AnswerSendButton)}
                            color="primary"
                            variant="contained"
                            disabled={model.message == null}
                            onClick={() => save()}
                        />
                    </Grid2>
                </Grid2>
            </DialogActions>
            ;
        </Dialog>
    );
};
