import React, { ReactNode, useRef } from "react";
import { FileUploadHandler } from "@/lib/file-upload-handler";

/**
 * Props for the DropZone component.
 *
 * @property {FileUploadHandler} uploader - The file upload handler.
 * @property {ReactNode} children - The child elements to be rendered inside the drop area.
 * @property {boolean} [disabled] - If true, disables the drop area.
 */
export type DropZoneProps = {
    uploader: FileUploadHandler;
    children: ReactNode;
    disabled?: boolean;
};

/**
 * FileDropArea component.
 *
 * @param {DropZoneProps} props - The props for the component.
 * @returns {React.ReactElement} The rendered FileDropArea component.
 */
export const FileDropArea = ({ children, uploader, disabled }: DropZoneProps): React.ReactElement => {
    const [upload] = uploader;
    const divRef = useRef<HTMLDivElement>(null);

    const handleDrop = (ev: React.DragEvent<HTMLDivElement>) => {
        if (disabled || divRef.current == null || !divRef.current.contains(ev.target as Node)) {
            return;
        }
        ev.preventDefault();

        const files = (
            (ev.dataTransfer?.items &&
                [...ev.dataTransfer.items].filter((i) => i.kind === "file").map((i) => i.getAsFile())) ||
            (ev.dataTransfer?.files && [...ev.dataTransfer.files]) ||
            []
        ).filter(Boolean) as File[];

        if (files?.length > 0) {
            upload(files);
        }
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        if (divRef.current == null || !divRef.current.contains(e.target as Node)) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        if (disabled) {
            e.dataTransfer.dropEffect = "none";
        } else {
            e.dataTransfer.dropEffect = "copy";
            return false;
        }
    };

    return (
        <span ref={divRef} style={{ height: "100%", width: "100%" }} onDrop={handleDrop} onDragOver={handleDragOver}>
            {children}
        </span>
    );
};
