import {AddressDTO, Country, DeliveryInstructionDTO} from "@/api/data-contracts";
import {CompanyFormI18N} from "@/translations";
import {useTranslation} from "react-i18next";

function formatCountry(country: Country, locale: string) {
    let countryString = "";
    // TODO: Temporaere Loesung, bis die Uebersetzungen im Backend stattfinden
    if (locale === "en") {
        if (country === Country.GERMANY) {
            countryString = "Germany";
        } else if (country === Country.NETHERLANDS) {
            countryString = "Netherlands";
        }
    } else if (locale === "de") {
        if (country === Country.GERMANY) {
            countryString = "Deutschland";
        } else if (country === Country.NETHERLANDS) {
            countryString = "Niederlande";
        }
    }
    return countryString;
}

/**
 * Formats an address object into a string based on the specified locale.
 *
 * @param {AddressDTO} [address] - The address object to format. If not provided, an empty string is returned.
 * @param {string} [locale="de"] - The locale to use for formatting. Defaults to "de" (German).
 * @returns {string} - The formatted address string.
 */
export function formatAddressToString(address?: AddressDTO, locale: string = "de"): string {
    if (!address) return "";

    let countryString = formatCountry(address.country, locale);

    // Format the address based on locale
    let formattedAddress =
        locale === "en"
            ? [
                address.street ?? "",
                address.city ?? "",
                address.zip ?? "", // In English, ZIP typically goes after the city
                countryString ?? ""
            ]
            : [
                address.street ?? "",
                address.zip ?? "", // In German, ZIP comes before the city
                address.city ?? "",
                countryString ?? ""
            ];

    return formattedAddress
        .filter(Boolean) // Filter out any undefined or empty strings
        .join(", ");
}

/**
 * Formats an address object into a multiline string based on the specified locale.
 *
 * @param {AddressDTO | undefined} address - The address object to format. If not provided, an empty string is returned.
 * @param {string} locale - The locale to use for formatting. Defaults to "de" (German).
 * @returns {string} - The formatted address string with each part on a new line.
 */
export function formatAddressToMultiline(address: AddressDTO | undefined, locale: string): string {
    if (!address) {
        return "";
    }
    const formattedAddress =
        locale === "en"
            ? [
                address.street ?? "",
                `${address.city ?? ""} ${address.zip ?? ""}`, // In English, ZIP typically goes after the city
                formatCountry(address.country, locale) ?? ""
            ]
            : [
                address.street ?? "",
                `${address.zip ?? ""} ${address.city ?? ""}`, // In German, ZIP comes before the city
                formatCountry(address.country, locale) ?? ""
            ];

    return formattedAddress.filter(Boolean).join("\n");
}

/**
 * Formats the weekdays of a delivery instruction into a string.
 *
 * @param {DeliveryInstructionDTO} deliveryInstruction - The delivery instruction object to format.
 * @returns {string} - The formatted weekdays string.
 */
export function generateStringListOfWeekdays(deliveryInstruction?: DeliveryInstructionDTO): string {
    const {t} = useTranslation();
    if (!deliveryInstruction) {
        return "";
    }
    let weekdaysStringGenerator: string[] = [];
    if (deliveryInstruction.mondayInstruction) {
        weekdaysStringGenerator.push(t(CompanyFormI18N.mondayShortLabel));
    }
    if (deliveryInstruction.tuesdayInstruction) {
        weekdaysStringGenerator.push(t(CompanyFormI18N.tuesdayShortLabel));
    }
    if (deliveryInstruction.wednesdayInstruction) {
        weekdaysStringGenerator.push(t(CompanyFormI18N.wednesdayShortLabel));
    }
    if (deliveryInstruction.thursdayInstruction) {
        weekdaysStringGenerator.push(t(CompanyFormI18N.thursdayShortLabel))
    }
    if (deliveryInstruction.fridayInstruction) {
        weekdaysStringGenerator.push(t(CompanyFormI18N.fridayShortLabel))
    }
    if (deliveryInstruction.saturdayInstruction) {
        weekdaysStringGenerator.push(t(CompanyFormI18N.saturdayShortLabel))
    }
    if (deliveryInstruction.sundayInstruction) {
        weekdaysStringGenerator.push(t(CompanyFormI18N.sundayShortLabel))
    }
    return weekdaysStringGenerator.join(", ");
}

export const formatZipCity = (address?: AddressDTO): string | undefined =>
    address && `${address.zip ?? ""} ${address.city ?? ""}`;
