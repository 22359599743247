// DateFormatter.ts
import { getI18n } from "react-i18next";

export type DateFormat = "date" | "date_hm" | "datetime";

type Locale = "en" | "de";
type FormatMapping = Record<Locale, { date: string; datetime: string; date_hm: string }>;

// Define format mapping with date and datetime formats
const formatMapping: FormatMapping = {
    en: { date: "MM/DD/YYYY", datetime: "MM/DD/YYYY HH:mm:ss", date_hm: "MM/DD/YYYY HH:mm" },
    de: { date: "DD.MM.YYYY", datetime: "DD.MM.YYYY HH:mm:ss", date_hm: "DD.MM.YYYY HH:mm" }
};

/**
 * Function to get the date format based on the current locale and whether to include time.
 *
 * @param format Konkretes Format
 * @returns {string} The date format string based on the locale and includeTime parameter.
 */
export const DateFormatter = (format: DateFormat = "date"): string => {
    // Get the current language from i18next
    const locale: Locale = (getI18n().language as Locale) || "en"; // Default to 'en' if locale is not set
    // Get date format based on locale and format type
    return (formatMapping[locale] && formatMapping[locale][format]) || "YYYY-MM-DD";
};
