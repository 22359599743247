/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { HttpClient, RequestParams } from "./http-client";

export class FileApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags file-api
     * @name GetHtmlFile
     * @request GET:/api/files/{filename}
     * @secure
     */
    getHtmlFile = (filename: string, params: RequestParams = {}) =>
        this.request<File, (string[] | Record<string, string>) | void>({
            path: `/api/files/${filename}`,
            method: "GET",
            secure: true,
            ...params
        });
}
