import * as React from "react";
import {useState} from "react";
import {withAuthWrapperComponent} from "@/app/WithAuthWrapperComponent";
import {userApi} from "@/boxhub-api";
import {useParams} from "react-router-dom";
import {AppFrame} from "@/app/AppFrame";
import {useModelState} from "@/lib/modelstate";
import {CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid2 as Grid} from "@mui/material";
import {ApiTokenDTO, UserDTO} from "@/api/data-contracts";
import {IconButtonEx} from "@/lib/components/buttons/IconButtonEx";
import {Lock, LockOpen} from "@mui/icons-material";
import ContentCopy from "@mui/icons-material/ContentCopy";
import {UserStatusBadge} from "@/common/UserStatusBadge";
import StringInputField from "@/lib/components/inputfields/string-input-field";
import {Constants} from "@/api/constants";
import InfoTooltipIcon from "@/lib/components/InfoTooltipIcon";
import SelectInputField from "@/lib/components/inputfields/select-input-field";
import {CardEx} from "@/lib/components/CardEx";
import {useUserEditSchema} from "@/pages/user-page/schema";
import {languageOptions, salutationOptions} from "@/datacaches";
import {ButtonEx} from "@/lib/components/buttons/ButtonEx";
import {useTranslation} from "react-i18next";
import {AdministrationI18N, GeneralI18N, LanguagesI18N, SalutationsI18N, UserFormI18N} from "@/translations";
import {truncateString} from "@/lib/utils";
import {useAuth} from "@/app/AuthContext";
import ConfirmationDialog from "@/lib/components/ConfirmationDialog";
import {useSnackbarEx} from "@/lib/snackbarex";
import DateInputField from "@/lib/components/inputfields/date-input-field";

const UserPage = () => {
    const {t} = useTranslation();
    const params = useParams();
    const {user} = useAuth();
    const {showMessage} = useSnackbarEx();

    const salutationSelectOptions = salutationOptions.map((option) => ({
        label: option && t(SalutationsI18N[option]),
        value: option
    }));
    const languageSelectOptions = languageOptions.map((option) => ({
        label: option && t(LanguagesI18N[option]),
        value: option
    }));

    const [locked, setLocked] = useState(true);

    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [showTokenDialog, setShowTokenDialog] = useState(false);
    const [tokenData, setTokenData] = useState<ApiTokenDTO | null>(null);
    const [showLockConfirmDialog, setShowLockConfirmDialog] = useState(false);

    const schema = useUserEditSchema();

    const {model, errors, onChange, status, isDirty, dispatch, setErrors, save} = useModelState<UserDTO, number>({
        initialState: {},
        id: +params.id!,
        schema,
        loader: userApi.getUser,
        updater: userApi.saveUser,
        validateDisabled: locked
    });

    const iAmTheUser = user?.id === model?.id;

    const doSaveUser = () => {
        const promise = save();
        if (promise) {
            promise.then(() => {
                setLocked(true);
            });
        }
    };

    const pageTitle = (
        <>
            {`${t(UserFormI18N.profileLabel)} '${truncateString(model?.firstName, 32)} ${truncateString(model?.lastName, 32)}'`} {" "}
            <UserStatusBadge status={model?.status}/>
        </>
    );

    const handleLockToggle = () => {
        if (locked) {
            setLocked(false);
        } else {
            if (isDirty) {
                setShowLockConfirmDialog(true);
            } else {
                setLocked(true);
                setErrors({});
            }
        }
    };

    const confirmLockForm = () => {
        if (isDirty) {
            dispatch({type: "--reset"});
        }
        setLocked(true);
        setErrors({});
        setShowLockConfirmDialog(false);
    };

    const confirmGenerateToken = () => {
        userApi.createApiToken()
            .then(response => {
                setTokenData(response.data);
                setShowTokenDialog(true);
                setShowConfirmDialog(false);
                showMessage({summary: t(UserFormI18N.generatedApiTokenSuccessMessage), severity: "success"})
            })
            .catch(error => {
                showMessage({summary: t(UserFormI18N.generatedApiTokenFailedMessage), severity: "error", error})
                setShowConfirmDialog(false);
            });
    };

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
    };

    const handleTokenDialogClose = () => {
        setShowTokenDialog(false);
        if (tokenData && tokenData.expirationDate) {
            dispatch({type: "--change", data: ["apiTokenExpirationDate", tokenData.expirationDate]});
        }
    };

    const buttons = (
        <Grid container spacing={2}>
            {!locked && (
                <Grid>
                    <ButtonEx
                        label={
                            status === "saving" ? (
                                <CircularProgress size={24}/>
                            ) : (
                                t(AdministrationI18N.saveChangesButton)
                            )
                        }
                        data-cy="save-user-button"
                        onClick={doSaveUser}
                        variant="contained"
                        color="primary"
                        disabled={!isDirty || status === "saving"}
                    />
                </Grid>
            )}
            {iAmTheUser && (
                <Grid>
                    <ButtonEx
                        label={t(UserFormI18N.generateNewApiTokenButton)}
                        data-cy={"generate-api-token-button"}
                        onClick={() => setShowConfirmDialog(true)}
                        variant="contained"
                        color="primary"
                    />
                </Grid>
            )}
            {iAmTheUser && (
                <Grid>
                    <IconButtonEx
                        Icon={locked ? Lock : LockOpen}
                        tooltip={
                            locked
                                ? t(AdministrationI18N.unlockForEditingDescription)
                                : t(AdministrationI18N.lockEditingDescription)
                        }
                        onClick={handleLockToggle}
                        disabled={status === "saving"}
                    />
                </Grid>
            )}
        </Grid>
    );

    return (
        <>
            <AppFrame modelState={status} title={pageTitle} buttons={buttons} isDirty={isDirty && !showTokenDialog}>
                <Grid container spacing={2} padding={2}>
                    <Grid size={{xs: 12}}>
                        <CardEx title={t(UserFormI18N.userFormTitle)}>
                            <Grid container spacing={3} direction="column" width="100%">
                                <Grid container direction="row" spacing={2} width="100%">
                                    <Grid size={{sm: 6, md: 4, lg: 3}}>
                                        <StringInputField
                                            name="email"
                                            disabled
                                            value={model?.email}
                                            label={t(UserFormI18N.emailLabel)}
                                            error={errors?.email}
                                            maxChars={Constants.EMAIL_LENGTH_MAX}
                                            endAdornment={
                                                <InfoTooltipIcon
                                                    tooltipText={t(UserFormI18N.emailCanNotBeChangedDescription)}
                                                />
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" spacing={2} width="100%">
                                    <Grid size={{sm: 4, md: 2, lg: 3}}>
                                        <SelectInputField
                                            name="salutation"
                                            disabled={locked}
                                            label={t(UserFormI18N.salutationLabel)}
                                            value={model?.salutation}
                                            error={errors?.salutation}
                                            options={salutationSelectOptions}
                                            displayEmpty={true}
                                            onChange={onChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" spacing={2} width="100%">
                                    <Grid size={{sm: 6, md: 4, lg: 3}}>
                                        <StringInputField
                                            name="firstName"
                                            disabled={locked}
                                            label={t(UserFormI18N.firstNameLabel)}
                                            value={model?.firstName}
                                            error={errors?.firstName}
                                            maxChars={Constants.FIRSTNAME_LENGTH_MAX}
                                            onChange={onChange}
                                        />
                                    </Grid>
                                    <Grid size={{sm: 6, md: 4, lg: 3}}>
                                        <StringInputField
                                            name="lastName"
                                            disabled={locked}
                                            label={t(UserFormI18N.lastNameLabel)}
                                            value={model?.lastName}
                                            error={errors?.lastName}
                                            maxChars={Constants.LASTNAME_LENGTH_MAX}
                                            onChange={onChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" spacing={2} width="100%">
                                    <Grid size={{sm: 6, md: 4, lg: 3}}>
                                        <StringInputField
                                            name="telephone"
                                            disabled={locked}
                                            label={t(UserFormI18N.phoneLabel)}
                                            value={model?.telephone}
                                            error={errors?.telephone}
                                            maxChars={Constants.PHONE_NUMBER_LENGTH_MAX}
                                            onChange={onChange}
                                        />
                                    </Grid>
                                    <Grid size={{sm: 6, md: 4, lg: 3}}>
                                        <StringInputField
                                            name="fax"
                                            disabled={locked}
                                            label={t(UserFormI18N.faxLabel)}
                                            value={model?.fax}
                                            error={errors?.fax}
                                            maxChars={Constants.PHONE_NUMBER_LENGTH_MAX}
                                            onChange={onChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" spacing={2} width="100%">
                                    <Grid size={{sm: 6, md: 4, lg: 3}}>
                                        <SelectInputField
                                            name="language"
                                            disabled={locked}
                                            label={t(UserFormI18N.languageLabel)}
                                            value={model?.language}
                                            error={errors?.language}
                                            options={languageSelectOptions}
                                            displayEmpty={true}
                                            onChange={onChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardEx>
                    </Grid>
                    {iAmTheUser && (
                        <Grid size={{xs: 12}}>
                            <CardEx title={t(UserFormI18N.apiTokenLabel)}>
                                <Grid container spacing={2} direction="column" width="100%">
                                    <Grid size={{xs: 12, sm: 6, md: 4}}>
                                        <DateInputField
                                            name="apiTokenExpirationDate"
                                            disabled
                                            value={model?.apiTokenExpirationDate}
                                            label={t(UserFormI18N.apiTokenExpirationDate)}
                                        />
                                    </Grid>
                                </Grid>
                            </CardEx>
                        </Grid>
                    )}
                </Grid>
            </AppFrame>

            <ConfirmationDialog
                open={showConfirmDialog}
                onClose={() => setShowConfirmDialog(false)}
                onConfirm={confirmGenerateToken}
                title={t(UserFormI18N.generatedApiTokenDialogTitle)}
                message={t(UserFormI18N.generatedApiTokenDialogMessage)}
                confirmButtonText={t(GeneralI18N.yes)}
                cancelButtonText={t(GeneralI18N.no)}
            />

            <ConfirmationDialog
                open={showLockConfirmDialog}
                onClose={() => setShowLockConfirmDialog(false)}
                onConfirm={confirmLockForm}
                title={t(UserFormI18N.lockFormDialogTitle)}
                message={t(UserFormI18N.lockFormDialogMessage)}
                confirmButtonText={t(GeneralI18N.yes)}
                cancelButtonText={t(GeneralI18N.no)}
            />

            <Dialog open={showTokenDialog} onClose={handleTokenDialogClose} fullWidth>
                <DialogTitle>{t(UserFormI18N.newApiTokenDialogTitle)}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} padding={2} width="100%">
                        <Grid size={{xs: 12}}>
                            <StringInputField
                                name="apiToken"
                                label={t(UserFormI18N.apiTokenLabel)}
                                value={tokenData?.apiToken || ""}
                                disabled
                                endAdornment={
                                    <IconButtonEx
                                        Icon={ContentCopy}
                                        tooltip={t("Copy")}
                                        onClick={() => copyToClipboard(tokenData?.apiToken || "")}
                                    />
                                }
                            />
                        </Grid>
                        <Grid size={{xs: 12}}>
                            <StringInputField
                                name="companyId"
                                label={t(UserFormI18N.companyIdLabel)}
                                value={tokenData?.companyId || ""}
                                disabled
                                endAdornment={
                                    <IconButtonEx
                                        Icon={ContentCopy}
                                        tooltip={t("Copy")}
                                        onClick={() => copyToClipboard(tokenData?.companyId || "")}
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonEx
                        label={t(GeneralI18N.closeButton)}
                        onClick={handleTokenDialogClose}
                        variant="contained"
                        color="primary"
                    />
                </DialogActions>
            </Dialog>
        </>
    );
};

export default withAuthWrapperComponent(UserPage);