import { Backup, Publish } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeUID } from "@/lib/utils";
import { FileUploadHandler, useFileUploadHandler } from "@/lib/file-upload-handler";
import { RequestParams } from "@/api/http-client";

/**
 * Props for the UploadButtonEx component.
 *
 * @template T - The type of the response from the upload function.
 * @property {string} [id] - The id of the input element.
 * @property {string} [label] - The label to display on the button.
 * @property {React.ReactNode} [icon] - The icon to display on the button.
 * @property {boolean} [disabled] - If true, disables the button.
 * @property {FileUploadHandler} uploader - The file upload handler.
 * @property {string} [accept] - The file types that the input should accept.
 * @property {boolean} [multiple] - If true, allows multiple file selection.
 * @property {(files: File[], params: any) => Promise<T>} [uploadFunction] - The function to call to upload the files.
 * @property {boolean} [fullWidth] - If true, the button will take up the full width of its container.
 * @property {"small" | "large" | "medium"} [size] - The size of the button.
 * @property {"text" | "outlined" | "contained"} [variant] - The variant of the button.
 */
export type UploadButtonExProps<T = any> = {
    id?: string;
    label?: string;
    icon?: React.ReactNode;
    disabled?: boolean;
    uploader: FileUploadHandler;
    accept?: string;
    multiple?: boolean;
    uploadFunction?: (files: File[], params: any) => Promise<T>;
    fullWidth?: boolean;
    size?: "small" | "large" | "medium";
    variant?: "text" | "outlined" | "contained";
};

/**
 * FileUploadButtonEx component.
 *
 * @template T - The type of the response from the upload function.
 * @param {UploadButtonExProps<T>} props - The props for the component.
 * @returns {React.ReactElement} The rendered FileUploadButtonEx component.
 */
export const FileUploadButtonEx = <T = any,>({
    id,
    label,
    icon,
    disabled,
    uploader,
    accept,
    multiple,
    fullWidth,
    size,
    variant
}: UploadButtonExProps<T>): React.ReactElement => {
    const lid = useMemo(() => id || makeUID("file"), [id]);

    const [upload, uploading] = uploader;
    const [files, setFiles] = useState<File[]>([]);
    const [inputKey, setInputKey] = useState(0);

    const onFormSubmit = useCallback(
        (e?: UIEvent) => {
            e?.preventDefault();
            e?.stopPropagation();
            upload(files);
        },
        [upload, files]
    );

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fl = e.target.files;
        if (fl != null) {
            const arr = [] as File[];
            for (let file = 0; file < fl.length; file++) {
                arr.push(fl[file]);
            }
            setFiles(arr);
        }
    };

    useEffect(() => {
        if (files != null && files.length > 0) {
            onFormSubmit();
            setFiles([]);
            setInputKey(inputKey + 1);
        }
    }, [files, onFormSubmit, inputKey]);

    return (
        <>
            <input
                key={inputKey}
                id={lid}
                type="file"
                accept={accept}
                style={{ display: "none" }}
                multiple={multiple}
                onChange={onChange}
                disabled={disabled || uploading}
            />
            <label htmlFor={lid}>
                {label ? (
                    <Button
                        startIcon={icon || <Publish />}
                        size={size}
                        variant={variant}
                        component="span"
                        disabled={disabled || uploading}
                        fullWidth={fullWidth}
                    >
                        {label}
                    </Button>
                ) : (
                    <IconButton size={size} component="span" disabled={disabled || uploading}>
                        <Backup />
                    </IconButton>
                )}
            </label>
        </>
    );
};

/**
 * Props for the FileUploadButton component.
 *
 * @template T - The type of the response from the upload function.
 * @property {string} [id] - The id of the input element.
 * @property {string} [label] - The label to display on the button.
 * @property {React.ReactNode} [icon] - The icon to display on the button.
 * @property {boolean} [disabled] - If true, disables the button.
 * @property {string} [accept] - The file types that the input should accept.
 * @property {boolean} [multiple] - If true, allows multiple file selection.
 * @property {(files: File[], params: RequestParams) => Promise<T>} uploadFunction - The function to call to upload the files.
 */
export type FileUploadButtonProps<T = any> = {
    id?: string;
    label?: string;
    icon?: React.ReactNode;
    disabled?: boolean;
    accept?: string;
    multiple?: boolean;
    uploadFunction: (files: File[], params: RequestParams) => Promise<T>;
};

export const FileUploadButton = <T = any,>({
    id,
    label,
    icon,
    disabled,
    accept,
    multiple,
    uploadFunction
}: FileUploadButtonProps<T>) => {
    const uploader = useFileUploadHandler(uploadFunction);
    return (
        <FileUploadButtonEx<T>
            id={id}
            label={label}
            icon={icon}
            disabled={disabled}
            uploader={uploader}
            accept={accept}
            multiple={multiple}
        />
    );
};
