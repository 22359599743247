import * as React from "react";
import { Grid2, useTheme } from "@mui/material";
import { ChatEntryDTO, ChatGroupDTO } from "@/api/data-contracts";
import { CardEx } from "@/lib/components/CardEx";
import { useTranslation } from "react-i18next";
import { ModelAction } from "@/lib/modelstate";
import { ButtonEx } from "@/lib/components/buttons/ButtonEx";
import { Chat } from "@mui/icons-material";
import { useDialogController } from "@/lib/dialog-controller";
import { ChatQuestionDialog } from "@/common/chat/ChatQuestionDialog";
import ChatMessageList from "@/common/chat/ChatMessageList";
import { ChatAnswerDialog } from "@/common/chat/ChatAnswerDialog";
import { ChatI18N } from "@/translations";

export type ChatGroupCardProps = {
    chatGroup?: ChatGroupDTO;
    dispatch: React.Dispatch<ModelAction>;
    canAsk?: boolean;
    readonly?: boolean;
};

export const ChatGroupCard = ({ chatGroup, canAsk, readonly, dispatch }: ChatGroupCardProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [DialogNode, showDialog] = useDialogController();

    const askQuestion = () => {
        showDialog((open, close) => (
            <ChatQuestionDialog
                open={open}
                close={close}
                chatGroup={chatGroup!}
                setChatGroup={(g) =>
                    dispatch({
                        type: "setChatGroup",
                        data: g,
                        nochange: true
                    })
                }
            />
        ));
    };

    const answerQuestion = (entry: ChatEntryDTO) => {
        showDialog((open, close) => (
            <ChatAnswerDialog
                open={open}
                close={close}
                entry={entry}
                chatGroup={chatGroup!}
                setChatGroup={(g) =>
                    dispatch({
                        type: "setChatGroup",
                        data: g,
                        nochange: true
                    })
                }
            />
        ));
    };

    const title = (
        <Grid2 container direction="row" alignItems="center">
            <Grid2 flexGrow={1}>Fragen und Antworten</Grid2>
            <Grid2>
                <ButtonEx
                    label={t(ChatI18N.QuestionTitle)}
                    startIcon={<Chat />}
                    onClick={askQuestion}
                    visible={!readonly && !!canAsk}
                />
            </Grid2>
        </Grid2>
    );

    return (
        <CardEx title={title}>
            {/*sx={{ backgroundColor: theme.palette.mode === "dark" ? brown[900] : orange[100] }}*/}
            <ChatMessageList entries={chatGroup?.entries} answerQuestion={answerQuestion} readonly={readonly} />
            <DialogNode />
        </CardEx>
    );
};
