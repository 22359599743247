import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { extractEvent } from "@/lib/utils";

export const useLocalState = <T = any>(
    itemName: string | undefined,
    init?: T | (() => T)
): [T | undefined, Dispatch<SetStateAction<T>>, (e: any) => void] => {
    const initFnc = useCallback(() => {
        const val = itemName == null ? null : localStorage.getItem(itemName);
        if (val != null) {
            try {
                const data = JSON.parse(val);
                if (data != null) {
                    return data;
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error("Failed to parse local state", val, e);
            }
        }
        if (typeof init === "function") {
            // @ts-ignore
            return init();
        }
        return init;
    }, [init, itemName]);

    const [state, setStateInt] = useState<T>(initFnc);

    const setState = useCallback(
        (val: T | ((old: T) => T)) => {
            setStateInt((old: T) => {
                // @ts-ignore
                const next = typeof val === "function" ? val(old) : val;
                if (itemName) {
                    const json = JSON.stringify(next);
                    localStorage.setItem(itemName, json);
                }
                return next;
            });
        },
        [setStateInt, itemName]
    );

    const onChange = useCallback(
        (e) => {
            const nv = extractEvent(e);
            if (nv) {
                setState((old) => ({ ...old, [nv[0]]: nv[1] }));
            } else {
                console.error("onChange called without name!");
            }
        },
        [setState]
    );

    return [state, setState, onChange];
};
