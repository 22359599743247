import * as React from "react";
import {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {AppFrame} from "@/app/AppFrame";
import {CircularProgress, Grid2 as Grid, Grid2} from "@mui/material";
import {useModelState} from "@/lib/modelstate";
import {UserSignUpDTO} from "@/api/data-contracts";
import {authenticationApi} from "@/boxhub-api";
import {AxiosError} from "axios";
import {FinishedPage, FinishedPageProps} from "@/common/FinishedPage";
import {useTranslation} from "react-i18next";
import {UserFormI18N, UserSignUpPageI18N} from "@/translations";
import * as yup from "yup";
import {Constants} from "@/api/constants";
import {PASSWORD_REGEX} from "@/regex";
import {CardEx} from "@/lib/components/CardEx";
import StringInputField from "@/lib/components/inputfields/string-input-field";
import {ButtonEx} from "@/lib/components/buttons/ButtonEx";
import {AppPaths} from "@/app/AppPaths";
import {encodePasswordBase64} from "@/lib/utils";

export const UserSignUpPage = () => {
    const {t} = useTranslation();
    const params = useParams();
    const [skipUnsavedCheck, setSkipUnsavedCheck] = useState(false);
    const navigate = useNavigate();

    const [finishedInfo, setFinishedInfo] = React.useState<FinishedPageProps | null>(null);

    const schema = yup.object().shape({
        password: yup
            .string()
            .required(t(UserFormI18N.requiredPasswordError))
            .min(
                Constants.PASSWORD_LENGTH_MIN,
                t(UserFormI18N.minPasswordError, {minInteger: Constants.PASSWORD_LENGTH_MIN})
            )
            .max(
                Constants.PASSWORD_LENGTH_MAX,
                t(UserFormI18N.maxPasswordError, {maxInteger: Constants.PASSWORD_LENGTH_MAX})
            )
            .matches(PASSWORD_REGEX, t(UserFormI18N.invalidPasswordError)),

        confirmPassword: yup
            .string()
            .required(t(UserFormI18N.requiredPasswordError))
            .min(
                Constants.PASSWORD_LENGTH_MIN,
                t(UserFormI18N.minPasswordError, {minInteger: Constants.PASSWORD_LENGTH_MIN})
            )
            .max(
                Constants.PASSWORD_LENGTH_MAX,
                t(UserFormI18N.maxPasswordError, {maxInteger: Constants.PASSWORD_LENGTH_MAX})
            )
            .oneOf([yup.ref("password")], t(UserFormI18N.passwordsMustMatchError))
    }) as yup.ObjectSchema<UserSignUpDTO>;

    const pageTitle = t(UserSignUpPageI18N.pageTitle);

    const onLoadError = (error: AxiosError<any>) => {
        setFinishedInfo({
            success: false,
            title: t(UserSignUpPageI18N.FinishedPage.failedTitle),
            subTitle: error.response?.data?.error?.message ?? t(UserSignUpPageI18N.FinishedPage.failedSubtitle)
        });
        return true;
    };

    const {model, errors, onChange, status, save, isDirty} = useModelState<UserSignUpDTO, string>({
        id: params.token,
        schema,
        loader: authenticationApi.beginUserSignUp,
        updater: authenticationApi.performUserSignUp,
        onLoadError,
        sendTransformer: (model) => ({
            ...model,
            password: encodePasswordBase64(model.password),
            confirmPassword: encodePasswordBase64(model.confirmPassword)
        }),
    });

    const handleSubmit = () => {
        setSkipUnsavedCheck(true);

        save()?.then(() => {
            navigate(AppPaths.SIGN_IN);
        });
    };

    if (finishedInfo) {
        return <FinishedPage {...finishedInfo} />;
    }

    return (
        <AppFrame title={pageTitle} modelState={status} isDirty={!skipUnsavedCheck && isDirty}>
            <Grid2 container spacing={2} padding={2}>
                <Grid2 size={{xs: 12}}>
                    <CardEx title={t(UserSignUpPageI18N.PasswordForm.cardTitle)}>
                        <Grid2 container direction="column" spacing={3}>
                            <Grid2 container direction="row" spacing={2} width="100%">
                                <Grid size={{sm: 6, md: 4, lg: 3}}>
                                    <StringInputField
                                        name="password"
                                        type="password"
                                        label={t(UserFormI18N.passwordLabel)}
                                        value={model?.password}
                                        error={errors?.password}
                                        maxChars={Constants.PASSWORD_LENGTH_MAX}
                                        onChange={onChange}
                                    />
                                </Grid>
                            </Grid2>
                            <Grid2 container direction="row" spacing={2} width="100%">
                                <Grid size={{sm: 6, md: 4, lg: 3}}>
                                    <StringInputField
                                        name="confirmPassword"
                                        type="password"
                                        label={t(UserFormI18N.confirmPasswordLabel)}
                                        value={model?.confirmPassword}
                                        error={errors?.confirmPassword}
                                        maxChars={Constants.PASSWORD_LENGTH_MAX}
                                        onChange={onChange}
                                    />
                                </Grid>
                            </Grid2>
                            <Grid2 container direction="row" spacing={2} width="100%">
                                <Grid2 size={{sm: 6, md: 4, lg: 3}}>
                                    <ButtonEx
                                        label={
                                            status === "saving" ? (
                                                <CircularProgress size={24}/>
                                            ) : (
                                                t(UserSignUpPageI18N.PasswordForm.submitButton)
                                            )
                                        }
                                        type="submit"
                                        fullWidth
                                        onClick={handleSubmit}
                                        variant="contained"
                                        sx={{mt: 3, mb: 2}}
                                    />
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    </CardEx>
                </Grid2>
            </Grid2>
        </AppFrame>
    );
};
