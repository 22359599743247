import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import {CompanySignUpDTO} from "@/api/data-contracts";
import {useParams} from "react-router-dom";
import {AppFrame} from "@/app/AppFrame";
import {useModelState} from "@/lib/modelstate";
import {useSignUpSchema} from "@/pages/company-sign-up-page/schema";
import {authenticationApi} from "@/boxhub-api";
import {AxiosError} from "axios";
import {CompanySignUpForm} from "@/pages/company-sign-up-page/CompanySignUpForm";
import {Step, StepLabel, Stepper} from "@mui/material";
import {FinishedPage, FinishedPageProps} from "@/common/FinishedPage";
import {useTranslation} from "react-i18next";
import {AuthenticationPageI18N, CompanyFormI18N, UserFormI18N} from "@/translations";
import {encodePasswordBase64} from "@/lib/utils";

export const CompanySignUpPage = () => {
    const {t} = useTranslation();
    const params = useParams();
    const [finishedInfo, setFinishedInfo] = React.useState<FinishedPageProps | null>(null);
    const schema = useSignUpSchema();
    const pageTitle = t(AuthenticationPageI18N.signUpTitle);

    const onLoadError = (error: AxiosError<any>) => {
        setFinishedInfo({
            success: false,
            title: t(AuthenticationPageI18N.registrationFailedMessage),
            subTitle: error.response?.data?.error?.message ?? t(AuthenticationPageI18N.registrationFailedDescription)
        });
        return true;
    };

    const [activeStep, setActiveStep] = useState(0);

    const steps = useMemo(
        () => [
            t(UserFormI18N.userFormTitle),
            t(CompanyFormI18N.companyFormTitle),
            t(CompanyFormI18N.moreDataTitle),
            t(CompanyFormI18N.bankConnectionTitle),
            t(AuthenticationPageI18N.agreementTermsTitle),
            t(AuthenticationPageI18N.privacyPolicyLabel),
            t(AuthenticationPageI18N.confidentialityAgreementLabel),
            t(AuthenticationPageI18N.termsAndConditionsLabel)
        ],
        [t]
    );

    // Define field names per step
    const stepFields = useMemo(
        () =>
            [
                [
                    "email",
                    "password",
                    "confirmPassword",
                    "salutation",
                    "firstName",
                    "lastName",
                    "phone",
                    "fax",
                    "language",
                    "ceo"
                ],
                [
                    "companyName",
                    "country",
                    "legalForm",
                    "streetAddress",
                    "postalCode",
                    "city",
                    "website",
                    "commercialRegisterNumber",
                    "vatId"
                ],
                [
                    "erpManufacturer",
                    "erpSoftwareName",
                    "erpSoftwareVersion",
                    "miniliner",
                    "midliner",
                    "maxiliner",
                    "dieCutting",
                    "folderGluerMachine",
                    "packaging",
                    "hazardousGoodsQualification",
                    "flexoPrinting",
                    "hdFlexoPrinting",
                    "offsetPrinting",
                    "digitalPrinting",
                    "officeHours",
                    "additionalInformation"
                ],
                ["bankName", "iban", "bic", "paymentTerms"],
                ["termsAccepted"],
                ["privacyPolicyAccepted"],
                ["confidentialityAccepted"],
                ["contractAndUsageAccepted"]
            ] as (keyof CompanySignUpDTO)[][],
        []
    );

    const {model, errors, onChange, status, save, validate, setErrors} = useModelState<CompanySignUpDTO, string>({
        initialState: {
            miniliner: false,
            midliner: false,
            maxiliner: false,
            dieCutting: false,
            folderGluerMachine: false,
            packaging: false,
            hazardousGoodsQualification: false,
            flexoPrinting: false,
            hdFlexoPrinting: false,
            offsetPrinting: false,
            digitalPrinting: false
        } as CompanySignUpDTO,
        schema,
        id: params.token,
        loader: authenticationApi.beginCompanySignUp,
        updater: authenticationApi.performCompanySignUp,
        context: () => ({
            schemaFields: activeStep != null ? stepFields[activeStep] : null
        }),
        sendTransformer: (model) => ({
            ...model,
            password: encodePasswordBase64(model.password),
            confirmPassword: encodePasswordBase64(model.confirmPassword),
            // Commercial register number is optional
            // Setting undefined if empty to avoid Validation Error in DTO in Backend
            commercialRegisterNumber: !model.commercialRegisterNumber ? undefined : model.commercialRegisterNumber
        }),
        onLoadError
    });

    const handleSubmit = () => {
        save()?.then(() => {
            setFinishedInfo({
                success: true,
                title: t(AuthenticationPageI18N.registrationSuccessMessage),
                subTitle: t(AuthenticationPageI18N.registrationProcessUnderReview)
            });
        });
    };

    const buttons = useMemo(
        () => (
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel sx={{whiteSpace: "nowrap"}}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        ),
        [activeStep, steps]
    );

    useEffect(() => {
        validate({silent: true});
    }, [activeStep, validate]);

    if (finishedInfo) {
        return <FinishedPage {...finishedInfo} />;
    }

    return (
        <AppFrame
            loading={status === "loading" || status === "saving"}
            title={pageTitle}
            padding={2}
            buttons={buttons}
            buttonsGrow
        >
            <CompanySignUpForm
                model={model}
                onChange={onChange}
                errors={errors}
                setErrors={setErrors}
                onSubmit={handleSubmit}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                steps={steps}
                token={params.token ?? ""}
            />
        </AppFrame>
    );
};
