import { LabelValue } from "@/lib/components/LabelValueList";

/**
 * Generates a field object for a string array with a label and optional tooltip.
 *
 * @param {string[] | undefined} stringValueArray - The array of strings to be displayed. If undefined or empty, the default value is used.
 * @param {string} label - The label for the field.
 * @param {string} [defaultValue] - The default value to use if the string array is not available.
 * @param {string} [tooltipKey] - The key for the tooltip text.
 * @returns {LabelValue} The field object with label, value, and tooltipKey properties.
 */
export const stringArrayField = (
    stringValueArray: string[] | undefined | null,
    label: string,
    defaultValue: string = "",
    tooltipKey?: string
): LabelValue => {
    const filteredArray = stringValueArray?.filter(Boolean) || [];
    return {
        label,
        value: filteredArray.length > 0 ? filteredArray.join(", ") : defaultValue,
        tooltipKey
    };
};
