import { InputAdornment, SxProps, TextField, TextFieldProps, Theme } from "@mui/material";
import React, { ChangeEventHandler } from "react";
import { autoCompleteOff, FieldError } from "@/lib/utils";

/**
 * Props for the StringInputField component.
 *
 * @property {string} [name] - The name of the input field.
 * @property {string} label - The label for the input field.
 * @property {ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>} [onChange] - The handler for change events.
 * @property {FieldError} [error] - The error object for the input field.
 * @property {boolean} [disabled] - If true, disables the input field.
 * @property {any} [inputProps] - Additional props for the input element.
 * @property {boolean} [activatePrefix] - If true, activates the prefix.
 * @property {string} [prefixValue] - The prefix value to display.
 * @property {boolean} [multiline] - If true, the input field will be multiline.
 * @property {string | null} [value] - The current value of the input field.
 * @property {number} [maxChars] - The maximum number of characters allowed.
 * @property {RegExp} [matchRule] - The regular expression rule to match the input value.
 * @property {SxProps<Theme>} [sx] - The style props for the input field.
 * @property {TextFieldProps["type"]} [type] - The type of the input field.
 * @property {TextFieldProps["autoComplete"]} [autoComplete] - The autocomplete attribute for the input field.
 * @property {TextFieldProps["ref"]} [textFieldRef] - The ref for the input field.
 */
interface StringInputFieldProps {
    name?: string;
    label: string;
    onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    error?: FieldError;
    disabled?: boolean;
    inputProps?: any;
    activatePrefix?: boolean;
    prefixValue?: string;
    multiline?: boolean;
    value?: string | null;
    maxChars?: number;
    matchRule?: RegExp;
    sx?: SxProps<Theme>;
    type?: TextFieldProps["type"];
    autoComplete?: TextFieldProps["autoComplete"];
    textFieldRef?: TextFieldProps["ref"];
    endAdornment?: React.ReactNode;
    variant?: TextFieldProps["variant"];
    helperText?: string;
    minRows?: number;
}

/**
 * StringInputField component.
 *
 * @param {StringInputFieldProps} props - The props for the component.
 * @returns {React.ReactElement} The rendered component.
 */
const StringInputField: React.FC<StringInputFieldProps> = ({
    name,
    label,
    onChange,
    error,
    disabled,
    inputProps,
    activatePrefix,
    prefixValue,
    multiline,
    value,
    maxChars,
    matchRule,
    sx,
    type,
    autoComplete = autoCompleteOff,
    textFieldRef,
    endAdornment,
    variant = "outlined",
    helperText,
    minRows
}: StringInputFieldProps): React.ReactElement => {
    const onChangeInt = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (onChange) {
            if (
                e.target?.value &&
                ((maxChars && maxChars < e.target.value.length) || (matchRule && !matchRule.test(e.target.value)))
            ) {
                return;
            }
            onChange(e);
        }
    };

    return (
        <TextField
            inputRef={textFieldRef}
            label={label}
            fullWidth
            value={value ?? ""}
            onChange={onChangeInt}
            error={!!error}
            helperText={error?.message ?? helperText}
            name={name}
            disabled={disabled}
            multiline={multiline}
            maxRows={multiline ? 5 : undefined}
            minRows={multiline ? (minRows ?? 1) : undefined}
            variant={variant}
            size="small"
            sx={sx}
            type={type}
            slotProps={{
                input: {
                    startAdornment: activatePrefix ? (
                        <InputAdornment position="start">{prefixValue}</InputAdornment>
                    ) : undefined,
                    endAdornment: endAdornment ? (
                        <InputAdornment position="end">{endAdornment}</InputAdornment>
                    ) : undefined,
                    ...inputProps
                },
                inputLabel: {
                    shrink: true
                }
            }}
            autoComplete={autoComplete}
        />
    );
};

export default StringInputField;
