import { PrintType } from "@/api/data-contracts";
import { LabelValue } from "@/lib/components/LabelValueList";

interface PrintField {
    printType?: PrintType | null;
    printValue?: string | null;
    numberOfColors?: number | null;
}

/**
 * Generates a field object for print details with a label, value, and optional tooltip.
 *
 * @param {string} label - The label for the field.
 * @param {PrintField | null} print - The print object containing print details.
 * @param {string} [defaultValue=""] - The default value to use if print details are not available.
 * @param {string} [tooltipKey] - The key for the tooltip text.
 * @returns {LabelValue} The field object with label, value, and optional tooltipKey properties.
 */
export const printField = (
    label: string,
    print?: PrintField | null,
    defaultValue: string = "",
    tooltipKey?: string
): LabelValue => {
    let value = defaultValue;

    if (print?.printType !== "NO_PRINT" && print?.printValue) {
        value = `${print.printValue}: ${print.numberOfColors ?? 0}`;
    } else if (print?.printType === "NO_PRINT") {
        value = print.printValue ?? defaultValue;
    }

    return {
        label,
        value,
        tooltipKey
    };
};
