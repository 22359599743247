import React from "react";
import { AppFrame } from "@/app/AppFrame";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import { ReportProblem } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { AuthenticationPageI18N } from "@/translations";

export type FinishedPageProps = {
    success: boolean;
    title: string;
    subTitle?: string;
};

export const FinishedPage = ({ success, title, subTitle }: FinishedPageProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleGoHome = () => {
        navigate("/");
    };

    return (
        <AppFrame>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "70vh",
                    textAlign: "center",
                    p: 3
                }}
            >
                <Box sx={{ mb: 4 }}>
                    {success ? (
                        <CheckIcon sx={{ fontSize: "800%" }} color="success" />
                    ) : (
                        <ReportProblem sx={{ fontSize: "800%" }} color="error" />
                    )}
                </Box>
                <Typography variant="h4" sx={{ mb: 2, fontWeight: "bold" }}>
                    {title}
                </Typography>
                {subTitle && (
                    <Typography variant="body1" sx={{ mb: 4, maxWidth: "500px" }}>
                        {subTitle}
                    </Typography>
                )}
                <Button variant="contained" size="large" onClick={handleGoHome}>
                    {t(AuthenticationPageI18N.goToHomeButton)}
                </Button>
            </Box>
        </AppFrame>
    );
};
