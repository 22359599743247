import * as React from "react";
import {ReactNode, useEffect, useMemo, useState} from "react";
import {quoteApi, requestApi} from "@/boxhub-api";
import {QuoteStatus, RequestActions, RequestDTO, RequestStatus} from "@/api/data-contracts";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {AppPaths} from "@/app/AppPaths";
import {AppFrame} from "@/app/AppFrame";
import {useModelState, ValidatorOptions} from "@/lib/modelstate";
import {useRequestSchema} from "@/pages/internal-request-page/schema";
import {requestReducer} from "@/model/request";
import {Button, CircularProgress, Grid2 as Grid} from "@mui/material";
import ConfirmationDialog from "@/lib/components/ConfirmationDialog";
import {AxiosResponse} from "axios";
import {useSnackbarEx} from "@/lib/snackbarex";
import {useDialogController} from "@/lib/dialog-controller";
import {RequestCard} from "@/common/request-cards/RequestCard";
import {OrderDocumentsCard} from "@/common/request-cards/OrderDocumentsCard";
import {ProductCard} from "@/common/request-cards/ProductCard";
import {PricingCard} from "@/common/request-cards/PricingCard";
import {PalletizingCard} from "@/common/request-cards/PalletizingCard";
import {ButtonEx} from "@/lib/components/buttons/ButtonEx";
import {IconButtonEx} from "@/lib/components/buttons/IconButtonEx";
import {CalendarViewWeek, ContentCopy} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {GeneralI18N, RequestKeyWordsI18N} from "@/translations";
import {RequestStatusBadge} from "@/common/RequestStatusBadge";
import EmailIcon from "@mui/icons-material/Email";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import UndoIcon from "@mui/icons-material/Undo";
import CancelIcon from "@mui/icons-material/Cancel";
import EmailHistoryDialog from "@/common/EmailHistoryDialog";
import {QuoteCard} from "@/common/request-cards/QuoteCard";
import {withAuthWrapperComponent} from "@/app/WithAuthWrapperComponent";
import {ShortCutHandler} from "@/lib/shortcut-handler";
import QuoteMatrixDialog from "@/pages/internal-request-page/QuoteMatrixDialog";
import {ErrorsShape} from "@/lib/utils";
import {transformToErrorShape} from "@/lib/accessor";
import {ChatGroupCard} from "@/common/chat/ChatGroupCard";

const InternalRequestPage = () => {
    const {t} = useTranslation();
    const {id: requestId} = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const [processing, setProcessing] = useState(false);
    // Added state to control if unsaved changes blocker shall be active
    const [skipUnsavedCheck, setSkipUnsavedCheck] = useState(false);

    const {showMessage} = useSnackbarEx();
    const [DialogNode, showDialog] = useDialogController();

    const schema = useRequestSchema();

    const [quoteIdxOrg, setQuoteIdx] = useState<number>(0);

    const fetchRequest = async (_id: any) => {
        if (requestId === "create") {
            return requestApi.getRequestTemplate();
        }
        if (requestId === "clone") {
            const sourceId = searchParams.get("sourceId");
            if (sourceId == null) {
                return Promise.reject(new Error("No sourceId provided"));
            }
            return requestApi.getRequestCloned(+sourceId);
        }
        if (requestId != null) {
            return requestApi.getRequest(+requestId);
        }
        return Promise.reject(new Error("No requestId provided"));
    };

    const doSave = async (publish: boolean) => {
        setSkipUnsavedCheck(true);
        if (!validate()) {
            return Promise.reject();
        }

        setProcessing(true);
        let promise: Promise<AxiosResponse<RequestDTO>>;
        if (publish) {
            promise = model?.id
                ? requestApi.updateAndPublishRequest(model.id, model)
                : requestApi.createAndPublishRequest(model);
        } else {
            promise = model?.id ? requestApi.updateRequest(model.id, model) : requestApi.createRequest(model);
        }
        return promise
            .then((requestResult) => {
                const newRequest = requestResult.data;
                dispatch({type: "--set", data: newRequest, nochange: true});
                showMessage({
                    summary: t(RequestKeyWordsI18N.requestSavedSuccessMessage, {
                        boxhubNumber: newRequest.boxhubNumber
                    }),
                    severity: "success"
                });
                setTimeout(() => {
                    if (requestId === "create" || requestId === "clone") {
                        navigate(AppPaths.INTERNAL_REQUEST_fn(newRequest.id!), {replace: true});
                    }
                    if (publish) {
                        showDialog((open, close) => (
                            <ConfirmationDialog
                                open={open}
                                onClose={close}
                                onConfirm={navigateToOverview}
                                message={
                                    <>
                                        {t(RequestKeyWordsI18N.modelMessage)}:
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                                marginLeft: 8
                                            }}
                                        >
                                            {getModel().boxhubNumber}
                                        </span>
                                        <IconButtonEx Icon={ContentCopy} onClick={handleCopy} title="Kopieren"/>
                                    </>
                                }
                                title="Speichern erfolgreich"
                                confirmButtonText="Zur Übersicht"
                                maxWidth="sm"
                                fullWidth
                                dataCy="request-publish-success-dialog"
                            />
                        ));
                    }
                }, 250);
            })
            .catch((error) => {
                showMessage({summary: t(GeneralI18N.unknownError), severity: "error", error});
                setErrors(error);
            })
            .finally(() => {
                setProcessing(false);
            });
    };

    const validator = async (model: RequestDTO, options: ValidatorOptions) => {
        if (options?.action?.type === "--change") {
            const name = options.action?.data[0];
            if (name === "deliveryDateFrom" || name === "deliveryDateTo" || name === "validUntil") {
                const response = await requestApi.validateRequestDates({
                    deliveryDateFrom: model.deliveryDateFrom,
                    deliveryDateTo: model.deliveryDateTo,
                    validUntil: model.validUntil
                });
                return transformToErrorShape(response.data?.error?.errors);
            }
        }
        return {} as ErrorsShape<RequestDTO>;
    };

    const {model, errors, onChange, status, isDirty, dispatch, setErrors, getModel, validate} = useModelState<
        RequestDTO,
        number | "template"
    >({
        initialState: {} as any,
        reducer: requestReducer,
        schema,
        validator,
        id: requestId == null ? null : +requestId,
        loader: fetchRequest
    });

    const quotes = model.quotes?.filter((quote) => quote.status !== QuoteStatus.REJECTED);
    const quoteIdx = Math.min(quoteIdxOrg, quotes?.length ?? 0);
    const quote = model.quotes?.length ? model.quotes[quoteIdx] : null;
    const orderData = quote ? quote.orderData : model.orderData;
    const orderDataOrg = quote ? model.orderData : null;
    const readonly = model?.status !== RequestStatus.CREATED && model?.status !== RequestStatus.DRAFT;

    const navigateToOverview = () => {
        navigate(AppPaths.INTERNAL_REQUEST_OVERVIEW, {replace: true});
    };

    const handleCopy = () => {
        const boxhubNumber = getModel().boxhubNumber;
        if (boxhubNumber) {
            if (navigator.clipboard) {
                navigator.clipboard.writeText(boxhubNumber).then(
                    () => {
                        showMessage({summary: "Clipboard copied!", severity: "success"});
                    },
                    (err) => {
                        showMessage({summary: "Could not copy text!", severity: "error", error: err});
                    }
                );
            } else {
                showMessage({summary: "Clipboard not supported", severity: "error"});
            }
        }
    };

    const handleDeleteRequest = () => {
        const modelId = model?.id;
        if (modelId != null) {
            showDialog((open, close) => (
                <ConfirmationDialog
                    open={open}
                    onClose={close}
                    onConfirm={() => {
                        setProcessing(true);
                        requestApi
                            .deleteRequest(modelId)
                            .then(() => {
                                showMessage({summary: t(RequestKeyWordsI18N.successDelete), severity: "success"});
                                navigateToOverview();
                                close();
                            })
                            .catch((error) => {
                                showMessage({
                                    summary: t(RequestKeyWordsI18N.failedDelete),
                                    severity: "error",
                                    error
                                });
                            })
                            .finally(() => {
                                setProcessing(false);
                            });
                    }}
                    title={t(RequestKeyWordsI18N.confirmDelete)}
                    message={`${t(RequestKeyWordsI18N.deleteMessagePartOne)} ${model?.boxhubNumber ?? t(GeneralI18N.notGiven)} ${t(RequestKeyWordsI18N.deleteMessagePartTwo)}`}
                    confirmButtonText={t(RequestKeyWordsI18N.delete)}
                    cancelButtonText={t(GeneralI18N.cancelButton)}
                    maxWidth="sm"
                    fullWidth
                />
            ));
        }
    };

    const handlePublishClick = () => {
        doSave(true);
    };

    const handleSaveClick = () => {
        doSave(false);
    };

    const handleCancelClick = () => {
        navigateToOverview();
    };

    const handleCancel = () => {
        let promise: Promise<any>;
        if (model.allowedActions?.includes(RequestActions.CANCEL_REQUEST)) {
            promise = requestApi.cancelRequest(model.id!);
        } else if (model.allowedActions?.includes(RequestActions.CANCEL_PREVIOUSLY_PUBLISHED_REQUEST)) {
            promise = requestApi.cancelPreviouslyPublishedRequest(model.id!);
        } else {
            throw new Error("Unknown Allowed Action for Cancel");
        }

        promise
            .then(() => {
                showMessage({summary: t(RequestKeyWordsI18N.cancelConfirmedNotification), severity: "success"});
                navigateToOverview();
            })
            .catch(() => {
                showMessage({summary: t(GeneralI18N.unknownError), severity: "error"});
            });
    };

    const handleWithdraw = () => {
        requestApi
            .unpublishRequest(model.id!)
            .then(() => {
                showMessage({summary: t(RequestKeyWordsI18N.withdrawConfirmedNotification), severity: "success"});
                navigateToOverview();
            })
            .catch(() => {
                showMessage({summary: t(GeneralI18N.unknownError), severity: "error"});
            });
    };

    const handleOpenHistoryDialog = () => {
        const requestId = model?.id;
        if (requestId) {
            showDialog((open, close) => <EmailHistoryDialog open={open} requestId={requestId} onClose={close}/>);
        }
    };

    const handleWithdrawDialog = () => {
        setSkipUnsavedCheck(true);
        showDialog((open, close) => (
            <ConfirmationDialog
                open={open}
                onClose={close}
                onConfirm={() => handleWithdraw()}
                title={t(RequestKeyWordsI18N.confirmWithdrawRequestTitle)}
                message={t(RequestKeyWordsI18N.confirmWithdrawRequestMessage)}
                confirmButtonText={t(RequestKeyWordsI18N.confirmButton)}
                cancelButtonText={t(GeneralI18N.cancelButton)}
            />
        ));
    };

    const handleCancelDialog = () => {
        setSkipUnsavedCheck(true);
        showDialog((open, close) => (
            <ConfirmationDialog
                open={open}
                onClose={close}
                onConfirm={() => handleCancel()}
                title={t(RequestKeyWordsI18N.confirmCancelRequestTitle)}
                message={t(RequestKeyWordsI18N.confirmCancelRequestMessage)}
                confirmButtonText={t(RequestKeyWordsI18N.confirmButton)}
                cancelButtonText={t(GeneralI18N.cancelButton)}
            />
        ));
    };

    const handleConfirmQuote = () => {
        setSkipUnsavedCheck(true);
        if (quote != null) {
            if (quote.orderData.quantityOptionSelected == null) {
                showMessage({summary: t(RequestKeyWordsI18N.selectQuoteError), severity: "error"});
                return;
            }
            showDialog((open, close) => (
                <ConfirmationDialog
                    open={open}
                    onClose={close}
                    onConfirm={() => {
                        quoteApi
                            .confirmQuote(quote.id!, {
                                quantityOptionUsed: quote.orderData.quantityOptionSelected!
                            })
                            .then(() => {
                                showMessage({
                                    summary: t(RequestKeyWordsI18N.quoteConfirmedMessage),
                                    severity: "success"
                                });
                                navigateToOverview();
                            })
                            .catch(() => {
                                showMessage({summary: t(GeneralI18N.unknownError), severity: "error"});
                            });
                    }}
                    title={t(RequestKeyWordsI18N.confirmQuoteTitle)}
                    message={t(RequestKeyWordsI18N.questionConfirmQuoteMessage)}
                    confirmButtonText={t(RequestKeyWordsI18N.confirmButton)}
                    cancelButtonText={t(GeneralI18N.cancelButton)}
                />
            ));
        }
    };

    const handleDismissQuote = () => {
        if (quote != null) {
            showDialog((open, close) => (
                <ConfirmationDialog
                    open={open}
                    onClose={close}
                    onConfirm={() => {
                        quoteApi
                            .dismissQuote(quote.id!)
                            .then((response) => {
                                showMessage({
                                    summary: t(RequestKeyWordsI18N.quoteDismissedMessage),
                                    severity: "success"
                                });
                                dispatch({
                                    type: "--set",
                                    data: response.data,
                                    nochange: true
                                });
                                close();
                            })
                            .catch(() => {
                                showMessage({summary: t(GeneralI18N.unknownError), severity: "error"});
                            });
                    }}
                    title={t(RequestKeyWordsI18N.dismissQuoteTitle)}
                    message={t(RequestKeyWordsI18N.questionDismissQuoteMessage)}
                    confirmButtonText={t(RequestKeyWordsI18N.confirmButton)}
                    cancelButtonText={t(GeneralI18N.cancelButton)}
                />
            ));
        }
    };

    const handleOpenMatrix = () => {
        showDialog((open, close) => (
            <QuoteMatrixDialog
                open={open}
                close={close}
                model={model}
                setQuote={(quote) => {
                    const idx = model.quotes?.indexOf(quote);
                    if (idx != null) {
                        setQuoteIdx(idx);
                    }
                }}
            />
        ));
    };

    useEffect(() => {
        if (model?.status === RequestStatus.CONFIRMED) {
            const idx = model?.quotes?.findIndex((q) => q.status === QuoteStatus.ACCEPTED);
            if (idx != null && idx !== -1) {
                setQuoteIdx(idx);
            }
        }
    }, [model?.quotes, model?.status]);

    let pageTitle: ReactNode;
    if (requestId === "create") {
        pageTitle = t(RequestKeyWordsI18N.titleCreateRequestPage);
    } else if (requestId === "clone") {
        pageTitle = `${t(RequestKeyWordsI18N.titleCloneRequestPage)} ${model?.clonedBoxhubNumber ?? ""}`;
    } else {
        pageTitle = (
            <Grid container spacing={3} direction="row" alignItems="center">
                {`${t(RequestKeyWordsI18N.titleYourRequestPage)} - BoxHub #${model?.boxhubNumber ?? t(GeneralI18N.notGiven)}`}{" "}
                <RequestStatusBadge status={model.status}/>
            </Grid>
        );
    }

    const displayErrors = errors;

    const buttons = (
        <Grid container direction="row" spacing={2}>
            {!readonly && (
                <Grid>
                    <ButtonEx
                        data-cy="cancel-button"
                        label={processing ? <CircularProgress size={24}/> : t(GeneralI18N.cancelButton)}
                        fullWidth
                        variant="contained"
                        color="error"
                        disabled={processing}
                        onClick={() => handleCancelClick()}
                    />
                </Grid>
            )}
            <ButtonEx
                visible={!!model?.id}
                variant="contained"
                startIcon={<EmailIcon/>}
                onClick={handleOpenHistoryDialog}
                label={t(RequestKeyWordsI18N.emailHistoryButton)}
            />
            <ButtonEx
                visible={model?.quotes && model.quotes.length > 1}
                variant="contained"
                startIcon={<CalendarViewWeek/>}
                onClick={handleOpenMatrix}
                label={t(RequestKeyWordsI18N.quoteOverviewButton)}
            />
            {model?.id && (
                <ButtonEx
                    data-cy={"copy-request-button"}
                    label={t(RequestKeyWordsI18N.copyRequestButton)}
                    variant="contained"
                    color={"primary"}
                    startIcon={<ContentCopyIcon/>}
                    to={AppPaths.INTERNAL_REQUEST_fn("clone", model.id)}
                />
            )}
            {model?.allowedActions?.includes(RequestActions.UNPUBLISH_REQUEST) && (
                <ButtonEx
                    data-cy={"withdraw-button"}
                    label={t(RequestKeyWordsI18N.withdrawRequestButton)}
                    variant="contained"
                    color="warning"
                    startIcon={<UndoIcon/>}
                    onClick={handleWithdrawDialog}
                />
            )}
            {(model?.allowedActions?.includes(RequestActions.CANCEL_REQUEST) ||
                model?.allowedActions?.includes(RequestActions.CANCEL_PREVIOUSLY_PUBLISHED_REQUEST)) && (
                <Button
                    data-cy={"rescind-button"}
                    variant="contained"
                    color="error"
                    startIcon={<CancelIcon/>}
                    onClick={handleCancelDialog}
                >
                    {t(RequestKeyWordsI18N.cancelOrderButton)}
                </Button>
            )}
            {!readonly && model?.id != null && model?.allowedActions?.includes(RequestActions.DELETE_REQUEST) && (
                <Grid>
                    <ButtonEx
                        data-cy="delete-button"
                        label={processing ? <CircularProgress size={24}/> : t(RequestKeyWordsI18N.delete)}
                        color="error"
                        fullWidth
                        variant="contained"
                        disabled={processing}
                        onClick={() => handleDeleteRequest()}
                    />
                </Grid>
            )}

            {!readonly && (
                <Grid>
                    <ButtonEx
                        data-cy="capture-button"
                        label={processing ? <CircularProgress size={24}/> : t(RequestKeyWordsI18N.unPublishSaveButton)}
                        fullWidth
                        variant="contained"
                        disabled={processing || !isDirty}
                        color="info"
                        onClick={handleSaveClick}
                    />
                </Grid>
            )}

            {!readonly && (
                <Grid>
                    <ButtonEx
                        data-cy="publish-button"
                        label={processing ? <CircularProgress size={24}/> : t(RequestKeyWordsI18N.publish)}
                        fullWidth
                        variant="contained"
                        disabled={processing}
                        color="success"
                        onClick={handlePublishClick}
                    />
                </Grid>
            )}
        </Grid>
    );

    const shortcuts = useMemo(
        () => ({
            "ALT-ArrowLeft": () => setQuoteIdx((idx) => Math.max(0, idx - 1)),
            "ALT-ArrowRight": () => setQuoteIdx((idx) => Math.min((model.quotes?.length ?? 0) - 1, idx + 1))
        }),
        [model.quotes?.length]
    );

    return (
        <ShortCutHandler shortcuts={shortcuts}>
            <AppFrame
                loading={status === "loading"}
                title={pageTitle}
                buttons={buttons}
                isDirty={!skipUnsavedCheck && isDirty && !readonly}
            >
                <Grid container spacing={2} size={{xs: 12}} padding={2}>
                    <Grid container direction="column" spacing={2} size={{sm: 12, md: 4}} alignContent="flex-start">
                        <Grid size={{xs: 12}}>
                            <RequestCard
                                model={model}
                                errors={readonly ? {} : displayErrors}
                                onChange={onChange}
                                readonly={readonly}
                                mode="request"
                            />
                        </Grid>
                        <Grid size={{xs: 12}}>
                            <OrderDocumentsCard
                                orderDocuments={model?.orderDocuments}
                                dispatch={dispatch}
                                readonly={readonly}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="column" spacing={2} size={{sm: 12, md: 4}} alignContent="flex-start">
                        <Grid size={{xs: 12}}>
                            <ProductCard
                                orderData={orderData}
                                orgData={orderDataOrg}
                                errors={readonly ? {} : displayErrors}
                                onChange={onChange}
                                readonly={readonly}
                                mode="request"
                            />
                        </Grid>
                        <Grid size={{xs: 12}}>
                            <PalletizingCard
                                orderData={orderData}
                                orgData={orderDataOrg}
                                errors={readonly ? {} : displayErrors}
                                onChange={onChange}
                                readonly={readonly}
                                mode="request"
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="column" spacing={2} size={{sm: 12, md: 4}} alignContent="flex-start">
                        <Grid size={{xs: 12}}>
                            <PricingCard
                                orderData={orderData}
                                orgData={orderDataOrg}
                                errors={readonly ? {} : displayErrors}
                                quoteStatus={quote?.status}
                                onChange={onChange}
                                dispatch={dispatch}
                                readonly={readonly}
                                mode={model.quotes?.length ? "accept" : "request"}
                                quoteIdx={quoteIdx}
                            />
                        </Grid>
                        {quote && (
                            <Grid size={{xs: 12}}>
                                <QuoteCard
                                    model={quote}
                                    errors={errors}
                                    requestStatus={model.status}
                                    onChange={onChange}
                                    readonly={readonly}
                                    mode={model.quotes?.length ? "accept" : "request"}
                                    quotes={model.quotes}
                                    quoteIdx={quoteIdx}
                                    setQuoteIdx={setQuoteIdx}
                                    handleConfirmQuote={handleConfirmQuote}
                                    handleDismissQuote={handleDismissQuote}
                                />
                            </Grid>
                        )}
                        <Grid size={{xs: 12}}>
                            <ChatGroupCard
                                chatGroup={model?.chatGroup}
                                dispatch={dispatch}
                                readonly={!model.allowedActions?.includes(RequestActions.ANSWER_IN_CHAT)}/>
                        </Grid>
                    </Grid>
                </Grid>
                <DialogNode/>
            </AppFrame>
        </ShortCutHandler>
    );
};
export default withAuthWrapperComponent(InternalRequestPage);
