/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    AccessLockDTO,
    CompanyDTO,
    CompanySearchParamsDTO,
    CompanyStatisticsDTO,
    CompanyTerminationDTO,
    ErrorResponseDTO,
    QuoteTerminationParamsDTO,
    SearchResultCompanyOverviewRowDTO
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class CompanyApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * @description Only back-office employees can approve registration requests. When a company is approved, its status changes to 'CONFIRMED' and the first employee (CEO) is activated.
     *
     * @tags company-api
     * @name ApproveRegistration
     * @summary Approve a company registration request
     * @request POST:/api/company/approve/{companyId}
     * @secure
     */
    approveRegistration = (companyId: number, params: RequestParams = {}) =>
        this.request<CompanyDTO, (string[] | Record<string, string>) | void | CompanyDTO>({
            path: `/api/company/approve/${companyId}`,
            method: "POST",
            secure: true,
            ...params
        });
    /**
     * @description Only back-office employees can cancel the termination of companies.
     *
     * @tags company-api
     * @name CancelTerminationCompany
     * @summary Cancel a termination of a company
     * @request POST:/api/company/cancel-termination/{companyId}
     * @secure
     */
    cancelTerminationCompany = (companyId: number, params: RequestParams = {}) =>
        this.request<CompanyDTO, (string[] | Record<string, string>) | void | CompanyDTO>({
            path: `/api/company/cancel-termination/${companyId}`,
            method: "POST",
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags company-api
     * @name GetCompany
     * @summary Get company by ID
     * @request GET:/api/company/{id}
     * @secure
     */
    getCompany = (id: number, params: RequestParams = {}) =>
        this.request<CompanyDTO, (string[] | Record<string, string>) | void | CompanyDTO>({
            path: `/api/company/${id}`,
            method: "GET",
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags company-api
     * @name GetCompanyStatistics
     * @summary Get company statistics
     * @request GET:/api/company/statistics/{id}
     * @secure
     */
    getCompanyStatistics = (id: number, params: RequestParams = {}) =>
        this.request<CompanyStatisticsDTO, (string[] | Record<string, string>) | void | CompanyStatisticsDTO>({
            path: `/api/company/statistics/${id}`,
            method: "GET",
            secure: true,
            ...params
        });
    /**
     * @description Prepares company termination with date set according to termination rules
     *
     * @tags company-api
     * @name InitiateCompanyTermination
     * @summary Initiate company termination
     * @request POST:/api/company/terminate/init/{companyId}
     * @secure
     */
    initiateCompanyTermination = (companyId: number, params: RequestParams = {}) =>
        this.request<CompanyTerminationDTO, (string[] | Record<string, string>) | void | CompanyTerminationDTO>({
            path: `/api/company/terminate/init/${companyId}`,
            method: "POST",
            secure: true,
            ...params
        });
    /**
     * @description Only back-office employees can lock companies. Firma sperren als Innendienstler
     *
     * @tags company-api
     * @name LockCompany
     * @summary Lock a company
     * @request POST:/api/company/lock/{companyId}
     * @secure
     */
    lockCompany = (companyId: number, data: AccessLockDTO, params: RequestParams = {}) =>
        this.request<AccessLockDTO, (string[] | Record<string, string>) | void | AccessLockDTO>({
            path: `/api/company/lock/${companyId}`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * @description Only back-office employees can activate companies.
     *
     * @tags company-api
     * @name ReactivateCompany
     * @summary Reactivate a company
     * @request POST:/api/company/reactivate/{companyId}
     * @secure
     */
    reactivateCompany = (companyId: number, params: RequestParams = {}) =>
        this.request<CompanyDTO, (string[] | Record<string, string>) | void | CompanyDTO>({
            path: `/api/company/reactivate/${companyId}`,
            method: "POST",
            secure: true,
            ...params
        });
    /**
     * @description Only back-office employees can reject registration requests. When a company is rejected, its status changes to 'REJECTED' and the first employee (CEO) is deactivated.
     *
     * @tags company-api
     * @name RejectRegistration
     * @summary Reject a company registration request
     * @request POST:/api/company/reject/{companyId}
     * @secure
     */
    rejectRegistration = (companyId: number, params: RequestParams = {}) =>
        this.request<CompanyDTO, (string[] | Record<string, string>) | void | CompanyDTO>({
            path: `/api/company/reject/${companyId}`,
            method: "POST",
            secure: true,
            ...params
        });
    /**
     * @description Only a user of the company or operator can save the data
     *
     * @tags company-api
     * @name SaveCompany
     * @summary save company
     * @request PUT:/api/company/{id}
     * @secure
     */
    saveCompany = (id: number, data: CompanyDTO, params: RequestParams = {}) =>
        this.request<CompanyDTO, (string[] | Record<string, string>) | void | CompanyDTO>({
            path: `/api/company/${id}`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags company-api
     * @name SearchCompanies
     * @request POST:/api/company/search
     * @secure
     */
    searchCompanies = (data: CompanySearchParamsDTO, params: RequestParams = {}) =>
        this.request<SearchResultCompanyOverviewRowDTO, (string[] | Record<string, string>) | void>({
            path: `/api/company/search`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * @description Only back-office employees can terminate companies.
     *
     * @tags company-api
     * @name TerminateCompany
     * @summary Terminate a conmpnay
     * @request POST:/api/company/terminate/{companyId}
     * @secure
     */
    terminateCompany = (companyId: number, data: CompanyTerminationDTO, params: RequestParams = {}) =>
        this.request<CompanyTerminationDTO, (string[] | Record<string, string>) | void | CompanyTerminationDTO>({
            path: `/api/company/terminate/${companyId}`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * @description Only back-office employees can unlock companies.
     *
     * @tags company-api
     * @name UnlockCompany
     * @summary Unlock a company
     * @request POST:/api/company/unlock/{companyId}
     * @secure
     */
    unlockCompany = (companyId: number, params: RequestParams = {}) =>
        this.request<CompanyDTO, (string[] | Record<string, string>) | void | CompanyDTO>({
            path: `/api/company/unlock/${companyId}`,
            method: "POST",
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags company-api
     * @name ValidateTerminateDates
     * @request POST:/api/company/validate-terminatin-date
     * @secure
     */
    validateTerminateDates = (data: QuoteTerminationParamsDTO, params: RequestParams = {}) =>
        this.request<ErrorResponseDTO, (string[] | Record<string, string>) | void>({
            path: `/api/company/validate-terminatin-date`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
}
