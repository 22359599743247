import { Box, FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup, Typography } from "@mui/material";
import React from "react";
import { NamedValueHandler } from "@/lib/modelstate";
import { FieldError } from "@/lib/utils";

/**
 * Props for the RadioButtonInputField component.
 *
 * @property {string} name - The name of the field.
 * @property {string} label - The label to display for the radio button group.
 * @property {string | boolean | undefined} value - The current value of the radio button group.
 * @property {NamedValueHandler} onChange - The function to call when the value of the radio button group changes.
 * @property {FieldError} [error] - The error object with message.
 * @property {{ label: string; value: string | boolean }[]} options - The options for the radio buttons. Each option has a label and a value.
 * @property {React.ReactNode} [endAdornment] - Optional end adornment to display next to the label.
 * @property {boolean} [disabled] - If true, disables the input field.
 */
interface RadioButtonInputFieldProps {
    name: string;
    label: string;
    value: string | boolean | undefined;
    onChange: NamedValueHandler;
    error?: FieldError;
    options: { label: string; value: string | boolean }[];
    endAdornment?: React.ReactNode;
    disabled?: boolean;
}

/**
 * A component that renders a group of radio buttons with labels and optional helper text.
 *
 * @param {RadioButtonInputFieldProps} props - The props for the component.
 * @returns {React.ReactElement} The rendered radio button input field component.
 */
const RadioButtonInputField: React.FC<RadioButtonInputFieldProps> = ({
    name,
    label,
    onChange,
    value,
    error,
    options,
    endAdornment,
    disabled = false
}: RadioButtonInputFieldProps): React.ReactElement => {
    const currentValue = value !== undefined ? String(value) : "";

    return (
        <FormControl component="fieldset" error={!!error} disabled={disabled}>
            <Box display="flex" alignItems="center" mb={1}>
                <Typography>{label}</Typography>
                {endAdornment}
            </Box>
            <RadioGroup
                row
                value={currentValue}
                onChange={(e) => {
                    const selectedStringValue = e.target.value;
                    const matchedOption = options.find((option) => String(option.value) === selectedStringValue);
                    onChange({ name, value: matchedOption ? matchedOption.value : selectedStringValue });
                }}
            >
                {options.map((option) => (
                    <FormControlLabel
                        key={String(option.value)}
                        value={String(option.value)}
                        control={<Radio />}
                        label={option.label}
                    />
                ))}
            </RadioGroup>
            {error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
    );
};

export default RadioButtonInputField;
