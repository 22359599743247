import { QuoteStatus } from "@/api/data-contracts";
import { AppThemeContextProps } from "@/app/AppThemeContext";
import { blue, green, grey, red, yellow } from "@mui/material/colors";
import { LabelValue } from "@/lib/components/LabelValueList";
import { GeneralI18N, QuoteStatusI18N } from "@/translations";

interface QuoteStatusField {
    quoteStatus?: QuoteStatus | null;
    statusValue?: string | null;
}

/**
 * Generates a field object for quote status with a label, value, tooltip, and color.
 *
 * @param {QuoteStatusField} status - The status object containing the quote status and status value.
 * @param {string} label - The label for the field.
 * @param {AppThemeContextProps} theme - The theme context properties.
 * @param {string} [defaultValue=""] - The default value to use if the status value is not available.
 * @param {string} [tooltipKey] - The key for the tooltip text.
 * @returns {LabelValue} The field object with label, value, tooltipKey, and color properties.
 */
export const quoteStatusField = (
    status: QuoteStatusField,
    label: string,
    theme: AppThemeContextProps,
    defaultValue?: QuoteStatusField,
    tooltipKey?: string
): LabelValue => ({
    label,
    value: status?.quoteStatus && status?.statusValue ? status?.statusValue : (defaultValue?.statusValue ?? ""),
    tooltipKey,
    color: getQuoteStatusColor(theme.mode, status.quoteStatus ?? undefined)
});

/**
 * Returns the color associated with a given quote status.
 *
 * @returns {string} The color corresponding to the given status code.
 * @param mode
 * @param status
 */
export const getQuoteStatusColor = (mode: "light" | "dark", status?: QuoteStatus): string => {
    const tone = mode === "light" ? 200 : 800;
    // Undefined or null bedeutet, dass noch keine Aktivitaet vorliegt
    if (!status) {
        return blue[tone];
    }
    switch (status) {
        case QuoteStatus.NOT_VACANT:
            return grey[tone];
        case QuoteStatus.VACANT:
            return blue[tone];
        case QuoteStatus.SUBMITTED:
            return yellow[tone];
        case QuoteStatus.ACCEPTED:
            return green[tone];
        case QuoteStatus.DISMISSED:
            return red[tone];
        case QuoteStatus.REJECTED:
            return red[tone];
        default:
            return grey[tone];
    }
};

export const getQuoteStatusText = (t: (key: string) => string, status?: QuoteStatus): string =>
    (status && t(QuoteStatusI18N[status])) ?? t(GeneralI18N.notGiven);
