// Please write Jest Tests, when you use this functions

import { generatePath } from "react-router";
import { getCurrentDate } from "@/lib/date-utils";
import { Base64 } from "js-base64";

export const isEdgeBrowser = navigator.userAgent.indexOf("Edg/") > -1;

export const autoCompleteOff = "off";

export type FieldError =
    | any
    | {
          message?: string;
      };

export type ErrorsShape<T> = {
    [K in keyof T]?: T[K] extends object
        ? ErrorsShape<T[K]> // Recursively transform sub-objects
        : FieldError; // Replace simple fields with a different type (e.g., any)
};

export type IsTEqual<T, U> = (<G>() => G extends T ? 1 : 2) extends <G>() => G extends U ? 1 : 2 ? true : false;

/**
 * Logs a message to the console if the environment is not production.
 *
 * @param message   The message to be logged.
 * @param optionalParams    Additional parameters to be logged.
 */
export const trace = (message: string, ...optionalParams: any[]) => {
    if (process.env.NODE_ENV !== "production") {
        // eslint-disable-next-line no-console
        console.log(message, ...optionalParams);
    }
};

/**
 * Formats a given amount as a currency string based on the specified locale.
 *
 * @param {number | null} [amount] - The amount to be formatted. If the amount is undefined, null, or NaN, the function returns null.
 * @param {string} [locale="de"] - The locale to be used for formatting. Defaults to "de" (German).
 * @returns {string | null} - The formatted currency string or null if the amount is invalid.
 */
export const formatCurrency = (amount?: number | null, locale: string = "de"): string | null => {
    if (amount === undefined || amount === null || isNaN(amount)) {
        return null;
    }
    return new Intl.NumberFormat(locale, {
        style: "currency",
        currency: getCurrencyCode(locale)
    }).format(amount);
};

/**
 * Parses a currency string into a number based on the specified locale.
 *
 * @param {string} locale - The locale to be used for parsing.
 * @returns {(currencyString?: string | null) => number | null} - A function that takes a currency string and returns the parsed number or null if the input is invalid.
 */
export const parseCurrency =
    (locale: string): ((currencyString?: string | null) => number | null) =>
    (currencyString?: string | null): number | null => {
        if (!currencyString || currencyString.trim() === "") {
            return null;
        }

        const { thousandSeparator, decimalSeparator } = getSeparators(locale);
        const currencySymbol = getCurrencySymbol(locale);

        const escapedThousandSeparator = thousandSeparator?.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        if (escapedThousandSeparator === undefined || decimalSeparator === undefined) {
            return null;
        }

        // Entfernen des Währungssymbols und Leerzeichen
        const cleanedString = currencyString.replace(new RegExp(`[${currencySymbol}\\s]`, "g"), "");

        const numericString = cleanedString
            .replace(new RegExp(escapedThousandSeparator, "g"), "")
            .replace(decimalSeparator, ".");

        const parsedNumber = parseFloat(numericString);

        return isNaN(parsedNumber) ? null : parsedNumber;
    };

/**
 * Returns the currency code based on the specified locale.
 *
 * @param {string} locale - The locale to determine the currency code.
 * @returns {string} - The currency code for the given locale.
 */
export function getCurrencyCode(locale: string): string {
    // TODO: Add more currency codes for different locales
    switch (locale) {
        // TODO: EUR for en is not correct
        case "en":
            return "EUR";
        case "de":
            return "EUR";
        default:
            return "EUR";
    }
}

/**
 * Returns the currency symbol based on the specified locale.
 *
 * @param {string} locale - The locale to determine the currency symbol.
 * @returns {string} - The currency symbol for the given locale.
 */
export function getCurrencySymbol(locale: string): string {
    // TODO: Add more currency symbols for different locales
    switch (locale) {
        // TODO: € for en is not correct
        case "en":
            return "€";
        case "de":
            return "€";
        default:
            return "€";
    }
}

/**
 * Retrieves the thousand and decimal separators for a given locale.
 *
 * @param {string} locale - The locale to determine the separators.
 * @returns {{ thousandSeparator: string | undefined, decimalSeparator: string | undefined }} - An object containing the thousand and decimal separators.
 */
export function getSeparators(locale: string): {
    thousandSeparator: string | undefined;
    decimalSeparator: string | undefined;
} {
    const formatter = new Intl.NumberFormat(locale);
    const parts = formatter.formatToParts(1234567.89);
    const thousandSeparator = parts.find((part) => part.type === "group")?.value;
    const decimalSeparator = parts.find((part) => part.type === "decimal")?.value;

    return { thousandSeparator, decimalSeparator };
}

/**
 * Formats a given number as an integer string based on the specified locale.
 *
 * @param {number | null} [number] - The number to be formatted. If the number is undefined, null, or NaN, the function returns null.
 * @param {string} [locale="de"] - The locale to be used for formatting. Defaults to "de" (German).
 * @returns {string | null} - The formatted integer string or null if the number is invalid.
 */
export const formatInteger = (number?: number | null, locale: string = "de"): string | null => {
    if (number === undefined || number === null || isNaN(number)) {
        return null;
    }
    return new Intl.NumberFormat(locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }).format(number);
};

export const formatNumber = (number?: number | null, fraction?: number, locale: string = "de"): string | null => {
    if (number === undefined || number === null || isNaN(number)) {
        return null;
    }
    return new Intl.NumberFormat(locale, {
        minimumFractionDigits: fraction ?? 0,
        maximumFractionDigits: fraction ?? 0
    }).format(number);
};

/**
 * Parses a numeric string into a number based on the specified locale.
 *
 * @param {string} locale - The locale to be used for parsing.
 * @returns {(value?: number | null, originalValue?: number | string | null) => number | null} - A function that takes a numeric string and returns the parsed number or null if the input is invalid.
 */
export const parseNumericString =
    (locale: string): ((value?: number | null, originalValue?: number | string | null) => number | null) =>
    (value?: number | null, originalValue?: number | string | null): number | null => {
        if (typeof originalValue !== "string") {
            if (typeof originalValue === "number") {
                return originalValue;
            }
            return value ?? null;
        }

        const decimalSeparator = getSeparators(locale).decimalSeparator ?? "";
        const thousandSeparator = getSeparators(locale).thousandSeparator ?? "";

        // Remove thousand separators
        const cleanedString = originalValue.replace(new RegExp(`\\${thousandSeparator}`, "g"), "");

        // Replace decimal separator with dot for standardization
        const normalizedString = cleanedString.replace(decimalSeparator, ".");

        const parsed = parseFloat(normalizedString);
        return isNaN(parsed) ? null : parsed;
    };

/**
 * Capitalizes the first letter of the given text.
 *
 * @param {string} text - The text to be capitalized.
 * @returns {string} - The text with the first letter capitalized.
 */
export const capitalizeFirstLetter = (text: string): string => text.charAt(0).toUpperCase() + text.slice(1);

/**
 * Capitalizes the first letter of each sentence in the given text after a period.
 *
 * @param {string} sentence - The text to be processed.
 * @returns {string} - The processed text with the first letter of each sentence capitalized.
 */
export const capitalizeAfterPeriod = (sentence: string): string => {
    // Split the sentence into parts based on the period
    const parts = sentence.split(". ");

    // Iterate over each part
    for (let i = 0; i < parts.length; i++) {
        // Trim any leading/trailing whitespace
        parts[i] = parts[i].trim();

        // Capitalize the first letter of the part
        if (parts[i].length > 0) {
            parts[i] = parts[i][0].toUpperCase() + parts[i].slice(1);
        }
    }

    // Join the parts back together with a period and a space
    return parts.join(". ");
};

/**
 * Capitalizes the first letter of each word in the given text.
 *
 * @param {string} text - The text to be processed.
 * @returns {string} - The processed text with the first letter of each word capitalized.
 */
export const capitalizeEachWord = (text: string): string =>
    text
        .split(" ")
        .map((word) => capitalizeFirstLetter(word))
        .join(" ");

/**
 * Returns a human-readable relative time string based on the given ISO date.
 *
 * The function calculates the difference between the current date and the given ISO date,
 * and returns a string that represents this difference in a human-readable format.
 * For example, if the given date is 5 minutes ago, the function will return "5 minutes ago".
 * The function handles different time ranges, such as seconds, minutes, hours, days, weeks, months, and years.
 *
 * @param {string} isoDate - The ISO date string to be converted to a relative time string.
 * @returns {string} - A string representing the relative time from the given date to now.
 */
export const getRelativeTime = (isoDate: string): string => {
    const now = getCurrentDate();
    const past = new Date(isoDate);
    const diffInSeconds = (now.getTime() - past.getTime()) / 1000;

    if (diffInSeconds < 60) {
        return "just now";
    } else if (diffInSeconds < 3600) {
        const minutes = Math.floor(diffInSeconds / 60);
        return `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
    } else if (diffInSeconds < 86400) {
        const hours = Math.floor(diffInSeconds / 3600);
        return `${hours} hour${hours === 1 ? "" : "s"} ago`;
    } else if (diffInSeconds < 604800) {
        const days = Math.floor(diffInSeconds / 86400);
        return `${days} day${days === 1 ? "" : "s"} ago`;
    } else if (diffInSeconds < 2419200) {
        const weeks = Math.floor(diffInSeconds / 604800);
        return `${weeks} week${weeks === 1 ? "" : "s"} ago`;
    } else if (diffInSeconds < 29030400) {
        const months = Math.floor(diffInSeconds / 2419200);
        return `${months} month${months === 1 ? "" : "s"} ago`;
    } else {
        const years = Math.floor(diffInSeconds / 29030400);
        return `${years} year${years === 1 ? "" : "s"} ago`;
    }
};

/**
 * Converts a hyphen-separated string to title case.
 *
 * This function splits the input text by hyphens, capitalizes the first letter of each word,
 * and then joins the words back together with spaces.
 *
 * Example:
 * - Input: "hello-world-example"
 * - Output: "Hello World Example"
 *
 * @param {string} text - The hyphen-separated text to be converted.
 * @returns {string} - The converted text in title case.
 */
export const hyphenToTitleCase = (text: string): string => {
    const words = text.split("-");
    return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
};
/**
 * Checks if the given value is a valid Date object.
 *
 * This function verifies if the input is an instance of Date and that it represents a valid date.
 *
 * @param {unknown} d - The value to be checked.
 * @returns {boolean} - True if the value is a valid Date object, false otherwise.
 */
export const isDate = (d: unknown): boolean => d instanceof Date && !Number.isNaN(d.getTime());

/**
 * Converts a number to a three-digit string.
 *
 * This function takes a number and converts it to a string with at least three digits,
 * padding with leading zeros if necessary. If the number is undefined, null, less than 0, or greater than 999,
 * the function returns null.
 *
 * @param {number | null} [num] - The number to be converted to a three-digit string.
 * @returns {string | null} - The three-digit string or null if the input is invalid.
 */
export function convertToThreeDigitString(num?: number | null): string | null {
    if (num === undefined || num === null || num < 0 || num > 999) {
        return null;
    }
    return num.toString().padStart(3, "0");
}

/**
 * Safely generates a path using the given path template and parameters.
 *
 * This function attempts to generate a path using the `generatePath` function from `react-router`.
 * If an error occurs during path generation, it logs the error and returns a fallback path ("#").
 *
 * @param {string} path - The path template to be used for generating the path.
 * @param {Record<string, string | number>} [params] - The parameters to be used for generating the path.
 * @returns {string} - The generated path or a fallback path ("#") if an error occurs.
 */
export const generatePathSafe = (path: string, params?: Record<string, string | number>): string => {
    try {
        return generatePath(path, params);
    } catch (error) {
        return "#";
    }
};

/**
 * Trims the given string and returns it, or returns null if the string is empty or undefined.
 *
 * This function trims leading and trailing whitespace from the input string.
 * If the input string is undefined, null, or results in an empty string after trimming, the function returns null.
 *
 * @param {string | undefined | null} value - The string to be trimmed.
 * @returns {string | null} - The trimmed string or null if the input is empty or undefined.
 */
export function trimOrNull(value: string | undefined | null): string | null {
    if (!value) {
        return null;
    }
    const trimmed = value.trim();
    return trimmed.length > 0 ? trimmed : null;
}

/**
 * Removes the word after the numeric string.
 *
 * @param {string | null | undefined} value - The input string containing a numeric value followed by a word.
 * @returns {string | null} - The numeric part of the input string or null if the input is null or undefined.
 */
export function extractNumericStringValue(value: string | null | undefined): string | null {
    if (value === null || value === undefined) {
        return null;
    }
    const trimmedValue = value.trim();
    const match = trimmedValue.match(/^\d+(\.\d+)?/);
    return match ? match[0] : trimmedValue;
}

/**
 * Encodes a password using Base64 encoding.
 *
 * This function takes a password string and encodes it using the Base64 encoding scheme.
 *
 * @param {string} password - The password to be encoded.
 * @returns {string} - The Base64 encoded password.
 */
export function encodePasswordBase64(password: string): string {
    return Base64.encode(password);
}

/**
 * Safely calls a function and logs any errors that occur.
 *
 * This function attempts to call the provided function `fnc`. If an error occurs during the function call,
 * it catches the error and logs it to the console.
 *
 * @param {Function} fnc - The function to be called safely.
 * @returns {*} - The return value of the function `fnc`, or undefined if an error occurs.
 */
export const safeCall = (fnc: Function): any => {
    try {
        return fnc && fnc();
    } catch (e) {
        console.error(e);
    }
};

/**
 * Extracts the name and value from an event object.
 *
 * This function attempts to extract the name and value from the provided event object `e`.
 * It handles various event types, including checkbox, radio, and standard input events.
 * If the event does not contain a name or value, it logs an error and returns null.
 *
 * @param {any} e - The event object from which to extract the name and value.
 * @returns {[string, any] | null} - A tuple containing the name and value, or null if extraction fails.
 */
export const extractEvent = (e: any): [string, any] | null => {
    if (e == null) {
        return null;
    }
    const name = e.name || e.target?.name || e.originalEvent?.name || e.originalEvent?.target?.name;
    if (name == null) {
        return null;
    }
    if (e.target) {
        if (e.target.type === "checkbox") {
            return [name, e.target.checked];
        }
        if (e.target.type === "radio") {
            return [name, e.target.value];
        }
    }
    if (Object.prototype.hasOwnProperty.call(e, "value")) {
        return [name, e.value];
    }
    if (e.target && Object.prototype.hasOwnProperty.call(e.target, "value")) {
        return [name, e.target.value];
    }
    if (e.originalEvent) {
        if (Object.prototype.hasOwnProperty.call(e.originalEvent, "value")) {
            return [name, e.originalEvent.value];
        }
        if (e.originalEvent.target && Object.prototype.hasOwnProperty.call(e.originalEvent.target, "value")) {
            return [name, e.originalEvent.target.value];
        }
    }
    return null;
};

/**
 * Converts a value to a Date object if the original value is a non-empty string.
 *
 * This function checks if the `originalValue` is a non-empty string. If it is, it attempts to parse it into a Date object.
 * If the parsing results in an invalid date, it returns null. Otherwise, it returns the parsed Date object.
 * If the `originalValue` is not a non-empty string, it returns the `value` as is.
 *
 * @param {any} value - The value to be returned if the original value is not a string.
 * @param {any} originalValue - The original value to be checked and possibly converted to a Date.
 * @returns {Date | null | undefined} - The parsed Date object, null if the parsing fails, or the original value if it is not a string.
 */
export const convertDate = (value: any, originalValue: any): Date | null | undefined => {
    if (typeof originalValue === "string" && originalValue.trim() !== "") {
        const parsedDate = new Date(originalValue);
        return isNaN(parsedDate.getTime()) ? null : parsedDate;
    }
    return value; // Pass through the value if already a Date
};

let globalUIDCounter = 1;
export const nextUID = () => {
    globalUIDCounter += 1;
    return globalUIDCounter;
};

export const makeUID = (name: string) => `${name || "uid"}-${nextUID()}`;

/**
 * Ensures that the given value is returned as an array.
 *
 * This function checks if the input value is null or undefined and returns an empty array in that case.
 * If the input value is already an array, it returns the value as is.
 * Otherwise, it wraps the value in an array and returns it.
 *
 * @template T - The type of the elements in the array.
 * @param {T | T[] | null | undefined} value - The value to be ensured as an array.
 * @returns {T[]} - The input value wrapped in an array, or an empty array if the input is null or undefined.
 */
export const ensureArray = <T>(value: T | T[] | null | undefined): T[] => {
    if (value == null) {
        return [];
    }
    return Array.isArray(value) ? value : [value];
};

/**
 * Truncates a string to a specified length and appends an ellipsis if necessary.
 *
 * This function checks if the input string is null or undefined, or if its length is less than or equal to the specified number.
 * If any of these conditions are met, it returns the original string. Otherwise, it truncates the string to the specified length
 * and appends an ellipsis ("...") to the end.
 *
 * @param {string | undefined} str - The string to be truncated.
 * @param {number} num - The maximum length of the truncated string.
 * @returns {string | undefined} - The truncated string with an ellipsis, or the original string if no truncation is needed.
 */
export const truncateString = (str: string | undefined, num: number) => {
    if (str == null || str.length <= num) {
        return str;
    }
    return `${str.slice(0, num)}...`;
};

export const formatNumberLeadingZero = (number: number, length: number): string => {
    if (number === undefined || number === null || isNaN(number)) {
        return "";
    }
    const str = number.toString();
    return str.length < length ? str.padStart(length, "0") : str;
};
