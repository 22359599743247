export const isEmpty = (obj: any): boolean => {
    if (obj == null) {
        return true;
    }
    if (Array.isArray(obj) || typeof obj === "string") {
        return obj.length === 0;
    }
    if (typeof obj === "object") {
        const keys = Object.keys(obj);
        if (keys.length === 0) {
            return true;
        }
        return keys.find((key) => !isEmpty(obj[key])) == null;
    }
    return false;
};

// eslint-disable-next-line eqeqeq
export const eqeq = (a: any, b: any) => a == b;

export const nameOf = <T>(name: keyof T) => name;

export const pathOf = <T1, T2, T3 = any, T4 = any>(
    name1: keyof T1,
    name2: keyof T2,
    name3: keyof T3 | null = null,
    name4: keyof T4 | null = null
) => {
    if (name4 != null) {
        return `${String(name1)}.${String(name2)}.${String(name3)}.${String(name4)}`;
    }
    if (name3 != null) {
        return `${String(name1)}.${String(name2)}.${String(name3)}`;
    }
    return `${String(name1)}.${String(name2)}`;
};

const byKey = /(.+)=(.+)\]$/;

const byIdx = /(\d+)\]$/;

const isNameArrayIdx = (name: string) => byKey.exec(name) != null || byIdx.exec(name) != null;

const resolveArrayIdx = (current: any, name: string) => {
    let match = byKey.exec(name);
    if (match) {
        const [, field, key] = match;
        if (Array.isArray(current)) {
            return current.findIndex((o) => eqeq(o[field], key));
        }
        return -1;
    }
    match = byIdx.exec(name);
    if (match) {
        if (Array.isArray(current)) {
            return Number(match[1]);
        }
        return -1;
    }
    return null;
};

const copyArray = (entry: any, nextIsIdx: boolean) =>
    (entry == null && nextIsIdx && []) || (Array.isArray(entry) ? [...entry] : {...entry});

export const setField = (obj: any, path: string, value: any) => {
    if (obj == null || path == null) {
        return obj;
    }

    const pathArr = path.split(/\.|\[/);
    const result = {...obj};
    let it = result;

    for (let pos = 0; pos < pathArr.length - 1; pos += 1) {
        const name = pathArr[pos];
        const nextIsIdx = pos < pathArr.length - 1 && isNameArrayIdx(pathArr[pos + 1]);
        let copy;
        const idx = resolveArrayIdx(it, name);
        if (idx != null) {
            while (it.length < idx) {
                // fill up with null
                it.push(null);
            }
            const entry = it[idx];
            copy = copyArray(entry, nextIsIdx);
            if (idx === -1) {
                it.push(copy);
            } else {
                it.splice(idx, 1, copy);
            }
        } else {
            const x = it[name];
            if (x == null && nextIsIdx) {
                copy = [];
            } else {
                copy = Array.isArray(x) ? [...x] : {...x};
            }
            it[name] = copy;
        }
        it = copy;
    }

    const name = pathArr[pathArr.length - 1];
    const entryIdx = resolveArrayIdx(it, name);
    if (entryIdx === null) {
        if (eqeq(it[name], value)) {
            return obj;
        }
        it[name] = value;
    } else if (value === null) {
        if (entryIdx !== -1) {
            it.splice(entryIdx, 1);
        }
    } else if (entryIdx === -1) {
        it.push(value);
    } else {
        it.splice(entryIdx, 1, value);
    }

    return result;
};

export const getField = (obj: any, path: string) => {
    if (obj == null || path == null) {
        return null;
    }
    const pathArr = path.split(/\.|\[/);
    let it = obj;
    for (let i = 0; i < pathArr.length; i += 1) {
        const name = pathArr[i];
        const entryIdx = resolveArrayIdx(it, name);
        if (entryIdx != null) {
            const entry = entryIdx === -1 ? null : it[entryIdx];
            if (entry == null) {
                return null;
            }
            it = entry;
        } else {
            it = it[name];
            if (it == null) {
                return null;
            }
        }
    }
    return it;
};

export const transformToErrorShape = (json: Record<any, String> | null | undefined): Object => {
    if (json == null) {
        return {};
    }
    return Object.entries(json).reduce((acc, [key, value]) => setField(acc, `${key}.message`, value), {});
};
