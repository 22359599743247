import * as yup from "yup";
import {CompanySignUpDTO, Country} from "@/api/data-contracts";
import {useMemo} from "react";
import {countryOptions, languageOptions, legalFormOptions, salutationOptions} from "@/datacaches";
import {Constants} from "@/api/constants";
import {
    BIC_REGEX,
    COMMERCIAL_REGISTRATION_NUMBER_REGEX,
    EMAIL_REGEX,
    IBAN_REGEX,
    PASSWORD_REGEX,
    PHONE_NUMBER_REGEX,
    URL_REGEX,
    VAT_ID_REGEX,
    ZIP_CODE_REGEX
} from "@/regex";
import {AuthenticationPageI18N, CompanyFormI18N, UserFormI18N} from "@/translations";
import {useTranslation} from "react-i18next";

export const useSignUpSchema = () => {
    const {t} = useTranslation();
    return useMemo(
        () =>
            yup.object().shape({
                email: yup
                    .string()
                    .required(t(UserFormI18N.requiredEmailError))
                    .min(
                        Constants.EMAIL_LENGTH_MIN,
                        t(UserFormI18N.minEmailError, {minInteger: Constants.EMAIL_LENGTH_MIN})
                    )
                    .max(
                        Constants.EMAIL_LENGTH_MAX,
                        t(UserFormI18N.maxEmailError, {maxInteger: Constants.EMAIL_LENGTH_MAX})
                    )
                    .matches(EMAIL_REGEX, t(UserFormI18N.invalidEmailError)),

                password: yup
                    .string()
                    .required(t(UserFormI18N.requiredPasswordError))
                    .min(
                        Constants.PASSWORD_LENGTH_MIN,
                        t(UserFormI18N.minPasswordError, {minInteger: Constants.PASSWORD_LENGTH_MIN})
                    )
                    .max(
                        Constants.PASSWORD_LENGTH_MAX,
                        t(UserFormI18N.maxPasswordError, {maxInteger: Constants.PASSWORD_LENGTH_MAX})
                    )
                    .matches(PASSWORD_REGEX, t(UserFormI18N.invalidPasswordError)),

                confirmPassword: yup
                    .string()
                    .required(t(UserFormI18N.requiredPasswordError))
                    .min(
                        Constants.PASSWORD_LENGTH_MIN,
                        t(UserFormI18N.minPasswordError, {minInteger: Constants.PASSWORD_LENGTH_MIN})
                    )
                    .max(
                        Constants.PASSWORD_LENGTH_MAX,
                        t(UserFormI18N.maxPasswordError, {maxInteger: Constants.PASSWORD_LENGTH_MAX})
                    )
                    .oneOf([yup.ref("password")], t(UserFormI18N.passwordsMustMatchError)),

                salutation: yup
                    .string()
                    .required(t(UserFormI18N.requiredSalutationError))
                    .oneOf(salutationOptions, t(UserFormI18N.invalidSalutationError)),

                firstName: yup
                    .string()
                    .required(t(UserFormI18N.requiredFirstNameError))
                    .min(
                        Constants.FIRSTNAME_LENGTH_MIN,
                        t(UserFormI18N.minFirstNameError, {minInteger: Constants.FIRSTNAME_LENGTH_MIN})
                    )
                    .max(
                        Constants.FIRSTNAME_LENGTH_MAX,
                        t(UserFormI18N.maxFirstNameError, {maxInteger: Constants.FIRSTNAME_LENGTH_MAX})
                    ),

                lastName: yup
                    .string()
                    .required(t(UserFormI18N.requiredLastNameError))
                    .min(
                        Constants.LASTNAME_LENGTH_MIN,
                        t(UserFormI18N.minLastNameError, {minInteger: Constants.LASTNAME_LENGTH_MIN})
                    )
                    .max(
                        Constants.LASTNAME_LENGTH_MAX,
                        t(UserFormI18N.maxLastNameError, {maxInteger: Constants.LASTNAME_LENGTH_MAX})
                    ),

                phone: yup
                    .string()
                    .required(t(UserFormI18N.requiredPhoneError))
                    .min(
                        Constants.PHONE_NUMBER_LENGTH_MIN,
                        t(UserFormI18N.minPhoneError, {minInteger: Constants.PHONE_NUMBER_LENGTH_MIN})
                    )
                    .max(
                        Constants.PHONE_NUMBER_LENGTH_MAX,
                        t(UserFormI18N.maxPhoneError, {maxInteger: Constants.PHONE_NUMBER_LENGTH_MAX})
                    )
                    .matches(PHONE_NUMBER_REGEX, t(UserFormI18N.invalidPhoneError)),

                fax: yup
                    .string()
                    .optional()
                    .nullable()
                    .min(
                        Constants.PHONE_NUMBER_LENGTH_MIN,
                        t(UserFormI18N.minFaxError, {minInteger: Constants.PHONE_NUMBER_LENGTH_MIN})
                    )
                    .max(
                        Constants.PHONE_NUMBER_LENGTH_MAX,
                        t(UserFormI18N.maxFaxError, {maxInteger: Constants.PHONE_NUMBER_LENGTH_MAX})
                    )
                    .matches(PHONE_NUMBER_REGEX, t(UserFormI18N.invalidFaxError)),

                language: yup.string().oneOf(languageOptions, t(UserFormI18N.requiredLanguageError)),

                ceo: yup
                    .boolean()
                    .required(t(UserFormI18N.requiredIsCEOError))
                    .oneOf([true], t(UserFormI18N.mustBeCEOError)),

                companyName: yup
                    .string()
                    .required(t(CompanyFormI18N.requiredCompanyNameError))
                    .min(
                        Constants.COMPANY_NAME_LENGTH_MIN,
                        t(CompanyFormI18N.minCompanyNameError, {minInteger: Constants.COMPANY_NAME_LENGTH_MIN})
                    )
                    .max(
                        Constants.COMPANY_NAME_LENGTH_MAX,
                        t(CompanyFormI18N.maxCompanyNameError, {maxInteger: Constants.COMPANY_NAME_LENGTH_MAX})
                    ),

                country: yup
                    .string()
                    .required(t(CompanyFormI18N.requiredCountryError))
                    .oneOf(countryOptions, t(CompanyFormI18N.invalidCountryError)),

                legalForm: yup
                    .string()
                    .required(t(CompanyFormI18N.requiredLegalFormError))
                    .oneOf(legalFormOptions, t(CompanyFormI18N.invalidLegalFormError)),

                streetAddress: yup
                    .string()
                    .optional()
                    .nullable()
                    .transform((value) => (!value ? null : value))
                    .min(
                        Constants.STREET_LENGTH_MIN,
                        t(CompanyFormI18N.minStreetError, {minInteger: Constants.STREET_LENGTH_MIN})
                    )
                    .max(
                        Constants.STREET_LENGTH_MAX,
                        t(CompanyFormI18N.maxStreetError, {maxInteger: Constants.STREET_LENGTH_MAX})
                    ),

                postalCode: yup
                    .string()
                    .required(t(CompanyFormI18N.requiredPostalCodeError))
                    .min(
                        Constants.ZIP_LENGTH_MIN,
                        t(CompanyFormI18N.minPostalCodeError, {minInteger: Constants.ZIP_LENGTH_MIN})
                    )
                    .max(
                        Constants.ZIP_LENGTH_MAX,
                        t(CompanyFormI18N.maxPostalCodeError, {maxInteger: Constants.ZIP_LENGTH_MAX})
                    )
                    .matches(ZIP_CODE_REGEX, t(CompanyFormI18N.invalidPostalCodeError)),

                city: yup
                    .string()
                    .required(t(CompanyFormI18N.requiredCityError))
                    .min(
                        Constants.CITY_LENGTH_MIN,
                        t(CompanyFormI18N.minCityError, {minInteger: Constants.CITY_LENGTH_MIN})
                    )
                    .max(
                        Constants.CITY_LENGTH_MAX,
                        t(CompanyFormI18N.maxCityError, {maxInteger: Constants.CITY_LENGTH_MAX})
                    ),

                website: yup
                    .string()
                    .optional()
                    .nullable()
                    .transform((value) => (!value ? null : value))
                    .min(
                        Constants.WEBSITE_LENGTH_MIN,
                        t(CompanyFormI18N.minCompanyWebsiteError, {minInteger: Constants.WEBSITE_LENGTH_MIN})
                    )
                    .max(
                        Constants.WEBSITE_LENGTH_MAX,
                        t(CompanyFormI18N.maxCompanyWebsiteError, {maxInteger: Constants.WEBSITE_LENGTH_MAX})
                    )
                    .matches(URL_REGEX, t(CompanyFormI18N.invalidCompanyWebsiteError)),

                commercialRegisterNumber: yup.string().when("country", {
                    is: (country: Country) => country === Country.GERMANY,
                    then: (schema) =>
                        schema
                            .required(t(CompanyFormI18N.requiredCommercialRegistrationNumberError)),
                    otherwise: (schema) => schema.optional().nullable()
                }).transform((value) => (!value ? null : value))
                    .min(
                        Constants.COMMERCIAL_REGISTER_NUMBER_LENGTH_MIN,
                        t(CompanyFormI18N.minCommercialRegistrationNumberError, {
                            minInteger: Constants.COMMERCIAL_REGISTER_NUMBER_LENGTH_MIN
                        })
                    ).max(
                        Constants.COMMERCIAL_REGISTER_NUMBER_LENGTH_MAX,
                        t(CompanyFormI18N.maxCommercialRegistrationNumberError, {
                            maxInteger: Constants.COMMERCIAL_REGISTER_NUMBER_LENGTH_MAX
                        })
                    ).matches(
                        COMMERCIAL_REGISTRATION_NUMBER_REGEX,
                        t(CompanyFormI18N.invalidCommercialRegistrationNumberError)
                    ),

                vatId: yup
                    .string()
                    .required(t(CompanyFormI18N.vatIdLabel))
                    .min(
                        Constants.VAT_ID_LENGTH_MIN,
                        t(CompanyFormI18N.minVatIdError, {minInteger: Constants.VAT_ID_LENGTH_MIN})
                    )
                    .max(
                        Constants.VAT_ID_LENGTH_MAX,
                        t(CompanyFormI18N.maxVatIdError, {maxInteger: Constants.VAT_ID_LENGTH_MAX})
                    )
                    .matches(VAT_ID_REGEX, t(CompanyFormI18N.invalidVatIdError)),

                erpManufacturer: yup
                    .string()
                    .required(t(CompanyFormI18N.requiredErpManufacturerError))
                    .min(
                        Constants.ERP_MANUFACTURER_LENGTH_MIN,
                        t(CompanyFormI18N.minErpManufacturerError, {
                            minInteger: Constants.ERP_MANUFACTURER_LENGTH_MIN
                        })
                    )
                    .max(
                        Constants.ERP_MANUFACTURER_LENGTH_MAX,
                        t(CompanyFormI18N.maxErpManufacturerError, {
                            maxInteger: Constants.ERP_MANUFACTURER_LENGTH_MAX
                        })
                    ),

                erpSoftwareName: yup
                    .string()
                    .required(t(CompanyFormI18N.requiredErpSoftwareNameError))
                    .min(
                        Constants.ERP_SOFTWARE_NAME_LENGTH_MIN,
                        t(CompanyFormI18N.minErpSoftwareNameError, {
                            minInteger: Constants.ERP_SOFTWARE_NAME_LENGTH_MIN
                        })
                    )
                    .max(
                        Constants.ERP_SOFTWARE_NAME_LENGTH_MAX,
                        t(CompanyFormI18N.maxErpSoftwareNameError, {
                            maxInteger: Constants.ERP_SOFTWARE_NAME_LENGTH_MAX
                        })
                    ),

                erpSoftwareVersion: yup
                    .string()
                    .required(t(CompanyFormI18N.requiredErpSoftwareVersionError))
                    .min(
                        Constants.ERP_SOFTWARE_VERSION_LENGTH_MIN,
                        t(CompanyFormI18N.minErpSoftwareVersionError, {
                            minInteger: Constants.ERP_SOFTWARE_VERSION_LENGTH_MIN
                        })
                    )
                    .max(
                        Constants.ERP_SOFTWARE_VERSION_LENGTH_MAX,
                        t(CompanyFormI18N.maxErpSoftwareVersionError, {
                            maxInteger: Constants.ERP_SOFTWARE_VERSION_LENGTH_MAX
                        })
                    ),

                officeHours: yup
                    .string()
                    .required(t(CompanyFormI18N.requiredOfficeHoursError))
                    .min(
                        Constants.OFFICE_HOURS_LENGTH_MIN,
                        t(CompanyFormI18N.minOfficeHoursError, {minInteger: Constants.OFFICE_HOURS_LENGTH_MIN})
                    )
                    .max(
                        Constants.OFFICE_HOURS_LENGTH_MAX,
                        t(CompanyFormI18N.maxOfficeHoursError, {maxInteger: Constants.OFFICE_HOURS_LENGTH_MAX})
                    ),

                paymentTerms: yup
                    .string()
                    .required(t(CompanyFormI18N.requiredPaymentTermsError))
                    .min(
                        Constants.PAYMENT_TERMS_LENGTH_MIN,
                        t(CompanyFormI18N.minPaymentTermsError, {minInteger: Constants.PAYMENT_TERMS_LENGTH_MIN})
                    )
                    .max(
                        Constants.PAYMENT_TERMS_LENGTH_MAX,
                        t(CompanyFormI18N.maxPaymentTermsError, {maxInteger: Constants.PAYMENT_TERMS_LENGTH_MAX})
                    ),

                additionalInformation: yup
                    .string()
                    .required(t(CompanyFormI18N.requiredAdditionalInformationError))
                    .min(
                        Constants.ADDITIONAL_INFORMATION_LENGTH_MIN,
                        t(CompanyFormI18N.minAdditionalInformationError, {
                            minInteger: Constants.ADDITIONAL_INFORMATION_LENGTH_MIN
                        })
                    )
                    .max(
                        Constants.ADDITIONAL_INFORMATION_LENGTH_MAX,
                        t(CompanyFormI18N.maxAdditionalInformationError, {
                            maxInteger: Constants.ADDITIONAL_INFORMATION_LENGTH_MAX
                        })
                    ),

                bankName: yup
                    .string()
                    .required(t(CompanyFormI18N.requiredBankNameError))
                    .min(
                        Constants.BANKNAME_LENGTH_MIN,
                        t(CompanyFormI18N.minBankNameError, {minInteger: Constants.BANKNAME_LENGTH_MIN})
                    )
                    .max(
                        Constants.BANKNAME_LENGTH_MAX,
                        t(CompanyFormI18N.maxBankNameError, {maxInteger: Constants.BANKNAME_LENGTH_MAX})
                    ),

                iban: yup
                    .string()
                    .required(t(CompanyFormI18N.requiredIbanError))
                    .min(
                        Constants.IBAN_LENGTH_MIN,
                        t(CompanyFormI18N.minIbanError, {minInteger: Constants.IBAN_LENGTH_MIN})
                    )
                    .max(
                        Constants.IBAN_LENGTH_MAX,
                        t(CompanyFormI18N.maxIbanError, {maxInteger: Constants.IBAN_LENGTH_MAX})
                    )
                    .matches(IBAN_REGEX, t(CompanyFormI18N.invalidIbanError)),

                bic: yup
                    .string()
                    .required(t(CompanyFormI18N.requiredBicError))
                    .min(
                        Constants.BIC_LENGTH_MIN,
                        t(CompanyFormI18N.minBicError, {minInteger: Constants.BIC_LENGTH_MIN})
                    )
                    .max(
                        Constants.BIC_LENGTH_MAX,
                        t(CompanyFormI18N.maxBicError, {maxInteger: Constants.BIC_LENGTH_MAX})
                    )
                    .matches(BIC_REGEX, t(CompanyFormI18N.invalidBicError)),

                miniliner: yup.boolean(),

                midliner: yup.boolean(),

                maxiliner: yup.boolean(),

                dieCutting: yup.boolean(),

                folderGluerMachine: yup.boolean(),

                packaging: yup.boolean(),

                hazardousGoodsQualification: yup.boolean(),

                flexoPrinting: yup.boolean(),

                hdFlexoPrinting: yup.boolean(),

                offsetPrinting: yup.boolean(),

                digitalPrinting: yup.boolean(),

                termsAccepted: yup
                    .boolean()
                    .required(t(AuthenticationPageI18N.requiredTermsAcceptedError))
                    .oneOf([true], t(AuthenticationPageI18N.requiredTermsAcceptedError)),

                privacyPolicyAccepted: yup
                    .boolean()
                    .required(t(AuthenticationPageI18N.requiredPrivacyPolicyAcceptedError))
                    .oneOf([true], t(AuthenticationPageI18N.requiredPrivacyPolicyAcceptedError)),

                confidentialityAccepted: yup
                    .boolean()
                    .required(t(AuthenticationPageI18N.requiredConfidentialityAcceptedError))
                    .oneOf([true], t(AuthenticationPageI18N.requiredConfidentialityAcceptedError)),

                contractAndUsageAccepted: yup
                    .boolean()
                    .required(t(AuthenticationPageI18N.requiredContractAndUsageAcceptedError))
                    .oneOf([true], t(AuthenticationPageI18N.requiredContractAndUsageAcceptedError))
            }) as yup.ObjectSchema<CompanySignUpDTO>,
        [t]
    );
};
