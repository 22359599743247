import { SxProps, Theme, Tooltip, TooltipProps, Typography, useTheme } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import React, { forwardRef, RefAttributes } from "react";
import Box from "@mui/material/Box";

export type BadgeProps = {
    label?: string;
    icon?: React.ReactNode;
    color?: string;
    backgroundColor?: string;
    onClick?: any;
    onDblClick?: any;
    fullWidth?: boolean;
    tooltip?: string | React.ReactNode;
    tooltipPlacement?: TooltipProps["placement"];
};

export const Badge = forwardRef(
    (
        {
            label,
            icon,
            fullWidth,
            color,
            backgroundColor,
            onClick,
            onDblClick,
            tooltip,
            tooltipPlacement = "left-start"
        }: BadgeProps,
        ref: RefAttributes<any>["ref"]
    ) => {
        const theme = useTheme();
        if (label == null || label.length === 0) {
            return null;
        }

        const onKeyDown = () => {};

        const classes: SxProps<Theme> = {
            display: "inline-flex",
            gap: "4px",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "4px",
            verticalAlign: "middle",
            whiteSpace: "nowrap",
            padding: "1px 8px 0px 8px",
            color: color || (theme.palette.mode === "dark" ? "white" : "black"),
            boxShadow: "inset 0 0 0 1px rgba(40, 42, 44, 0.25)",
            backgroundColor: backgroundColor || blue[200],
            cursor: "default",
            width: fullWidth ? "100%" : "auto",
            height: "fit-content",
            "&.active": {
                userSelect: "none",
                cursor: "pointer"
            },
            "&:hover.active": {
                borderColor: theme.palette.mode === "dark" ? grey[300] : "black",
                borderStyle: "solid",
                borderSize: 2,
                margin: -2
            },
            "& span": {
                textAlign: "center",
                overflow: "hidden",
                textOverflow: "ellipsis"
            }
        };

        const badge = (
            <Box
                ref={ref}
                className={(onClick || onDblClick) && "active"}
                sx={classes}
                role={(onClick || onDblClick) && "button"}
                onClick={onClick}
                onKeyDown={onKeyDown}
                onDoubleClick={onDblClick}
            >
                <span>{icon}</span>
                <Typography variant="caption" fontWeight="bold" textTransform="uppercase">
                    {label}
                </Typography>
            </Box>
        );

        return tooltip ? (
            <Tooltip arrow placement={tooltipPlacement} title={tooltip}>
                {badge}
            </Tooltip>
        ) : (
            badge
        );
    }
);

Badge.displayName = "Badge";
