import * as yup from "yup";
import {CompanyDTO, Country} from "@/api/data-contracts";
import {useMemo} from "react";
import {countryOptions, legalFormOptions} from "@/datacaches";
import {Constants} from "@/api/constants";
import {BIC_REGEX, COMMERCIAL_REGISTRATION_NUMBER_REGEX, IBAN_REGEX, URL_REGEX, VAT_ID_REGEX} from "@/regex";
import {useTranslation} from "react-i18next";
import {CompanyFormI18N} from "@/translations";

export type CompanyEditDTO = Pick<
    CompanyDTO,
    | "name"
    | "legalForm"
    | "companyAddress"
    | "website"
    | "commercialRegistrationNumber"
    | "vatIdentificationNumber"
    | "erpManufacturer"
    | "erpSoftwareName"
    | "erpSoftwareVersion"
    | "maschineryFleet"
    | "officeHours"
    | "paymentTerms"
    | "additionalInformation"
    | "bankName"
    | "iban"
    | "bic"
>;

export const useCompanyEditSchema = () => {
    const {t} = useTranslation();
    return useMemo(() => {
        const schema = yup.object().shape({
            name: yup
                .string()
                .required(t(CompanyFormI18N.requiredCompanyNameError))
                .min(
                    Constants.COMPANY_NAME_LENGTH_MIN,
                    t(CompanyFormI18N.minCompanyNameError, {minInteger: Constants.COMPANY_NAME_LENGTH_MIN})
                )
                .max(
                    Constants.COMPANY_NAME_LENGTH_MAX,
                    t(CompanyFormI18N.maxCompanyNameError, {maxInteger: Constants.COMPANY_NAME_LENGTH_MAX})
                ),

            legalForm: yup
                .string()
                .required(t(CompanyFormI18N.requiredLegalFormError))
                .oneOf(legalFormOptions, t(CompanyFormI18N.invalidLegalFormError)),

            companyAddress: yup.object().shape({
                country: yup
                    .string()
                    .required(t(CompanyFormI18N.requiredCountryError))
                    .oneOf(countryOptions, t(CompanyFormI18N.invalidCountryError))
            }),

            website: yup
                .string()
                .optional()
                .nullable()
                .transform((value) => (!value ? null : value))
                .min(
                    Constants.WEBSITE_LENGTH_MIN,
                    t(CompanyFormI18N.minCompanyWebsiteError, {minInteger: Constants.WEBSITE_LENGTH_MIN})
                )
                .max(
                    Constants.WEBSITE_LENGTH_MAX,
                    t(CompanyFormI18N.maxCompanyWebsiteError, {maxInteger: Constants.WEBSITE_LENGTH_MAX})
                )
                .matches(URL_REGEX, t(CompanyFormI18N.invalidCompanyWebsiteError)),

            commercialRegistrationNumber: yup.string().when("companyAddress.country", {
                is: (country: Country) => country === Country.GERMANY,
                then: (schema) =>
                    schema
                        .required(t(CompanyFormI18N.requiredCommercialRegistrationNumberError)),
                otherwise: (schema) => schema.optional().nullable()
            }).transform((value) => (!value ? null : value))
                .min(
                    Constants.COMMERCIAL_REGISTER_NUMBER_LENGTH_MIN,
                    t(CompanyFormI18N.minCommercialRegistrationNumberError, {
                        minInteger: Constants.COMMERCIAL_REGISTER_NUMBER_LENGTH_MIN
                    })
                ).max(
                    Constants.COMMERCIAL_REGISTER_NUMBER_LENGTH_MAX,
                    t(CompanyFormI18N.maxCommercialRegistrationNumberError, {
                        maxInteger: Constants.COMMERCIAL_REGISTER_NUMBER_LENGTH_MAX
                    })
                ).matches(
                    COMMERCIAL_REGISTRATION_NUMBER_REGEX,
                    t(CompanyFormI18N.invalidCommercialRegistrationNumberError)
                ),

            vatIdentificationNumber: yup
                .string()
                .required(t(CompanyFormI18N.vatIdLabel))
                .min(
                    Constants.VAT_ID_LENGTH_MIN,
                    t(CompanyFormI18N.minVatIdError, {minInteger: Constants.VAT_ID_LENGTH_MIN})
                )
                .max(
                    Constants.VAT_ID_LENGTH_MAX,
                    t(CompanyFormI18N.maxVatIdError, {maxInteger: Constants.VAT_ID_LENGTH_MAX})
                )
                .matches(VAT_ID_REGEX, t(CompanyFormI18N.invalidVatIdError)),

            erpManufacturer: yup
                .string()
                .required(t(CompanyFormI18N.requiredErpManufacturerError))
                .min(
                    Constants.ERP_MANUFACTURER_LENGTH_MIN,
                    t(CompanyFormI18N.minErpManufacturerError, {minInteger: Constants.ERP_MANUFACTURER_LENGTH_MIN})
                )
                .max(
                    Constants.ERP_MANUFACTURER_LENGTH_MAX,
                    t(CompanyFormI18N.maxErpManufacturerError, {maxInteger: Constants.ERP_MANUFACTURER_LENGTH_MAX})
                ),

            erpSoftwareName: yup
                .string()
                .required(t(CompanyFormI18N.requiredErpSoftwareNameError))
                .min(
                    Constants.ERP_SOFTWARE_NAME_LENGTH_MIN,
                    t(CompanyFormI18N.minErpSoftwareNameError, {minInteger: Constants.ERP_SOFTWARE_NAME_LENGTH_MIN})
                )
                .max(
                    Constants.ERP_SOFTWARE_NAME_LENGTH_MAX,
                    t(CompanyFormI18N.maxErpSoftwareNameError, {maxInteger: Constants.ERP_SOFTWARE_NAME_LENGTH_MAX})
                ),

            erpSoftwareVersion: yup
                .string()
                .required(t(CompanyFormI18N.requiredErpSoftwareVersionError))
                .min(
                    Constants.ERP_SOFTWARE_VERSION_LENGTH_MIN,
                    t(CompanyFormI18N.minErpSoftwareVersionError, {
                        minInteger: Constants.ERP_SOFTWARE_VERSION_LENGTH_MIN
                    })
                )
                .max(
                    Constants.ERP_SOFTWARE_VERSION_LENGTH_MAX,
                    t(CompanyFormI18N.maxErpSoftwareVersionError, {
                        maxInteger: Constants.ERP_SOFTWARE_VERSION_LENGTH_MAX
                    })
                ),

            maschineryFleet: yup.object().shape({
                miniliner: yup.boolean(),

                midliner: yup.boolean(),

                maxiliner: yup.boolean(),

                dieCutting: yup.boolean(),

                folderGluerMachine: yup.boolean(),

                packaging: yup.boolean(),

                hazardousGoodsQualification: yup.boolean(),

                flexoPrinting: yup.boolean(),

                hdFlexoPrinting: yup.boolean(),

                offsetPrinting: yup.boolean(),

                digitalPrinting: yup.boolean(),
            }),

            paymentTerms: yup
                .string()
                .required(t(CompanyFormI18N.requiredPaymentTermsError))
                .min(
                    Constants.PAYMENT_TERMS_LENGTH_MIN,
                    t(CompanyFormI18N.minPaymentTermsError, {minInteger: Constants.PAYMENT_TERMS_LENGTH_MIN})
                )
                .max(
                    Constants.PAYMENT_TERMS_LENGTH_MAX,
                    t(CompanyFormI18N.maxPaymentTermsError, {maxInteger: Constants.PAYMENT_TERMS_LENGTH_MAX})
                ),

            additionalInformation: yup
                .string()
                .required(t(CompanyFormI18N.requiredAdditionalInformationError))
                .min(
                    Constants.ADDITIONAL_INFORMATION_LENGTH_MIN,
                    t(CompanyFormI18N.minAdditionalInformationError, {
                        minInteger: Constants.ADDITIONAL_INFORMATION_LENGTH_MIN
                    })
                )
                .max(
                    Constants.ADDITIONAL_INFORMATION_LENGTH_MAX,
                    t(CompanyFormI18N.maxAdditionalInformationError, {
                        maxInteger: Constants.ADDITIONAL_INFORMATION_LENGTH_MAX
                    })
                ),

            bankName: yup
                .string()
                .required(t(CompanyFormI18N.requiredBankNameError))
                .min(
                    Constants.BANKNAME_LENGTH_MIN,
                    t(CompanyFormI18N.minBankNameError, {minInteger: Constants.BANKNAME_LENGTH_MIN})
                )
                .max(
                    Constants.BANKNAME_LENGTH_MAX,
                    t(CompanyFormI18N.maxBankNameError, {maxInteger: Constants.BANKNAME_LENGTH_MAX})
                ),

            iban: yup
                .string()
                .required(t(CompanyFormI18N.requiredIbanError))
                .min(
                    Constants.IBAN_LENGTH_MIN,
                    t(CompanyFormI18N.minIbanError, {minInteger: Constants.IBAN_LENGTH_MIN})
                )
                .max(
                    Constants.IBAN_LENGTH_MAX,
                    t(CompanyFormI18N.maxIbanError, {maxInteger: Constants.IBAN_LENGTH_MAX})
                )
                .matches(IBAN_REGEX, t(CompanyFormI18N.invalidIbanError)),

            bic: yup
                .string()
                .required(t(CompanyFormI18N.requiredBicError))
                .min(Constants.BIC_LENGTH_MIN, t(CompanyFormI18N.minBicError, {minInteger: Constants.BIC_LENGTH_MIN}))
                .max(Constants.BIC_LENGTH_MAX, t(CompanyFormI18N.maxBicError, {maxInteger: Constants.BIC_LENGTH_MAX}))
                .matches(BIC_REGEX, t(CompanyFormI18N.invalidBicError))
        });

        const typed = schema as yup.ObjectSchema<CompanyEditDTO>;
        return typed as yup.ObjectSchema<CompanyDTO>;
    }, [t]);
};
