import { formatCurrency } from "@/lib/utils";
import { LabelValue } from "@/lib/components/LabelValueList";

/**
 * Generates a field object for a currency value with optional formatting and tooltip.
 *
 * @param {number | undefined} numberValue - The numeric value to be formatted as currency. If undefined, a default "not available" message is used.
 * @param {string} label - The label for the field.
 * @param {string} [defaultValue=""] - The default value.
 * @param {string} [locale] - The locale to use for formatting the currency.
 * @param {string} [tooltipKey] - The key for the tooltip text.
 * @returns {LabelValue} The field object with label, value, and tooltipKey properties.
 */
export const currencyField = (
    numberValue: number | undefined,
    label: string,
    defaultValue: string = "",
    locale?: string,
    tooltipKey?: string
): LabelValue => ({
    label,
    value: numberValue ? (formatCurrency(numberValue, locale) ?? defaultValue) : defaultValue,
    tooltipKey
});
