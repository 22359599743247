import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useAppTheme} from "@/app/AppThemeContext";
import {
    formatCustomerRequestNumber,
    sortExternalRequestsByStatusAndDeliveryDate,
    sortInternalRequestsByStatusAndDeliveryDate
} from "@/model/request";
import {DateFormatter} from "@/lib/components/DateFormate";
import {requestApi} from "@/boxhub-api";
import {RequestFilter, RequestOverviewRowDTO} from "@/api/data-contracts";
import {getI18n, useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {AppFrame} from "@/app/AppFrame";
import {useSnackbarEx} from "@/lib/snackbarex";
import {DataTable} from "@/lib/components/DataTable";
import {ColumnDef} from "@tanstack/react-table";
import {Grid2, Table, TableBody, TableCell, TableRow, Tooltip} from "@mui/material";
import {RequestStatusBadge} from "@/common/RequestStatusBadge";
import {GeneralI18N, PrintTypeI18N, QuoteStatusI18N, RequestKeyWordsI18N, RequestStatusI18N} from "@/translations";
import {requestStatusField} from "@/common/request-details/request-status-field";
import {printField} from "@/common/request-details/print-field";
import {stringField} from "@/common/fields/string-field";
import {dateRangeField} from "@/common/fields/date-range-field";
import {addressField} from "@/common/fields/address-field";
import {ensureArray, formatCurrency, formatInteger} from "@/lib/utils";
import {quoteStatusField} from "@/common/request-details/quote-status-field";
import {QuoteStatusBadge} from "@/common/QuoteStatusBadge";
import {useQueryState} from "@/lib/querystate";
import MultiSelectInputField from "@/lib/components/inputfields/multi-select-input-field";
import {Globe, Layout, MessageCircleQuestion, MessageCircleWarning, Printer} from "lucide-react";
import {CardEx} from "@/lib/components/CardEx";
import {dimensionsField} from "@/common/request-details/internal-dimensions-field";
import {blue, green, red} from "@mui/material/colors";
import {TOOLTIP_DELAY_MS} from "@/constants";

export interface Option<S> {
    label: string;
    value: S;
}

interface OverviewQueryParams<S> {
    statuses?: S[];
}

export interface RequestOverviewPageProps<S> {
    type: RequestFilter;
    statusField: "requestStatuses" | "quoteStatuses";
    initial: S[];
    target: (id: number) => string;
    options?: Option<S>[];
    localStorageName: string;
}

export const RequestOverviewPage = <S = any, >({
                                                   type,
                                                   target,
                                                   statusField,
                                                   initial,
                                                   options,
                                                   localStorageName
                                               }: RequestOverviewPageProps<S>) => {
    const {t} = useTranslation();
    const theme = useAppTheme();
    const locale = getI18n().language;
    const dateFormat = DateFormatter();
    const [requestDisplays, setRequestDisplays] = useState<RequestOverviewRowDTO[]>([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const {showMessage} = useSnackbarEx();

    const {searchParams, onChangeSearchParams, getSearchParams, searchParamTrigger} = useQueryState<
        OverviewQueryParams<S>
    >({
        initialState: {
            statuses: initial
        },
        enhancer: {
            statuses: ensureArray
        },
        localStorageName
    });

    const fetchRequestDisplays = useCallback(() => {
        requestApi
            .searchRequests({
                [statusField]: getSearchParams().statuses,
                type
            })
            .then((response) => {
                const requestDisplays = response.data;
                let sortedRequestDisplays: RequestOverviewRowDTO[];
                if (type === RequestFilter.INTERNAL) {
                    sortedRequestDisplays = sortInternalRequestsByStatusAndDeliveryDate(requestDisplays);
                } else {
                    sortedRequestDisplays = sortExternalRequestsByStatusAndDeliveryDate(requestDisplays);
                }
                setRequestDisplays(sortedRequestDisplays);
            })
            .catch((error) => {
                showMessage({summary: t(GeneralI18N.unknownError), severity: "error", error});
            })
            .finally(() => {
                setLoading(false);
            });

        setLoading(true);
    }, [getSearchParams, showMessage, statusField, t, type]);

    // Delayed submission approach similar to CompanyOverviewPage to avoid immediate 400 on status change
    useEffect(() => {
        setTimeout(() => {
            fetchRequestDisplays();
        }, 250);
    }, [fetchRequestDisplays, onChangeSearchParams]);

    const columns = useMemo<ColumnDef<RequestOverviewRowDTO>[]>(
        () => [
            {
                accessorKey: "customerRequestNumber",
                header: t(RequestKeyWordsI18N.customerRequestNumber),
                enableSorting: true,
                sortingFn: "alphanumeric",
                accessorFn: (row) =>
                    stringField(
                        formatCustomerRequestNumber(row) ?? undefined,
                        t(RequestKeyWordsI18N.customerRequestNumber),
                        t(GeneralI18N.notGiven)
                    ).value
            },
            {
                accessorKey: "status",
                header: t(RequestKeyWordsI18N.status),
                accessorFn: (row) =>
                    type === RequestFilter.INTERNAL
                        ? requestStatusField(
                            {
                                requestStatus: row.requestStatus,
                                statusValue: row.requestStatus && t(RequestStatusI18N[row.requestStatus]),
                                submittedQuoteCount: row.submittedQuoteCount
                            },
                            t(RequestKeyWordsI18N.status),
                            theme,
                            {
                                requestStatus: row.requestStatus,
                                statusValue: row.requestStatus && t(RequestStatusI18N[row.requestStatus])
                            }
                        )
                        : quoteStatusField(
                            {
                                quoteStatus: row.quoteStatus,
                                statusValue: row.quoteStatus && t(QuoteStatusI18N[row.quoteStatus])
                            },
                            t(RequestKeyWordsI18N.status),
                            theme,
                            {
                                quoteStatus: row.quoteStatus,
                                statusValue: row.quoteStatus && t(QuoteStatusI18N[row.quoteStatus])
                            }
                        ),
                cell: (row) =>
                    type === RequestFilter.INTERNAL ? (
                        <RequestStatusBadge
                            status={row.row.original.requestStatus}
                            count={row.row.original.submittedQuoteCount}
                            fullWidth
                        />
                    ) : (
                        <QuoteStatusBadge status={row.row.original.quoteStatus} fullWidth/>
                    )
            },
            {
                accessorKey: "deliveryAddress",
                header: t(RequestKeyWordsI18N.deliveryAddress),
                accessorFn: (row) =>
                    addressField(
                        row.deliveryAddress,
                        t(RequestKeyWordsI18N.deliveryAddress),
                        t(GeneralI18N.notGiven),
                        locale
                    ).value
            },
            {
                accessorKey: "deliveryDateRange",
                header: t(RequestKeyWordsI18N.deliveryDateLabel),
                accessorFn: (row) =>
                    dateRangeField(
                        row.deliveryDateFrom,
                        row.deliveryDateTo,
                        t(RequestKeyWordsI18N.deliveryDateRange),
                        t(GeneralI18N.notGiven),
                        dateFormat
                    ).value
            },
            {
                accessorKey: "cartonGrade",
                header: t(RequestKeyWordsI18N.variety),
                accessorFn: (row) =>
                    stringField(row.cartonGrade, t(RequestKeyWordsI18N.variety), t(GeneralI18N.notGiven)).value
            },
            {
                accessorKey: "fefcoCode",
                header: "FEFCO", // t(RequestKeyWordsI18N.fefcoCode),
                accessorFn: (row) =>
                    stringField(row.fefcoCode, t(RequestKeyWordsI18N.fefcoCode), t(GeneralI18N.notGiven)).value
            },
            {
                accessorKey: "internalDimensions",
                header: t(RequestKeyWordsI18N.internalDimensions),
                accessorFn: (row) =>
                    dimensionsField(
                        row.internalDimension,
                        t(RequestKeyWordsI18N.internalDimensions),
                        t(GeneralI18N.notGiven),
                        locale
                    ).value
            },
            {
                accessorKey: "externalPrintLabel",
                header: t(RequestKeyWordsI18N.externalPrintLabel),
                accessorFn: (row) =>
                    printField(
                        t(RequestKeyWordsI18N.externalPrintLabel),
                        {
                            printType: row.externalPrint,
                            printValue: row.externalPrint && t(PrintTypeI18N[row.externalPrint]),
                            numberOfColors: row.externalNumberOfColors
                        },
                        t(GeneralI18N.notGiven)
                    ).value
            },
            {
                accessorKey: "internalPrintLabel",
                header: t(RequestKeyWordsI18N.internalPrintLabel),
                accessorFn: (row) =>
                    printField(
                        t(RequestKeyWordsI18N.internalPrintLabel),
                        {
                            printType: row.internalPrint,
                            printValue: row.internalPrint && t(PrintTypeI18N[row.internalPrint]),
                            numberOfColors: row.internalNumberOfColors
                        },
                        t(GeneralI18N.notGiven)
                    ).value
            },
            {
                accessorKey: "quantityOptions1",
                header: "Anfragemenge",
                cell: (row) => (
                    <Table border={0}>
                        <TableBody>
                            {row.row.original.quantityOptions?.map((qo) => (
                                <TableRow key={qo.quantity}>
                                    <TableCell>
                                        {formatInteger(qo.quantity, locale)} {t(RequestKeyWordsI18N.pcsShortLabel)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )
            },
            {
                accessorKey: "quantityOptions2",
                header: t(RequestKeyWordsI18N.netPriceShortLabel),
                cell: (row) => (
                    <Table border={0}>
                        <TableBody>
                            {row.row.original.quantityOptions?.map((qo) => (
                                <TableRow key={qo.quantity}>
                                    <TableCell>{formatCurrency(qo.netPricePer1000, locale) ?? <>&nbsp;</>}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )
            },
            {
                accessorKey: "quantityOptions3",
                header: t(RequestKeyWordsI18N.totalPriceShortLabel),
                cell: (row) => (
                    <Table border={0}>
                        <TableBody>
                            {row.row.original.quantityOptions?.map((qo) => (
                                <TableRow key={qo.quantity}>
                                    <TableCell>{formatCurrency(qo.netPriceTotal, locale) ?? <>&nbsp;</>}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )
            },
            {
                id: "info",
                header: t(RequestKeyWordsI18N.infoLabel),
                meta: {
                    disableTooltip: true,
                },
                cell: ({row}) => {
                    const request = row.original;
                    return (
                        <div style={{display: "flex", gap: "0.5rem", alignItems: "center"}}>
                            {request.hasPrintImages && (
                                <Tooltip title={t(RequestKeyWordsI18N.hasPrintImagesLabel)}
                                         enterDelay={TOOLTIP_DELAY_MS} enterNextDelay={TOOLTIP_DELAY_MS}>
                                    <Printer/>
                                </Tooltip>
                            )}
                            {request.hasBluePrints && (
                                <Tooltip title={t(RequestKeyWordsI18N.hasBluePrintsLabel)}
                                         enterDelay={TOOLTIP_DELAY_MS} enterNextDelay={TOOLTIP_DELAY_MS}>
                                    <Layout/>
                                </Tooltip>
                            )}

                            {/* Chat-related icons - only shown for external requests */}
                            {type === RequestFilter.EXTERNAL && (
                                <>
                                    {/* Unanswered questions from supplier's company */}
                                    {request.hasMyUnansweredChatQuestions && (
                                        <Tooltip title={t(RequestKeyWordsI18N.hasUnansweredChatQuestionsLabel)}
                                                 enterDelay={TOOLTIP_DELAY_MS}
                                                 enterNextDelay={TOOLTIP_DELAY_MS}>
                                            <MessageCircleQuestion color={red[600]}/>
                                        </Tooltip>
                                    )}

                                    {/* Answered questions from supplier's company (if there are chat questions but none are unanswered) */}
                                    {request.hasMyChatQuestions && !request.hasMyUnansweredChatQuestions && (
                                        <Tooltip title={t(RequestKeyWordsI18N.hasAnsweredChatQuestionsLabel)}
                                                 enterDelay={TOOLTIP_DELAY_MS} enterNextDelay={TOOLTIP_DELAY_MS}>
                                            <MessageCircleWarning color={green[800]}/>
                                        </Tooltip>
                                    )}

                                    {/* For published questions */}
                                    {request.hasPublishedChatQuestions && (
                                        <Tooltip title={t(RequestKeyWordsI18N.hasPublishedChatQuestionsLabel)}
                                                 enterDelay={TOOLTIP_DELAY_MS} enterNextDelay={TOOLTIP_DELAY_MS}>
                                            <Globe color={blue[600]}/>
                                        </Tooltip>
                                    )}
                                </>
                            )}

                            {type === RequestFilter.INTERNAL && (
                                <>
                                    {/* At least one unanswered question */}
                                    {request.hasAnyUnansweredChatQuestions && (
                                        <Tooltip title={t(RequestKeyWordsI18N.hasAnyUnansweredChatQuestions)}
                                                 enterDelay={TOOLTIP_DELAY_MS} enterNextDelay={TOOLTIP_DELAY_MS}>
                                            <MessageCircleQuestion color={red[600]}/>
                                        </Tooltip>
                                    )}

                                    {/* Only answered questions */}
                                    {request.hasOnlyAnsweredChatQuestions && (
                                        <Tooltip title={t(RequestKeyWordsI18N.hasOnlyAnsweredChatQuestions)}
                                                 enterDelay={TOOLTIP_DELAY_MS} enterNextDelay={TOOLTIP_DELAY_MS}>
                                            <MessageCircleWarning color={green[800]}/>
                                        </Tooltip>
                                    )}
                                </>
                            )}
                        </div>
                    );
                }
            }
        ],
        [dateFormat, locale, t, theme, type]
    );

    return (
        <AppFrame
            title={t(
                type === RequestFilter.INTERNAL
                    ? RequestKeyWordsI18N.internalRequests
                    : RequestKeyWordsI18N.externalRequests
            )}
            loading={loading}
            padding={2}
        >
            <CardEx>
                <Grid2 container direction="column" spacing={2} padding={2}>
                    <Grid2 container direction="row" spacing={2}>
                        <Grid2 size={{sm: 12, md: 8}}>
                            <MultiSelectInputField
                                name="statuses"
                                label="Status"
                                options={options ?? []}
                                value={searchParams.statuses ?? []}
                                onChange={onChangeSearchParams}
                                renderOption={(option) =>
                                    type == RequestFilter.EXTERNAL ? (
                                        <QuoteStatusBadge status={option.value}/>
                                    ) : (
                                        <RequestStatusBadge status={option.value}/>
                                    )
                                }
                            />
                        </Grid2>
                    </Grid2>
                    <Grid2>
                        <DataTable
                            columns={columns}
                            data={requestDisplays}
                            noResultsValue="Keine Anfragen gefunden"
                            striped
                            onRowClick={(row) => {
                                navigate(target(row.id!));
                            }}
                        />
                    </Grid2>
                </Grid2>
            </CardEx>
        </AppFrame>
    );
};
