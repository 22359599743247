import * as React from "react";
import { RequestFilter, RequestStatus } from "@/api/data-contracts";
import { withAuthWrapperComponent } from "@/app/WithAuthWrapperComponent";
import { RequestOverviewPage } from "@/pages/request-overview-page/RequestOverviewPage";
import { AppPaths } from "@/app/AppPaths";
import { RequestStatusI18N } from "@/translations";
import { useTranslation } from "react-i18next";
import { requestStatusOptions } from "@/datacaches";

const initial = [
    RequestStatus.CREATED,
    RequestStatus.PUBLISHED,
    RequestStatus.CONFIRMED,
    RequestStatus.TO_BE_CONFIRMED
];

const InternalRequestOverviewPage = () => {
    const { t } = useTranslation();

    const options = requestStatusOptions.map((option) => ({
        label: option && t(RequestStatusI18N[option]),
        value: option
    }));

    return (
        <RequestOverviewPage
            type={RequestFilter.INTERNAL}
            options={options}
            initial={initial}
            target={AppPaths.INTERNAL_REQUEST_fn}
            statusField="requestStatuses"
            localStorageName="internalRequestOverviewPageStatus"
        />
    );
};

export default withAuthWrapperComponent(InternalRequestOverviewPage);
