import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";

export type DataCacheStatus = "init" | "loading" | "loaded" | "error";
export type DataCache<T = any> = {
    status: DataCacheStatus;
    data: T[];
    error?: any;
    etag?: string;
    waiting: ((loaded: DataCache<T>) => void)[];
};

const globalCache = new Map<string, DataCache>();

export const useDataCache = <T = any>(
    name: string,
    loader: (params?: any, headers?: Record<string, string>) => Promise<AxiosResponse<T[]>>
): [T[], DataCacheStatus] => {
    const [cache, setCache] = useState<T[]>([]);
    const [status, setStatus] = useState<DataCacheStatus>("init");

    useEffect(() => {
        let cacheEntry = globalCache.get(name);
        if (cacheEntry == null) {
            cacheEntry = {
                status: "init",
                data: [],
                waiting: []
            } as DataCache<T>;
            globalCache.set(name, cacheEntry);
        }

        const headers: Record<string, string> = {};

        if (cacheEntry.etag) {
            headers["If-None-Match"] = cacheEntry.etag;
        }

        const fetchData = () => {
            cacheEntry.status = "loading";
            setStatus("loading");

            loader(undefined, headers)
                .then((response) => {
                    if (response.status === 200) {
                        cacheEntry.data = response.data;
                        cacheEntry.etag = response.headers["etag"];
                        cacheEntry.status = "loaded";
                        setCache(response.data);
                        setStatus("loaded");
                    } else if (response.status === 304) {
                        cacheEntry.status = "loaded";
                        setCache(cacheEntry.data);
                        setStatus("loaded");
                    }
                    cacheEntry.waiting.forEach((w) => w(cacheEntry));
                })
                .catch((error) => {
                    cacheEntry.error = error;
                    cacheEntry.status = "error";
                    setStatus("error");
                    cacheEntry.waiting.forEach((w) => w(cacheEntry));
                });
        };

        if (cacheEntry.status === "loading") {
            cacheEntry.waiting.push((loaded) => {
                setCache(loaded.data);
                setStatus(loaded.status);
            });
        } else if (cacheEntry.status === "init" || !cacheEntry.etag) {
            fetchData();
        } else {
            setCache(cacheEntry.data);
            setStatus(cacheEntry.status);
        }
    }, [loader, name]);

    return [cache, status];
};
