import { useSearchParams } from "react-router-dom";
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SelectChangeEvent } from "@mui/material";
import { extractEvent } from "@/lib/utils";
import { NamedValue } from "@/lib/modelstate";
import { isEmpty } from "@/lib/accessor";
import { useLocalState } from "@/lib/localstate";

export const useQueryState = <T = any>({
    initialState,
    enhancer,
    localStorageName
}: {
    initialState?: T | (() => T);
    enhancer?: Record<string, Function>;
    localStorageName?: string;
}) => {
    const [searchParamsRaw, setSearchParamsRaw] = useSearchParams();
    const [searchParamTrigger, setTrigger] = useState(0);
    const [store, setStore] = useLocalState<T>(localStorageName, initialState);

    const searchParamsRawIgnoreRef = useRef(false);

    const enhancerRef = useRef(enhancer);
    enhancerRef.current = enhancer;

    const enhancerFn = useCallback((key: string, value: any) => {
        const fn = enhancerRef.current?.[key];
        return fn ? fn(value) : value;
    }, []);

    const searchParams = useMemo(() => {
        const paramsObject = Object.fromEntries(
            Array.from(searchParamsRaw.entries()).map(([key, value]) => {
                if (!isNaN(Number(value)) && value.trim() !== "") {
                    return [key, Number(value)]; // Convert to number (but keep 0)
                }
                if (value === "true" || value === "false") {
                    return [key, value === "true"];
                }
                if (value.includes(",")) {
                    return [key, value.split(",").filter(Boolean)]; // Convert to array & remove empty values
                }
                return [key, enhancerFn(key, value)];
            })
        );
        return paramsObject as T;
    }, [enhancerFn, searchParamsRaw]);

    const setSearchParams = useCallback(
        (updates: Partial<T> | ((prev: T) => T)): void => {
            setSearchParamsRaw(
                (prev) => {
                    const newParams = new URLSearchParams();
                    const updatesObject =
                        typeof updates === "function"
                            ? updates(Object.fromEntries(prev.entries()) as T)
                            : (updates as T);
                    setStore(updatesObject);
                    if (updatesObject) {
                        Object.entries(updatesObject).forEach(([key, value]) => {
                            if (value != null && value !== "" && !(Array.isArray(value) && value.length === 0)) {
                                if (Array.isArray(value)) {
                                    newParams.set(key, value.join(",")); // Convert array back to comma-separated string
                                } else {
                                    newParams.set(key, value.toString());
                                }
                            }
                        });
                    }
                    searchParamsRawIgnoreRef.current = true;
                    return newParams;
                },
                { replace: true }
            );
        },
        [setSearchParamsRaw, setStore]
    );

    const onChangeSearchParams = useCallback(
        (e: ChangeEvent | NamedValue | SelectChangeEvent<any>) => {
            const nv = extractEvent(e);
            if (nv) {
                setSearchParams((old) => ({ ...old, [nv[0]]: nv[1] }));
            }
        },
        [setSearchParams]
    );

    const searchParmasRef = useRef(searchParams);
    searchParmasRef.current = searchParams;

    const getSearchParams = useCallback(() => searchParmasRef.current, []);

    useEffect(() => {
        if (searchParamsRawIgnoreRef.current) {
            searchParamsRawIgnoreRef.current = false;
        } else {
            setTrigger((t) => t + 1);
        }
    }, [searchParamsRaw]);

    useEffect(() => {
        if (store && isEmpty(searchParmasRef.current)) {
            setSearchParams(store);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { searchParams, onChangeSearchParams, setSearchParams, getSearchParams, searchParamTrigger };
};
