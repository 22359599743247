import MenuIcon from "@mui/icons-material/Menu";
import {Button, Divider, Grid2, IconButton, Menu, MenuItem} from "@mui/material";
import {MouseEvent, ReactElement, ReactNode, useState} from "react";
import {useNavigate} from "react-router";

export type MenuButtonItem = {
    label?: ReactNode;
    icon?: ReactNode;
    hidden?: boolean;
    content: (() => void) | ReactElement | "divider" | "header" | "link";
    to?: string;
    useRouter?: boolean;
    "data-cy"?: string;
};

export type MenuButtonProps = {
    text?: string;
    title?: string;
    icon?: ReactNode;
    size?: "small" | "large" | "medium";
    disabled?: boolean;
    menu: MenuButtonItem[] | ((onClose: () => void) => MenuButtonItem[]);
    "data-cy"?: string;
};

export const MenuButton = ({text, title, icon, size, disabled, menu, "data-cy": dataCy}: MenuButtonProps) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const [computed, setComputed] = useState<MenuButtonItem[]>([]);

    const onOpen = (e: MouseEvent<HTMLButtonElement>) => {
        setComputed(((typeof menu === "function" ? menu(onClose) : menu) ?? []).filter((i) => i.hidden !== true));
        setAnchorEl(e.currentTarget);
    };

    const onClose = () => {
        setAnchorEl(null);
    };

    const onMenuItem = (item: MenuButtonItem) => () => {
        setAnchorEl(null);
        if (typeof item.content === "function") {
            item.content();
        } else if (item.content === "link" && item.to) {
            if (item.useRouter) {
                navigate(item.to);
            }
        }
    };

    const button = text ? (
        <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            size={size}
            onClick={onOpen}
            disabled={disabled}
            title={title}
            startIcon={icon}
            data-cy={dataCy}
        >
            {text}
        </Button>
    ) : (
        <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            size={size}
            onClick={onOpen}
            disabled={disabled}
            title={title}
            data-cy={dataCy}
        >
            {icon || <MenuIcon/>}
        </IconButton>
    );

    return (
        <>
            {button}
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={onClose} disablePortal>
                {computed
                    .filter((item) => item.content != "link" || item.to != null)
                    .map(
                        (item, idx) =>
                            (item.content === "divider" && <Divider key={idx} orientation="horizontal"/>) ||
                            (item.content === "header" && (
                                <MenuItem key={idx} divider dense disabled>
                                    <Grid2 container spacing={item.icon ? 1 : 0}>
                                        <Grid2>{item.icon}</Grid2>
                                        <Grid2>{item.label}</Grid2>
                                    </Grid2>
                                </MenuItem>
                            )) ||
                            (typeof item.content === "function" && (
                                <MenuItem key={idx} dense onClick={onMenuItem(item)}>
                                    <Grid2 container spacing={item.icon ? 1 : 0}>
                                        <Grid2>{item.icon}</Grid2>
                                        <Grid2>{item.label}</Grid2>
                                    </Grid2>
                                </MenuItem>
                            )) ||
                            (item.content === "link" && (
                                <MenuItem
                                    key={idx}
                                    dense
                                    onClick={onMenuItem(item)}
                                    component={item.useRouter ? "div" : "a"}
                                    href={!item.useRouter ? item.to : undefined}
                                    data-cy={item["data-cy"]}
                                >
                                    <Grid2 container spacing={item.icon ? 1 : 0}>
                                        <Grid2>{item.icon}</Grid2>
                                        <Grid2>{item.label}</Grid2>
                                    </Grid2>
                                </MenuItem>
                            )) ||
                            (item.content && (
                                <div key={idx} style={{padding: "2px 16px"}} data-cy={item["data-cy"]}>
                                    {item.content as ReactNode}
                                </div>
                            ))
                    )}
            </Menu>
        </>
    );
};
