import React, { JSX, ReactElement, RefObject, useCallback, useImperativeHandle, useRef, useState } from "react";

type Api = {
    show: (dialog: DialogFunction) => void;
    open: boolean;
};

export type DialogFunction = (open: boolean, close: (e?: any, reason?: string) => void) => ReactElement;

export type DialogNodeProps = {
    api: RefObject<Api | undefined>;
};

export const DialogNode = ({ api }: DialogNodeProps) => {
    const [open, setOpen] = useState(false);
    const [active, setActive] = useState(false);
    const [dialogFnc, setDialogFnc] = useState<DialogFunction | null>(null);

    const closeHandler = useCallback((e?: any, reason?: string) => {
        if (reason === "backdropClick") {
            return;
        }
        setOpen(false);
        setTimeout(() => {
            setActive(false);
            setTimeout(() => {
                setDialogFnc(null);
            }, 250);
        }, 250);
    }, []);

    const show = useCallback((dialog: DialogFunction) => {
        setDialogFnc(() => dialog);
        setActive(true);
        setOpen(true);
    }, []);

    useImperativeHandle(
        api,
        () => ({
            show,
            open
        }),
        [show, open]
    );

    return active && dialogFnc && dialogFnc(open, closeHandler);
};

export const useDialogController = (): [
    Anker: () => JSX.Element,
    show: (arg: (open: boolean, close: () => void) => ReactElement) => void
] => {
    const api = useRef<Api>(undefined);

    const show = useCallback((fnc: DialogFunction) => {
        api.current?.show(fnc);
    }, []);

    const Node = useCallback(() => <DialogNode api={api} />, []);

    return [Node, show];
};
