let oid = 0;

export const oidOf = (o: any): number | null => {
    if (o == null) {
        return null;
    }
    if (o.__oid == null) {
        Object.defineProperty(o, "__oid", {
            // eslint-disable-next-line no-plusplus
            value: ++oid,
            enumerable: false,
            writable: false
        });
    }
    return o.__oid;
};

export const aidOf = (o: any): number | null => {
    if (o == null) {
        return null;
    }
    if (o.__aid == null) {
        Object.defineProperty(o, "__aid", {
            value: oidOf(o),
            enumerable: true,
            writable: false
        });
    }
    return o.__aid;
};

/** remove aid */
export const aidClear = (o: any) => {
    if (o != null && o.__aid != null) {
        delete o.__aid;
    }
    return o;
};
