import React, { useState } from "react";
import { useModelState } from "@/lib/modelstate";
import { CircularProgress, Grid2 } from "@mui/material";
import StringInputField from "@/lib/components/inputfields/string-input-field";
import { ButtonEx } from "@/lib/components/buttons/ButtonEx";
import { authenticationApi } from "@/boxhub-api";
import * as yup from "yup";
import { EMAIL_REGEX } from "@/regex";
import { useTranslation } from "react-i18next";
import { FinishedPage, FinishedPageProps } from "@/common/FinishedPage";
import { AppFrame } from "@/app/AppFrame";
import { CardEx } from "@/lib/components/CardEx";
import { UserFormI18N } from "@/translations";
import { Constants } from "@/api/constants";
import { AxiosError } from "axios";

export const PasswordResetRequestPage = () => {
    const { t } = useTranslation();
    const [finishedInfo, setFinishedInfo] = useState<FinishedPageProps | null>(null);
    const [skipUnsavedCheck, setSkipUnsavedCheck] = useState(false);

    const schema = yup.object().shape({
        email: yup
            .string()
            .required(t(UserFormI18N.requiredEmailError))
            .min(Constants.EMAIL_LENGTH_MIN, t(UserFormI18N.minEmailError, { minInteger: Constants.EMAIL_LENGTH_MIN }))
            .max(Constants.EMAIL_LENGTH_MAX, t(UserFormI18N.maxEmailError, { maxInteger: Constants.EMAIL_LENGTH_MAX }))
            .matches(EMAIL_REGEX, t(UserFormI18N.invalidEmailError))
    });

    const onLoadError = (error: AxiosError<any>) => {
        setFinishedInfo({
            success: false,
            title: t(UserFormI18N.requestPasswordResetFailedTitle),
            subTitle: error.response?.data?.error?.message ?? t(UserFormI18N.requestPasswordResetFailedSubtitle)
        });
        return true;
    };

    const { model, status, errors, onChange, save, isDirty } = useModelState<{ email: string }>({
        initialState: { email: "" },
        schema,
        saver: (state) => authenticationApi.generateUserPasswordReset({ email: state.email }),
        onLoadError
    });

    if (finishedInfo) {
        return <FinishedPage {...finishedInfo} />;
    }

    return (
        <AppFrame title={t(UserFormI18N.resetPasswordLabel)} modelState={status} isDirty={!skipUnsavedCheck && isDirty}>
            <Grid2 container spacing={2} padding={2}>
                <Grid2 size={{ xs: 12 }}>
                    <CardEx title={t(UserFormI18N.insertEmailTitle)}>
                        <Grid2 container direction="column" spacing={3}>
                            <Grid2 container direction="row" spacing={2} width="100%">
                                <Grid2 size={{ sm: 6, md: 4, lg: 3 }}>
                                    <StringInputField
                                        label={t(UserFormI18N.emailLabel)}
                                        name="email"
                                        value={model.email}
                                        onChange={onChange}
                                        error={errors.email}
                                    />
                                </Grid2>
                            </Grid2>
                            <Grid2 container direction="row" spacing={2} width="100%">
                                <Grid2 size={{ sm: 6, md: 4, lg: 3 }}>
                                    <ButtonEx
                                        label={
                                            status === "saving" ? (
                                                <CircularProgress size={24} />
                                            ) : (
                                                t(UserFormI18N.requestResetPasswordButton)
                                            )
                                        }
                                        type="submit"
                                        fullWidth
                                        onClick={() => {
                                            setSkipUnsavedCheck(true);
                                            save()?.then(() => {
                                                setFinishedInfo({
                                                    success: true,
                                                    title: t(UserFormI18N.requestPasswordResetSuccessTitle),
                                                    subTitle: t(UserFormI18N.requestPasswordResetSuccessSubtitle)
                                                });
                                            });
                                        }}
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    />
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    </CardEx>
                </Grid2>
            </Grid2>
        </AppFrame>
    );
};

export default PasswordResetRequestPage;
