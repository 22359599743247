import { FieldError, formatCurrency, getCurrencySymbol } from "@/lib/utils";
import React from "react";
import { NamedValueHandler } from "@/lib/modelstate";
import NumberInputField from "@/lib/components/inputfields/number-input-field";
import { getI18n } from "react-i18next";

/**
 * Props for the CurrencyInputField component.
 *
 * @property {string} name - The name of the input field.
 * @property {string} label - The label for the input field.
 * @property {NamedValueHandler} [onChange] - The handler for change events.
 * @property {number | null} [value] - The current value of the input field.
 * @property {FieldError} [error] - The error object for the input field.
 * @property {string} [locale] - The locale for formatting. Deprecated.
 * @property {string} [suffix] - The suffix to display after the value.
 * @property {React.ReactNode} [endAdornment] - The end adornment for the input field.
 * @property {boolean} [disabled] - If true, disables the input field.
 * @property {number} [min] - The minimum value allowed.
 * @property {number} [max] - The maximum value allowed.
 */
interface CurrencyInputFieldProps {
    name: string;
    label: string;
    onChange?: NamedValueHandler;
    value?: number | null;
    error?: FieldError;
    suffix?: string;
    endAdornment?: React.ReactNode;
    disabled?: boolean;
    min?: number;
    max?: number;
    helperText?: string | number;
}

/**
 * CurrencyInputField component.
 *
 * @param {CurrencyInputFieldProps} props - The props for the component.
 * @returns {React.ReactElement} The rendered component.
 */
const CurrencyInputField: React.FC<CurrencyInputFieldProps> = ({
    name,
    label,
    value,
    onChange,
    error,
    suffix,
    endAdornment,
    disabled,
    min,
    max,
    helperText
}: CurrencyInputFieldProps): React.ReactElement => {
    const locale = getI18n().language;
    const format = (text: string | number | undefined) => {
        if (typeof text === "number") {
            return formatCurrency(text, locale) ?? undefined;
        }
        return text;
    };
    return (
        <NumberInputField
            label={label}
            name={name}
            min={min}
            max={max}
            value={value}
            onChange={onChange}
            decimalScale={2}
            fixedDecimalScale
            allowNegative={false}
            suffix={suffix ?? ` ${getCurrencySymbol(locale)}`}
            error={error}
            helperText={format(helperText)}
            disabled={disabled}
            endAdornment={endAdornment}
        />
    );
};

export default CurrencyInputField;
