import { formatInteger } from "@/lib/utils";
import { DimensionDTO, OrderDataDTO } from "@/api/data-contracts";
import { LabelValue } from "@/lib/components/LabelValueList";

/**
 * Generates a field object for internal dimensions with a label, value, and optional tooltip.
 *
 * @param {OrderDataDTO | null | undefined} data - The data object containing internal dimensions.
 * @param {string} label - The label for the field.
 * @param {string} [defaultValue=""] - The default value to use if any dimension is not available.
 * @param {string} [locale] - The locale to use for formatting the dimensions.
 * @param {string} [tooltipKey] - The key for the tooltip text.
 * @returns {LabelValue} The field object with label, value, and optional tooltipKey properties.
 */
export const internalDimensionsField = (
    data: OrderDataDTO | null | undefined,
    label: string,
    defaultValue: string = "",
    locale?: string,
    tooltipKey?: string
): LabelValue => {
    const length = data?.internalDimensionLength;
    const width = data?.internalDimensionWidth;
    const height = data?.internalDimensionHeight;

    const allValuesUndefined = !length && !width && !height;

    return {
        label,
        value: allValuesUndefined
            ? defaultValue
            : `${length !== undefined && length !== null ? formatInteger(length, locale) : defaultValue} x ${
                  width !== undefined && width !== null ? formatInteger(width, locale) : defaultValue
              } x ${height !== undefined && height !== null ? formatInteger(height, locale) : defaultValue} mm`,
        tooltipKey
    };
};

export const dimensionsField = (
    data: DimensionDTO | null | undefined,
    label: string,
    defaultValue: string = "",
    locale?: string,
    tooltipKey?: string
): LabelValue => {
    const length = data?.length;
    const width = data?.width;
    const height = data?.height;

    const allValuesUndefined = !length && !width && !height;

    return {
        label,
        value: allValuesUndefined
            ? defaultValue
            : `${length !== undefined && length !== null ? formatInteger(length, locale) : defaultValue} x ${
                  width !== undefined && width !== null ? formatInteger(width, locale) : defaultValue
              } x ${height !== undefined && height !== null ? formatInteger(height, locale) : defaultValue} mm`,
        tooltipKey
    };
};
