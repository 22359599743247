import { TextField, TextFieldProps } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { autoCompleteOff, FieldError, formatNumber, getSeparators } from "@/lib/utils";
import React, { useMemo } from "react";
import { NamedValueHandler } from "@/lib/modelstate";
import { getI18n } from "react-i18next";
import { NumberFormatValues } from "react-number-format/types/types";

/**
 * Props for the NumberInputField component.
 *
 * @property {string} name - The name of the input field.
 * @property {string} label - The label for the input field.
 * @property {number | null} [value] - The current value of the input field.
 * @property {NamedValueHandler} [onChange] - The handler for change events.
 * @property {FieldError} [error] - The error object for the input field.
 * @property {string} [locale] - The locale for formatting. Deprecated.
 * @property {string} [suffix] - The suffix to display after the value.
 * @property {number} [decimalScale] - The number of decimal places to display.
 * @property {React.ReactNode} [endAdornment] - The end adornment for the input field.
 * @property {number} [min] - The minimum value allowed.
 * @property {number} [max] - The maximum value allowed.
 * @property {boolean} [disabled] - If true, disables the input field.
 */
interface NumberInputFieldProps {
    name: string;
    label: string;
    value?: number | null;
    onChange?: NamedValueHandler;
    error?: FieldError;
    /** @deprecated */
    locale?: string;
    suffix?: string;
    decimalScale?: number;
    endAdornment?: React.ReactNode;
    min?: number;
    max?: number;
    disabled?: boolean;
    fixedDecimalScale?: boolean;
    allowNegative?: boolean;
    variant?: TextFieldProps["variant"];
    helperText?: string | number;
}

/**
 * NumberInputField component.
 *
 * @param {NumberInputFieldProps} props - The props for the component.
 * @returns {React.ReactElement} The rendered component.
 */
const NumberInputField: React.FC<NumberInputFieldProps> = ({
    name,
    label,
    value,
    onChange,
    error,
    locale,
    suffix,
    decimalScale = 0,
    endAdornment,
    min,
    max,
    disabled,
    fixedDecimalScale = true,
    allowNegative = false,
    variant = "outlined",
    helperText
}: NumberInputFieldProps): React.ReactElement => {
    locale = getI18n().language;
    const separators = getSeparators(locale);
    const isAllowed = useMemo(() => {
        const minValueStr = min?.toString() ?? "";
        const maxValueStr = max?.toString() ?? "";

        const [, minDecimalPart] = minValueStr.split(".");
        const [maxIntegerPart, maxDecimalPart] = maxValueStr.split(".");

        const maxDigits = maxIntegerPart.length;
        const maxDecimals = Math.max(minDecimalPart?.length || 0, maxDecimalPart?.length || 0, decimalScale);

        return (values: NumberFormatValues) => {
            const { floatValue, formattedValue } = values;
            if (floatValue == null) {
                return true; // Allow empty input
            }
            const cleanedValue = formattedValue.replace(/[^\d.,-]/g, "").replace(/^,/, "");
            const [integerPart, decimalPart] = cleanedValue.split(separators.decimalSeparator ?? ".");

            if (integerPart.replace(/\D/g, "").length > maxDigits) {
                return false;
            }
            return !(decimalPart && decimalPart.length > maxDecimals);
        };
    }, [min, max, decimalScale, separators.decimalSeparator]);

    const format = (text: string | number | undefined) => {
        if (typeof text === "number") {
            return formatNumber(text, decimalScale) ?? undefined;
        }
        return text;
    };

    return (
        <NumericFormat
            // @ts-ignore
            customInput={TextField}
            name={name}
            value={value ?? ""}
            isAllowed={isAllowed}
            onValueChange={(values) => {
                const { floatValue } = values;
                if (onChange) {
                    onChange({
                        name,
                        value: floatValue
                    });
                }
            }}
            label={label}
            fullWidth
            min={min}
            max={max}
            thousandSeparator={separators.thousandSeparator}
            decimalSeparator={separators.decimalSeparator}
            decimalScale={decimalScale}
            fixedDecimalScale={fixedDecimalScale}
            allowNegative={allowNegative}
            suffix={suffix}
            error={!!error}
            helperText={error?.message ?? format(helperText)}
            size="small"
            disabled={disabled}
            variant={variant}
            slotProps={{
                input: {
                    startAdornment: endAdornment,
                    sx: { paddingLeft: 0, width: "100%" }
                },
                htmlInput: {
                    style: { textAlign: "right" },
                    autoComplete: autoCompleteOff
                },
                inputLabel: {
                    shrink: true
                }
            }}
        />
    );
};

export default NumberInputField;
