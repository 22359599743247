import React, { MouseEvent, ReactNode } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { ButtonEx } from "@/lib/components/buttons/ButtonEx";

/**
 * Props for the ConfirmationDialog component.
 *
 * @property {boolean} open - If true, the dialog is open.
 * @property {() => void} onClose - The function to call when the dialog is closed.
 * @property {(e?: MouseEvent<HTMLButtonElement>) => void | Promise<void>} onConfirm - The function to call when the confirm button is clicked.
 * @property {ReactNode} title - The title of the dialog.
 * @property {ReactNode} message - The message to display in the dialog.
 * @property {string} confirmButtonText - The text to display on the confirm button.
 * @property {string} cancelButtonText - The text to display on the cancel button.
 * @property {"xs" | "sm" | "md" | "lg" | "xl"} [maxWidth] - The maximum width of the dialog.
 * @property {boolean} [fullWidth] - If true, the dialog will take up the full width of its container.
 * @property {string} [dataCy] - The data-cy attribute for the dialog.
 */
interface ConfirmationDialogProps {
    open: boolean;
    onClose: () => void;
    onConfirm: (e?: MouseEvent<HTMLButtonElement>) => void | Promise<void>;
    title: ReactNode;
    message: ReactNode;
    confirmButtonText: string;
    cancelButtonText?: string;
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
    fullWidth?: boolean;
    dataCy?: string;
}

/**
 * ConfirmationDialog component.
 *
 * @param {ConfirmationDialogProps} props - The props for the component.
 * @returns {React.ReactElement}  The rendered ConfirmationDialog component.
 */
const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
    open,
    onClose,
    onConfirm,
    title,
    message,
    confirmButtonText,
    cancelButtonText,
    maxWidth = "sm",
    fullWidth = true,
    dataCy
}: ConfirmationDialogProps): React.ReactElement => (
    <Dialog
        data-cy={dataCy ?? "confirmation-dialog"}
        open={open}
        onClose={onClose}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
    >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "flex-end", padding: 2, gap: 2 }}>
            {cancelButtonText && (
                <ButtonEx
                    label={cancelButtonText}
                    data-cy="cancel-button"
                    onClick={onClose}
                    variant="outlined"
                    color="secondary"
                />
            )}
            <ButtonEx
                label={confirmButtonText}
                data-cy="confirm-button"
                onClick={onConfirm}
                variant="contained"
                shouldFocus
            />
        </DialogActions>
    </Dialog>
);

export default ConfirmationDialog;
