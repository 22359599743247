import React from "react";
import { useAppTheme } from "@/app/AppThemeContext";
import { Box } from "@mui/material";

/**
 * Props for the PageLoadingSpinner component.
 *
 * @property {boolean} [fullScreen=true] - If true, the spinner will cover the entire screen.
 */
interface LoadingSpinnerProps {
    fullScreen?: boolean;
}

/**
 * PageLoadingSpinner component.
 *
 * @param {LoadingSpinnerProps} props - The props for the component.
 * @returns {React.ReactElement} The rendered PageLoadingSpinner component.
 */
const PageLoadingSpinner: React.FC<LoadingSpinnerProps> = ({
    fullScreen = true
}: LoadingSpinnerProps): React.ReactElement => {
    const { mode } = useAppTheme();

    const boxStyles = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...(fullScreen
            ? {
                  height: "100vh",
                  width: "100vw",
                  position: "fixed",
                  top: 0,
                  left: 0,
                  backgroundColor: mode === "dark" ? "#000" : "#f5f5f5",
                  zIndex: 9999
              }
            : {
                  height: "100%",
                  width: "100%"
              })
    };

    const glass = {
        backgroundColor: mode === "dark" ? "#000" : "#f5f5f5",
        borderRadius: "10px",
        zIndex: 9999,
        position: "absolute",
        top: 0,
        left: 0,
        opacity: 0.5
    };

    return (
        <Box sx={glass}>
            <Box sx={boxStyles}>
                <Box
                    sx={{
                        width: "50px",
                        height: "50px",
                        border: "6px solid #ccc",
                        borderTop: "6px solid #8B008B",
                        borderRadius: "50%",
                        animation: "spin 1s linear infinite"
                    }}
                >
                    <style>
                        {`
                        @keyframes spin {
                            0% { transform: rotate(0deg); }
                            100% { transform: rotate(360deg); }
                        }
                    `}
                    </style>
                </Box>
            </Box>
        </Box>
    );
};

export default PageLoadingSpinner;
