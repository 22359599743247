import {Dialog, DialogActions, DialogContent, DialogTitle, Grid2 as Grid, Grid2} from "@mui/material";
import StringInputField from "@/lib/components/inputfields/string-input-field";
import {useModelState, ValidatorOptions} from "@/lib/modelstate";
import {ButtonEx} from "@/lib/components/buttons/ButtonEx";
import * as React from "react";
import {useMemo} from "react";
import {terminationInitiatorOptions} from "@/datacaches";
import * as yup from "yup";
import {useTranslation} from "react-i18next";
import {
    CompanyFormI18N,
    GeneralI18N,
    SalutationsI18N,
    TerminationI18N,
    TerminationInitiatorI18N,
    UserFormI18N
} from "@/translations";
import {convertDate, ErrorsShape} from "@/lib/utils";
import DateInputField from "@/lib/components/inputfields/date-input-field";
import RadioButtonInputField from "@/lib/components/inputfields/radio-button-field";
import ConfirmationDialog from "@/lib/components/ConfirmationDialog";
import {CompanyDTO, CompanyTerminationDTO} from "@/api/data-contracts";
import {Constants} from "@/api/constants";
import {companyApi} from "@/boxhub-api";
import {useDialogController} from "@/lib/dialog-controller";
import {transformToErrorShape} from "@/lib/accessor";

export type TerminationCompanyDialogProps = {
    open: boolean;
    close: () => void;
    onSuccess?: () => void;
    company?: CompanyDTO;
};

type TerminationCompanyValidation = Omit<CompanyTerminationDTO, "requestExecDate"> & {
    requestExecDate: any;
} & Pick<CompanyTerminationDTO, "requestReason" | "requestSource">;

const useSchema = () => {
    const {t} = useTranslation();
    return useMemo(
        () => {
            const schema = yup.object().shape({
                requestExecDate: yup
                    .date()
                    .required(t(TerminationI18N.requiredTerminationDateError))
                    .transform(convertDate)
                    .typeError(t(TerminationI18N.invalidTerminationDateError)),

                requestReason: yup
                    .string()
                    .required(t(TerminationI18N.requiredTerminationReasonError))
                    .min(Constants.REASON_LENGTH_MIN, t(TerminationI18N.minTerminationReasonError, {minInteger: Constants.REASON_LENGTH_MIN}))
                    .max(Constants.REASON_LENGTH_MAX, t(TerminationI18N.maxTerminationReasonError, {maxInteger: Constants.REASON_LENGTH_MAX})),

                requestSource: yup
                    .string()
                    .required(t(TerminationI18N.requiredTerminationInitiatorError))
                    .oneOf(terminationInitiatorOptions, t(TerminationI18N.invalidTerminationInitiatorError)),
            });
            const typed = schema as yup.ObjectSchema<TerminationCompanyValidation>;
            return typed as yup.ObjectSchema<CompanyTerminationDTO>;
        }, [t]);
};

export const TerminationCompanyDialog = ({open, close, onSuccess, company}: TerminationCompanyDialogProps) => {
    const {t} = useTranslation();
    const [DialogNode, showDialog] = useDialogController();
    const terminationInitiatorRadioButtonOptions = terminationInitiatorOptions.map((option) => ({
        label: option && t(TerminationInitiatorI18N[option]),
        value: option
    }));
    const schema = useSchema();

    const validator = async (model: CompanyTerminationDTO, options: ValidatorOptions) => {
        if (options?.action?.type === "--change") {
            const name = options.action?.data[0];
            if (name === "requestExecDate") {
                const response = await companyApi.validateTerminateDates({
                    requestExecDate: model.requestExecDate,
                });
                return transformToErrorShape(response.data?.error?.errors);
            }
        }
        return {} as ErrorsShape<CompanyTerminationDTO>;
    };

    const {
        model,
        errors,
        onChange,
        save,
        validate
    } = useModelState<CompanyTerminationDTO, number>({
        initialState: {} as CompanyTerminationDTO,
        schema,
        id: company?.id,
        loader: companyApi.initiateCompanyTermination,
        updater: companyApi.terminateCompany,
        validator
    });

    const handleConfirmTermination = () => {
        save()?.then(() => {
            close();
            onSuccess && onSuccess();
        });
    };

    const salutationOfCEO = useMemo(() => {
        const salutationOfCEO = company?.users?.find((user) => user.ceo)?.salutation;
        if (salutationOfCEO) {
            return t(SalutationsI18N[salutationOfCEO]);
        } else {
            return "";
        }
    }, [t, company]);

    const ceoFirstName = useMemo(() => {
        return company?.users?.find((user) => user.ceo)?.firstName ?? "";
    }, [t, company]);

    const ceoLastName = useMemo(() => {
        return company?.users?.find((user) => user.ceo)?.lastName ?? "";
    }, [t, company]);

    return (
        <>
            <Dialog open={open} onClose={close} maxWidth="sm" fullWidth>
                <DialogTitle>{t(TerminationI18N.companyTerminationDialogTitle, {companyName: company?.name})}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} padding={2} width="100%">
                        <Grid width="100%">
                            <StringInputField
                                value={company?.name}
                                label={t(CompanyFormI18N.companyNameLabel)}
                                disabled
                            />
                        </Grid>
                        <Grid width="100%">
                            <StringInputField
                                label={t(CompanyFormI18N.streetLabel)}
                                value={company?.companyAddress?.street}
                                disabled
                            />
                        </Grid>
                        <Grid container spacing={2} width="100%">
                            <Grid size={{xs: 6}}>
                                <StringInputField
                                    label={t(CompanyFormI18N.postalCodeLabel)}
                                    value={company?.companyAddress?.zip}
                                    disabled
                                />
                            </Grid>
                            <Grid size={{xs: 6}}>
                                <StringInputField
                                    label={t(CompanyFormI18N.cityLabel)}
                                    value={company?.companyAddress?.city}
                                    disabled
                                />
                            </Grid>
                        </Grid>
                        <Grid width="100%">
                            <StringInputField
                                label={t(CompanyFormI18N.countryLabel)}
                                value={company?.companyAddress?.country}
                                disabled
                            />
                        </Grid>
                        <Grid container spacing={2} width="100%">
                            <Grid size={{xs: 2}}>
                                <StringInputField
                                    label={t(UserFormI18N.salutationLabel)}
                                    value={salutationOfCEO}
                                    disabled
                                />
                            </Grid>
                            <Grid size={{xs: 5}}>
                                <StringInputField
                                    label={t(UserFormI18N.firstNameLabel)}
                                    value={ceoFirstName}
                                    disabled
                                />
                            </Grid>
                            <Grid size={{xs: 5}}>
                                <StringInputField
                                    label={t(UserFormI18N.lastNameLabel)}
                                    value={ceoLastName}
                                    disabled
                                />
                            </Grid>
                        </Grid>
                        <Grid width="100%">
                            <DateInputField
                                name="requestExecDate"
                                label={t(TerminationI18N.terminationDateLabel)}
                                value={model?.requestExecDate}
                                error={errors?.requestExecDate}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid width="100%">
                            <StringInputField
                                name="requestReason"
                                label={t(TerminationI18N.terminationReasonLabel)}
                                value={model?.requestReason}
                                error={errors?.requestReason}
                                onChange={onChange}
                                maxChars={Constants.REASON_LENGTH_MAX}
                                multiline
                            />
                        </Grid>
                        <Grid width="100%">
                            <RadioButtonInputField
                                name="requestSource"
                                options={terminationInitiatorRadioButtonOptions}
                                label={t(TerminationI18N.terminationInitiatorLabel)}
                                value={model?.requestSource}
                                error={errors?.requestSource}
                                onChange={onChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid2 container direction="row" flexWrap="nowrap" spacing={2} padding={1}>
                        <Grid2>
                            <ButtonEx
                                label={t(GeneralI18N.cancelButton)}
                                color="secondary"
                                variant="contained"
                                onClick={close}
                            />
                        </Grid2>
                        <Grid2>
                            <ButtonEx
                                label={t(TerminationI18N.terminateCompanyButton)}
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                    if (validate()) {
                                        showDialog((open, close) => (
                                            <ConfirmationDialog
                                                open={open}
                                                onClose={close}
                                                onConfirm={handleConfirmTermination}
                                                title={t(TerminationI18N.companyTerminationConfirmationDialogTitle, {companyName: company?.name})}
                                                message={t(TerminationI18N.companyTerminationConfirmationDialogMessage, {companyName: company?.name})}
                                                confirmButtonText={t(GeneralI18N.yes)}
                                                cancelButtonText={t(GeneralI18N.no)}
                                            />
                                        ));
                                    }
                                }}
                            />
                        </Grid2>
                    </Grid2>
                </DialogActions>
            </Dialog>
            <DialogNode/>
        </>
    );
};
