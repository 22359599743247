import { Dialog, DialogActions, DialogContent, DialogTitle, Grid2 as Grid, Grid2 } from "@mui/material";
import StringInputField from "@/lib/components/inputfields/string-input-field";
import { useModelState } from "@/lib/modelstate";
import { ButtonEx } from "@/lib/components/buttons/ButtonEx";
import { authenticationApi } from "@/boxhub-api";
import { Language, UserInvitationDTO } from "@/api/data-contracts";
import * as React from "react";
import { useMemo } from "react";
import SelectInputField from "@/lib/components/inputfields/select-input-field";
import { languageOptions, salutationOptions } from "@/datacaches";
import * as yup from "yup";
import { EMAIL_REGEX, PHONE_NUMBER_REGEX } from "@/regex";
import { Constants } from "@/api/constants";
import { useTranslation } from "react-i18next";
import { GeneralI18N, LanguagesI18N, SalutationsI18N, UserFormI18N } from "@/translations";

export type UserInvitationDialogProps = {
    open: boolean;
    close: () => void;
    onSuccess?: () => void;
};

const useSchema = () => {
    const { t } = useTranslation();
    return useMemo(
        () =>
            yup.object().shape({
                email: yup
                    .string()
                    .required(t(UserFormI18N.requiredEmailError))
                    .min(
                        Constants.EMAIL_LENGTH_MIN,
                        t(UserFormI18N.minEmailError, { minInteger: Constants.EMAIL_LENGTH_MIN })
                    )
                    .max(
                        Constants.EMAIL_LENGTH_MAX,
                        t(UserFormI18N.maxEmailError, { maxInteger: Constants.EMAIL_LENGTH_MAX })
                    )
                    .matches(EMAIL_REGEX, t(UserFormI18N.invalidEmailError)),

                salutation: yup
                    .string()
                    .required(t(UserFormI18N.requiredSalutationError))
                    .oneOf(salutationOptions, t(UserFormI18N.invalidSalutationError)),

                firstName: yup
                    .string()
                    .required(t(UserFormI18N.requiredFirstNameError))
                    .min(
                        Constants.FIRSTNAME_LENGTH_MIN,
                        t(UserFormI18N.minFirstNameError, { minInteger: Constants.FIRSTNAME_LENGTH_MIN })
                    )
                    .max(
                        Constants.FIRSTNAME_LENGTH_MAX,
                        t(UserFormI18N.maxFirstNameError, { maxInteger: Constants.FIRSTNAME_LENGTH_MAX })
                    ),

                lastName: yup
                    .string()
                    .required(t(UserFormI18N.requiredLastNameError))
                    .min(
                        Constants.LASTNAME_LENGTH_MIN,
                        t(UserFormI18N.minLastNameError, { minInteger: Constants.LASTNAME_LENGTH_MIN })
                    )
                    .max(
                        Constants.LASTNAME_LENGTH_MAX,
                        t(UserFormI18N.maxLastNameError, { maxInteger: Constants.LASTNAME_LENGTH_MAX })
                    ),

                phone: yup
                    .string()
                    .required(t(UserFormI18N.requiredPhoneError))
                    .min(
                        Constants.PHONE_NUMBER_LENGTH_MIN,
                        t(UserFormI18N.minPhoneError, { minInteger: Constants.PHONE_NUMBER_LENGTH_MIN })
                    )
                    .max(
                        Constants.PHONE_NUMBER_LENGTH_MAX,
                        t(UserFormI18N.maxPhoneError, { maxInteger: Constants.PHONE_NUMBER_LENGTH_MAX })
                    )
                    .matches(PHONE_NUMBER_REGEX, t(UserFormI18N.invalidPhoneError)),

                fax: yup
                    .string()
                    .optional()
                    .nullable()
                    .min(
                        Constants.PHONE_NUMBER_LENGTH_MIN,
                        t(UserFormI18N.minFaxError, { minInteger: Constants.PHONE_NUMBER_LENGTH_MIN })
                    )
                    .max(
                        Constants.PHONE_NUMBER_LENGTH_MAX,
                        t(UserFormI18N.maxFaxError, { maxInteger: Constants.PHONE_NUMBER_LENGTH_MAX })
                    )
                    .matches(PHONE_NUMBER_REGEX, t(UserFormI18N.invalidFaxError)),

                language: yup
                    .string()
                    .required(t(UserFormI18N.requiredLanguageError))
                    .oneOf(languageOptions, t(UserFormI18N.requiredLanguageError))
            }) as yup.ObjectSchema<UserInvitationDTO>,
        [t]
    );
};

export const UserInvitationDialog = ({ open, close, onSuccess }: UserInvitationDialogProps) => {
    const { t } = useTranslation();
    const salutationSelectOptions = salutationOptions.map((option) => ({
        label: option && t(SalutationsI18N[option]),
        value: option
    }));
    const languageSelectOptions = languageOptions.map((option) => ({
        label: option && t(LanguagesI18N[option]),
        value: option
    }));
    const schema = useSchema();
    const { model, errors, onChange, save } = useModelState<UserInvitationDTO>({
        initialState: { language: Language.GERMAN } as UserInvitationDTO,
        schema,
        saver: authenticationApi.generateUserSignUpInvitation
    });

    return (
        <Dialog open={open} onClose={close} maxWidth="sm" fullWidth>
            <DialogTitle>{t(UserFormI18N.inviteEmployeeTitle)}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} padding={2} width="100%">
                    <Grid width="100%">
                        <StringInputField
                            name="email"
                            value={model?.email}
                            label={t(UserFormI18N.emailLabel)}
                            error={errors?.email}
                            maxChars={Constants.EMAIL_LENGTH_MAX}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid width="100%">
                        <SelectInputField
                            name="salutation"
                            label={t(UserFormI18N.salutationLabel)}
                            value={model?.salutation}
                            error={errors?.salutation}
                            options={salutationSelectOptions}
                            displayEmpty={true}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid width="100%">
                        <StringInputField
                            name="firstName"
                            label={t(UserFormI18N.firstNameLabel)}
                            value={model?.firstName}
                            error={errors?.firstName}
                            maxChars={Constants.FIRSTNAME_LENGTH_MAX}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid width="100%">
                        <StringInputField
                            name="lastName"
                            label={t(UserFormI18N.lastNameLabel)}
                            value={model?.lastName}
                            error={errors?.lastName}
                            maxChars={Constants.LASTNAME_LENGTH_MAX}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid width="100%">
                        <StringInputField
                            name="phone"
                            label={t(UserFormI18N.phoneLabel)}
                            value={model?.phone}
                            error={errors?.phone}
                            maxChars={Constants.PHONE_NUMBER_LENGTH_MAX}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid width="100%">
                        <StringInputField
                            name="fax"
                            label={t(UserFormI18N.faxLabel)}
                            value={model?.fax}
                            error={errors?.fax}
                            maxChars={Constants.PHONE_NUMBER_LENGTH_MAX}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid width="100%">
                        <SelectInputField
                            name="language"
                            label={t(UserFormI18N.languageLabel)}
                            value={model?.language}
                            error={errors?.language}
                            options={languageSelectOptions}
                            displayEmpty={true}
                            onChange={onChange}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid2 container direction="row" flexWrap="nowrap" spacing={2} padding={1}>
                    <Grid2>
                        <ButtonEx
                            label={t(GeneralI18N.cancelButton)}
                            color="secondary"
                            variant="contained"
                            onClick={close}
                        />
                    </Grid2>
                    <Grid2>
                        <ButtonEx
                            label="Einladen"
                            color="primary"
                            variant="contained"
                            disabled={model.email == null}
                            onClick={() =>
                                save()?.then(() => {
                                    close();
                                    onSuccess && onSuccess();
                                })
                            }
                        />
                    </Grid2>
                </Grid2>
            </DialogActions>
        </Dialog>
    );
};
