import { Badge } from "@/lib/components/Badge";
import * as React from "react";
import { RequestStatus } from "@/api/data-contracts";
import { getRequestStatusColor, getRequestStatusText } from "@/common/request-details/request-status-field";
import { useAppTheme } from "@/app/AppThemeContext";
import { GroupBox } from "@/lib/components/GroupBox";
import { useTranslation } from "react-i18next";

export const RequestStatusBadge = ({
    status,
    fullWidth,
    count
}: {
    status?: RequestStatus;
    fullWidth?: boolean;
    count?: number;
}) => {
    const theme = useAppTheme();
    const { t } = useTranslation();
    let label = getRequestStatusText(t, status);
    if (count) {
        label = `${label}: ${count}`;
    }
    return (
        status && (
            <Badge label={label} backgroundColor={getRequestStatusColor(theme.mode, status)} fullWidth={fullWidth} />
        )
    );
};

export const RequestStatusBadgeField = ({ status }: { status: RequestStatus }) => (
    <GroupBox title="Status" paddingTop={9} paddingBottom={9}>
        <RequestStatusBadge status={status} fullWidth />
    </GroupBox>
);
