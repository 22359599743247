import {ChangeEvent, useCallback, useEffect, useImperativeHandle, useMemo, useReducer, useRef, useState} from "react";
import isEqual from "react-fast-compare";
import {ErrorsShape, extractEvent, IsTEqual} from "@/lib/utils";
import {isEmpty, setField} from "@/lib/accessor";
import {useSnackbarEx} from "@/lib/snackbarex";
import {RequestParams} from "@/api/http-client";
import {AxiosError, AxiosResponse} from "axios";
import {startTimer} from "@/lib/timer";
import {SelectChangeEvent} from "@mui/material";
import {useTranslation} from "react-i18next";
import {AnyObject, Maybe, ObjectSchema, ValidationError} from "yup";
import {SnackbarKey} from "notistack";
import {MessagesI18N} from "@/translations";

export type NamedValue = {
    name: string;
    value: any;
};

export type ChangeHandler = (e: ChangeEvent | NamedValue | SelectChangeEvent<any>) => void;

export type NamedValueHandler = (nv: NamedValue) => void;

export type ModelActionType = string | "--clear" | "--reset" | "--set" | "--update" | "--change";

export type ModelAction = {
    type: ModelActionType;
    data?: any;
    nochange?: boolean;
};

export type ValidatorOptions = {
    action?: ModelAction;
};

export type ModelStateParams<M extends Maybe<AnyObject>, ID, S = M> = {
    initialState?: M;
    id?: ID | null;
    reducer?: (state: M, action: ModelAction) => M;
    loader?: (id: ID, params?: RequestParams | undefined) => Promise<AxiosResponse<M>>;
    saver?: (state: S, params?: RequestParams | undefined) => Promise<AxiosResponse<M | void>>;
    updater?: (id: ID, state: S, params?: RequestParams | undefined) => Promise<AxiosResponse<M | void>>;
    creator?: (state: S, params?: RequestParams | undefined) => Promise<AxiosResponse<M | void>>;
    schema?: ObjectSchema<M>;
    context?: () => any;
    validateDisabled?: boolean;
    validator?: (model: M, options: ValidatorOptions) => Promise<ErrorsShape<M>>;
    onLoadError?: (error: AxiosError) => boolean;
    sendTransformer?: (data: M) => S;
};

export type ModelStateWithS<M, S> = {
    sendTransformer: (data: M) => S;
};

export type ModelState = "init" | "loading" | "saving" | "error" | "edit";

type Api<M extends Maybe<AnyObject>, ID, S = M> = ModelStateParams<M, ID, S>;

export const useModelState = <M extends Maybe<AnyObject> = any, ID = any, S = M>({
                                                                                     initialState = {} as M,
                                                                                     id,
                                                                                     reducer,
                                                                                     loader,
                                                                                     saver,
                                                                                     updater,
                                                                                     creator,
                                                                                     schema,
                                                                                     context,
                                                                                     onLoadError,
                                                                                     validateDisabled,
                                                                                     validator,
                                                                                     sendTransformer
                                                                                 }: ModelStateParams<M, ID, S> & (IsTEqual<M, S> extends true ? {} : ModelStateWithS<M, S>)) => {
    const [status, setState] = useState<ModelState>("init");
    const originalRef = useRef<M>(initialState ?? ({} as M));

    const [errors, setErrorsInt] = useState<ErrorsShape<M>>({});

    const validateTimerRef = useRef<number>(undefined);

    const {t} = useTranslation();
    const {showMessage} = useSnackbarEx();
    const snackbarKeyRef = useRef<SnackbarKey>(undefined);
    const validateCallRef = useRef<number>(1);

    const apiRef = useRef<Api<M, ID, S>>({
        loader,
        saver,
        updater,
        creator,
        id,
        schema,
        context,
        sendTransformer,
        validator
    });

    const setErrors = useCallback(
        (error: ValidationError | ErrorsShape<M>, silent?: boolean) => {
            let errorObj: ErrorsShape<M> = {};
            if (error instanceof ValidationError) {
                error.inner.forEach((err) => {
                    errorObj = setField(errorObj, `${err.path}.message`, err.message);
                });
                if (!silent) {
                    snackbarKeyRef.current = showMessage({
                        summary: t(MessagesI18N.validationError),
                        list: error.inner
                            .map((e) => e.message)
                            .filter((m, i, a) => i === a.findIndex((m2) => m2 === m)),
                        severity: "error",
                        life: 10000,
                        snackbarKey: snackbarKeyRef.current
                    });
                }
            } else {
                errorObj = error;
            }
            setErrorsInt((le: any) => (isEmpty(le) && isEmpty(errorObj) ? le : errorObj));
        },
        [showMessage, t]
    );

    const validate = useCallback(
        (options?: { silent?: boolean; fields?: (keyof M)[]; action?: ModelAction }) => {
            // If context indicates disabled then skip all validations
            if (apiRef.current?.validateDisabled === true) {
                setErrors({});
                return true;
            }
            const e0 = {};
            let err: any = e0;
            if (apiRef.current?.schema) {
                const context = apiRef.current.context ? apiRef.current.context() : undefined;
                let schemaToUse: ObjectSchema<any> = apiRef.current.schema;
                if (options?.fields || context?.schemaFields) {
                    schemaToUse = schemaToUse.pick(options?.fields ?? context?.schemaFields);
                }
                try {
                    schemaToUse.validateSync(modelRef.current, {
                        abortEarly: false,
                        disableStackTrace: true,
                        context
                    });
                } catch (error) {
                    // Set error from caught ValidationError to ensure proper nested structure
                    if (error instanceof ValidationError) {
                        err = error;
                    }
                }
            }

            setErrors(err, options?.silent);

            const sync = validateCallRef.current + 1;
            validateCallRef.current = sync;
            if (apiRef.current.validator != null) {
                apiRef.current.validator(modelRef.current, {action: options?.action}).then((r) => {
                    if (!isEmpty(r) && validateCallRef.current === sync) {
                        setErrorsInt((old) => ({...old, ...r}));
                    }
                });
            }

            return err == e0; //TODO doof, besser async / per Promise?
        },
        [setErrors]
    );

    const internal = useCallback(
        (last: M, action: ModelAction) => {
            const dispatch = (m: M) => {
                switch (action.type) {
                    case "--clear":
                        return apiRef.current.initialState;
                    case "--reset":
                        return originalRef.current;
                    case "--set":
                        return action.data;
                    case "--update": {
                        return typeof action.data === "function" ? action.data(m) : {...m, ...action.data};
                    }
                    case "--change": {
                        const next = setField(m, action.data[0], action.data[1]);
                        if (isEqual(next, m)) {
                            return m;
                        }
                        if (reducer) {
                            const probe = reducer(next, {type: "--onchange", data: action.data});
                            if (probe != null) {
                                return probe;
                            }
                        }
                        return next;
                    }
                    default: {
                        if (reducer != null) {
                            const probe = reducer(m, action);
                            if (probe != null) {
                                return probe;
                            }
                        }
                        return m;
                    }
                }
            };
            const next = dispatch(last);
            if (isEqual(next, last)) {
                return last;
            }
            if (action.nochange) {
                originalRef.current = dispatch(originalRef.current);
            } else if (apiRef.current?.schema) {
                startTimer(validateTimerRef, 250, () => {
                    validate({silent: true, action});
                });
            }
            return next;
        },
        [reducer, validate]
    );

    const [model, dispatch] = useReducer<M, any>(internal, initialState ?? ({} as M));
    const modelRef = useRef<M>(model);
    modelRef.current = model;

    const isDirty = useMemo(() => !isEqual(originalRef.current, model), [model]);

    const onChange = useCallback((e: ChangeEvent | NamedValue | SelectChangeEvent<any>) => {
        const nv = extractEvent(e);
        if (nv) {
            dispatch({type: "--change", data: nv});
        }
    }, []);

    const getModel = useCallback(() => modelRef.current, []);

    const load = useCallback(
        ({silent = true}: { silent?: boolean } = {}) => {
            if (apiRef.current.loader && id != null) {
                setState("loading");
                apiRef.current
                    .loader(id)
                    .then((response) => {
                        dispatch({type: "--set", data: response.data});
                        originalRef.current = response.data;
                        setState("edit");
                        // if (apiRef.current.validateDisabled !== true) {
                        //     setTimeout(() => validate({ silent: true }), 120);
                        // } else {
                        setErrors({});
                        // }
                        if (!silent) {
                            snackbarKeyRef.current = showMessage({
                                summary: t(MessagesI18N.loaded),
                                severity: "info",
                                snackbarKey: snackbarKeyRef.current
                            });
                        }
                    })
                    .catch((error) => {
                        setState("error");
                        if (apiRef.current.onLoadError == null || !apiRef.current.onLoadError(error)) {
                            snackbarKeyRef.current = showMessage({
                                summary: t(MessagesI18N.loadFailed),
                                severity: "error",
                                error,
                                snackbarKey: snackbarKeyRef.current
                            });
                        }
                    });
            } else {
                setTimeout(() => {
                    const data = apiRef.current?.initialState ?? ({} as M);
                    dispatch({type: "--set", data});
                    originalRef.current = data;
                    setState("edit");
                    // if (apiRef.current.validateDisabled !== true) {
                    //     setTimeout(() => validate({ silent: true }), 120);
                    // } else {
                    setErrors({});
                    // }
                    if (!silent) {
                        snackbarKeyRef.current = showMessage({
                            summary: t(MessagesI18N.loaded),
                            severity: "info",
                            snackbarKey: snackbarKeyRef.current
                        });
                    }
                }, 100);
            }
        },
        [id, setErrors, showMessage, t]
    );

    const save = useCallback(
        ({successMsg}: { successMsg?: string } = {}): Promise<AxiosResponse<M | void>> | null => {
            if (!validate()) {
                return null;
            }
            let promise: Promise<AxiosResponse<M | void>> | null = null;
            if (apiRef.current.id != null) {
                if (apiRef.current.updater) {
                    promise = apiRef.current.updater(
                        apiRef.current.id,
                        apiRef.current.sendTransformer!(modelRef.current)
                    );
                } else if (apiRef.current.saver) {
                    promise = apiRef.current.saver(apiRef.current.sendTransformer!(modelRef.current));
                }
            } else if (apiRef.current.creator) {
                promise = apiRef.current.creator(apiRef.current.sendTransformer!(modelRef.current));
            } else if (apiRef.current.saver) {
                promise = apiRef.current.saver(apiRef.current.sendTransformer!(modelRef.current));
            }
            if (promise) {
                setState("saving");
                promise = promise
                    .then((response) => {
                        if (response.data) {
                            originalRef.current = response.data;
                            dispatch({type: "--set", data: response.data});
                        }
                        setState("edit");
                        snackbarKeyRef.current = showMessage({
                            summary: successMsg ?? t(MessagesI18N.saved),
                            severity: "info",
                            snackbarKey: snackbarKeyRef.current
                        });
                        return response;
                    })
                    .catch((error) => {
                        setState("edit");
                        snackbarKeyRef.current = showMessage({
                            summary: t(MessagesI18N.saveFailed),
                            severity: "error",
                            error,
                            snackbarKey: snackbarKeyRef.current
                        });
                        // TODO use error.response.data...‚.
                        return Promise.reject(error);
                    });
            }
            return promise;
        },
        [showMessage, t, validate]
    );

    useImperativeHandle(
        apiRef,
        () => ({
            initialState,
            loader,
            saver,
            updater,
            creator,
            id,
            schema,
            context,
            onLoadError,
            validateDisabled,
            validator,
            sendTransformer: sendTransformer ?? ((d) => d as any)
        }),
        [
            initialState,
            loader,
            saver,
            updater,
            creator,
            id,
            schema,
            context,
            onLoadError,
            validateDisabled,
            validator,
            sendTransformer
        ]
    );

    useEffect(() => {
        load({silent: true});
    }, [load]);

    useEffect(() => {
        if (validateDisabled !== true) {
            validate({silent: true});
        }
    }, [validate, validateDisabled]);

    const original = originalRef.current;
    return {model, original, errors, status, isDirty, dispatch, onChange, getModel, validate, save, load, setErrors};
};
