import * as React from "react";
import { ReactNode } from "react";
import { Card, CardContent, CardContentProps, CardHeader, CardHeaderProps, SxProps, Theme } from "@mui/material";

/**
 * CardEx component.
 *
 * @param {Object} props - The props for the component.
 * @param {ReactNode} props.title - The title to display in the card header.
 * @param {ReactNode} props.children - The content to display inside the card.
 * @returns {React.ReactElement} The rendered CardEx component.
 */
export const CardEx = ({
    title,
    sx,
    children
}: {
    title?: ReactNode;
    sx?: SxProps<Theme>;
    children: ReactNode;
}): React.ReactElement => {
    const slotProps: CardHeaderProps["slotProps"] = {
        title: {
            fontSize: "1.25rem"
        }
    };
    const style: CardContentProps["style"] = {
        paddingTop: 8
    };
    return (
        <Card sx={{ ...sx, overflow: "auto" }}>
            {title && <CardHeader title={title} slotProps={slotProps} />}
            <CardContent style={style}>{children}</CardContent>
        </Card>
    );
};
