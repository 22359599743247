import { useCallback, useEffect } from "react";
import { useBlocker } from "react-router";

interface UseUnsavedChangesBlockerReturn {
    blocked: boolean;
    proceed: () => void;
    reset: () => void;
}

/**
 * A reusable hook that sets up a navigation blocker when there are unsaved changes.
 * @param shouldBlock Whether navigation should be blocked.
 * @returns {UseUnsavedChangesBlockerReturn} An object with the current state and methods to proceed or reset.
 */
export function useUnsavedChangesBlocker(shouldBlock?: boolean): UseUnsavedChangesBlockerReturn {
    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) => !!shouldBlock && currentLocation.pathname !== nextLocation.pathname
    );

    useEffect(() => {
        if (shouldBlock) {
            const handleBeforeUnload = (e: BeforeUnloadEvent) => {
                e.preventDefault();
                e.returnValue = "";
            };

            window.addEventListener("beforeunload", handleBeforeUnload);
            return () => {
                window.removeEventListener("beforeunload", handleBeforeUnload);
            };
        }
    }, [shouldBlock]);

    const proceed = useCallback(() => {
        blocker.proceed?.();
    }, [blocker]);

    const reset = useCallback(() => {
        blocker.reset?.();
    }, [blocker]);

    return {
        blocked: blocker.state === "blocked",
        proceed,
        reset
    };
}
