import React, { useEffect } from "react";
import { useAuth } from "@/app/AuthContext";
import PageLoadingSpinner from "@/lib/components/PageLoadingSpinner";
import { useNavigate } from "react-router-dom";
import { AppPaths } from "@/app/AppPaths";

export function withAuthWrapperComponent<P = any>(WrappedComponent: React.ComponentType<P>) {
    return function AuthenticatedComponent(props: P) {
        const { authenticated, loading } = useAuth();
        const navigate = useNavigate();

        useEffect(() => {
            if (!loading && !authenticated) {
                navigate(AppPaths.SIGN_IN);
            }
        }, [authenticated, loading, navigate]);

        if (loading) {
            return <PageLoadingSpinner />;
        }

        if (!authenticated) {
            return null;
        }

        // @ts-ignore
        return <WrappedComponent {...props} />;
    };
}
