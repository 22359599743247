import React, { useEffect, useRef, useState } from "react";
import { Box, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CheckboxInputField from "@/lib/components/inputfields/checkbox-input-field";
import { ChangeHandler } from "@/lib/modelstate";
import { CardEx } from "@/lib/components/CardEx";
import { useTranslation } from "react-i18next";
import { AuthenticationPageI18N } from "@/translations";

interface AgreementProps {
    name: string;
    title?: string;
    agreementText: string;
    value?: boolean;
    onChange: ChangeHandler;
    error: any;
}

const Agreement: React.FC<AgreementProps> = ({ name, title, agreementText, value, error, onChange }) => {
    const { t } = useTranslation();
    const [canAccept, setCanAccept] = useState(false);
    const textBoxRef = useRef<HTMLDivElement>(null);
    const theme = useTheme();

    // Wenn der Wert true ist, Checkbox bereits ausgewählt, also die Freigabe aktivieren
    useEffect(() => {
        if (value) {
            setCanAccept(true);
        }
    }, [value]);

    const handleScroll = () => {
        if (textBoxRef.current) {
            const element = textBoxRef.current;
            const isAtBottom = Math.abs(element.scrollHeight - element.clientHeight - element.scrollTop) < 10; // 10px Toleranz
            if (isAtBottom && !canAccept) {
                setCanAccept(true);
            }
        }
    };

    return (
        <CardEx title={title}>
            <Paper
                elevation={2}
                sx={{
                    p: 2,
                    mb: 2,
                    maxHeight: "600px",
                    overflowY: "auto",
                    borderRadius: "8px",
                    backgroundColor: theme.palette.mode === "light" ? theme.palette.grey[200] : theme.palette.grey[800]
                }}
                ref={textBoxRef}
                onScroll={handleScroll}
            >
                <Typography
                    component="div"
                    dangerouslySetInnerHTML={{ __html: agreementText }}
                    sx={{ lineHeight: 1.6, fontSize: "0.95rem" }}
                />
            </Paper>
            <Box sx={{ mt: 2 }}>
                <CheckboxInputField
                    name={name}
                    label={t(AuthenticationPageI18N.termsAgreementLabel)}
                    value={value}
                    onChange={onChange}
                    disabled={!canAccept}
                    error={error}
                />
                {!canAccept && (
                    <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ display: "block", mt: 1, fontStyle: "italic", textAlign: "center" }}
                    >
                        {t(AuthenticationPageI18N.scrollDownMessage)}
                    </Typography>
                )}
            </Box>
        </CardEx>
    );
};

export default Agreement;
