import { AppFrame } from "@/app/AppFrame";
import { Card, CardContent, Grid2 } from "@mui/material";
import { ButtonEx } from "@/lib/components/buttons/ButtonEx";
import { buildRightAlignedCell, DataTable } from "@/lib/components/DataTable";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import {
    CompanyOverviewRowDTO,
    CompanySearchParamsDTO,
    CompanyStatus,
    SearchResultCompanyOverviewRowDTO
} from "@/api/data-contracts";
import { useDialogController } from "@/lib/dialog-controller";
import { CompanyInvitationDialog } from "@/pages/company-overview-page/CompanyInvitationDialog";
import { companyApi } from "@/boxhub-api";
import { PersonAdd } from "@mui/icons-material";
import StringInputField from "@/lib/components/inputfields/string-input-field";
import MultiSelectInputField from "@/lib/components/inputfields/multi-select-input-field";
import { ensureArray, formatInteger } from "@/lib/utils";
import i18n from "@/i18n";
import { DateFormatter } from "@/lib/components/DateFormate";
import dayjs from "dayjs";
import { withAuthWrapperComponent } from "@/app/WithAuthWrapperComponent";
import { useQueryState } from "@/lib/querystate";
import { useSnackbarEx } from "@/lib/snackbarex";
import { ShortCutHandler } from "@/lib/shortcut-handler";
import { AppPaths } from "@/app/AppPaths";
import { useTranslation } from "react-i18next";
import { AdministrationI18N, CompanyStatusI18N } from "@/translations";
import { CompanyStatusBadge } from "@/common/CompanyStatusBadge";
import { useNavigate } from "react-router-dom";
import { companyStatusOptions } from "@/datacaches";

export interface CompanyOverviewPageProps {
    unverified?: boolean;
}

const CompanyOverviewPage = ({ unverified }: CompanyOverviewPageProps) => {
    const [Anker, showDialog] = useDialogController();
    const { t } = useTranslation();
    const locale = i18n.language;
    const dateformat = DateFormatter("date_hm");
    const navigate = useNavigate();
    const { showMessage } = useSnackbarEx();

    const [result, setResult] = useState<SearchResultCompanyOverviewRowDTO>({
        rows: []
    });

    // ModelState for search criteria (form fields)
    const { searchParams, onChangeSearchParams, getSearchParams, searchParamTrigger } =
        useQueryState<CompanySearchParamsDTO>({
            initialState: {
                status: unverified ? undefined : [CompanyStatus.CONFIRMED],
                companyName: ""
            },
            enhancer: {
                status: ensureArray
            },
            localStorageName: unverified
                ? ":company-unverfified-page-search-params"
                : ":company-overview-page-search-params"
        });

    // Status-Optionen
    const companyStatusSelectOptions = companyStatusOptions.map((option) => ({
        label: option && t(CompanyStatusI18N[option]),
        value: option
    }));

    const columns = useMemo<ColumnDef<CompanyOverviewRowDTO>[]>(
        () => [
            {
                accessorKey: "name",
                header: t(AdministrationI18N.companyNameLabel),
                accessorFn: (row) => row.company?.name
            },
            {
                accessorKey: "address.street",
                header: t(AdministrationI18N.streetLabel),
                accessorFn: (row) => row.company?.companyAddress?.street
            },
            {
                accessorKey: "address.zip",
                header: t(AdministrationI18N.postalCodeLabel),
                accessorFn: (row) => row.company?.companyAddress?.zip
            },
            {
                accessorKey: "address.country",
                header: t(AdministrationI18N.countryLabel),
                accessorFn: (row) => row.company?.companyAddress?.country
            },
            {
                accessorKey: "activeEmployeeCount",
                header: t(AdministrationI18N.numberOfActiveCompanyUsersLabel),
                cell: buildRightAlignedCell,
                accessorFn: (row) => row?.countActiveUsers
            },
            {
                accessorKey: "lastStatusUpdate",
                header: t(AdministrationI18N.lastUpdatedLabel),
                accessorFn: (row) => dayjs(row.lastUpdateOfCompanyStatus).format(dateformat)
            },
            {
                accessorKey: "status",
                header: t(AdministrationI18N.companyStatusLabel),
                accessorFn: (row) => row.company?.status && t(CompanyStatusI18N[row.company?.status]),
                cell: (info) => <CompanyStatusBadge status={info.row.original.company?.status} />
            },
            {
                accessorKey: "countOfRequests",
                header: t(AdministrationI18N.requestsLabel),
                accessorFn: (row) => formatInteger(row.countOfRequests, locale),
                cell: buildRightAlignedCell
            },
            {
                accessorKey: "countOfQuotes",
                header: t(AdministrationI18N.quotesLabel),
                accessorFn: (row) => formatInteger(row.countOfQuotes, locale),
                cell: buildRightAlignedCell
            }
        ],
        [dateformat, locale, t]
    );

    const handleInviteCompany = () => {
        showDialog((open, close) => <CompanyInvitationDialog open={open} close={close} />);
    };

    const handleSearch = useCallback(() => {
        const sp = { ...getSearchParams() };
        if (unverified) {
            sp.status = [CompanyStatus.UNVERIFIED];
        }
        companyApi
            .searchCompanies(sp)
            .then((response) => {
                setResult(response.data);
            })
            .catch((error) => {
                showMessage({ summary: t(AdministrationI18N.searchFailedError), error, severity: "error" });
            });
    }, [getSearchParams, showMessage, t, unverified]);

    useEffect(() => {
        setTimeout(() => {
            handleSearch();
        }, 250);
    }, [handleSearch, onChangeSearchParams, searchParamTrigger]);

    const buttons = (
        <Grid2 container direction="row" spacing={2}>
            <Grid2>
                <ButtonEx
                    label={t(AdministrationI18N.inviteCompanyButton)}
                    color="secondary"
                    variant="contained"
                    StartIcon={PersonAdd}
                    onClick={handleInviteCompany}
                />
            </Grid2>
        </Grid2>
    );

    const shortcuts = {
        Enter: handleSearch
    };

    return (
        <AppFrame
            title={t(unverified ? AdministrationI18N.companyRequestsTitle : AdministrationI18N.companyOverviewTitle)}
            buttons={buttons}
            padding={2}
        >
            <ShortCutHandler shortcuts={shortcuts}>
                <Card>
                    <CardContent>
                        <Grid2 container direction="column" spacing={2} padding={2}>
                            <Grid2 container direction="row" spacing={2} padding={2}>
                                {unverified ? null : (
                                    <Grid2 size={{ sm: 12, md: 8 }}>
                                        <MultiSelectInputField
                                            name="status"
                                            label={t(AdministrationI18N.companyStatusLabel)}
                                            options={companyStatusSelectOptions}
                                            value={searchParams.status ?? []}
                                            onChange={onChangeSearchParams}
                                            renderOption={(option) => <CompanyStatusBadge status={option.value} />}
                                            disabled={unverified}
                                        />
                                    </Grid2>
                                )}
                                <Grid2 size={{ sm: 12, md: 4 }}>
                                    <StringInputField
                                        name="companyName"
                                        label={t(AdministrationI18N.companyNameLabel)}
                                        value={searchParams.companyName ?? ""}
                                        onChange={onChangeSearchParams}
                                    />
                                </Grid2>
                            </Grid2>

                            <Grid2>
                                <DataTable
                                    columns={columns}
                                    data={result?.rows ?? []}
                                    noResultsValue={t(AdministrationI18N.noCompaniesFoundLabel)}
                                    onRowClick={(row) => {
                                        navigate(AppPaths.COMPANY_fn(row.id!));
                                    }}
                                    striped
                                />
                            </Grid2>
                        </Grid2>
                    </CardContent>
                </Card>
                <Anker />
            </ShortCutHandler>
        </AppFrame>
    );
};

export default withAuthWrapperComponent(CompanyOverviewPage);
