import * as React from "react";
import { Grid2 as Grid } from "@mui/material";
import { OrderDataDTO, PrintType } from "@/api/data-contracts";
import { ChangeHandler } from "@/lib/modelstate";
import { MILLIMETRE_UNIT } from "@/constants";
import { CardEx } from "@/lib/components/CardEx";
import AutocompleteInputField from "@/lib/components/inputfields/auto-complete-input-field";
import { printOptions, useCartonGrades, useFefcoCodes } from "@/datacaches";
import NumberInputField from "@/lib/components/inputfields/number-input-field";
import CheckboxInputField from "@/lib/components/inputfields/checkbox-input-field";
import { GroupBox } from "@/lib/components/GroupBox";
import SelectInputField from "@/lib/components/inputfields/select-input-field";
import { Constants } from "@/api/constants";
import { useTranslation } from "react-i18next";
import { PrintTypeI18N, RequestKeyWordsI18N } from "@/translations";
import { OrderDataType } from "@/model/orderData";
import { useCardChangeColors } from "@/common/request-cards/common";

export type ProductCardProps = {
    orderData?: OrderDataDTO;
    onChange: ChangeHandler;
    errors: any;
    readonly?: boolean;
    mode: OrderDataType;
    orgData?: OrderDataDTO | null;
};

export const ProductCard = ({ orderData, orgData, errors, onChange, readonly, mode }: ProductCardProps) => {
    const { t } = useTranslation();
    const { getGridChangeSx, getCheckboxChangeSx } = useCardChangeColors();
    const [fefcoCodesOptions] = useFefcoCodes();
    const [varietyOptions] = useCartonGrades();
    const printSelectOptions = printOptions.map((option) => ({
        label: option && t(PrintTypeI18N[option]),
        value: option
    }));
    const readonlyEx = readonly || mode === "quote";

    const helperTextCartonGrade =
        orgData?.cartonGrade && orgData?.cartonGrade !== orderData?.cartonGrade ? orgData.cartonGrade : undefined;

    return (
        <CardEx title="Produkt">
            <Grid container spacing={3} direction="column">
                <Grid size={{ sm: 12 }} container direction="row" spacing={2} justifyContent="stretch">
                    <Grid size={{ sm: 6 }}>
                        <AutocompleteInputField
                            name="orderData.fefcoCode"
                            label={t(RequestKeyWordsI18N.fefcoCode)}
                            onChange={onChange}
                            value={orderData?.fefcoCode}
                            error={errors?.orderData?.fefcoCode}
                            options={fefcoCodesOptions}
                            maxChars={Constants.FEFCO_CODE_LENGTH_MAX}
                            disabled={readonlyEx}
                            freeSolo
                        />
                    </Grid>
                    <Grid size={{ sm: 6 }} sx={getGridChangeSx(orgData, helperTextCartonGrade)}>
                        <AutocompleteInputField
                            name="orderData.cartonGrade"
                            label={t(RequestKeyWordsI18N.variety)}
                            onChange={onChange}
                            value={orderData?.cartonGrade}
                            error={errors?.orderData?.cartonGrade}
                            options={varietyOptions}
                            maxChars={Constants.CARTON_GRADE_LENGTH_MAX}
                            disabled={readonly}
                            helperText={helperTextCartonGrade}
                            freeSolo
                        />
                    </Grid>
                </Grid>
                <Grid size={{ sm: 12 }} container direction="row" spacing={2}>
                    <Grid size={{ sm: 4 }}>
                        <NumberInputField
                            name="orderData.internalDimensionLength"
                            label={t(RequestKeyWordsI18N.length)}
                            onChange={onChange}
                            value={orderData?.internalDimensionLength}
                            error={errors?.orderData?.internalDimensionLength}
                            suffix={` ${MILLIMETRE_UNIT}`}
                            min={Constants.INTERNAL_DIMENSION_MIN}
                            max={Constants.INTERNAL_DIMENSION_MAX}
                            disabled={readonlyEx}
                        />
                    </Grid>
                    <Grid size={{ sm: 4 }}>
                        <NumberInputField
                            name="orderData.internalDimensionWidth"
                            label={t(RequestKeyWordsI18N.width)}
                            onChange={onChange}
                            value={orderData?.internalDimensionWidth}
                            error={errors?.orderData?.internalDimensionWidth}
                            suffix={` ${MILLIMETRE_UNIT}`}
                            min={Constants.INTERNAL_DIMENSION_MIN}
                            max={Constants.INTERNAL_DIMENSION_MAX}
                            disabled={readonlyEx}
                        />
                    </Grid>
                    <Grid size={{ sm: 4 }}>
                        <NumberInputField
                            name="orderData.internalDimensionHeight"
                            label={t(RequestKeyWordsI18N.height)}
                            onChange={onChange}
                            value={orderData?.internalDimensionHeight}
                            error={errors?.orderData?.internalDimensionHeight}
                            suffix={` ${MILLIMETRE_UNIT}`}
                            min={Constants.INTERNAL_DIMENSION_MIN}
                            max={Constants.INTERNAL_DIMENSION_MAX}
                            disabled={readonlyEx}
                        />
                    </Grid>
                </Grid>
                <Grid size={{ sm: 12 }} container direction="row" spacing={2}>
                    <Grid size={{ sm: 6 }}>
                        <GroupBox
                            title={t(RequestKeyWordsI18N.closureType)}
                            paddingTop={12}
                            paddingBottom={12}
                            disabled={readonlyEx}
                        >
                            <Grid container direction="row" spacing={1}>
                                <Grid>
                                    <CheckboxInputField
                                        name="orderData.glued"
                                        label={t(RequestKeyWordsI18N.Glue)}
                                        onChange={onChange}
                                        value={orderData?.glued}
                                        error={errors?.orderData?.glued}
                                        dense
                                        disabled={readonly}
                                        sx={getCheckboxChangeSx(orgData, orgData?.glued !== orderData?.glued)}
                                    />
                                </Grid>
                                <Grid>
                                    <CheckboxInputField
                                        name="orderData.stitched"
                                        label={t(RequestKeyWordsI18N.Staple)}
                                        onChange={onChange}
                                        value={orderData?.stitched}
                                        error={errors?.orderData?.stitched}
                                        dense
                                        disabled={readonly}
                                        sx={getCheckboxChangeSx(orgData, orgData?.stitched !== orderData?.stitched)}
                                    />
                                </Grid>
                                <Grid>
                                    <CheckboxInputField
                                        name="orderData.taped"
                                        label={t(RequestKeyWordsI18N.Tap)}
                                        onChange={onChange}
                                        value={orderData?.taped}
                                        error={errors?.orderData?.taped}
                                        dense
                                        disabled={readonly}
                                        sx={getCheckboxChangeSx(orgData, orgData?.taped !== orderData?.taped)}
                                    />
                                </Grid>
                            </Grid>
                        </GroupBox>
                    </Grid>
                    <Grid size={{ sm: 6 }}>
                        <GroupBox
                            title="Verschluss- und Öffnungshilfen"
                            paddingTop={12}
                            paddingBottom={12}
                            disabled={readonlyEx}
                        >
                            <Grid container direction="row" spacing={0}>
                                <Grid>
                                    <CheckboxInputField
                                        name="orderData.siliconeTape"
                                        label={t(RequestKeyWordsI18N.siliconTapeLabel)}
                                        onChange={onChange}
                                        value={orderData?.siliconeTape}
                                        error={errors?.orderData?.siliconeTape}
                                        dense
                                        disabled={readonlyEx}
                                    />
                                </Grid>
                                <Grid>
                                    <CheckboxInputField
                                        name="orderData.tearTape"
                                        label={t(RequestKeyWordsI18N.tearTapeLabel)}
                                        onChange={onChange}
                                        value={orderData?.tearTape}
                                        error={errors?.orderData?.tearTape}
                                        dense
                                        disabled={readonlyEx}
                                    />
                                </Grid>
                            </Grid>
                        </GroupBox>
                    </Grid>
                </Grid>
                <Grid size={{ sm: 12 }} container direction="row" spacing={2}>
                    <Grid size={{ sm: 6 }} container spacing={2}>
                        <Grid size={{ sm: 6 }}>
                            <SelectInputField
                                name="orderData.externalPrint"
                                label={t(RequestKeyWordsI18N.externalPrintLabel)}
                                value={orderData?.externalPrint}
                                error={errors?.orderData?.externalPrint}
                                options={printSelectOptions}
                                displayEmpty={true}
                                onChange={onChange}
                                disabled={readonlyEx}
                            />
                        </Grid>
                        <Grid size={{ sm: 6 }}>
                            <NumberInputField
                                name="orderData.externalNumberOfColors"
                                label={t(RequestKeyWordsI18N.numberOfColors)}
                                onChange={onChange}
                                value={orderData?.externalNumberOfColors}
                                error={errors?.orderData?.externalNumberOfColors}
                                disabled={
                                    readonlyEx ||
                                    orderData?.externalPrint == null ||
                                    orderData?.externalPrint === PrintType.NO_PRINT
                                }
                                min={Constants.NUMBER_OF_COLORS_MIN}
                                max={Constants.NUMBER_OF_COLORS_MAX}
                            />
                        </Grid>
                    </Grid>
                    <Grid size={{ sm: 6 }} container spacing={2}>
                        <Grid size={{ sm: 6 }}>
                            <SelectInputField
                                name="orderData.internalPrint"
                                label={t(RequestKeyWordsI18N.internalPrintLabel)}
                                value={orderData?.internalPrint}
                                error={errors?.orderData?.internalPrint}
                                options={printSelectOptions}
                                displayEmpty
                                onChange={onChange}
                                disabled={readonlyEx}
                            />
                        </Grid>
                        <Grid size={{ sm: 6 }}>
                            <NumberInputField
                                name="orderData.internalNumberOfColors"
                                label={t(RequestKeyWordsI18N.numberOfColors)}
                                value={orderData?.internalNumberOfColors}
                                onChange={onChange}
                                error={errors?.orderData?.internalNumberOfColors}
                                disabled={
                                    readonlyEx ||
                                    orderData?.internalPrint == null ||
                                    orderData?.internalPrint === PrintType.NO_PRINT
                                }
                                min={Constants.NUMBER_OF_COLORS_MIN}
                                max={Constants.NUMBER_OF_COLORS_MAX}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </CardEx>
    );
};
