import React from "react";
import {
    CellContext,
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import {
    alpha,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import {useTheme as useMuiTheme} from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {TooltipContent} from "@/lib/components/TooltipContent";
import {brown, yellow} from "@mui/material/colors";

/**
 * Interface representing an identifiable entity.
 */
interface Identifiable {
    id?: number;
}

/**
 * Interface for custom column metadata.
 */
interface ColumnMeta {
    disableTooltip?: boolean;
}

/**
 * Props for the DataTable component.
 *
 * @template TData - The type of data for each row.
 * @property {ColumnDef<TData, any>[]} columns - The column definitions for the table.
 * @property {TData[]} data - The data to display in the table.
 * @property {string} noResultsValue - The value to display when no results are found.
 * @property {(row: TData) => void} [onRowClick] - The function to call when a row is clicked.
 * @property {number | null} [selectedRowId] - The ID of the selected row.
 * @property {(row: TData) => boolean} [isRowSelectable] - The function to determine if a row is selectable.
 * @property {boolean} [expandable] - If true, rows can be expanded.
 * @property {(row: any) => React.ReactNode} [renderRowSubComponent] - The function to render the subcomponent for an expanded row.
 * @property {string | number} [maxHeight] - The maximum height of the table container.
 * @property {boolean} [striped] - If true, alternate row background colors ("zebra striping").
 */
interface DataTableProps<TData extends Identifiable> {
    columns: ColumnDef<TData, any>[];
    data: TData[];
    noResultsValue: string;
    onRowClick?: (row: TData) => void;
    selectedRowId?: number | null;
    isRowSelectable?: (row: TData) => boolean;
    expandable?: boolean;
    renderRowSubComponent?: (row: any) => React.ReactNode;
    maxHeight?: string | number;
    striped?: boolean;
}

/**
 * DataTable component.
 *
 * @template TData - The type of data for each row.
 * @param {DataTableProps<TData>} props - The props for the component.
 * @returns {React.ReactElement} The rendered DataTable component.
 */
export function DataTable<TData extends Identifiable>({
                                                          columns,
                                                          data,
                                                          noResultsValue,
                                                          onRowClick,
                                                          selectedRowId,
                                                          isRowSelectable,
                                                          expandable = false,
                                                          renderRowSubComponent,
                                                          maxHeight,
                                                          striped = false,
                                                      }: DataTableProps<TData>): React.ReactElement {
    const theme = useMuiTheme();

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getRowCanExpand: () => expandable,
    });

    const sx = {
        "& td": {
            borderBottom: "none",
            marginLeft: 4,
            marginRight: 4,
        },
        "& tr:hover, & tr:hover td": {
            backgroundColor:
                theme.palette.mode === "dark"
                    ? alpha(brown[900], 0.7)
                    : yellow[400],
            cursor: onRowClick ? "pointer" : undefined,
        },

        // Inner table with border
        "& table td:has(table)": {
            padding: 0,
        },
        "& table table td": {
            paddingLeft: 1,
            paddingRight: 1,
            paddingTop: 0,
            paddingBottom: 0,
            textAlign: "right",
            borderLeft: "1px solid",
            borderTop: "1px solid",
            borderBottom: "1px solid",
        },
        "& table table td:last-child": {
            borderRight: "1px solid",
        },
        "& table td:has(table):last-child": {
            paddingRight: 2,
        },
    };

    return (
        <TableContainer style={{maxHeight}} sx={sx}>
            <Table stickyHeader>
                <TableHead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {expandable && (
                                <TableCell
                                    style={{
                                        // backgroundColor: theme.palette.primary.main,
                                        width: "50px",
                                    }}
                                />
                            )}
                            {headerGroup.headers.map((header) => (
                                <TableCell
                                    key={header.id}
                                    style={{
                                        // backgroundColor: theme.palette.primary.main,
                                        // color: theme.palette.primary.contrastText,
                                        fontWeight: "bold",
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        // maxWidth: "200px",
                                        minWidth: header.column.columnDef.minSize,
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody>
                    {table.getRowModel().rows.length > 0 ? (
                        table.getRowModel().rows.map((row, index) => (
                            <React.Fragment key={row.id}>
                                <TableRow
                                    onClick={() => {
                                        if (
                                            isRowSelectable &&
                                            !isRowSelectable(row.original)
                                        ) {
                                            return;
                                        }
                                        onRowClick?.(row.original);
                                    }}
                                    hover
                                    selected={selectedRowId === row.original.id}
                                    style={{
                                        cursor: expandable
                                            ? isRowSelectable && !isRowSelectable(row.original)
                                                ? "not-allowed"
                                                : "pointer"
                                            : "default",
                                        opacity:
                                            isRowSelectable && !isRowSelectable(row.original)
                                                ? 0.5
                                                : 1,
                                        // Apply row striping if striped=true
                                        backgroundColor: striped
                                            ? index % 2 === 0
                                                ? theme.palette.mode === "dark"
                                                    ? alpha(theme.palette.background.paper, 0.7)
                                                    : alpha(theme.palette.primary.light, 0.2)
                                                : theme.palette.mode === "dark"
                                                    ? alpha(theme.palette.background.default, 0.21)
                                                    : theme.palette.common.white
                                            : "inherit",
                                    }}
                                >
                                    {expandable && (
                                        <TableCell>
                                            <IconButton
                                                size="small"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    row.toggleExpanded();
                                                }}
                                            >
                                                {row.getIsExpanded() ? (
                                                    <KeyboardArrowUpIcon/>
                                                ) : (
                                                    <KeyboardArrowDownIcon/>
                                                )}
                                            </IconButton>
                                        </TableCell>
                                    )}
                                    {row.getVisibleCells().map((cell) => {
                                        const cellContent = flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                        );

                                        // Access the disableTooltip property from the meta object using type assertion
                                        const disableTooltip = (cell.column.columnDef.meta as ColumnMeta)?.disableTooltip;

                                        return (
                                            <TableCell
                                                key={cell.id}
                                                style={{
                                                    paddingTop: 8,
                                                    paddingBottom: 8,
                                                    maxWidth: "200px",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                {disableTooltip ? (
                                                    cellContent
                                                ) : (
                                                    <TooltipContent value={cellContent}/>
                                                )}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                                {row.getIsExpanded() && (
                                    <TableRow>
                                        <TableCell
                                            colSpan={
                                                expandable ? columns.length + 1 : columns.length
                                            }
                                            style={{paddingBottom: 0, paddingTop: 0}}
                                        >
                                            <Collapse
                                                in={row.getIsExpanded()}
                                                timeout="auto"
                                                unmountOnExit
                                            >
                                                {renderRowSubComponent &&
                                                    renderRowSubComponent(row)}
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </React.Fragment>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell
                                colSpan={columns.length + (expandable ? 1 : 0)}
                                align="center"
                            >
                                {noResultsValue}
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

/**
 * Builds a right-aligned cell component.
 *
 * @param {CellContext<any, any>} info - The cell context.
 * @returns {React.ReactElement} The rendered cell.
 */
export const buildRightAlignedCell = (info: CellContext<any, any>) => (
    <div style={{textAlign: "right"}}>{info.getValue() as number}</div>
);