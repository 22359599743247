// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Import your translation JSON files
import de from "../messages/de.json";

i18n.use(LanguageDetector)
    //.use(HttpBackend)
    .use(initReactI18next)
    .init({
        // debug: true,
        // backend: {
        //     loadPath: "/locales/{{lng}}/{{ns}}.json",
        // },
        resources: {
            en: {
                // translation: en,
            },
            de: {
                translation: de
            }
        },
        lng: "de",
        fallbackLng: "de",
        // detection: {
        //     order: ["localStorage"],
        //     caches: ["localStorage"],
        // },
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
