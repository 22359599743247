import dayjs from "dayjs";
import { LabelValue } from "@/lib/components/LabelValueList";

/**
 * Generates a field object for a date with optional formatting and tooltip.
 *
 * @param {string | undefined} date - The date to be displayed. If undefined, a default "not available" message is used.
 * @param {string} label - The label for the field.
 * @param {string} [defaultValue=""] - The default value.
 * @param {string} [dateFormat] - The format to use for the date.
 * @param {string} [tooltipKey] - The key for the tooltip text.
 * @returns {LabelValue} The field object with label, value, and tooltipKey properties.
 */
export const dateField = (
    date: string | undefined,
    label: string,
    defaultValue: string = "",
    dateFormat?: string,
    tooltipKey?: string
): LabelValue => ({
    label,
    value: date ? dayjs(date).format(dateFormat) : defaultValue,
    tooltipKey
});
