import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { HomePageI18N } from "@/translations";

export default function Logo() {
    const { t } = useTranslation();
    return (
        <Box id="logo" width="100%">
            <Container
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    pt: { xs: 14, sm: 20 },
                    pb: { xs: 8, sm: 12 }
                }}
            >
                <Stack spacing={2} useFlexGap sx={{ width: { xs: "100%", sm: "70%" } }}>
                    <Typography
                        variant="h1"
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" },
                            alignSelf: "center",
                            textAlign: "center",
                            fontSize: "clamp(3.5rem, 10vw, 4rem)"
                        }}
                    >
                        {t(HomePageI18N.ourlatest)} &nbsp;
                        <Typography
                            component="span"
                            variant="h1"
                            sx={{
                                fontSize: "clamp(3rem, 10vw, 4rem)",
                                color: (theme) => (theme.palette.mode === "light" ? "primary.main" : "primary.light")
                            }}
                        >
                            {t(HomePageI18N.product)}
                        </Typography>
                    </Typography>
                    <Typography
                        textAlign="center"
                        color="text.secondary"
                        sx={{ alignSelf: "center", width: { sm: "100%", md: "80%" } }}
                    >
                        {t(HomePageI18N.description)}
                    </Typography>
                </Stack>
            </Container>
        </Box>
    );
}
