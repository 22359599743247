import * as React from "react";
import Logo from "@/pages/landing-page/Logo";
import LogoCollection from "@/pages/landing-page/LogoCollection";
import { Header } from "@/app/Header";
import { Footer } from "@/app/Footer";
import { useAppTheme } from "@/app/AppThemeContext";
import { Container, Grid2 } from "@mui/material";

export const LandingPage = () => {
    const { mode, toggleColorMode } = useAppTheme();

    return (
        <Container maxWidth="xl">
            <Header mode={mode} toggleColorMode={toggleColorMode} />
            <Grid2 container direction="column" sx={{ minHeight: "calc( 100vh - 64px)" }}>
                <Grid2 width="100%">
                    <Logo />
                </Grid2>
                <Grid2 width="100%" flexGrow={1}>
                    <LogoCollection />
                </Grid2>
                <Grid2 width="100%">
                    <Footer mode={mode} />
                </Grid2>
            </Grid2>
        </Container>
    );
};
