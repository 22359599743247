import * as React from "react";
import {Grid2 as Grid} from "@mui/material";
import {CompanySignUpDTO} from "@/api/data-contracts";
import {ChangeHandler} from "@/lib/modelstate";
import {CardEx} from "@/lib/components/CardEx";
import StringInputField from "@/lib/components/inputfields/string-input-field";
import SelectInputField from "@/lib/components/inputfields/select-input-field";
import {countryOptions, legalFormOptions} from "@/datacaches";
import {useTranslation} from "react-i18next";
import {CompanyFormI18N, CountriesI18N, LegalFormI18N} from "@/translations";
import {Constants} from "@/api/constants";

export const CompanyForm = ({
                                model,
                                errors,
                                onChange
                            }: {
    model?: CompanySignUpDTO;
    onChange: ChangeHandler;
    errors: any;
}) => {
    const {t} = useTranslation();
    const countrySelectOptions = countryOptions.map((option) => ({
        label: option && t(CountriesI18N[option]),
        value: option
    }));
    const businessStructureSelectOptions = legalFormOptions.map((option) => ({
        label: t(LegalFormI18N[option]),
        value: option
    }));

    return (
        <CardEx title={t(CompanyFormI18N.companyFormTitle)}>
            <Grid container direction="column" spacing={3} width="100%">
                <Grid size={{sm: 6, md: 5, lg: 4}}>
                    <StringInputField
                        name="companyName"
                        value={model?.companyName}
                        label={t(CompanyFormI18N.companyNameLabel)}
                        error={errors?.companyName}
                        maxChars={Constants.COMPANY_NAME_LENGTH_MAX}
                        onChange={onChange}
                    />
                </Grid>
                <Grid container direction="row" gap={3} width="100%">
                    <Grid size={{sm: 4, md: 3, lg: 2}}>
                        <SelectInputField
                            name="country"
                            label={t(CompanyFormI18N.countryLabel)}
                            value={model?.country}
                            error={errors?.country}
                            options={countrySelectOptions}
                            displayEmpty={true}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid size={{sm: 4, md: 3, lg: 2}}>
                        <SelectInputField
                            name="legalForm"
                            label={t(CompanyFormI18N.legalFormLabel)}
                            value={model?.legalForm}
                            error={errors?.legalForm}
                            options={businessStructureSelectOptions}
                            displayEmpty={true}
                            onChange={onChange}
                        />
                    </Grid>
                </Grid>

                <Grid size={{sm: 12, md: 6, lg: 4}}>
                    <StringInputField
                        name="website"
                        value={model?.website}
                        label={t(CompanyFormI18N.companyWebsiteLabel)}
                        error={errors?.website}
                        maxChars={Constants.WEBSITE_LENGTH_MAX}
                        onChange={onChange}
                        activatePrefix={true}
                        prefixValue="https://"
                    />
                </Grid>
                <Grid container direction="row" gap={3} width="100%">
                    <Grid size={{sm: 8, md: 4, lg: 2}}>
                        <StringInputField
                            name="commercialRegisterNumber"
                            value={model?.commercialRegisterNumber}
                            label={t(CompanyFormI18N.commercialRegisterNumberLabel)}
                            error={errors?.commercialRegisterNumber}
                            maxChars={Constants.COMMERCIAL_REGISTER_NUMBER_LENGTH_MAX}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid size={{sm: 4, md: 3, lg: 2}}>
                        <StringInputField
                            name="vatId"
                            value={model?.vatId}
                            label={t(CompanyFormI18N.vatIdLabel)}
                            error={errors?.vatId}
                            maxChars={Constants.VAT_ID_LENGTH_MAX}
                            onChange={onChange}
                        />
                    </Grid>
                </Grid>

                <Grid size={{sm: 4}}>
                    <StringInputField
                        name="streetAddress"
                        value={model?.streetAddress}
                        label={t(CompanyFormI18N.streetLabel)}
                        error={errors?.streetAddress}
                        maxChars={Constants.STREET_LENGTH_MAX}
                        onChange={onChange}
                    />
                </Grid>
                <Grid container direction="row" gap={3} width="100%">
                    <Grid size={{sm: 4, md: 2, lg: 2}}>
                        <StringInputField
                            name="postalCode"
                            value={model?.postalCode}
                            label={t(CompanyFormI18N.postalCodeLabel)}
                            error={errors?.postalCode}
                            maxChars={Constants.ZIP_LENGTH_MAX}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid size={{sm: 4, md: 2, lg: 2}}>
                        <StringInputField
                            name="city"
                            value={model?.city}
                            label={t(CompanyFormI18N.cityLabel)}
                            error={errors?.city}
                            maxChars={Constants.CITY_LENGTH_MAX}
                            onChange={onChange}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </CardEx>
    );
};
