import * as React from "react";
import { Grid2 as Grid, InputAdornment, Radio, Typography } from "@mui/material";
import { OrderDataDTO, QuoteStatus } from "@/api/data-contracts";
import { ChangeHandler, ModelAction } from "@/lib/modelstate";
import { CardEx } from "@/lib/components/CardEx";
import CurrencyInputField from "@/lib/components/inputfields/currency-input-field";
import { aidOf } from "@/lib/oid";
import NumberInputField from "@/lib/components/inputfields/number-input-field";
import { IconButtonEx } from "@/lib/components/buttons/IconButtonEx";
import RemoveIcon from "@mui/icons-material/Remove";
import InfoTooltipIcon from "@/lib/components/InfoTooltipIcon";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import { ButtonEx } from "@/lib/components/buttons/ButtonEx";
import CheckboxInputField from "@/lib/components/inputfields/checkbox-input-field";
import { Constants } from "@/api/constants";
import { useTranslation } from "react-i18next";
import { RequestKeyWordsI18N } from "@/translations";
import { OrderDataType } from "@/model/orderData";
import { useCardChangeColors } from "@/common/request-cards/common";

export type PricingCardProps = {
    orderData?: OrderDataDTO;
    onChange: ChangeHandler;
    errors: any;
    dispatch: React.Dispatch<ModelAction>;
    readonly?: boolean;
    mode: OrderDataType;
    quoteIdx?: number;
    quoteStatus?: QuoteStatus;
    orgData?: OrderDataDTO | null;
};

export const PricingCard = ({
    orderData,
    orgData,
    errors,
    onChange,
    dispatch,
    readonly,
    mode,
    quoteIdx,
    quoteStatus
}: PricingCardProps) => {
    const { t } = useTranslation();
    const { getGridChangeSx } = useCardChangeColors();
    const handleAddQuantityRow = () => {
        if (
            orderData?.quantityOptions != null &&
            orderData.quantityOptions.length < Constants.QUANTITY_OPTIONS_LENGTH_MAX
        ) {
            const index = orderData.quantityOptions.length;
            dispatch({ type: "addQuantityRow" });
            setTimeout(() => {
                const el = document.getElementsByName(`orderData.quantityOptions[${index}].quantity`)[0] as HTMLElement;
                el?.focus();
            }, 250);
        }
    };

    const handleRemoveQuantityRow = (index: number) => {
        dispatch({ type: "remQuantityRow", data: index });
    };

    const readonlyEx = readonly || mode !== "request";

    return (
        <CardEx title="Mengen und Preise">
            <Grid container spacing={2} direction="column">
                <Grid container spacing={2} direction="column">
                    {orderData?.quantityOptions?.map((field, index) => (
                        <Grid key={aidOf(field)} container size={{ xs: 12 }} spacing={2} alignItems="start">
                            <Grid
                                size={{ sm: 4 }}
                                sx={getGridChangeSx(
                                    orgData,
                                    orgData?.quantityOptions?.[index]?.quantity !== field.quantity
                                )}
                            >
                                <NumberInputField
                                    name={`orderData.quantityOptions[${index}].quantity`}
                                    label={t(RequestKeyWordsI18N.quantityLabel)}
                                    onChange={onChange}
                                    value={field.quantity}
                                    error={errors?.orderData?.quantityOptions?.[index]?.quantity}
                                    suffix={` ${t(RequestKeyWordsI18N.pcsShortLabel)}`}
                                    min={Constants.QUANTITY_MIN}
                                    max={Constants.QUANTITY_MAX}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            {mode === "accept" || (mode === "quote" && readonly) ? (
                                                <Radio
                                                    name="orderData.quantityOptionSelected"
                                                    size="small"
                                                    checked={orderData?.quantityOptionSelected === index}
                                                    value="quantityOptionSelected"
                                                    disabled={
                                                        mode !== "accept" || quoteStatus !== QuoteStatus.SUBMITTED
                                                    }
                                                    onClick={() => {
                                                        onChange({
                                                            name: `quotes[${quoteIdx}].orderData.quantityOptionSelected`,
                                                            value:
                                                                orderData?.quantityOptionSelected === index
                                                                    ? null
                                                                    : index
                                                        });
                                                    }}
                                                />
                                            ) : mode == "request" && index > 0 ? (
                                                <IconButtonEx
                                                    size="small"
                                                    Icon={RemoveIcon}
                                                    onClick={() => handleRemoveQuantityRow(index)}
                                                />
                                            ) : (
                                                <Box width="30px" />
                                            )}
                                        </InputAdornment>
                                    }
                                    disabled={readonly}
                                    helperText={
                                        orgData?.quantityOptions?.[index]?.quantity !== field.quantity
                                            ? orgData?.quantityOptions?.[index]?.quantity
                                            : undefined
                                    }
                                />
                            </Grid>
                            <Grid
                                size={{ sm: 4 }}
                                sx={getGridChangeSx(
                                    orgData,
                                    orgData?.quantityOptions?.[index]?.netPricePer1000 !== field.netPricePer1000
                                )}
                            >
                                <CurrencyInputField
                                    name={`orderData.quantityOptions[${index}].netPricePer1000`}
                                    label={t(RequestKeyWordsI18N.netPriceLabel)}
                                    onChange={onChange}
                                    value={field.netPricePer1000}
                                    error={errors?.orderData?.quantityOptions?.[index]?.netPricePer1000}
                                    min={Constants.NET_PRICE_PER_1000_MIN}
                                    max={Constants.NET_PRICE_PER_1000_MAX}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <InfoTooltipIcon tooltipText={t(RequestKeyWordsI18N.netPriceInfoTooltip)} />
                                        </InputAdornment>
                                    }
                                    disabled={readonly}
                                    helperText={
                                        orgData?.quantityOptions?.[index]?.netPricePer1000 !== field.netPricePer1000
                                            ? orgData?.quantityOptions?.[index]?.netPricePer1000
                                            : undefined
                                    }
                                />
                            </Grid>
                            <Grid size={{ sm: 4 }}>
                                <CurrencyInputField
                                    name={`orderData.quantityOptions[${index}].netPriceTotal`}
                                    label={t(RequestKeyWordsI18N.netPriceFreeOfHouseFull)}
                                    disabled
                                    value={field.netPriceTotal}
                                />
                            </Grid>
                        </Grid>
                    ))}

                    {!readonlyEx &&
                    (orderData?.quantityOptions?.length == null ||
                        orderData?.quantityOptions?.length < Constants.QUANTITY_OPTIONS_LENGTH_MAX) ? (
                        <Grid size={{ xs: 12 }} container direction="row" spacing={2}>
                            <Grid size={{ xs: 6 }}>
                                <ButtonEx
                                    data-cy={"add-price-request-button"}
                                    label={t(RequestKeyWordsI18N.addPriceButton)}
                                    variant="text"
                                    startIcon={<AddIcon />}
                                    onClick={handleAddQuantityRow}
                                    size="small"
                                />
                            </Grid>
                        </Grid>
                    ) : null}
                </Grid>

                <Grid size={{ xs: 12 }}>
                    <CheckboxInputField
                        name="orderData.separateExtraCosts"
                        label={t(RequestKeyWordsI18N.separateExtraCosts)}
                        onChange={onChange}
                        value={orderData?.separateExtraCosts}
                        error={errors?.orderData?.separateExtraCosts}
                        disabled={readonlyEx}
                    />
                </Grid>

                {mode === "quote" &&
                    (orderData?.separateExtraCosts ? (
                        <>
                            <Grid
                                size={{ xs: 12 }}
                                sx={getGridChangeSx(orgData, orgData?.toolingCosts !== orderData?.toolingCosts)}
                            >
                                <CurrencyInputField
                                    name="orderData.toolingCosts"
                                    label={t(RequestKeyWordsI18N.toolingCostsLabel)}
                                    onChange={onChange}
                                    value={orderData.toolingCosts}
                                    error={errors?.orderData?.toolingCosts}
                                    min={Constants.TOOLING_COSTS_PRICE_MIN}
                                    max={Constants.TOOLING_COSTS_PRICE_MAX}
                                    disabled={readonly}
                                    helperText={
                                        orgData && orgData?.toolingCosts !== orderData?.toolingCosts
                                            ? orgData?.toolingCosts
                                            : undefined
                                    }
                                />
                            </Grid>
                            <Grid
                                size={{ xs: 12 }}
                                sx={getGridChangeSx(orgData, orgData?.clicheCosts !== orderData?.clicheCosts)}
                            >
                                <CurrencyInputField
                                    name="orderData.clicheCosts"
                                    label={t(RequestKeyWordsI18N.clicheCostsLabel)}
                                    onChange={onChange}
                                    value={orderData.clicheCosts}
                                    error={errors?.orderData?.clicheCosts}
                                    min={Constants.CLICHE_COSTS_PRICE_MIN}
                                    max={Constants.CLICHE_COSTS_PRICE_MAX}
                                    disabled={readonly}
                                    helperText={
                                        orgData && orgData?.clicheCosts !== orderData?.clicheCosts
                                            ? orgData?.clicheCosts
                                            : undefined
                                    }
                                />
                            </Grid>
                        </>
                    ) : (
                        <Grid marginTop={-2}>
                            <Typography variant="body1" color="textSecondary">
                                Werkzeug- und Klischee-Kosten sind in den Nettopreisen-frei-Haus-pro-1000 enthalten.
                            </Typography>
                        </Grid>
                    ))}
            </Grid>
        </CardEx>
    );
};
