import { KeyboardEvent, ReactNode, useImperativeHandle, useLayoutEffect, useRef } from "react";
import { trace } from "@/lib/utils";

export type ShortCut = Record<string, () => void>;

export type ShortCutHandlerProps = {
    shortcuts: ShortCut;
    children: ReactNode;
};

type ShortCutApi = {
    exec: (cmd: () => void) => void;
    shortcuts: ShortCut;
};

export const ShortCutHandler = ({ shortcuts, children }: ShortCutHandlerProps) => {
    const domRef = useRef<HTMLSpanElement>(undefined);
    const apiRef = useRef<ShortCutApi>(undefined);

    useImperativeHandle(
        apiRef,
        () => ({
            exec: (cmd: () => void) => cmd(),
            shortcuts
        }),
        [shortcuts]
    );

    useLayoutEffect(() => {
        const handler = (event: KeyboardEvent) => {
            if (
                (!event.repeat && domRef.current?.contains(event.target as HTMLElement)) ||
                event.target === document.body
            ) {
                // check if key is Alt, Ctrl or SHIFT itself
                const key =
                    event.key !== "Alt" && event.key !== "Control" && event.key !== "Shift"
                        ? (event.ctrlKey ? "CTRL-" : "") +
                          (event.altKey ? "ALT-" : "") +
                          (event.shiftKey ? "SHIFT-" : "") +
                          event.key
                        : event.key;

                const shortcut = apiRef.current?.shortcuts[key.toUpperCase()] || apiRef.current?.shortcuts[key];
                trace("shortcut", { key, shortcut: shortcut ? "yes" : "no" });
                if (shortcut) {
                    event.preventDefault();
                    event.stopPropagation();
                    apiRef.current?.exec(shortcut);
                }
            }
        };

        if (apiRef.current?.shortcuts) {
            window.document.addEventListener("keydown", handler as any, false);
            return () => window.document.removeEventListener("keydown", handler as any, false);
        }

        return undefined;
    }, []);

    return <span ref={domRef as any}>{children}</span>;
};
