import React, { createContext, useContext, useEffect, useState } from "react";
import { createTheme, ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { PaletteMode } from "@mui/material";
import { grey, indigo } from "@mui/material/colors";

export interface AppThemeContextProps {
    mode: PaletteMode;
    toggleColorMode: () => void;
}

const AppThemeContext = createContext<AppThemeContextProps | undefined>(undefined);

// const scrollLeft = keyframes`
//     0% {
//         transform: translateX(0%);
//     }
//     100% {
//         transform: translateX(-50%);
//     }
// `;

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [mode, setMode] = useState<PaletteMode>("light");

    useEffect(() => {
        const storedMode = localStorage.getItem("themeMode");
        if (storedMode && (storedMode === "light" || storedMode === "dark")) {
            setMode(storedMode);
        }
    }, []);

    const toggleColorMode = () => {
        setMode((prevMode) => {
            const newMode = prevMode === "light" ? "dark" : "light";
            localStorage.setItem("themeMode", newMode);
            return newMode;
        });
    };

    const theme = createTheme({
        palette: {
            mode,
            primary: {
                main: indigo[500]
            },
            info: {
                main: indigo[300],
                light: indigo[400],
                dark: indigo[500]
            },
            background: {
                // default: mode === "dark" ? "black" : blue[100],
                // paper: mode === "dark" ?  grey["200"]  : "white",
            }
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 960,
                lg: 1280,
                xl: 1920
            }
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    body: {
                        backgroundColor: mode === "dark" ? undefined : grey[50]
                    }
                }
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        // backgroundColor: mode === "dark" ? "black" : "white",
                        // "--paper-shadow": undefined,
                    }
                }
            },
            // MuiFormHelperText: {
            //     styleOverrides: {
            //         root: {
            //             overflow: "hidden",
            //             position: "relative",
            //             // width: "100%",
            //             "&>div": {
            //                 display: "inline-block",
            //                 whiteSpace: "nowrap",
            //                 animation: `${scrollLeft} 6s linear infinite`
            //             }
            //         }
            //     }
            // },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        "& input:-webkit-autofill": {
                            WebkitBoxShadow: `0 0 0 100px ${mode === "dark" ? grey[800] : "white"} inset !important`,
                            backgroundColor: "transparent !important",
                            caretColor: "inherit"
                        },
                        "& input:-webkit-autofill:hover": {
                            backgroundColor: "transparent !important"
                        },
                        "& input:-webkit-autofill:focus": {
                            backgroundColor: "transparent !important"
                        },
                        "& input:-webkit-autofill:active": {
                            backgroundColor: "transparent !important"
                        }
                    }
                }
            }
        }
    });

    return (
        <AppThemeContext.Provider value={{ mode, toggleColorMode }}>
            <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
        </AppThemeContext.Provider>
    );
};

export const useAppTheme = (): AppThemeContextProps => {
    const context = useContext(AppThemeContext);
    if (!context) {
        throw new Error("useTheme must be used within a ThemeProvider");
    }
    return context;
};
