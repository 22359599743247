import React, { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import "dayjs/locale/en";
import "dayjs/locale/de";
import { PickersInputLocaleText } from "@mui/x-date-pickers/locales";

import type { i18n } from "i18next";

export type LocaleProviderProps = {
    i18n: i18n;
    children: React.ReactNode;
};

export const LocaleProvider = ({ i18n, children }: LocaleProviderProps) => {
    const [locale, setLocale] = useState(i18n.language || "de");
    const [dateLocaleText, setDateLocaleText] = useState<PickersInputLocaleText<any> | undefined>(undefined);

    useEffect(() => {
        const handleLanguageChange = async (lng: string) => {
            try {
                if (lng !== "de") {
                    await import(`dayjs/locale/${lng}`); // Dynamically load the locale
                }
                setLocale(lng); // Update the locale state for MUI
            } catch (error) {
                console.error(`Failed to load Day.js locale for ${lng}:`, error);
                setLocale("en");
            }
        };

        i18n.on("languageChanged", handleLanguageChange);
        return () => {
            i18n.off("languageChanged", handleLanguageChange);
        };
    }, [i18n]);

    useEffect(() => {
        dayjs.locale(locale); // Set Day.js locale globally
        setLocale(locale); // Update the locale state for MUI
        switch (locale) {
            case "de":
                setDateLocaleText({
                    fieldDayPlaceholder: (params) => "TT",
                    fieldYearPlaceholder: (params) => "JJJJ"
                });
                break;
            default:
                setDateLocaleText(undefined);
                break;
        }
    }, [locale]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale} localeText={dateLocaleText}>
            {children}
        </LocalizationProvider>
    );
};
