import React, {useEffect, useMemo} from "react";
import {Box, CircularProgress, Grid2} from "@mui/material";
import * as yup from "yup";
import {useAuth} from "@/app/AuthContext";
import {useNavigate} from "react-router";
import {Link, useSearchParams} from "react-router-dom";
import {AppPaths} from "@/app/AppPaths";
import {SignInDTO} from "@/api/data-contracts";
import {useModelState} from "@/lib/modelstate";
import StringInputField from "@/lib/components/inputfields/string-input-field";
import {ButtonEx} from "@/lib/components/buttons/ButtonEx";
import {useSnackbarEx} from "@/lib/snackbarex";
import {EMAIL_REGEX} from "@/regex";
import {useTranslation} from "react-i18next";
import {AuthenticationPageI18N, UserFormI18N} from "@/translations";
import {encodePasswordBase64} from "@/lib/utils";

export default function UserAuthForm() {
    const {t} = useTranslation();
    const {login} = useAuth();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const redirectUrl = searchParams.get("redirect") || AppPaths.EXTERNAL_REQUEST_OVERVIEW;
    const [busy, setBusy] = React.useState(false);
    const {showMessage} = useSnackbarEx();

    const formSchema = useMemo(
        () =>
            yup.object().shape({
                email: yup
                    .string()
                    .required(t(UserFormI18N.requiredEmailError))
                    .matches(EMAIL_REGEX, t(UserFormI18N.invalidEmailError)),
                password: yup.string().required(t(UserFormI18N.requiredPasswordError))
            }),
        [t]
    );

    const {model, errors, onChange, setErrors} = useModelState<SignInDTO>({
        schema: formSchema,
        initialState: {email: "", password: ""},
    });

    const formIsValid = useMemo(() => {
        try {
            formSchema.validateSync(model, {abortEarly: false});
            return true;
        } catch (error) {
            return false;
        }
    }, [model, formSchema]);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (model.email != null && model.password != null) {
            try {
                setBusy(true);
                // Base64 Kodierung des Passworts nach der Validierung. UTF-8 konform.
                const encodedPassword = encodePasswordBase64(model.password);
                await login(model.email, encodedPassword);
                navigate(redirectUrl);
            } catch (error: any) {
                const errorMessage =
                    error?.response?.data?.error?.message || t(AuthenticationPageI18N.loginFailedSummary);
                setErrors({password: errorMessage});
                showMessage({
                    severity: "error",
                    summary: t(AuthenticationPageI18N.loginFailedSummary),
                    details: t(AuthenticationPageI18N.loginFailedMessage)
                });
            } finally {
                setBusy(false);
            }
        }
    };

    const emailRef = React.useRef<HTMLInputElement>(null);

    useEffect(() => {
        setTimeout(() => {
            emailRef.current?.focus();
        }, 250);
    }, []);

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{mt: 1, width: {xs: "auto", sm: 400, md: 400, lg: 400}}}>
            <Grid2 container direction="column" spacing={3}>
                <Grid2>
                    <StringInputField
                        label={t(UserFormI18N.emailLabel)}
                        name="email"
                        value={model.email}
                        onChange={onChange}
                        disabled={busy}
                        error={errors.email}
                        autoComplete="username"
                        textFieldRef={emailRef}
                    />
                </Grid2>
                <Grid2>
                    <StringInputField
                        label={t(UserFormI18N.passwordLabel)}
                        name="password"
                        value={model.password}
                        onChange={onChange}
                        type="password"
                        disabled={busy}
                        error={errors.password}
                        autoComplete="current-password"
                    />
                </Grid2>
                <Grid2>
                    <ButtonEx
                        label={busy ? <CircularProgress size={24}/> : t(AuthenticationPageI18N.loginButton)}
                        type="submit"
                        fullWidth
                        variant="contained"
                        disabled={busy || !formIsValid}
                        sx={{mt: 3, mb: 2}}
                    />
                </Grid2>
                <Grid2 container justifyContent="flex-end">
                    <Link
                        to={AppPaths.USER_PASSWORD_RESET_REQUEST}
                        style={{textDecoration: "none", color: "#1976d2"}}
                    >
                        Passwort vergessen?
                    </Link>
                </Grid2>
            </Grid2>
        </Box>
    );
}
