import {
    AlertTriangle,
    ArrowDownLeft,
    ArrowLeftRight,
    ArrowRight,
    ArrowUpRight,
    BadgeAlert,
    Check,
    ChevronLeft,
    ChevronRight,
    CircuitBoardIcon,
    Command,
    CreditCard,
    Edit,
    FacebookIcon,
    File,
    FileCheck2,
    FileText,
    Folder,
    GanttChart,
    HelpCircle,
    Image,
    IndianRupee,
    InstagramIcon,
    Laptop,
    LayoutDashboardIcon,
    LinkedinIcon,
    Loader2,
    LogIn,
    LucideIcon,
    LucideProps,
    Moon,
    MoreVertical,
    Plus,
    Settings,
    SunMedium,
    Trash,
    TwitterIcon,
    User,
    User2Icon,
    UserCog,
    UserX2Icon,
    X
} from "lucide-react";
import MenuIcon from "@mui/icons-material/Menu";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import WbSunnyRoundedIcon from "@mui/icons-material/WbSunnyRounded";
import ModeNightRoundedIcon from "@mui/icons-material/ModeNightRounded";

export type Icon = LucideIcon;

export const Icons = {
    dashboard: LayoutDashboardIcon,
    logo: Command,
    login: LogIn,
    close: X,
    profile: User2Icon,
    spinner: Loader2,
    kanban: CircuitBoardIcon,
    chevronLeft: ChevronLeft,
    chevronRight: ChevronRight,
    trash: Trash,
    employee: UserX2Icon,
    post: FileText,
    page: File,
    media: Image,
    settings: Settings,
    billing: CreditCard,
    ellipsis: MoreVertical,
    add: Plus,
    warning: AlertTriangle,
    user: User,
    arrowRight: ArrowRight,
    help: HelpCircle,
    sun: SunMedium,
    moon: Moon,
    laptop: Laptop,
    edit: Edit,
    credit: ArrowDownLeft,
    debit: ArrowUpRight,
    description: GanttChart,
    transaction: IndianRupee,
    issue: BadgeAlert,
    report: FileCheck2,
    manager: UserCog,
    total: ArrowLeftRight,
    folder: Folder,
    facebook: FacebookIcon,
    instagram: InstagramIcon,
    twitter: TwitterIcon,
    linkedin: LinkedinIcon,
    check: Check,
    menu: MenuIcon,
    AutoAwesomeRounded: AutoAwesomeRoundedIcon,
    WbSunnyRounded: WbSunnyRoundedIcon,
    ModeNightRounded: ModeNightRoundedIcon,
    github: ({ ...props }: LucideProps) => (
        <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fab"
            data-icon="github"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 496 512"
            {...props}
        >
            <path
                fill="currentColor"
                d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3 .3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5 .3-6.2 2.3zm44.2-1.7c-2.9 .7-4.9 2.6-4.6 4.9 .3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3 .7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3 .3 2.9 2.3 3.9 1.6 1 3.6 .7 4.3-.7 .7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3 .7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3 .7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"
            ></path>
        </svg>
    ),
    google: ({ ...props }: LucideProps) => (
        <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fab"
            data-icon="google"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 496 512"
            {...props}
        >
            <path
                className="text-pink-600"
                fill="currentColor"
                d="M488 261.8c0-15-1.3-29.6-3.8-43.8H252v83.3h133.1c-5.8 31.3-23.2 57.8-49.1 75.4v62.7h79.4c46.6-43 73.6-106.4 73.6-177.6zM252 504c68.2 0 125.5-22.7 167.3-61.4l-79.4-62.7c-21.9 14.7-49.7 23.6-87.9 23.6-67.6 0-124.9-45.7-145.4-107.4H23.1v67.6C64.9 457.2 143 504 252 504zM106.6 303.5c-4.8-14.3-7.6-29.5-7.6-45.5s2.7-31.1 7.6-45.5v-67.6H23.1C8.3 177.9 0 217.1 0 257.9s8.3 80 23.1 112.5l83.5-66.9zM252 110.4c38.4 0 72.3 13.3 99.3 39.2l74.5-74.5C377.4 42.3 319.2 18 252 18 143 18 64.9 64.8 23.1 146.5l83.5 66.9c20.5-61.7 77.8-107.4 145.4-107.4z"
            />
        </svg>
    )
};
