// AppPaths.ts
import { generatePathSafe } from "@/lib/utils";

export const AppPaths = {
    LANDING_PAGE: "/",
    SIGN_IN: "/sign-in",

    COMPANY_SIGNUP: "/company-signup/:token",
    COMPANY_SIGNUP_fn: (token: string) => generatePathSafe(AppPaths.COMPANY_SIGNUP, { token }),

    COMPANY_OVERVIEW: "/company-overview",
    COMPANY_UNVERIFIED: "/company-unverified",
    COMPANY: "/company/:id",
    COMPANY_fn: (id: number) => generatePathSafe(AppPaths.COMPANY, { id }),

    USER_SIGNUP: "/user-signup/:token",
    USER_PASSWORD_RESET: "/user-password-reset/:token",
    USER_PASSWORD_RESET_REQUEST: "/user-password-reset-request",
    USER: "/user/:id",
    USER_fn: (id: number) => generatePathSafe(AppPaths.USER, { id }),

    EXTERNAL_REQUEST_OVERVIEW: "/external-request-overview",
    EXTERNAL_REQUEST: "/external-request/:id",
    EXTERNAL_REQUEST_fn: (id: number) => generatePathSafe(AppPaths.EXTERNAL_REQUEST, { id }),

    INTERNAL_REQUEST_OVERVIEW: "/internal-request-overview",
    INTERNAL_REQUEST: "/internal-request/:id?",
    INTERNAL_REQUEST_fn: (id: number | "create" | "clone", cloneId?: number) => {
        const route = generatePathSafe(AppPaths.INTERNAL_REQUEST, { id });
        if (id === "clone") {
            return `${route}?sourceId=${cloneId}`;
        }
        return route;
    }
};
