import { DataCacheStatus, useDataCache } from "@/lib/datacache";
import { cartonGradeApi, fefcoCodeApi, userApi } from "@/boxhub-api";
import { useMemo } from "react";
import {
    CompanyStatus,
    Country,
    Language,
    LegalForm,
    NumberOfPallet,
    PalletType,
    PrintType,
    QuoteStatus,
    RequestStatus,
    Salutation,
    TerminationSource,
    UserDisplayDTO
} from "@/api/data-contracts";

export const useFefcoCodes = (): [string[], DataCacheStatus] => {
    const cache = useDataCache("fefco-codes", fefcoCodeApi.getFefcoCodes);
    const fefcoCodes = useMemo<string[]>(
        () => cache[0]?.map((fefcoCode) => fefcoCode.code as string).filter(Boolean) || [],
        [cache]
    );
    return [fefcoCodes, cache[1]];
};

export const useCartonGrades = (): [string[], DataCacheStatus] => {
    const cache = useDataCache("carton-grades", cartonGradeApi.getCartonGrades);
    const cartonGrades = useMemo(
        () => cache[0]?.map((cartonGrade) => cartonGrade.code as string).filter(Boolean) || [],
        [cache]
    );
    return [cartonGrades, cache[1]];
};

export const useEmployees = (): [UserDisplayDTO[], DataCacheStatus] =>
    useDataCache("employees", userApi.getActiveCompanyUsers);

export const palletTypeOptions = [
    PalletType.EURO_PALLET_NEW,
    PalletType.EURO_PALLET_NW,
    PalletType.EURO_PALLET_USED,
    PalletType.INDUSTRY_PALLET,
    PalletType.ONE_WAY_PALLET
];

export const printOptions = [PrintType.NO_PRINT, PrintType.WARNING_PRINT, PrintType.FULL_AREA_PRINT];

export const numberOfPalletLabels = [NumberOfPallet.ONE_ON_THE_SIDE, NumberOfPallet.ONE_ON_THE_LONG_AND_SHORT_SIDE];

export const salutationOptions = [
    Salutation.MR,
    Salutation.MS,
    Salutation.DR_MALE,
    Salutation.DR_FEMALE,
    Salutation.PROF_MALE,
    Salutation.PROF_FEMALE
];

export const companyStatusOptions = [
    CompanyStatus.OPERATOR,
    CompanyStatus.INVITED,
    CompanyStatus.UNVERIFIED,
    CompanyStatus.CONFIRMED,
    CompanyStatus.REJECTED,
    CompanyStatus.CONFIRMED_LOCKED,
    CompanyStatus.TERMINATION_RECEIVED,
    CompanyStatus.TERMINATION_RECEIVED_LOCKED,
    CompanyStatus.TERMINATED
];

export const quoteStatusOptions = [
    QuoteStatus.VACANT,
    QuoteStatus.SUBMITTED,
    QuoteStatus.REJECTED,
    QuoteStatus.ACCEPTED,
    QuoteStatus.DISMISSED,
    QuoteStatus.NOT_VACANT
];

export const requestStatusOptions = [
    RequestStatus.CREATED,
    RequestStatus.PUBLISHED,
    RequestStatus.TO_BE_CONFIRMED,
    RequestStatus.CONFIRMED,
    RequestStatus.CANCELLED,
    RequestStatus.DEACTIVATED
];

export const languageOptions = [Language.GERMAN];

export const countryOptions = [Country.GERMANY, Country.NETHERLANDS];

export const terminationInitiatorOptions = [TerminationSource.CLIENT, TerminationSource.OPERATOR];

export const legalFormOptions = [
    LegalForm.AG,
    LegalForm.AG_KG,
    LegalForm.AGCoKGaA,
    LegalForm.AGCoOHG,
    LegalForm.GAG,
    LegalForm.GbR,
    LegalForm.GmbH,
    LegalForm.GmbHCoKG,
    LegalForm.GmbHCoKGaA,
    LegalForm.GmbHCoOHG,
    LegalForm.GmbHG,
    LegalForm.KG,
    LegalForm.KGaA,
    LegalForm.KGaACoKG,
    LegalForm.OHG,
    LegalForm.OHGMbh,
    LegalForm.PartG,
    LegalForm.PartGMbB,
    LegalForm.Sonstige,
    LegalForm.StiftungCoKG,
    LegalForm.UGH,
    LegalForm.UGHCoKG
];
