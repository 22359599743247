import { ReactNode } from "react";
import { Grid2, SxProps, Theme } from "@mui/material";

export type LayoutItemProps = {
    children: ReactNode;
    grow?: boolean;
    body?: boolean;
    sx?: SxProps<Theme>;
    padding?: number;
};

export const LayoutItem = ({ sx, grow, padding, body, children }: LayoutItemProps) => {
    return (
        <Grid2
            sx={{ height: body ? "100%" : undefined, overflow: body ? "auto" : undefined, ...sx }}
            flexGrow={grow ? 1 : undefined}
            padding={padding}
        >
            {children}
        </Grid2>
    );
};

export type LayoutProps = {
    root?: boolean;
    children: ReactNode;
    sx?: SxProps<Theme>;
};

export const Layout = ({ root, sx, children }: LayoutProps) => {
    return (
        <Grid2
            container
            direction="column"
            flexWrap="nowrap"
            alignItems="stretch"
            height={root ? "100vh" : "100%"}
            sx={sx}
        >
            {children}
        </Grid2>
    );
};
