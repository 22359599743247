import dayjs from "dayjs";

let globalDisplacementDays = 0;

export const setGlobalDateDisplacement = (displacementDays?: number) => {
    globalDisplacementDays = displacementDays && displacementDays > 0 ? displacementDays : 0;
};

export const getCurrentDate = () => {
    if (globalDisplacementDays > 0) {
        return dayjs().add(globalDisplacementDays, "day").toDate();
    }
    return new Date();
};
