import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

/**
 * Props for the InfoTooltipIcon component.
 *
 * @property {string} tooltipText - The text to display inside the tooltip.
 */
interface InfoTooltipIconProps {
    tooltipText: string;
}

/**
 * InfoTooltipIcon component.
 *
 * @param {InfoTooltipIconProps} props - The props for the component.
 * @returns {JSX.Element} The rendered InfoTooltipIcon component.
 */
const InfoTooltipIcon: React.FC<InfoTooltipIconProps> = ({ tooltipText }) => {
    // Split the tooltipText by '\n' and map to JSX
    const formattedTooltipText = tooltipText.split("\n").map((line, index) => (
        <span key={index}>
            {line}
            <br />
        </span>
    ));

    return (
        <Tooltip title={<span>{formattedTooltipText}</span>} arrow>
            <IconButton size="small" tabIndex={-1}>
                <InfoIcon fontSize="small" />
            </IconButton>
        </Tooltip>
    );
};

export default InfoTooltipIcon;
