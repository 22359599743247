/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { CartonGradeDTO } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class CartonGradeApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * @description Find a specific product variety based on the code
     *
     * @tags carton-grade-api
     * @name GetCartonGradeByCode
     * @summary Find product variety by code
     * @request GET:/api/carton-grades/{code}
     * @secure
     */
    getCartonGradeByCode = (code: string, params: RequestParams = {}) =>
        this.request<CartonGradeDTO, (string[] | Record<string, string>) | void | CartonGradeDTO>({
            path: `/api/carton-grades/${code}`,
            method: "GET",
            secure: true,
            ...params
        });
    /**
     * @description Fetch all codes from the product_variety table
     *
     * @tags carton-grade-api
     * @name GetCartonGrades
     * @summary Get all product variety codes
     * @request GET:/api/carton-grades
     * @secure
     */
    getCartonGrades = (params: RequestParams = {}) =>
        this.request<CartonGradeDTO[], (string[] | Record<string, string>) | void>({
            path: `/api/carton-grades`,
            method: "GET",
            secure: true,
            ...params
        });
}
