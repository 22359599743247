import {
    OrderDataDTO,
    QuoteStatus,
    RequestDisplayDTO,
    RequestDTO,
    RequestOverviewRowDTO,
    RequestStatus
} from "@/api/data-contracts";
import {convertToThreeDigitString, formatCurrency, isDate, parseCurrency, parseNumericString} from "@/lib/utils";
import dayjs from "dayjs";
import {ModelAction} from "@/lib/modelstate";
import {getField, pathOf, setField} from "@/lib/accessor";
import {quoteStatusOptions, requestStatusOptions} from "@/datacaches";

/**
 * Sorts an array of internal requests by their status and delivery date.
 *
 * @param {RequestOverviewRowDTO[]} requests - The array of requests to be sorted.
 * @returns {RequestOverviewRowDTO[]} - The sorted array of requests.
 */
export function sortInternalRequestsByStatusAndDeliveryDate(requests: RequestOverviewRowDTO[]) {
    return requests.sort((a, b) => {
        const statusA = a.requestStatus ?? RequestStatus.CREATED;
        const statusB = a.requestStatus ?? RequestStatus.CREATED;

        const statusIndexA = requestStatusOptions.indexOf(statusA);
        const statusIndexB = requestStatusOptions.indexOf(statusB);

        if (statusIndexA !== statusIndexB) {
            return statusIndexA - statusIndexB;
        }

        const deliveryDateA = new Date(a.deliveryDateFrom ?? 0).getTime();
        const deliveryDateB = new Date(b.deliveryDateFrom ?? 0).getTime();

        return deliveryDateA - deliveryDateB;
    });
}

/**
 * Sorts an array of external requests by their quote status and delivery date.
 *
 * @param {RequestOverviewRowDTO[]} requests - The array of requests to be sorted.
 * @returns {RequestOverviewRowDTO[]} - The sorted array of requests.
 */
export function sortExternalRequestsByStatusAndDeliveryDate(requests: RequestOverviewRowDTO[]) {
    return requests.sort((a, b) => {
        const statusA = a.quoteStatus ?? QuoteStatus.VACANT;
        const statusB = b.quoteStatus ?? QuoteStatus.VACANT;

        const statusIndexA = quoteStatusOptions.indexOf(statusA);
        const statusIndexB = quoteStatusOptions.indexOf(statusB);

        if (statusIndexA !== statusIndexB) {
            return statusIndexA - statusIndexB;
        }

        const deliveryDateA = new Date(a.deliveryDateFrom ?? 0).getTime();
        const deliveryDateB = new Date(b.deliveryDateFrom ?? 0).getTime();

        return deliveryDateA - deliveryDateB;
    });
}

/**
 * Formats a customer quotation number from the given request.
 *
 * @param {RequestDisplayDTO | RequestDTO | undefined | null} request - The request object containing the company number and customer request number.
 * @returns {string | null} - The constructed customer quotation number in the format "XXX-YYY" or null if the request is not provided.
 */
export function formatCustomerRequestNumber(request: RequestDisplayDTO | RequestDTO | undefined | null): string | null {
    if (!request) {
        return null;
    }
    return `${convertToThreeDigitString(request.company?.number)}-${request.customerReferenceNumber}`;
}

export interface TotalPriceDisplayProps {
    locale: string;
    netPriceValue: string | number;
    quantityValue?: string | number | null;
}

export function calcTotalPrice(netPricePerThousand?: number, quantity?: number) {
    return netPricePerThousand == null || quantity == null ? null : (netPricePerThousand * quantity) / 1000;
}

/**
 * Calculates the full price as a currency string based on the provided total price display properties.
 *
 * @param {TotalPriceDisplayProps} totalPriceDisplayProps - The properties used to calculate the full price.
 * @param {string} totalPriceDisplayProps.locale - The locale used for currency formatting.
 * @param {string | number} totalPriceDisplayProps.netPriceValue - The net price value, either as a string or number.
 * @param {string | number | null} [totalPriceDisplayProps.quantityValue] - The quantity value, either as a string, number, or null.
 * @returns {string | null} - The formatted full price as a currency string, or null if the calculation fails.
 */
export function calculateFullPriceAsCurrency(totalPriceDisplayProps: TotalPriceDisplayProps): string | null {
    const parseCurrencyWithLocale = parseCurrency(totalPriceDisplayProps.locale);
    const parseNumericStringWithLocale = parseNumericString(totalPriceDisplayProps.locale);

    const parsedNetPrice =
        typeof totalPriceDisplayProps.netPriceValue === "string"
            ? parseCurrencyWithLocale(totalPriceDisplayProps.netPriceValue)
            : totalPriceDisplayProps.netPriceValue;
    const netPricePerThousand = parsedNetPrice ?? 0;

    const parsedQuantity = parseNumericStringWithLocale(0, totalPriceDisplayProps.quantityValue);
    const quantity = parsedQuantity ?? 0;

    // Nettopreis-frei-Haus-Gesamt = (Nettopreis-frei-Haus-pro-1000 * Anzahl) / 1000
    return formatCurrency(calcTotalPrice(netPricePerThousand, quantity), totalPriceDisplayProps.locale);
}

export const requestReducer = (model: RequestDTO, action: ModelAction) => {
    switch (action.type) {
        case "addQuantityRow":
            return {
                ...model,
                orderData: {
                    ...model.orderData,
                    quantityOptions: [
                        ...(model.orderData?.quantityOptions ?? []),
                        {
                            quantity: undefined,
                            netPricePer1000: undefined
                        }
                    ]
                }
            };

        case "remQuantityRow":
            return action.data > 0
                ? {
                    ...model,
                    orderData: {
                        ...model.orderData,
                        quantityOptions: model.orderData?.quantityOptions?.filter((_, index) => index !== action.data)
                    }
                }
                : model;

        case "setDocuments":
            return {
                ...model,
                orderDocuments: {
                    ...model.orderDocuments,
                    documents: action.data
                }
            };

        case "setChatGroup":
            return {
                ...model,
                chatGroup: action.data
            };

        case "remDocument":
            return {
                ...model,
                orderDocuments: {
                    ...model.orderDocuments,
                    documents: model.orderDocuments?.documents?.filter((o) => o !== action.data)
                }
            };

        case "--onchange": {
            const field = action.data[0];
            switch (field) {
                case pathOf<RequestDTO, OrderDataDTO>("orderData", "allowPalletOverhang"):
                    if (!model.orderData?.allowPalletOverhang) {
                        return setField(model, pathOf<RequestDTO, OrderDataDTO>("orderData", "palletOverhang"), null);
                    }
                    break;

                case pathOf<RequestDTO, OrderDataDTO>("orderData", "palletizingFixed"):
                    if (!model.orderData?.palletizingFixed) {
                        return setField(model, pathOf<RequestDTO, OrderDataDTO>("orderData", "palletizingNotes"), null);
                    }
                    break;
            }
            switch (true) {
                case /deliveryDateFrom/.test(field): {
                    if (isDate(model.deliveryDateFrom)) {
                        if (model.deliveryDateTo == null) {
                            return {...model, deliveryDateTo: model.deliveryDateFrom};
                        } else if (model.deliveryDateFrom) {
                            const differenceInDays = dayjs(model.deliveryDateTo).diff(
                                dayjs(model.deliveryDateFrom),
                                "day"
                            );
                            const newDateTo =
                                differenceInDays <= 0
                                    ? model.deliveryDateFrom
                                    : dayjs(model.deliveryDateFrom).add(differenceInDays, "day").toDate();
                            return {...model, deliveryDateTo: newDateTo as any};
                        }
                    }
                    break;
                }

                // case /deliveryDateTo/.test(field): {
                //     break;
                // }

                case /orderData\.quantityOptions\[\w+]/.test(field): {
                    const match = field.match(/(orderData\.quantityOptions\[\d+])\./);
                    if (match) {
                        const quantityField = `${match[1]}.quantity`;
                        const netPricePer1000Field = `${match[1]}.netPricePer1000`;
                        const netPriceTotalField = `${match[1]}.netPriceTotal`;
                        return setField(
                            model,
                            netPriceTotalField,
                            calcTotalPrice(getField(model, netPricePer1000Field), getField(model, quantityField))
                        );
                    }
                    break;
                }
            }
            return model;
        }
        default:
            return model;
    }
};

export function normalizeBooleansInRequest(request: RequestDTO): RequestDTO {
    if (!request) {
        return request;
    }

    const booleanProperties = [
        "glued",
        "stitched",
        "taped",
        "siliconeTape",
        "tearTape",
        "palletLabelNeutral",
        "stretching",
        "counterPallets",
        "palletExchange",
        "allowPalletOverhang",
        "palletizingFixed"
        // Add any other boolean properties here
    ];

    // TODO: Move it to utils function
    // Helper function to recursively normalize boolean properties in an object
    function normalizeObject(obj: any): any {
        if (obj == null || typeof obj !== "object") {
            return obj;
        }

        for (const key of Object.keys(obj)) {
            const value = obj[key];

            if (booleanProperties.includes(key)) {
                if (value === null) {
                    obj[key] = false; // Set null booleans to false
                }
            } else if (Array.isArray(value)) {
                // If the property is an array, normalize each element
                obj[key] = value.map((item: any) => normalizeObject(item));
            } else if (typeof value === "object") {
                // Recursively normalize nested objects
                obj[key] = normalizeObject(value);
            }
            // For non-boolean primitive values, do nothing
        }

        return obj;
    }

    // Begin normalization from the root request object
    return normalizeObject({...request}); // Spread to avoid mutating original
}
