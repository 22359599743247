/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ChatAnswerDTO, ChatGroupDTO, ChatQuestionDTO } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class ChatApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * @description A question is answered
     *
     * @tags chat-api
     * @name ChatAnswer
     * @summary Answer question
     * @request POST:/api/chats/{id}/answer
     * @secure
     */
    chatAnswer = (id: number, data: ChatAnswerDTO, params: RequestParams = {}) =>
        this.request<ChatGroupDTO, (string[] | Record<string, string>) | void | ChatGroupDTO>({
            path: `/api/chats/${id}/answer`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * @description A question is created
     *
     * @tags chat-api
     * @name ChatQuestion
     * @summary Ask a question
     * @request POST:/api/chats/{id}/question
     * @secure
     */
    chatQuestion = (id: number, data: ChatQuestionDTO, params: RequestParams = {}) =>
        this.request<ChatGroupDTO, (string[] | Record<string, string>) | void | ChatGroupDTO>({
            path: `/api/chats/${id}/question`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
}
