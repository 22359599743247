import { LabelValue } from "@/lib/components/LabelValueList";

/**
 * Generates a field object for a string with a label and optional tooltip.
 *
 * @param {string | undefined} stringValue - The string value to be displayed. If undefined, the default value is used.
 * @param {string} label - The label for the field.
 * @param {string} [defaultValue] - The default value to use if the string value is not available.
 * @param {string} [tooltipKey] - The key for the tooltip text.
 * @param {boolean} [multiline=false] - Whether the field should support multiline text.
 * @returns {LabelValue} The field object with label, value, tooltipKey, and multiline properties.
 */
export const stringField = (
    stringValue: string | undefined | null,
    label: string,
    defaultValue: string = "",
    tooltipKey?: string,
    multiline: boolean = false
): LabelValue => ({
    label,
    value: !!stringValue ? stringValue : defaultValue,
    tooltipKey,
    multiline
});
