import {Badge} from "@/lib/components/Badge";
import * as React from "react";
import {useAppTheme} from "@/app/AppThemeContext";
import {GroupBox} from "@/lib/components/GroupBox";
import {CompanyStatus} from "@/api/data-contracts";
import {useTranslation} from "react-i18next";
import {CompanyStatusI18N} from "@/translations";
import {blue, deepOrange, green, grey, orange, red, yellow} from "@mui/material/colors";

function getCompanyStatusText(t: (key: string) => string, status: CompanyStatus) {
    return status && t(CompanyStatusI18N[status]);
}

function getCompanyStatusColor(mode: string, status: CompanyStatus) {
    if (mode === "light") {
        switch (status) {
            case CompanyStatus.OPERATOR:
                return blue[100]; // Hellblau
            case CompanyStatus.INVITED:
                return grey[200]; // Hellgrau
            case CompanyStatus.UNVERIFIED:
                return yellow[200]; // Gelb
            case CompanyStatus.CONFIRMED:
                return green[200]; // Grün
            case CompanyStatus.TERMINATION_RECEIVED:
                return orange[200]; // Orange
            case CompanyStatus.TERMINATED:
                return grey[400]; // Grau
            case CompanyStatus.REJECTED:
                return red[300]; // Rot
            case CompanyStatus.DEACTIVATED:
                return deepOrange[300]; // Dunkelorange
            case CompanyStatus.CONFIRMED_LOCKED:
                return deepOrange[200]; // Dunkelorange (heller)
            case CompanyStatus.TERMINATION_RECEIVED_LOCKED:
                return red[500]; // Dunkelrot
            default:
                return grey[200];
        }
    } else {
        switch (status) {
            case CompanyStatus.OPERATOR:
                return blue[800]; // Dunkelblau
            case CompanyStatus.INVITED:
                return grey[700]; // Dunkelgrau
            case CompanyStatus.UNVERIFIED:
                return yellow[700]; // Dunkelgelb
            case CompanyStatus.CONFIRMED:
                return green[700]; // Dunkelgrün
            case CompanyStatus.TERMINATION_RECEIVED:
                return orange[700]; // Dunkelorange
            case CompanyStatus.TERMINATED:
                return grey[600]; // Dunkelgrau
            case CompanyStatus.REJECTED:
                return red[600]; // Dunkelrot
            case CompanyStatus.DEACTIVATED:
                return deepOrange[700]; // Dunkles Dunkelorange
            case CompanyStatus.CONFIRMED_LOCKED:
                return deepOrange[600]; // Dunkles Dunkelorange
            case CompanyStatus.TERMINATION_RECEIVED_LOCKED:
                return red[800]; // Dunkelrot
            default:
                return grey[700];
        }
    }
}

export const CompanyStatusBadge = ({status, fullWidth}: { status?: CompanyStatus; fullWidth?: boolean }) => {
    const theme = useAppTheme();
    const {t} = useTranslation();
    return (
        status && (
            <Badge
                label={getCompanyStatusText(t, status)}
                backgroundColor={getCompanyStatusColor(theme.mode, status)}
                fullWidth={fullWidth}
            />
        )
    );
};

export const CompanyStatusBadgeField = ({status}: { status: CompanyStatus }) => (
    <GroupBox title="Status" paddingTop={9} paddingBottom={9}>
        <CompanyStatusBadge status={status} fullWidth/>
    </GroupBox>
);