export const startTimer = (
    timerRef: RefObject<any>,
    timeout: number,
    callback: (args: void) => void,
    repeat: boolean = false
) => {
    if (timerRef.current != null) {
        clearTimeout(timerRef.current);
    }
    timerRef.current = (repeat ? setInterval : setTimeout)(callback, timeout);
};

import { RefObject } from "react";

export const stopTimer = (timerRef: RefObject<any>) => {
    if (timerRef.current != null) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
    }
};
