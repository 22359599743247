/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { MailDTO, ServerInfoDTO, TimeControlDTO } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class AdminApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags admin-api
     * @name ExecuteCompoanyWatch
     * @request POST:/api/admin/execute-company-watch
     * @secure
     */
    executeCompoanyWatch = (params: RequestParams = {}) =>
        this.request<void, (string[] | Record<string, string>) | void>({
            path: `/api/admin/execute-company-watch`,
            method: "POST",
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags admin-api
     * @name ExecuteMailSender
     * @request POST:/api/admin/execute-mail-sender
     * @secure
     */
    executeMailSender = (params: RequestParams = {}) =>
        this.request<void, (string[] | Record<string, string>) | void>({
            path: `/api/admin/execute-mail-sender`,
            method: "POST",
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags admin-api
     * @name ExecuteRequestWatch
     * @request POST:/api/admin/execute-request-watch
     * @secure
     */
    executeRequestWatch = (params: RequestParams = {}) =>
        this.request<void, (string[] | Record<string, string>) | void>({
            path: `/api/admin/execute-request-watch`,
            method: "POST",
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags admin-api
     * @name GetMails
     * @request GET:/api/admin/mails-all
     * @secure
     */
    getMails = (params: RequestParams = {}) =>
        this.request<MailDTO[], (string[] | Record<string, string>) | void>({
            path: `/api/admin/mails-all`,
            method: "GET",
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags admin-api
     * @name GetServerInfo
     * @request GET:/api/admin/server-info
     * @secure
     */
    getServerInfo = (params: RequestParams = {}) =>
        this.request<ServerInfoDTO, (string[] | Record<string, string>) | void>({
            path: `/api/admin/server-info`,
            method: "GET",
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags admin-api
     * @name GetTimeControl
     * @request GET:/api/admin/time-control
     * @secure
     */
    getTimeControl = (params: RequestParams = {}) =>
        this.request<TimeControlDTO, (string[] | Record<string, string>) | void>({
            path: `/api/admin/time-control`,
            method: "GET",
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags admin-api
     * @name SetTimeControl
     * @request POST:/api/admin/time-control
     * @secure
     */
    setTimeControl = (
        query: {
            /** @format int32 */
            displacement: number;
        },
        params: RequestParams = {}
    ) =>
        this.request<TimeControlDTO, (string[] | Record<string, string>) | void>({
            path: `/api/admin/time-control`,
            method: "POST",
            query: query,
            secure: true,
            ...params
        });
}
