import { Grid2, LinearProgress } from "@mui/material";
import { ForwardedRef, forwardRef, useImperativeHandle, useState } from "react";
import { AxiosProgressEvent } from "axios";

/**
 * Props for the FileUploadInfo component.
 *
 * @property {File[]} files - The list of files to be uploaded.
 */
export type FileUploadInfoProps = {
    files: File[];
};

/**
 * API for the FileUploadInfo component.
 *
 * @property {(progressEvent: AxiosProgressEvent) => void} update - Function to update the upload progress.
 */
export type FileUploadInfoApi = {
    update: (progressEvent: AxiosProgressEvent) => void;
};

/**
 * FileUploadInfo component.
 *
 * @param {FileUploadInfoProps} props - The props for the component.
 * @param {ForwardedRef<FileUploadInfoApi>} ref - The ref to expose the update function.
 * @returns {React.ReactElement} The rendered FileUploadInfo component.
 */
export const FileUploadInfo = forwardRef((props: FileUploadInfoProps, ref: ForwardedRef<FileUploadInfoApi>) => {
    const [state, setState] = useState(
        props.files.map((file) => ({
            file,
            progress: 0
        }))
    );

    useImperativeHandle(ref, () => ({
        update: (progressEvent: AxiosProgressEvent) => {
            if (!progressEvent.total) {
                return;
            }
            Math.round((progressEvent.loaded * 100) / progressEvent.total);
            const bytesLoaded = progressEvent.loaded;
            let last = 0;
            setState(
                state.map((fp) => {
                    const p0 = bytesLoaded - last;
                    if (p0 <= 0) {
                        return fp;
                    }
                    last += fp.file.size;
                    const p1 = Math.min(fp.file.size, p0);
                    const progress = Math.round((p1 * 100) / fp.file.size);
                    return {
                        ...fp,
                        progress
                    };
                })
            );
        }
    }));

    return (
        <Grid2 container direction="column">
            <Grid2>
                <div>Datei(en) werden hochgeladen...</div>
            </Grid2>
            {state.map((fp) => (
                <Grid2 key={fp.file.name}>
                    <span>
                        <span>{fp.file.name}</span>
                        <LinearProgress variant="determinate" value={fp.progress} />
                    </span>
                </Grid2>
            ))}
        </Grid2>
    );
});

FileUploadInfo.displayName = "FileUploadInfo";
