import * as React from "react";
import { Grid2 as Grid } from "@mui/material";
import { CompanySignUpDTO } from "@/api/data-contracts";
import { ChangeHandler } from "@/lib/modelstate";
import { CardEx } from "@/lib/components/CardEx";
import StringInputField from "@/lib/components/inputfields/string-input-field";
import { Constants } from "@/api/constants";
import { useTranslation } from "react-i18next";
import { CompanyFormI18N } from "@/translations";

export const BankDetailsForm = ({
    model,
    errors,
    onChange
}: {
    model?: CompanySignUpDTO;
    onChange: ChangeHandler;
    errors: any;
}) => {
    const { t } = useTranslation();

    return (
        <CardEx title={t(CompanyFormI18N.bankConnectionTitle)}>
            <Grid container direction="column" spacing={3} width="100%">
                <Grid size={{ sm: 6, md: 4, lg: 3 }}>
                    <StringInputField
                        name="bankName"
                        value={model?.bankName}
                        label={t(CompanyFormI18N.bankNameLabel)}
                        error={errors?.bankName}
                        maxChars={Constants.BANKNAME_LENGTH_MAX}
                        onChange={onChange}
                        multiline={true}
                    />
                </Grid>
                <Grid size={{ sm: 6, md: 4, lg: 3 }}>
                    <StringInputField
                        name="iban"
                        value={model?.iban}
                        label={t(CompanyFormI18N.ibanLabel)}
                        error={errors?.iban}
                        maxChars={Constants.IBAN_LENGTH_MAX}
                        onChange={onChange}
                        multiline={true}
                    />
                </Grid>
                <Grid size={{ sm: 6, md: 4, lg: 3 }}>
                    <StringInputField
                        name="bic"
                        value={model?.bic}
                        label={t(CompanyFormI18N.bicLabel)}
                        error={errors?.bic}
                        maxChars={Constants.BIC_LENGTH_MAX}
                        onChange={onChange}
                        multiline={true}
                    />
                </Grid>
                <Grid size={{ sm: 12, md: 6, lg: 4 }}>
                    <StringInputField
                        name="paymentTerms"
                        value={model?.paymentTerms}
                        label={t(CompanyFormI18N.paymentTermsLabel)}
                        error={errors?.paymentTerms}
                        maxChars={Constants.PAYMENT_TERMS_LENGTH_MAX}
                        onChange={onChange}
                        multiline={true}
                    />
                </Grid>
            </Grid>
        </CardEx>
    );
};
