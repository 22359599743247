import * as React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { Grid2, PaletteMode, useTheme } from "@mui/material";
import { APP_NAME } from "@/constants";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { FooterI18N } from "@/translations";
import { getCurrentDate } from "@/lib/date-utils";

interface HeaderProps {
    mode: PaletteMode;
}

export const Footer = ({ mode }: HeaderProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    return (
        <Box paddingTop={2}>
            {theme.palette.mode === "dark" ? <Divider /> : null}
            <Grid2
                container
                padding={2}
                flexWrap="nowrap"
                direction="row"
                gap={4}
                sx={{
                    backgroundColor: theme.palette.background.default,
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px"
                }}
            >
                <Grid2 container direction="row" alignItems="center" gap={2}>
                    <Grid2>
                        <img
                            src="/boxhub-logo.jpg"
                            alt={`${APP_NAME} Logo`}
                            width="120"
                            style={mode === "dark" ? { filter: "invert(100%)" } : undefined}
                        />
                    </Grid2>
                    <Grid2>
                        <Typography fontSize="200%" marginTop={-2}>
                            {APP_NAME}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" marginTop={-1}>
                            {"Copyright ©"} {getCurrentDate().getFullYear()}
                        </Typography>
                    </Grid2>
                </Grid2>
                <Grid2
                    container
                    direction="row"
                    alignItems="start"
                    gap={2}
                    flexGrow={1}
                    justifyContent="space-evenly"
                    flexWrap="nowrap"
                >
                    <Grid2 container direction="column">
                        <Typography variant="body2" fontWeight={600}>
                            {t(FooterI18N.product)}
                        </Typography>
                        <Link color="text.secondary" href="#">
                            {t(FooterI18N.features)}
                        </Link>
                        <Link color="text.secondary" href="#">
                            {t(FooterI18N.testimonials)}
                        </Link>
                        <Link color="text.secondary" href="#">
                            {t(FooterI18N.highlights)}
                        </Link>
                        <Link color="text.secondary" href="#">
                            {t(FooterI18N.faqs)}
                        </Link>
                    </Grid2>
                    <Grid2 container direction="column">
                        <Typography variant="body2" fontWeight={600}>
                            {t(FooterI18N.company)}
                        </Typography>
                        <Link color="text.secondary" href="#">
                            {t(FooterI18N.aboutUs)}
                        </Link>
                        <Link color="text.secondary" href="#">
                            {t(FooterI18N.careers)}
                        </Link>
                        <Link color="text.secondary" href="#">
                            {t(FooterI18N.press)}
                        </Link>
                    </Grid2>
                    <Grid2 container direction="column">
                        <Typography variant="body2" fontWeight={600}>
                            {t(FooterI18N.legal)}
                        </Typography>
                        <Link color="text.secondary" href="#">
                            {t(FooterI18N.terms)}
                        </Link>
                        <Link color="text.secondary" href="#">
                            {t(FooterI18N.privacyPolicy)}
                        </Link>
                        <Link color="text.secondary" href="#">
                            {t(FooterI18N.termsOfService)}
                        </Link>
                        <Link color="text.secondary" href="#">
                            {t(FooterI18N.contact)}
                        </Link>
                    </Grid2>
                </Grid2>
            </Grid2>
        </Box>
    );
};
