import {QuoteDTO} from "@/api/data-contracts";
import {ModelAction} from "@/lib/modelstate";
import {getField, setField} from "@/lib/accessor";
import {calcTotalPrice} from "@/model/request";

export const quoteReducer = (model: QuoteDTO, action: ModelAction) => {
    switch (action.type) {
        case "setChatGroup":
            return {
                ...model,
                chatGroup: action.data
            };

        case "--onchange": {
            const field = action.data[0];
            switch (true) {
                case /orderData\.quantityOptions\[\w+]/.test(field): {
                    const match = field.match(/(orderData\.quantityOptions\[\d+])\./);
                    if (match) {
                        const quantityField = `${match[1]}.quantity`;
                        const netPricePer1000Field = `${match[1]}.netPricePer1000`;
                        const netPriceTotalField = `${match[1]}.netPriceTotal`;
                        model = setField(
                            model,
                            netPriceTotalField,
                            calcTotalPrice(getField(model, netPricePer1000Field), getField(model, quantityField))
                        );
                    }
                    return model;
                }
            }
            return model;
        }
        default:
            return model;
    }
};
