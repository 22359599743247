import * as yup from "yup";
import { UserDTO } from "@/api/data-contracts";
import { useMemo } from "react";
import { languageOptions, salutationOptions } from "@/datacaches";
import { Constants } from "@/api/constants";
import { EMAIL_REGEX, PHONE_NUMBER_REGEX } from "@/regex";
import { useTranslation } from "react-i18next";
import { UserFormI18N } from "@/translations";

export type UserEditDTO = Pick<
    UserDTO,
    "email" | "salutation" | "firstName" | "lastName" | "telephone" | "fax" | "language"
>;

export const useUserEditSchema = () => {
    const { t } = useTranslation();
    return useMemo(() => {
        const schema = yup.object().shape({
            email: yup
                .string()
                .required(t(UserFormI18N.requiredEmailError))
                .min(
                    Constants.EMAIL_LENGTH_MIN,
                    t(UserFormI18N.minEmailError, { minInteger: Constants.EMAIL_LENGTH_MIN })
                )
                .max(
                    Constants.EMAIL_LENGTH_MAX,
                    t(UserFormI18N.maxEmailError, { maxInteger: Constants.EMAIL_LENGTH_MAX })
                )
                .matches(EMAIL_REGEX, t(UserFormI18N.invalidEmailError)),

            salutation: yup
                .string()
                .required(t(UserFormI18N.requiredSalutationError))
                .oneOf(salutationOptions, t(UserFormI18N.invalidSalutationError)),

            firstName: yup
                .string()
                .required(t(UserFormI18N.requiredFirstNameError))
                .min(
                    Constants.FIRSTNAME_LENGTH_MIN,
                    t(UserFormI18N.minFirstNameError, { minInteger: Constants.FIRSTNAME_LENGTH_MIN })
                )
                .max(
                    Constants.FIRSTNAME_LENGTH_MAX,
                    t(UserFormI18N.maxFirstNameError, { maxInteger: Constants.FIRSTNAME_LENGTH_MAX })
                ),

            lastName: yup
                .string()
                .required(t(UserFormI18N.requiredLastNameError))
                .min(
                    Constants.LASTNAME_LENGTH_MIN,
                    t(UserFormI18N.minLastNameError, { minInteger: Constants.LASTNAME_LENGTH_MIN })
                )
                .max(
                    Constants.LASTNAME_LENGTH_MAX,
                    t(UserFormI18N.maxLastNameError, { maxInteger: Constants.LASTNAME_LENGTH_MAX })
                ),

            telephone: yup
                .string()
                .required(t(UserFormI18N.requiredPhoneError))
                .min(
                    Constants.PHONE_NUMBER_LENGTH_MIN,
                    t(UserFormI18N.minPhoneError, { minInteger: Constants.PHONE_NUMBER_LENGTH_MIN })
                )
                .max(
                    Constants.PHONE_NUMBER_LENGTH_MAX,
                    t(UserFormI18N.maxPhoneError, { maxInteger: Constants.PHONE_NUMBER_LENGTH_MAX })
                )
                .matches(PHONE_NUMBER_REGEX, t(UserFormI18N.invalidPhoneError)),

            fax: yup
                .string()
                .optional()
                .nullable()
                .transform((value) => (value === "" ? null : value))
                .min(
                    Constants.PHONE_NUMBER_LENGTH_MIN,
                    t(UserFormI18N.minFaxError, { minInteger: Constants.PHONE_NUMBER_LENGTH_MIN })
                )
                .max(
                    Constants.PHONE_NUMBER_LENGTH_MAX,
                    t(UserFormI18N.maxFaxError, { maxInteger: Constants.PHONE_NUMBER_LENGTH_MAX })
                )
                .matches(PHONE_NUMBER_REGEX, t(UserFormI18N.invalidFaxError)),

            language: yup
                .string()
                .required(t(UserFormI18N.requiredLanguageError))
                .oneOf(languageOptions, t(UserFormI18N.requiredLanguageError))
        });
        const typed = schema as yup.ObjectSchema<UserEditDTO>;
        return typed as yup.ObjectSchema<UserDTO>;
    }, [t]);
};
