import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { Grid2 } from "@mui/material";

const whiteLogos = [
    "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628e8573c43893fe0ace_Sydney-white.svg",
    "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d520d0517ae8e8ddf13_Bern-white.svg",
    "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f46794c159024c1af6d44_Montreal-white.svg",
    "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e891fa22f89efd7477a_TerraLight.svg",
    "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a09d1f6337b1dfed14ab_colorado-white.svg",
    "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5caa77bf7d69fb78792e_Ankara-white.svg"
];

const darkLogos = [
    "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628889c3bdf1129952dc_Sydney-black.svg",
    "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d4d8b829a89976a419c_Bern-black.svg",
    "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f467502f091ccb929529d_Montreal-black.svg",
    "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e911fa22f2203d7514c_TerraDark.svg",
    "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a0990f3717787fd49245_colorado-black.svg",
    "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5ca4e548b0deb1041c33_Ankara-black.svg"
];

export default function LogoCollection() {
    const theme = useTheme();
    const logos = theme.palette.mode === "light" ? darkLogos : whiteLogos;

    return (
        <Box id="logoCollection" sx={{ py: 4 }}>
            <Typography component="p" variant="subtitle2" align="center" color="text.secondary">
                Trusted by the best companies
            </Typography>
            <Grid2 container justifyContent="center" sx={{ mt: 0.5, opacity: 0.6 }}>
                {logos.map((logo, index) => (
                    <Grid2 key={logo} sx={{ mx: 2 }}>
                        <Box
                            component="img"
                            sx={{
                                width: 100,
                                height: 80
                            }}
                            alt={`Company logo ${index + 1}`}
                            src={logo}
                        />
                    </Grid2>
                ))}
            </Grid2>
        </Box>
    );
}
