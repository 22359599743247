import * as React from "react";
import { Header } from "@/app/Header";
import { Footer } from "@/app/Footer";
import { useAppTheme as useThemeContext } from "@/app/AppThemeContext";
import PageLoadingSpinner from "@/lib/components/PageLoadingSpinner";
import { AppBar, Container, Grid2, Typography, useTheme } from "@mui/material";
import { useLocalState } from "@/lib/localstate";
import { Layout, LayoutItem } from "@/lib/components/Layout";
import { UnsavedChangesDialog } from "@/lib/components/UnsavedChangesDialog";
import { useUnsavedChangesBlocker } from "@/lib/unsaved-changes-blocker";
import { ModelState } from "@/lib/modelstate";

export type AppFrameProps = {
    loading?: boolean;
    modelState?: ModelState;
    padding?: number;
    buttonsGrow?: boolean;
    title?: React.ReactNode;
    isDirty?: boolean;
    buttons?: React.ReactNode;
    children: React.ReactNode;
};

export const AppFrame = ({
    loading,
    modelState,
    title,
    buttons,
    buttonsGrow,
    padding,
    isDirty,
    children
}: AppFrameProps) => {
    const { mode, toggleColorMode } = useThemeContext();
    const theme = useTheme();
    const [expanded, setExpanded] = useLocalState("frame-expanded", false);
    const maxWidth: any = expanded ? "100%" : "xl";
    const toolbar = title || buttons;

    const blocker = useUnsavedChangesBlocker(isDirty);

    const busy = loading || modelState === "loading" || modelState === "saving";

    const blur = busy
        ? {
              "& > div > *": {
                  filter: "blur(2px)"
              }
          }
        : undefined;

    return (
        <Container disableGutters maxWidth={maxWidth} sx={{ position: "relative" }}>
            {busy && <PageLoadingSpinner />}
            <Layout root sx={blur}>
                <LayoutItem>
                    <Header
                        mode={mode}
                        toggleColorMode={toggleColorMode}
                        expanded={expanded}
                        setExpanded={setExpanded}
                    />
                </LayoutItem>
                {toolbar && (
                    <LayoutItem>
                        <AppBar
                            position="relative"
                            variant="outlined"
                            sx={{
                                backgroundColor: "transparent",
                                // backgroundColor: mode === "dark" ? "black" : "white",
                                color: theme.palette.text.primary,
                                // borderBottomLeftRadius: expanded ? undefined : 4,
                                // borderBottomRightRadius: expanded ? undefined : 4,
                                // zIndex: theme.zIndex.drawer,
                                linearGradient: "none",
                                paperShadow: "none",
                                border: "none"
                                // top: 64,
                            }}
                        >
                            <Grid2
                                container
                                paddingLeft={2}
                                paddingRight={2}
                                paddingTop={2}
                                paddingBottom={1}
                                // flexWrap="nowrap"
                            >
                                <Grid2 flexGrow={buttonsGrow ? undefined : 1}>
                                    <Typography variant="h5">{title}</Typography>
                                </Grid2>
                                <Grid2 flexGrow={buttonsGrow ? 1 : 0}>{buttons}</Grid2>
                            </Grid2>
                        </AppBar>
                    </LayoutItem>
                )}
                <LayoutItem body>
                    <Layout>
                        <LayoutItem grow padding={padding}>
                            {children}
                        </LayoutItem>
                        <LayoutItem>
                            <Footer mode={mode} />
                        </LayoutItem>
                    </Layout>
                </LayoutItem>
            </Layout>
            <UnsavedChangesDialog
                open={blocker.blocked} // Prevents second popup
                onConfirm={blocker.proceed}
                onCancel={blocker.reset}
            />
        </Container>
    );
};
