import React, { useEffect, useRef, useState } from "react";
import { Box, useTheme } from "@mui/material";

// Type definition for a ball
type Ball = {
    id: number;
    size: number;
    top: number;
    left: number;
    velocityX: number;
    velocityY: number;
    color: string;
};

const LightningBalls: React.FC = () => {
    const theme = useTheme();
    const ballsRef = useRef<Ball[]>([]);
    const ballsElementsRef = useRef<(HTMLDivElement | null)[]>([]);
    const requestRef = useRef<number>(undefined);

    const containerRef = useRef<HTMLDivElement | null>(null);
    const [containerWidth, setContainerWidth] = useState<number>(0);
    const [containerHeight, setContainerHeight] = useState<number>(0);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                const { width, height } = entry.contentRect;
                setContainerWidth(width);
                setContainerHeight(height);
            }
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            resizeObserver.disconnect();
            if (requestRef.current) {
                cancelAnimationFrame(requestRef.current);
            }
        };
    }, []);

    useEffect(() => {
        const numBalls = 20; // Adjust the number of balls as desired
        // Updated from red/violet shades to blue tone by using theme's colors
        const colors = [
            theme.palette.info.light,
            theme.palette.info.main,
            theme.palette.primary.light,
            theme.palette.primary.main,
            theme.palette.primary.dark
        ];
        const newBalls: Ball[] = [];

        for (let i = 0; i < numBalls; i++) {
            const size = Math.random() * (180 - 20) + 20;
            const top = Math.random() * (containerHeight - size);
            const left = Math.random() * (containerWidth - size);
            const velocityX = (Math.random() - 0.5) * 5; // Adjust speed here
            const velocityY = (Math.random() - 0.5) * 5;
            const color = colors[Math.floor(Math.random() * colors.length)];

            newBalls.push({ id: i, size, top, left, velocityX, velocityY, color });
        }

        ballsRef.current = newBalls;

        // Start animation after balls are initialized
        const updatePositions = () => {
            ballsRef.current.forEach((ball, index) => {
                let newTop = ball.top + ball.velocityY;
                let newLeft = ball.left + ball.velocityX;

                // Bounce off the container walls
                if (newTop <= 0 || newTop + ball.size >= containerHeight) {
                    ball.velocityY = -ball.velocityY;
                }
                if (newLeft <= 0 || newLeft + ball.size >= containerWidth) {
                    ball.velocityX = -ball.velocityX;
                }

                newTop = Math.max(0, Math.min(newTop, containerHeight - ball.size));
                newLeft = Math.max(0, Math.min(newLeft, containerWidth - ball.size));

                ball.top = newTop;
                ball.left = newLeft;

                // Update the DOM element's style directly
                const element = ballsElementsRef.current[index];
                if (element) {
                    element.style.transform = `translate(${newLeft}px, ${newTop}px)`;
                }
            });

            requestRef.current = requestAnimationFrame(updatePositions);
        };

        // Cancel any previous animation frame
        if (requestRef.current) {
            cancelAnimationFrame(requestRef.current);
        }
        requestRef.current = requestAnimationFrame(updatePositions);

        // Cleanup on component unmount
        return () => {
            if (requestRef.current) {
                cancelAnimationFrame(requestRef.current);
            }
        };
    }, [containerWidth, containerHeight, theme]);

    return (
        <Box
            ref={containerRef}
            sx={{
                position: "relative",
                width: "100%",
                height: "100%",
                overflow: "hidden"
            }}
        >
            {ballsRef.current.map((ball, index) => (
                <Box
                    key={ball.id}
                    ref={(el: HTMLDivElement | null) => {
                        ballsElementsRef.current[index] = el;
                    }}
                    sx={{
                        position: "absolute",
                        width: ball.size,
                        height: ball.size,
                        borderRadius: "50%",
                        opacity: 0.8,
                        backgroundColor: ball.color,
                        pointerEvents: "none",
                        willChange: "transform", // Hint to browser for smoother animations
                        transform: `translate(${ball.left}px, ${ball.top}px)`
                    }}
                />
            ))}
        </Box>
    );
};

export default LightningBalls;
