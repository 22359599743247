import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {Box, Collapse, Grid2} from "@mui/material";
import {ChangeHandler} from "@/lib/modelstate";
import {UserSignUpForm} from "@/pages/company-sign-up-page/steps/UserSignUpForm";
import {CompanyForm} from "@/pages/company-sign-up-page/steps/CompanyForm";
import Agreement from "@/pages/company-sign-up-page/steps/AgreementForm";
import {CompanySignUpDTO} from "@/api/data-contracts";
import {BankDetailsForm} from "@/pages/company-sign-up-page/steps/BankDetailsForm";
import {CompanyDetailsForm} from "@/pages/company-sign-up-page/steps/CompanyDetailsForm";
import {isEmpty} from "@/lib/accessor";
import {ButtonEx} from "@/lib/components/buttons/ButtonEx";
import {useTranslation} from "react-i18next";
import {AuthenticationPageI18N, GeneralI18N} from "@/translations";
import {fileApi} from "@/boxhub-api";
import {useSnackbarEx} from "@/lib/snackbarex";

export const CompanySignUpForm = ({
                                      model,
                                      errors,
                                      onChange,
                                      onSubmit,
                                      activeStep,
                                      setActiveStep,
                                      steps,
                                      token
                                  }: {
    model?: CompanySignUpDTO;
    onChange: ChangeHandler;
    errors: any;
    setErrors: Function;
    onSubmit?: () => void;
    activeStep: number;
    setActiveStep: (arg: (step: number) => number) => void;
    steps: string[];
    token: string;
}) => {
    const {t} = useTranslation();
    const {showMessage} = useSnackbarEx();

    // State for HTML content
    const [agbContent, setAgbContent] = useState<string>("Loading...");
    const [privacyContent, setPrivacyContent] = useState<string>("Loading...");
    const [confidentialityContent, setConfidentialityContent] = useState<string>("Loading...");
    const [termsContent, setTermsContent] = useState<string>("Loading...");

    const loadFile = useCallback(
        (filename: string, setContent: React.Dispatch<React.SetStateAction<string>>, errorI18n: string) => {
            fileApi
                .getHtmlFile(filename, {headers: {"invitation-token": token}})
                .then((res) => {
                    setContent(res.data as any);
                })
                .catch((error) => {
                    setContent(t(GeneralI18N.unknownError));
                    showMessage({summary: t(errorI18n), severity: "error", error});
                });
        },
        [showMessage, t, token]
    );
    useEffect(() => {
        loadFile("agb.html", setAgbContent, AuthenticationPageI18N.agbLoadingError);
        loadFile("privacy.html", setPrivacyContent, AuthenticationPageI18N.privacyPolicyLoadingError);
        loadFile(
            "confidentiality_agreement.html",
            setConfidentialityContent,
            AuthenticationPageI18N.confidentialityLoadingError
        );
        loadFile("terms_and_conditions.html", setTermsContent, AuthenticationPageI18N.termsAndConditionsLoadingError);
    }, [loadFile, showMessage, t]);

    const handleNext = () => {
        if (isEmpty(errors)) {
            if (activeStep === steps.length - 1) {
                // If we're at the final step and valid, trigger onSubmit if provided
                if (onSubmit) {
                    onSubmit();
                }
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <>
            {steps.map((step, stepIndex) => (
                <Collapse key={stepIndex} in={activeStep === stepIndex} timeout="auto" unmountOnExit>
                    <Box sx={{mt: 2}}>
                        {stepIndex === 0 && <UserSignUpForm model={model} onChange={onChange} errors={errors}/>}
                        {stepIndex === 1 && <CompanyForm model={model} onChange={onChange} errors={errors}/>}
                        {stepIndex === 2 && <CompanyDetailsForm model={model} onChange={onChange} errors={errors}/>}
                        {stepIndex === 3 && <BankDetailsForm model={model} onChange={onChange} errors={errors}/>}
                        {stepIndex === 4 && (
                            <Agreement
                                name="termsAccepted"
                                title={t(AuthenticationPageI18N.agreementTermsTitle)}
                                agreementText={agbContent}
                                value={model?.termsAccepted}
                                onChange={onChange}
                                error={errors?.termsAccepted}
                            />
                        )}
                        {stepIndex === 5 && (
                            <Agreement
                                name="privacyPolicyAccepted"
                                title={t(AuthenticationPageI18N.privacyPolicyLabel)}
                                agreementText={privacyContent}
                                value={model?.privacyPolicyAccepted}
                                onChange={onChange}
                                error={errors?.privacyPolicyAccepted}
                            />
                        )}
                        {stepIndex === 6 && (
                            <Agreement
                                name="confidentialityAccepted"
                                title={t(AuthenticationPageI18N.confidentialityAgreementLabel)}
                                agreementText={confidentialityContent}
                                value={model?.confidentialityAccepted}
                                onChange={onChange}
                                error={errors?.confidentialityAccepted}
                            />
                        )}
                        {stepIndex === 7 && (
                            <Agreement
                                name="contractAndUsageAccepted"
                                title={t(AuthenticationPageI18N.termsAndConditionsLabel)}
                                agreementText={termsContent}
                                value={model?.contractAndUsageAccepted}
                                onChange={onChange}
                                error={errors?.contractAndUsageAccepted}
                            />
                        )}
                    </Box>
                </Collapse>
            ))}
            <Grid2 container direction="row" justifyContent="space-between" paddingTop={2} width="100%">
                <Grid2>
                    {activeStep !== 0 && (
                        <ButtonEx
                            label={t(AuthenticationPageI18N.backButton)}
                            variant="contained"
                            onClick={handleBack}
                        />
                    )}
                </Grid2>
                <Grid2>
                    <ButtonEx
                        label={
                            activeStep === steps.length - 1
                                ? t(AuthenticationPageI18N.finishButton)
                                : t(AuthenticationPageI18N.continueButton)
                        }
                        variant="contained"
                        onClick={handleNext}
                        sx={{ml: "auto"}}
                        disabled={!isEmpty(errors)}
                    />
                </Grid2>
            </Grid2>
        </>
    );
};
