import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid2, Typography} from "@mui/material";
import {TimeControlDTO} from "@/api/data-contracts";
import {adminApi} from "@/boxhub-api";
import StringInputField from "@/lib/components/inputfields/string-input-field";
import dayjs from "dayjs";
import {DateFormatter} from "@/lib/components/DateFormate";
import {useSnackbarEx} from "@/lib/snackbarex";
import {setGlobalDateDisplacement} from "@/lib/date-utils";
import {ButtonEx} from "@/lib/components/buttons/ButtonEx";
import NumberInputField from "@/lib/components/inputfields/number-input-field";

interface AdminDialogProps {
    open: boolean;
    onClose: () => void;
}

export const AdminDialog: React.FC<AdminDialogProps> = ({open, onClose}: AdminDialogProps) => {
    const dateFormat = useMemo(() => DateFormatter(), []);
    const [timeControl, setTimeControl] = useState<TimeControlDTO>();
    const {showMessage} = useSnackbarEx();

    const loadTimeControl = useCallback(() => {
        adminApi
            .getTimeControl()
            .then((res) => {
                setTimeControl(res.data);
                setGlobalDateDisplacement(res.data.displacement);
            })
            .catch((error) => showMessage({summary: "Laden TimeControl gescheitert", severity: "error", error}));
    }, [showMessage]);

    useEffect(() => {
        loadTimeControl();
    }, [loadTimeControl]);

    const timeControlPanel = timeControl && (
        <Grid2 container>
            <Grid2 size={{sm: 12}}>
                <Typography>Time Control</Typography>
            </Grid2>
            <Grid2 size={{sm: 4}}>
                <StringInputField label="Datum" value={dayjs(timeControl.today).format(dateFormat)}/>
            </Grid2>
            <Grid2 size={{sm: 3}}>
                <NumberInputField
                    label="Zeitversatz in Tagen"
                    name="displacement"
                    value={timeControl.displacement}
                    onChange={(e) => setTimeControl({...timeControl, displacement: e.value})}
                    min={0}
                    max={100}
                    endAdornment={
                        <ButtonEx
                            label="Setzen"
                            onClick={() => {
                                adminApi
                                    .setTimeControl({
                                        displacement: timeControl?.displacement ?? 0
                                    })
                                    .then(() => loadTimeControl())
                                    .catch((error) =>
                                        showMessage({
                                            summary: "Setzen gescheitert",
                                            severity: "error",
                                            error
                                        })
                                    );
                            }}
                        />
                    }
                />
            </Grid2>
        </Grid2>
    );

    return (
        <Dialog data-cy={"admin-dialog"} open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Admin Control</DialogTitle>
            <DialogContent>
                <Grid2 container direction="column" padding={1} spacing={3} width="100%">
                    {timeControlPanel}
                    <Grid2 size={{sm: 12}}>
                        <Typography>Job Control</Typography>
                    </Grid2>
                    <Grid2 container spacing={2} direction="row" width="100%">
                        <Grid2>
                            <ButtonEx
                                label="Anfrage-Watch-Job auslösen"
                                variant="contained"
                                color="warning"
                                onClick={() => {
                                    adminApi
                                        .executeRequestWatch()
                                        .then(() =>
                                            showMessage({
                                                summary: "Request Watcher ausgelöst",
                                                severity: "info"
                                            })
                                        )
                                        .catch((error) =>
                                            showMessage({
                                                summary: "Request Watcher auslösen gescheitert",
                                                severity: "error",
                                                error
                                            })
                                        );
                                }}
                            />
                        </Grid2>
                        <Grid2>
                            <ButtonEx
                                label="Mail-Send-Job auslösen"
                                variant="contained"
                                color="warning"
                                onClick={() => {
                                    adminApi
                                        .executeMailSender()
                                        .then(() =>
                                            showMessage({
                                                summary: "Mail Sender ausgelöst",
                                                severity: "info"
                                            })
                                        )
                                        .catch((error) =>
                                            showMessage({
                                                summary: "Mail Sender auslösen gescheitert",
                                                severity: "error",
                                                error
                                            })
                                        );
                                }}
                            />
                        </Grid2>
                        <Grid2>
                            <ButtonEx
                                label="Firmen-Watch-Job auslösen"
                                variant="contained"
                                color="warning"
                                onClick={() => {
                                    adminApi
                                        .executeCompoanyWatch()
                                        .then(() =>
                                            showMessage({
                                                summary: "Firmen-Watch-Job ausgelöst",
                                                severity: "info"
                                            })
                                        )
                                        .catch((error) =>
                                            showMessage({
                                                summary: "Firmen-Watch-Job auslösen gescheitert",
                                                severity: "error",
                                                error
                                            })
                                        );
                                }}
                            />
                        </Grid2>
                    </Grid2>
                </Grid2>
            </DialogContent>
            <DialogActions sx={{display: "flex", justifyContent: "flex-end", padding: 2, gap: 2}}>
                <ButtonEx
                    label="Schließen"
                    data-cy="close-button"
                    onClick={onClose}
                    variant="outlined"
                    color="secondary"
                />
            </DialogActions>
        </Dialog>
    );
};
