import dayjs from "dayjs";
import { LabelValue } from "@/lib/components/LabelValueList";

/**
 * Generates a field object for a date range with optional formatting and tooltip.
 *
 * @param {string | undefined} startDate - The start date of the range. If undefined, a default "not available" message is used.
 * @param {string | undefined} endDate - The end date of the range. If undefined, a default "not available" message is used.
 * @param {string} label - The label for the field.
 * @param {string} [defaultValue=""] - The default value.
 * @param {string} [dateFormat] - The format to use for the dates.
 * @param {string} [tooltipKey] - The key for the tooltip text.
 * @returns {LabelValue} The field object with label, value, and tooltipKey properties.
 */
export const dateRangeField = (
    startDate: string | undefined | null,
    endDate: string | undefined | null,
    label: string,
    defaultValue: string = "",
    dateFormat?: string,
    tooltipKey?: string
): LabelValue => ({
    label,
    value:
        startDate && endDate
            ? `${dayjs(startDate).format(dateFormat)} - ${dayjs(endDate).format(dateFormat)}`
            : defaultValue,
    tooltipKey
});
