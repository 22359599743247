"use client";
import React, { useEffect, useState } from "react";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid2,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    useTheme
} from "@mui/material";
import { QuantityOptionDTO, QuoteDTO, QuoteStatus, RequestActions, RequestDTO } from "@/api/data-contracts";
import { getI18n, useTranslation } from "react-i18next";
import { TOOLTIP_DELAY_MS } from "@/constants";
import { DateFormatter } from "@/lib/components/DateFormate";
import { stringField } from "@/common/fields/string-field";
import { dateRangeField } from "@/common/fields/date-range-field";
import { dateField } from "@/common/fields/date-field";
import { integerField } from "@/common/fields/integer-field";
import { booleanField } from "@/common/fields/boolean-field";
import { stringArrayField } from "@/common/fields/string-array-field";
import { internalDimensionsField } from "@/common/request-details/internal-dimensions-field";
import { printField } from "@/common/request-details/print-field";
import { currencyField } from "@/common/fields/currency-field";
import { withAuthWrapperComponent } from "@/app/WithAuthWrapperComponent";
import { useAppTheme } from "@/app/AppThemeContext";
import { useSnackbarEx } from "@/lib/snackbarex";
import { GeneralI18N, NumberOfPalletI18N, PalletTypeI18N, PrintTypeI18N, RequestKeyWordsI18N } from "@/translations";
import { IconButtonEx } from "@/lib/components/buttons/IconButtonEx";
import { Close, Fullscreen } from "@mui/icons-material";
import { ButtonEx } from "@/lib/components/buttons/ButtonEx";
import { blue, brown, lightBlue } from "@mui/material/colors";
import { RequestStatusBadge } from "@/common/RequestStatusBadge";
import { QuoteStatusBadge } from "@/common/QuoteStatusBadge";
import { formatCurrency, formatInteger } from "@/lib/utils";

const tableCellContentNoWrap = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block"
};

const Prices = ({ quantityOptions, t }: { quantityOptions: QuantityOptionDTO[]; t: any }) => {
    if (quantityOptions == null) {
        return null;
    }
    return (
        <Table sx={{ "& td, & th": { px: 2, py: 0.5, border: "1px solid", textAlign: "right" } }}>
            <TableHead>
                <TableRow>
                    <TableCell>Menge</TableCell>
                    <TableCell>Preis/1000</TableCell>
                    <TableCell>Gesamt</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {quantityOptions.map((option, index) => (
                    <TableRow key={index}>
                        <TableCell>{formatInteger(option.quantity)}</TableCell>
                        <TableCell>{formatCurrency(option.netPricePer1000)}</TableCell>
                        <TableCell>{formatCurrency(option.netPriceTotal)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

const QuoteMatrixDialog = ({
    open,
    close,
    model,
    setQuote
}: {
    open: boolean;
    close: () => void;
    model: RequestDTO;
    setQuote: (quote: QuoteDTO) => void;
}) => {
    const { t } = useTranslation();
    const appTheme = useAppTheme();

    const theme = useTheme();

    const locale = getI18n().language;
    const dateFormat = DateFormatter();

    const [fullScreen, setFullScreen] = useState(false);

    const { showMessage } = useSnackbarEx();

    const quotes = model.quotes ?? [];

    // Handler for the checkboxes
    /**
     * State to keep track of the selected quantity option index for each quote.
     * The state is an object where the key is the quote index and the value is the selected option index.
     * The value can be a number (the selected option index), undefined, or null.
     */
    const [selectedQuantityOptionIndices, setSelectedQuantityOptionIndices] = useState<{
        [key: number]: number | null;
    }>({});

    /**
     * Handles the change event for the checkboxes.
     * Toggles the selected quantity option index for a given quote.
     *
     * @param {number} quoteIndex - The index of the quote in the quotes array.
     * @param {number} optionIndex - The index of the selected quantity option.
     */
    const handleCheckboxChange = (quoteIndex: number, optionIndex: number) => {
        const quote = quotes[quoteIndex];
        const isModifiable =
            model?.allowedActions?.includes(RequestActions.CONFIRM_QUOTE) && quote.status === QuoteStatus.SUBMITTED;

        if (!isModifiable) {
            return;
        }

        setSelectedQuantityOptionIndices((prev) => ({
            ...prev,
            [quoteIndex]: prev[quoteIndex] === optionIndex ? null : optionIndex
        }));
    };

    useEffect(() => {
        if (quotes.length > 0) {
            const initialSelected: { [key: number]: number | null } = {};
            quotes.forEach((quote, index) => {
                const isModifiable =
                    model?.allowedActions?.includes(RequestActions.CONFIRM_QUOTE) &&
                    quote.status === QuoteStatus.SUBMITTED;

                if (!isModifiable && quote.orderData?.quantityOptionSelected) {
                    initialSelected[index] = quote.orderData.quantityOptionSelected;
                }
            });
            setSelectedQuantityOptionIndices(initialSelected);
        }
    }, [quotes, model?.allowedActions]);

    /**
     * Returns a table cell content wrapped in a Tooltip component.
     * The Tooltip displays the full value on hover.
     *
     * @param {string} value - The content to be displayed in the table cell.
     * @returns {JSX.Element} The table cell content wrapped in a Tooltip.
     */
    const getTableCellContent = React.useCallback(
        (value: string) => (
            <Tooltip title={value} enterDelay={TOOLTIP_DELAY_MS} enterNextDelay={TOOLTIP_DELAY_MS}>
                <span style={tableCellContentNoWrap}>{value}</span>
            </Tooltip>
        ),
        []
    );

    return (
        <Dialog open={open} onClose={close} fullWidth maxWidth="lg" scroll="paper" fullScreen={fullScreen}>
            <DialogTitle>
                <Grid2 container direction="row">
                    <Grid2 flexGrow={1}>
                        {t(RequestKeyWordsI18N.quoteOverviewButton, { boxhubNumber: model?.boxhubNumber })}
                    </Grid2>
                    <Grid2>
                        <IconButtonEx Icon={Fullscreen} onClick={() => setFullScreen(!fullScreen)} />
                    </Grid2>
                    <Grid2>
                        <IconButtonEx Icon={Close} onClick={() => close()} />
                    </Grid2>
                </Grid2>
            </DialogTitle>
            <DialogContent sx={{ padding: 2, overflow: "hidden" }}>
                <TableContainer
                    sx={{
                        width: "100%",
                        // height: "500px",
                        overflow: "auto",

                        "&>table": {
                            borderCollapse: "separate",
                            width: "max-content"
                        },
                        "&>table>thead>tr>th": {
                            backgroundColor: theme.palette.mode === "dark" ? blue[800] : blue[600],
                            position: "sticky",
                            height: 20,
                            top: 0,
                            zIndex: 12
                        },

                        "&>table>thead>tr>th:first-child,&>table>tbody>tr>td:first-child": {
                            position: "sticky",
                            minWidth: 320,
                            maxWidth: 320,
                            left: 0,
                            zIndex: 13,
                            textAlign: "right"
                        },
                        "&>table>thead>tr>th:nth-child(2), &>table>tbody>tr>td:nth-child(2)": {
                            position: "sticky",
                            left: 320,
                            zIndex: 13
                        },
                        "&>table>tbody>tr>td, &>table>thead>tr>th": {
                            border: "none",
                            width: 320,
                            maxWidth: 320,
                            minWidth: 320,
                            zIndex: 10
                        },
                        "& td.gap, & th.gap": {
                            width: 16,
                            maxWidth: 16,
                            minWidth: 16,
                            backgroundColor: theme.palette.background.paper
                        },
                        // "& td>div": {
                        //     marginLeft: 4,
                        //     marginRight: 4
                        // },
                        "&>table>tbody>tr:nth-child(odd) td:not(.gap)": {
                            backgroundColor: theme.palette.mode === "dark" ? brown[800] : lightBlue[50]
                        },
                        "&>table>tbody>tr:nth-child(even) td:not(.gap)": {
                            backgroundColor: theme.palette.mode === "dark" ? brown[900] : lightBlue[100]
                        }
                        // "& tr:hover, & tr:hover td": {
                        //     backgroundColor: theme.palette.mode === "dark" ? alpha(brown[900], 0.7) : yellow[400]
                        // }
                    }}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    style={{
                                        backgroundColor: theme.palette.background.paper,
                                        zIndex: 14
                                    }}
                                />
                                <TableCell className="gap" />
                                <TableCell>
                                    <Box>Meine Anfrage</Box>
                                </TableCell>
                                {quotes.map((quote) => (
                                    <>
                                        <TableCell className="gap" />
                                        <TableCell>
                                            <Grid2 container direction="row" spacing={2} alignItems="center">
                                                <Grid2 flexGrow={1}>
                                                    {getTableCellContent(
                                                        stringField(
                                                            quote.company?.name,
                                                            "companyName",
                                                            t(GeneralI18N.notGiven)
                                                        ).value
                                                    )}
                                                </Grid2>
                                                <ButtonEx
                                                    label="Anzeigen"
                                                    variant="contained"
                                                    size="small"
                                                    onClick={() => {
                                                        setQuote(quote);
                                                        close();
                                                    }}
                                                />
                                            </Grid2>
                                        </TableCell>
                                    </>
                                ))}
                                <TableCell className="gap" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{t(RequestKeyWordsI18N.deliveryDateRange)}</TableCell>
                                <TableCell className="gap" />
                                <TableCell>
                                    {getTableCellContent(
                                        dateRangeField(
                                            model?.deliveryDateFrom,
                                            model?.deliveryDateTo,
                                            "deliveryDateRange",
                                            undefined,
                                            dateFormat
                                        ).value
                                    )}
                                </TableCell>
                                {quotes.map((quote) => (
                                    <>
                                        <TableCell className="gap" />
                                        <TableCell key={quote.id}>
                                            {getTableCellContent(
                                                dateField(
                                                    quote.orderData?.deliveryDate,
                                                    "deliveryDateLabel",
                                                    undefined,
                                                    dateFormat
                                                ).value
                                            )}
                                        </TableCell>
                                    </>
                                ))}
                                <TableCell className="gap" />
                            </TableRow>

                            <TableRow>
                                <TableCell>{t(RequestKeyWordsI18N.variety)}</TableCell>
                                <TableCell className="gap" />
                                <TableCell>
                                    {getTableCellContent(stringField(model?.orderData?.cartonGrade, "variety").value)}
                                </TableCell>
                                {quotes.map((quote) => (
                                    <>
                                        <TableCell className="gap" />
                                        <TableCell key={quote.id}>
                                            {getTableCellContent(
                                                stringField(quote.orderData?.cartonGrade, "variety").value
                                            )}
                                        </TableCell>
                                    </>
                                ))}
                                <TableCell className="gap" />
                            </TableRow>

                            <TableRow>
                                <TableCell>{t(RequestKeyWordsI18N.fefcoCode)}</TableCell>
                                <TableCell className="gap" />
                                <TableCell>
                                    {getTableCellContent(stringField(model?.orderData?.fefcoCode, "fefcoCode").value)}
                                </TableCell>
                                {quotes.map((quote) => (
                                    <>
                                        <TableCell className="gap" />
                                        <TableCell key={quote.id}>
                                            {getTableCellContent(
                                                stringField(quote.orderData?.fefcoCode, "fefcoCode").value
                                            )}
                                        </TableCell>
                                    </>
                                ))}
                                <TableCell className="gap" />
                            </TableRow>

                            <TableRow>
                                <TableCell>{t(RequestKeyWordsI18N.palletType)}</TableCell>
                                <TableCell className="gap" />
                                <TableCell>
                                    {getTableCellContent(
                                        stringField(
                                            model?.orderData?.palletType &&
                                                t(PalletTypeI18N[model?.orderData?.palletType]),
                                            "palletType"
                                        ).value
                                    )}
                                </TableCell>
                                {quotes.map((quote) => (
                                    <>
                                        <TableCell className="gap" />
                                        <TableCell key={quote.id}>
                                            {getTableCellContent(
                                                stringField(
                                                    quote.orderData?.palletType &&
                                                        t(PalletTypeI18N[quote.orderData?.palletType]),
                                                    "palletType"
                                                ).value
                                            )}
                                        </TableCell>
                                    </>
                                ))}
                                <TableCell className="gap" />
                            </TableRow>

                            <TableRow>
                                <TableCell>{t(RequestKeyWordsI18N.palletHeight)}</TableCell>
                                <TableCell className="gap" />
                                <TableCell>
                                    {getTableCellContent(
                                        integerField(model?.orderData?.palletHeight, "palletHeight", undefined, locale)
                                            .value
                                    )}
                                </TableCell>
                                {quotes.map((quote) => (
                                    <>
                                        <TableCell className="gap" />
                                        <TableCell key={quote.id}>
                                            {getTableCellContent(
                                                integerField(
                                                    quote.orderData?.palletHeight,
                                                    "palletHeight",
                                                    undefined,
                                                    locale
                                                ).value
                                            )}
                                        </TableCell>
                                    </>
                                ))}
                                <TableCell className="gap" />
                            </TableRow>

                            <TableRow>
                                <TableCell>{t(RequestKeyWordsI18N.numberOfPalletLabel)}</TableCell>
                                <TableCell className="gap" />
                                <TableCell>
                                    {getTableCellContent(
                                        stringField(
                                            model?.orderData?.numberOfPalletLabel &&
                                                t(NumberOfPalletI18N[model?.orderData?.numberOfPalletLabel]),
                                            "numberOfPalletLabel"
                                        ).value
                                    )}
                                </TableCell>
                                {quotes.map((quote) => (
                                    <>
                                        <TableCell className="gap" />
                                        <TableCell key={quote.id}>
                                            {getTableCellContent(
                                                stringField(
                                                    quote.orderData?.numberOfPalletLabel &&
                                                        t(NumberOfPalletI18N[quote.orderData?.numberOfPalletLabel]),
                                                    "numberOfPalletLabel"
                                                ).value
                                            )}
                                        </TableCell>
                                    </>
                                ))}
                                <TableCell className="gap" />
                            </TableRow>

                            <TableRow>
                                <TableCell>{t(RequestKeyWordsI18N.palletLabelNeutral)}</TableCell>
                                <TableCell className="gap" />
                                <TableCell>
                                    {getTableCellContent(
                                        booleanField(
                                            model?.orderData?.palletLabelNeutral,
                                            t(GeneralI18N.yes),
                                            t(GeneralI18N.no),
                                            "palletLabelNeutral"
                                        ).value
                                    )}
                                </TableCell>
                                {quotes.map((quote) => (
                                    <>
                                        <TableCell className="gap" />
                                        <TableCell key={quote.id}>
                                            {getTableCellContent(
                                                booleanField(
                                                    quote.orderData?.palletLabelNeutral,
                                                    t(GeneralI18N.yes),
                                                    t(GeneralI18N.no),
                                                    "palletLabelNeutral"
                                                ).value
                                            )}
                                        </TableCell>
                                    </>
                                ))}
                                <TableCell className="gap" />
                            </TableRow>

                            <TableRow>
                                <TableCell>{t(RequestKeyWordsI18N.closureType)}</TableCell>
                                <TableCell className="gap" />
                                <TableCell>
                                    {getTableCellContent(
                                        stringArrayField(
                                            [
                                                booleanField(
                                                    model?.orderData?.glued,
                                                    t(RequestKeyWordsI18N.Glue),
                                                    "",
                                                    ""
                                                ).value,
                                                booleanField(
                                                    model?.orderData?.stitched,
                                                    t(RequestKeyWordsI18N.Staple),
                                                    "",
                                                    ""
                                                ).value,
                                                booleanField(
                                                    model?.orderData?.taped,
                                                    t(RequestKeyWordsI18N.Tap),
                                                    "",
                                                    ""
                                                ).value
                                            ],
                                            ""
                                        ).value
                                    )}
                                </TableCell>
                                {quotes.map((quote) => (
                                    <>
                                        <TableCell className="gap" />
                                        <TableCell key={quote.id}>
                                            {getTableCellContent(
                                                stringArrayField(
                                                    [
                                                        booleanField(
                                                            quote.orderData?.glued,
                                                            t(RequestKeyWordsI18N.Glue),
                                                            "",
                                                            ""
                                                        ).value,
                                                        booleanField(
                                                            quote.orderData?.stitched,
                                                            t(RequestKeyWordsI18N.Staple),
                                                            "",
                                                            ""
                                                        ).value,
                                                        booleanField(
                                                            quote.orderData?.taped,
                                                            t(RequestKeyWordsI18N.Tap),
                                                            "",
                                                            ""
                                                        ).value
                                                    ],
                                                    ""
                                                ).value
                                            )}
                                        </TableCell>
                                    </>
                                ))}
                                <TableCell className="gap" />
                            </TableRow>

                            <TableRow>
                                <TableCell>{t(RequestKeyWordsI18N.palletizingFixedLabel)}</TableCell>
                                <TableCell className="gap" />
                                <TableCell>
                                    {getTableCellContent(
                                        booleanField(
                                            model?.orderData?.palletizingFixed,
                                            t(GeneralI18N.yes),
                                            t(GeneralI18N.no),
                                            "palletizingFixedLabel"
                                        ).value
                                    )}
                                </TableCell>
                                {quotes.map((quote) => (
                                    <>
                                        <TableCell className="gap" />
                                        <TableCell key={quote.id}>
                                            {getTableCellContent(
                                                booleanField(
                                                    quote.orderData?.palletizingFixed,
                                                    t(GeneralI18N.yes),
                                                    t(GeneralI18N.no),
                                                    "palletizingFixedLabel"
                                                ).value
                                            )}
                                        </TableCell>
                                    </>
                                ))}
                                <TableCell className="gap" />
                            </TableRow>

                            <TableRow>
                                <TableCell>{t(RequestKeyWordsI18N.palletizingNotesLabel)}</TableCell>
                                <TableCell className="gap" />
                                <TableCell>
                                    {getTableCellContent(
                                        stringField(model?.orderData?.palletizingNotes, "palletizingNotesLabel").value
                                    )}
                                </TableCell>
                                {quotes.map((quote) => (
                                    <>
                                        <TableCell className="gap" />
                                        <TableCell key={quote.id}>
                                            {getTableCellContent(
                                                stringField(quote.orderData?.palletizingNotes, "palletizingNotesLabel")
                                                    .value
                                            )}
                                        </TableCell>
                                    </>
                                ))}
                                <TableCell className="gap" />
                            </TableRow>

                            <TableRow>
                                <TableCell>{t(RequestKeyWordsI18N.internalDimensions)}</TableCell>
                                <TableCell className="gap" />
                                <TableCell>
                                    {getTableCellContent(
                                        internalDimensionsField(
                                            model?.orderData,
                                            t(RequestKeyWordsI18N.internalDimensions),
                                            "",
                                            locale
                                        ).value
                                    )}
                                </TableCell>
                                {quotes.map((quote) => (
                                    <>
                                        <TableCell className="gap" />
                                        <TableCell key={quote.id}>
                                            {getTableCellContent(
                                                internalDimensionsField(
                                                    quote.orderData,
                                                    t(RequestKeyWordsI18N.internalDimensions),
                                                    "",
                                                    locale
                                                ).value
                                            )}
                                        </TableCell>
                                    </>
                                ))}
                                <TableCell className="gap" />
                            </TableRow>

                            <TableRow>
                                <TableCell>{t(RequestKeyWordsI18N.externalPrintLabel)}</TableCell>
                                <TableCell className="gap" />
                                <TableCell>
                                    {getTableCellContent(
                                        printField("", {
                                            printType: model?.orderData?.externalPrint,
                                            printValue:
                                                model?.orderData?.externalPrint &&
                                                t(PrintTypeI18N[model?.orderData?.externalPrint]),
                                            numberOfColors: model?.orderData?.externalNumberOfColors
                                        }).value
                                    )}
                                </TableCell>
                                {quotes.map((quote) => (
                                    <>
                                        <TableCell className="gap" />
                                        <TableCell key={quote.id}>
                                            {getTableCellContent(
                                                printField("", {
                                                    printType: quote.orderData?.externalPrint,
                                                    printValue:
                                                        quote.orderData?.externalPrint &&
                                                        t(PrintTypeI18N[quote.orderData?.externalPrint]),
                                                    numberOfColors: quote.orderData?.externalNumberOfColors
                                                }).value
                                            )}
                                        </TableCell>
                                    </>
                                ))}
                                <TableCell className="gap" />
                            </TableRow>

                            <TableRow>
                                <TableCell>{t(RequestKeyWordsI18N.internalPrintLabel)}</TableCell>
                                <TableCell className="gap" />
                                <TableCell>
                                    {getTableCellContent(
                                        printField("", {
                                            printType: model?.orderData?.internalPrint,
                                            printValue:
                                                model?.orderData?.internalPrint &&
                                                t(PrintTypeI18N[model?.orderData?.internalPrint]),
                                            numberOfColors: model?.orderData?.internalNumberOfColors
                                        }).value
                                    )}
                                </TableCell>
                                {quotes.map((quote) => (
                                    <>
                                        <TableCell className="gap" />
                                        <TableCell key={quote.id}>
                                            {getTableCellContent(
                                                printField("", {
                                                    printType: quote.orderData?.internalPrint,
                                                    printValue:
                                                        quote.orderData?.internalPrint &&
                                                        t(PrintTypeI18N[quote.orderData?.internalPrint]),
                                                    numberOfColors: quote.orderData?.internalNumberOfColors
                                                }).value
                                            )}
                                        </TableCell>
                                    </>
                                ))}
                                <TableCell className="gap" />
                            </TableRow>

                            <TableRow>
                                <TableCell>{t(RequestKeyWordsI18N.stretching)}</TableCell>
                                <TableCell className="gap" />
                                <TableCell>
                                    {getTableCellContent(
                                        booleanField(
                                            model?.orderData?.stretching,
                                            t(GeneralI18N.yes),
                                            t(GeneralI18N.no),
                                            "stretching"
                                        ).value
                                    )}
                                </TableCell>
                                {quotes.map((quote) => (
                                    <>
                                        <TableCell className="gap" />
                                        <TableCell key={quote.id}>
                                            {getTableCellContent(
                                                booleanField(
                                                    quote.orderData?.stretching,
                                                    t(GeneralI18N.yes),
                                                    t(GeneralI18N.no),
                                                    "stretching"
                                                ).value
                                            )}
                                        </TableCell>
                                    </>
                                ))}
                                <TableCell className="gap" />
                            </TableRow>

                            {model.orderData.separateExtraCosts ? (
                                <>
                                    <TableRow>
                                        <TableCell>{t(RequestKeyWordsI18N.toolingCostsLabel)}</TableCell>
                                        <TableCell className="gap" />
                                        <TableCell>
                                            {getTableCellContent(
                                                currencyField(
                                                    model?.orderData?.toolingCosts,
                                                    "toolingCostsLabel",
                                                    undefined,
                                                    locale
                                                ).value
                                            )}
                                        </TableCell>
                                        {quotes.map((quote) => (
                                            <>
                                                <TableCell className="gap" />
                                                <TableCell key={quote.id}>
                                                    {getTableCellContent(
                                                        currencyField(
                                                            quote.orderData?.toolingCosts,
                                                            "toolingCostsLabel",
                                                            undefined,
                                                            locale
                                                        ).value
                                                    )}
                                                </TableCell>
                                            </>
                                        ))}
                                        <TableCell className="gap" />
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>{t(RequestKeyWordsI18N.clicheCostsLabel)}</TableCell>
                                        <TableCell className="gap" />
                                        <TableCell>
                                            {getTableCellContent(
                                                currencyField(
                                                    model?.orderData?.clicheCosts,
                                                    "clicheCostsLabel",
                                                    undefined,
                                                    locale
                                                ).value
                                            )}
                                        </TableCell>
                                        {quotes.map((quote) => (
                                            <>
                                                <TableCell className="gap" />
                                                <TableCell key={quote.id}>
                                                    {getTableCellContent(
                                                        currencyField(
                                                            quote.orderData?.clicheCosts,
                                                            "clicheCostsLabel",
                                                            undefined,
                                                            locale
                                                        ).value
                                                    )}
                                                </TableCell>
                                            </>
                                        ))}
                                        <TableCell className="gap" />
                                    </TableRow>
                                </>
                            ) : null}

                            <TableRow>
                                <TableCell>Preise</TableCell>
                                <TableCell className="gap" />
                                <TableCell>
                                    <Prices quantityOptions={model?.orderData?.quantityOptions} t={t} />
                                </TableCell>

                                {quotes.map((quote, quoteIndex) => (
                                    <>
                                        <TableCell className="gap" />
                                        <TableCell>
                                            <Prices quantityOptions={quote?.orderData?.quantityOptions} t={t} />
                                        </TableCell>
                                    </>
                                ))}
                                <TableCell className="gap" />
                            </TableRow>

                            <TableRow>
                                <TableCell>{t(RequestKeyWordsI18N.status)}</TableCell>
                                <TableCell className="gap" />
                                <TableCell>
                                    <RequestStatusBadge status={model?.status} fullWidth />
                                </TableCell>
                                {quotes.map((quote) => (
                                    <>
                                        <TableCell className="gap" />
                                        <TableCell key={quote.id}>
                                            <QuoteStatusBadge status={quote.status} fullWidth />
                                        </TableCell>
                                    </>
                                ))}
                                <TableCell className="gap" />
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
        </Dialog>
    );
};
export default withAuthWrapperComponent(QuoteMatrixDialog);
